// Define the chatbot flow with multiple languages
export default {
    'en-US': [
      {
        id: 1,
        text: [
          'Hello, I’m Nayomi.',
          'Welcome to the nurtur platform! We understand the unique challenges that come with being a mom-to-be and our platform is designed to support you through your pregnancy journey by providing a clinically proven protocol tailored to your needs.',
        ],
        options: [
          { text: 'Next', nextId: 2 }
        ],
      },
      {
        id: 2,
        text: 'By completing all the modules, you’ll be equipped with valuable insights and tools to navigate the complexities of the postpartum period with greater ease. Our program is based on a protocol proven to prevent postpartum depression by up to 53%.',
        options: [
          { text: 'Next', nextId: 3 }
        ],
      },
      {
        id: 3,
        text: 'Your well-being is our priority, so please don’t hesitate to reach out if you have any questions or need further assistance along the way. Let’s embark on this journey together and empower you to prioritize self-care while nurturing your growing family.',
        options: [
          { text: 'Next', nextId: 4 }
        ],
      },
      {
        id: 4,
        text: 'Let me know when you’re ready to get started.',
        options: [{ text: 'Next', nextId: 5 }],
      },
      {
        id: 5,
        text: [
          'Before we start today’s session, please let me ask a couple of questions to help me better understand how to better help you with any issues.',
          'Over the last 24 hours, how often have you been bothered by having little interest or pleasure in doing things?',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 6 },{ text: '1', nextId: 6 },{ text: '2', nextId: 6 },{ text: '3', nextId: 6 }],
      },
      {
        id: 6,
        text: [
          'Over the last 24 hours, how often have you been bothered by feeling down, depressed, or hopeless?',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 7 },{ text: '1', nextId: 7 },{ text: '2', nextId: 7 },{ text: '3', nextId: 7 }],
      },
      {
      id: 10,
        text: [
          'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
          'Little interest or pleasure in doing things? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 11 },{ text: '1', nextId: 11 },{ text: '2', nextId: 11 },{ text: '3', nextId: 11 }],
      },
      {
      id: 11,
        text: [
          'Feeling down, depressed, or hopeless? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 12 },{ text: '1', nextId: 12 },{ text: '2', nextId: 12 },{ text: '3', nextId: 12 }],
      },                        
      {
      id: 12,
        text: [
          'Trouble falling or staying asleep, or sleeping too much? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 13 },{ text: '1', nextId: 13 },{ text: '2', nextId: 13 },{ text: '3', nextId: 13 }],
      },
      {
      id: 13,
        text: [
          'Feeling tired or having little energy? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 14 },{ text: '1', nextId: 14 },{ text: '2', nextId: 14 },{ text: '3', nextId: 14 }],
      },
      {
      id: 14,
        text: [
          'Poor appetite or overeating? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 15 },{ text: '1', nextId: 15 },{ text: '2', nextId: 15 },{ text: '3', nextId: 15 }],
      },
      {
      id: 15,
        text: [
          'Feeling bad about yourself or that you are a failure or have let yourself or your family down? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 16 },{ text: '1', nextId: 16 },{ text: '2', nextId: 16 },{ text: '3', nextId: 16 }],
      },  
      {
      id: 16,
        text: [
          'Trouble concentrating on things, such as reading the newspaper or watching television? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 17 },{ text: '1', nextId: 17 },{ text: '2', nextId: 17 },{ text: '3', nextId: 17 }],
      },
      {
      id: 17,
        text: [
          'Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 19 },{ text: '1', nextId: 19 },{ text: '2', nextId: 19 },{ text: '3', nextId: 19 }],
      },
      {
      id: 19,
        text: [
          'Thank you for completing the survey!',
'Based on your score, we recommend that you seek professional help. Please contact your healthcare provider for further assistance. Or access the following help lines.',
'Postpartum Support International HelpLine: 1-800-944-4773 (available for calls and texts)', 
'Support for new and expecting parents struggling with perinatal mental health concerns.',
'National Maternal Mental Health Hotline: 1-833-TLC-MAMA (1-833-852-6262)',
'24/7 emotional support, resources, and counseling for pregnant and postpartum individuals.',
        ],
        options: [{ text: 'OK', nextId: 47 }],
      },
        {
      id: 47,
        text: [
          'Have you contacted the listed phone number?'
        ],
        options: [{ text: 'Yes', nextId: 7 },{ text: 'No', nextId: 19 }],
      },
              {
        id: 7,
        text: [
          'Thank you. Let’s get started with today’s session.',
          'Hello, I’m Nayomi, your virtual prenatal educator here. Congratulations on your pregnancy!',
        ],
        options: [{ text: 'Yes', nextId: 8 }],
      },
                  {
      id: 8,
        text: [
          'I’m here to support you through the ROSE Program, which stands for Reach Out, Stay Strong, Essentials. It’s a series of sessions designed to teach you skills to manage stress, especially after your baby arrives.',
        ],
        options: [{ text: 'OK', nextId: 20 }],
      },
  {
    id:20,text: ['Having a baby is a joyful time, but it can also bring new challenges. We want to help you get a good start for your baby and yourself. It may be helpful to set aside a specific time every day to complete these sessions.',
  'Before we begin, please limit any distractions to stay focused. On average, each session will take between 10 to 15 minutes so you can plan accordingly.',],
  options:[{text: 'OK', nextId: 21 }],
},    
  {
    id:21,text:[ 'Select next when you’re ready to continue.',],options:[{text: 'Next', nextId: 22 }],
},
  
  {id:22,text: ['Women are not all the same. Some women are overjoyed at having a baby and will say they have never been happier. Others may have mixed feelings- at times they feel “so happy’” and at other times “so overwhelmed.” And for some women it is very stressful. However, no-one talks about how hard it can be - the changes you will need to make and that becoming a parent can be one of the hardest jobs in your life - a job that comes with very little training.',],
  options:[{text: 'Next', nextId: 23}],},
  
  {id:23,text: ['If you think about it realistically - you have to put your own needs aside, you have to feed, rock, change diapers, clothes, not to mention the regular chores of a home - plus all your other roles as a partner, daughter, friend, worker. All this while you are exhausted from sleep deprivation and your body, and your hormones have gone haywire. Sounds like a form of torture - actually sleep deprivation is a well-known form of torture.What has happened to this wonderful time! No one talks about how difficult it can be. New mothers feel guilty to complain as they have the picture-perfect image of the new mother with her baby smiling filled with joy and energy.',],
  options:[{text: 'OK', nextId: 24}],},
  
  {id:24,text:['Did your mother ever share with you what it was like the first couple of months of having you as a baby?',],
  options:[{ text: 'Yes', nextId: 25 },{ text: 'No', nextId: 36 }],},
  
  {id:36,text:['That is normal and many women tend to not share that phase of their life based on how difficult it might has been.',
  'It is true that some women do not have a hard time, but most women if you ask lots of questions, will tell you of some difficulties during the first three months. I am going to share with you some realities - not to scare you, but to prepare you, and to make sure that you do not feel guilty, and blame yourself should you struggle once you have your baby.',
  'If you are unprepared, you may become alarmed, and this could increase your negative feelings. The more you know the better off you will be.'],options:[{ text: 'OK', nextId: 26 }],},
  
  {id:37,text:['It’s great to hear that you’ve had a relatively stable emotional journey during your pregnancy. Everyone’s experience is unique, and it’s wonderful that you’ve been feeling steady throughout this time. If you have any questions or need support at any point, feel free to reach out.',
  'Do you know the signs of depression?',],
  options:[{ text: 'Next', nextId: 38 }],},
  {id:38,text: ['It can be helpful for everyone to know the signs, whether they’ve experienced it personally or not. If you ever want to learn more about the signs or have any concerns about your mental health or that of someone else, feel free to ask. I’m here to provide information and support whenever you need it. Now, we’ll delve into postpartum depression, which is a more serious condition. New moms must be aware of this.',],options:[{ text: 'Next', nextId: 39 }],},
  
  {id:39,text: ['After the birth, postpartum depression is usually slow and gradual. It usually happens within the first two months, and you may start to feel you cannot take care of your baby in the right way, you think you are not a good mother, you feel badly about yourself, you feel a burden to others. There is significant difficulty in the ability to care for your baby, care for yourself, and cope with family relationships.',],options:[{ text: 'Next', nextId: 40 }],},
  {id:40,text:['About 10-15% of new mothers show symptoms of postpartum depression. This means one in seven women will experience postpartum depression. For women who are on public assistance or struggle financially it is more common: about one in three such women will experience postpartum depression. Women who have had previous episodes of depression are more likely to experience depression after childbirth. Those women who have had postpartum depression are also more likely to experience it again.',]
  ,options:[{ text: 'Next', nextId: 41 }],},
  {id:41,text:['The symptoms can begin gradually, but they can often build leaving a new mom feeling overwhelmed, hopeless, or frustrated. Some common thoughts may be “I cannot take care of my baby in the right way, I am not a good mother, I am a burden to others.',]
  ,options:[{ text: 'Next', nextId: 42 }],},
  {id:42,text:['While baby blues are temporary, postpartum depression is characterized by persistent negative emotions and a diminished ability to function. Symptoms typically last for at least two weeks.',],options:[{ text: 'Next', nextId: 43 }],},
  {id:43,text: ['Some common signs include feeling overwhelmed, hopeless, or guilty about caring for your baby. You might also experience difficulty sleeping or eating, or a loss of interest in activities you once enjoyed.',]
  ,options:[{ text: 'Next', nextId: 44 }],},
  {id:44,text:['It can be scary, but the important message is that postpartum depression is treatable. If you experience these symptoms, please reach out to your healthcare provider immediately.',]
  ,options:[{ text: 'Next', nextId: 31 }],},
  {id:25,text: ['I’m so glad to hear that your mom shared that with you.',
  'It is true that some women do not have a hard time, but most women if you ask lots of questions, will tell you of some difficulties during the first three months. I am going to share with you some realities - not to scare you, but to prepare you, and to make sure that you do not feel guilty, and blame yourself should you struggle once you have your baby. If you are unprepared, you may become alarmed, and this could increase your negative feelings. The more you know the better off you will be.',],options:[{ text: 'OK', nextId: 26 }],},
  {id:26,text: ['Let’s discuss the range of postpartum “blues”. Women do feel differently in that time after the birth of their baby. For some it is worse than for others, and lasts longer. As we discussed earlier, some women fall in love immediately with their baby are overjoyed and remain overjoyed, while others may feel very overwhelmed.',],options:[{ text: 'Next', nextId: 27 }],},
  {id:27,text: ['Most new mothers (about 30-80%) have the “baby blues.” Baby blues is usually two to five days after childbirth. You may experience periods of weeping for no reason, mood swings, be very sensitive, feel overwhelmed, irritable, and just plain exhausted. It is a very emotional time and you are caught off guard. You think I have a beautiful, healthy baby how can I feel like crying? This phase usually passes in a few days to a few weeks, as you and your body adjusts to your new situation. Usually, it lasts about 10 days.',],options:[{ text: 'Next', nextId: 28 }],},
  {id:28,text: ['Have you experienced any emotional changes during your pregnancy so far?',],options:[{ text: 'Yes', nextId: 29 },{ text: 'No', nextId: 37 }],},
  {id:29,text: ['Its natural you are feeling this and thanks for sharing. Now let’s talk about Postpartum Depression. Some new mothers have a more upsetting time and the difficulties we have discussed are more long-lasting and more intense. Postpartum depression is when the symptoms persist, nearly every day and there is no relief for at least two weeks.',
  'Do you know the signs of depression?',],options:[{ text: 'Yes', nextId: 30 },{ text: 'No', nextId: 38 }],},
  {id:30,text: ['Great to hear! Knowing the signs of depression can be crucial for understanding your mental health and seeking support when needed. If you have any questions about the signs or want to discuss them further, feel free to ask. I’m here to provide information and support. It’s great you’re aware of your emotions. We’ll discuss coping mechanisms for emotional changes in future sessions.',],options:[{ text: 'Next', nextId: 31 }],},
  {id:31,text: ['Before we wrap up this session, do you understand the difference between baby blues and postpartum depression?',],options:[{ text: 'Yes', nextId: 32 },{ text: 'No', nextId: 45 }],},
  {id:32,text: ['Great job! If you have any questions about the differences between baby blues and postpartum depression, feel free to ask. I’m here to help clarify any concerns you might have. As a reminder here are the symptoms you should look out for:',
  'BABY BLUES - 30-80% of people experience Baby Blues It usually occurs 2-5 days after delivery and usually goes away after about two weeks. Some symptoms are',
  'CryingMood swings',
  'Exhaustion',
  'Tension',
  'Anxiety',
  'Restlessness',],options:[{ text: 'Next', nextId: 33 }],},
  
  {id:33,text:['POSTPARTUM DEPRESSION SESSION Sleep problems (example: you cannot return to sleep after feeding the baby)',
  
  'Eating problems-eating too much or too little',
  'Anxiety and worry',
  'Avoiding people, avoiding contact with the baby, wanting to be on your own',
  'No energy',
  'Death wish, suicidal thoughts',
  'Difficulty having positive feelings towards the baby',
  'Difficulty making decisions',
  'Mania-feeling speedy, being excitable and irritable, talking fast, and having less need for sleep',
  'Panic attacks',
  'Fears for the baby, fantasies about harming or killing the baby',],options:[{ text: 'Next', nextId: 34 }],},
  {id:34,text: ['If you ever feel that you might hurt yourself, your baby or anyone else, please talk to your healthcare provider or call 911.',],options:[{ text: 'Next', nextId: 35 }],},
  {id:35,text: ['In the next session, we will help you understand postpartum depression risk factors, symptoms, and treatment options.',
  'Emphasizing normalcy of feelings, importance of seeking help, and strategies for coping and seeking support during the transition to motherhood.',],options:[{ text: 'Next', nextId: 48 }],},
  
  {id:45,text: ['No worries. Let’s quickly review it. The distinction between baby blues and postpartum depression lies in their duration, severity, and impact on daily functioning. Baby blues are typically mild, transient feelings of sadness and mood swings that occur within the first few weeks after childbirth and often resolve on their own.',]
  ,options:[{ text: 'OK', nextId: 46 }],},
  {id:46,text: ['On the other hand, postpartum depression is a more serious and longer-lasting condition characterized by persistent feelings of sadness, hopelessness, and anxiety that can significantly interfere with a mother’s ability to care for herself and her baby. It’s essential to recognize the signs and seek support if you or someone you know may be experiencing postpartum depression. Here is a list that could be helpful.',],options:[{ text: 'OK', nextId: 32 }]},
  
  
  // Session 2
  
  {id:48,text: ['Press OK if you would like to proceed to the session where we will go through relaxation exercises and role transitions.',],options:[{ text: 'OK', nextId: 49 },{ text: 'Repeat', nextId: 8 }],},
  
      {
      id: 49,
        text: [
          'Welcome to Session 2!!! Today we will delve into Relaxation Exercise and Role Transition.',
  'From research we know women who have had previous episode/s of depression, especially postpartum depression, are more likely to experience depression after childbirth. Also, those who have a history of depression or a family member with depression or mental health problems, and those who have a poor support system. What is important is that it can and should be treated. As a reminder here are the postpartum depression symptoms:',
  'Eating problems-eating too much or too little',
  'Anxiety and worry',
  'Avoiding people, avoiding contact with the baby, wanting to be on your own',
  'No energy',
  'Thoughts of suicide',
  'Difficulty having positive feelings towards the baby',
  'Difficulty making decisions',
  'Mania-feeling speedy, being excitable and irritable, talking fast, and having less need for sleep',
  'Panic attacks',
  'Fears for the baby, fantasies about harming or killing the baby'
        ],
        options: [{ text: 'OK', nextId: 62 }],
      },
      {
      id: 62,
        text: [
          'If you have any of the symptoms we have discussed, please discuss with your doctor, healthcare provider, or even your baby’s doctor and ask for a referral to a mental health professional, a therapist, counselor, social worker or a psychiatrist. Here are different resources and keep it handy: Maternal Mental Health Hotline Domestic Violence Parenting Legal Services Suicide Prevention',
        ],
        options: [{ text: 'OK', nextId: 63 }],
      },
      {
      id: 63,
        text: [
          'The most important message to take home with you from these sessions is that although you may have the "baby blues, or even depression after delivery, you should not be ashamed of these feelings, feel guilty, think I am a bad mother, or think there’s something so wrong with me.',
        ],
        options: [{ text: 'OK', nextId: 64 }],
      },
       {
      id: 64,
        text: [
          'You need to understand that many of these feelings are normal reactions to having a new baby. Remember: You are not alone, you are not to blame and you can feel better.',
        ],
        options: [{ text: 'OK', nextId: 65 }],
      },   
         {
      id: 65,
        text: [
          'Also, it is okay to talk about your difficulties. Don’t be embarrassed or afraid to discuss your feelings with friends, family and your healthcare provider. Actually, you will feel a lot better if you do talk to someone close to you about your feelings.',
  'Having a baby is a major event that affects the body and the mind. This all happens at a time when you are required to take care of a needy, helpless, human being, and with little sleep and often with very little outside help. In the next few sessions, we will be talking about ways to cope with the “roller-coaster ride” that you might experience after having your baby.',
        ],
        options: [{ text: 'OK', nextId: 66 }],
      },   
         {
      id: 66,
        text: [
          'I cannot smooth the road for you or take away some of the bad bumps. However, we will discuss ways for you to manage the blues or down times so that you do not spiral down into a depression.',
        ],
        options: [{ text: 'OK', nextId: 67 }],
      },   
         {
      id: 67,
        text: [
          'Our ROSE protocol approach is based on the idea that you are facing a very major transition. We know that this is going to take new skills and place new demands. One major issue when we face stress is that it can really help to have great support. Good support can reduce the likelihood that a woman will develop postpartum depression after delivery. Unfortunately, at the same time that you deserve incredible amounts of support, many women are surprised to realize that it is not so easy to find during this period.',
        ],
        options: [{ text: 'OK', nextId: 68 }],
      },   
             {
      id: 68,
        text: [
          'Demands of being with the baby can reduce time to spend with others socially, demands of the baby can be very hard on relationships, and others may simply not realize the types of support or help that would be nice for you. So, we at nurtur want to do everything we can to help you feel that you are getting as much support as you deserve.',
          'We cannot guarantee this, but we can certainly help think about your goals for support, and teach you some strategies to help in that area. We will focus on different ways to decrease stress in your life once your baby is here, and talk about supportive people in your life.'
        ],
        options: [{ text: 'OK', nextId: 69 }],
      },
             {
      id: 69,
        text: [
  'On a scale from 1 to 10 with 1 being no anxiety and 10 being high anxiety, where would you rate your anxiety level right now?'
        ],
        options: [{ text: '1', nextId: 70 },{ text: '2', nextId: 70 },{ text: '3', nextId: 70 },{ text: '4', nextId: 70 },{ text: '5', nextId: 70 },{ text: '6', nextId: 70 },{ text: '7', nextId: 70 },{ text: '8', nextId: 70 },{ text: '9', nextId: 70 },{ text: '10', nextId: 70 }],
      },
                 {
      id: 70,
        text: [
  'I’m going to provide you with the script for the exercise. I recommend practicing for 10-20 minutes daily. Regular practice will reinforce your relaxation skills, so you will be more likely to use it whenever you are in need of stress control.',
  'Let’s begin by finding a comfortable position, either sitting or lying down, whichever feels best for you. Take a deep breath in, and as you exhale, let go of any tension in your body.'
        ],
        options: [{ text: 'OK', nextId: 71 }],
      },
                     {
      id: 71,
        text: [
  'Now let’s start with a relaxation exercise called Progressive Muscle Relaxation.',
  '1. Finding ways to relax and manage stress can be very helpful when dealing with life’s stresses, in general, and dealing with stressful relationships or if you have difficulty falling sleep.',
  '2. Relaxation techniques also have been shown to reduce stress and tension. Progressive Muscle Relaxation is designed to relax the muscles in your body by teaching you to tense then relax various muscle groups.',
  '3. By first creating tension and then releasing that tension all at once, you can produce a better reduction in muscle tension. The sudden release of tension creates a special kind of energy which allows the muscles to relax beyond even normal resting levels.',
  '4. Move your attention to your calves and thighs. Tighten the muscles in your lower legs by pressing your heels into the ground, and then release. Feel the warmth and relaxation spreading through your legs.',
  '5. Next, tense the muscles in your buttocks by squeezing them tightly, and then let go. Notice the difference between tension and relaxation in your body.',
  '6. Now, focus on your stomach. Tighten your abdominal muscles by drawing your belly button towards your spine, and then release. Allow your breathing to become deep and natural.',
  '7. Move your attention to your chest and back. Take a deep breath in, filling your lungs with air, and hold for a moment. Now, exhale slowly and completely, releasing any tension in your chest and back muscles.',
  '8. Shift your focus to your shoulders. Shrug them up towards your ears, and then let them drop down, releasing any tension you may be holding. Feel the weight of your shoulders sinking into the surface below you.',
  '9. Now, move to your arms and hands. Make fists with your hands, squeezing tightly, and then release. Feel the tension leaving your arms as you allow them to become heavy and relaxed.',
  '10. Finally, focus on your face and neck. Scrunch up your face tightly, wrinkling your forehead and squinting your eyes, and then release. Let your jaw relax, allowing your lips to part slightly.',
  '11. Take a moment to scan your body from head to toe, noticing any remaining areas of tension.',
  '12. If you notice any tension, take a deep breath in, and as you exhale, imagine that tension melting away, leaving you feeling completely relaxed and at ease.',
  '13. Continue to breathe deeply and slowly for a few more moments, enjoying the sensation of relaxation in your body.',
  '14. When you’re ready, gently open your eyes and return to the present moment, feeling refreshed and rejuvenated.'
        ],
        options: [{ text: 'OK', nextId: 72 }],
      },
                 {
      id: 72,
        text: [
  'Now, on that same scale from 1 to 10, where would you rate your anxiety level? Display numbers from 1 to 10'
        ],
        options: [{ text: '1', nextId: 73 },{ text: '2', nextId: 73 },{ text: '3', nextId: 73 },{ text: '4', nextId: 73 },{ text: '5', nextId: 73 },{ text: '6', nextId: 73 },{ text: '7', nextId: 73 },{ text: '8', nextId: 73 },{ text: '9', nextId: 73 },{ text: '10', nextId: 73 }],
      },
                 {
      id: 73,
        text: [
  'Now, let’s move on to our main topic for today which is Role Transitions.',
  'Having a baby is going to change your life. Both good and bad changes can be stressful as both bring about new demands, new schedules.',
  'Think of how stressful a wedding can be for a bride. Big changes, particularly stressful ones, are often associated with depression. One of the high-risk periods for depression is when moms have very young children in the home.'
        ],
        options: [{ text: 'OK', nextId: 74 }],
      },
                {
      id: 74,
        text: [
  'The good news is that there is a great deal known about how to effectively make it through big changes. Today, we are going to guide you through some of the important tools in thinking about these types of major changes/transitions. I have information that previews the role transitions we’ll be discussing. Can you think of any other examples of changes you’ve experienced, the changes you had to make during a change in roles, and how you survived?']},
  
                {
      id: 75,
        text: [
  'I understand and acknowledge what you mean. Now let’s talk about changes that a new baby will bring in your life. By increasing our understanding, we can hope to do a better job in meeting our needs during this time of upheaval.'
        ],
        options: [{ text: 'OK', nextId: 76 }],
      },
                {
      id: 76,
        text: [
  'Let’s start by exploring some of the losses or things you might miss or have to change when the baby comes. What are some losses that come to your mind?'
        ]
      },
                {
      id: 77,
        text: [
  'I understand and acknowledge what you mean. Some examples of losses are:-',
  '1. Loss of routine, as a baby disrupts the 24-hour schedule',
  '2. Household chores not getting done',
  '3. Less time for partner, other children, friends, etc.',
  '4. Loss of productivity with laundry, shopping.',
  '5. Less opportunity to socialize leading to isolation.',
  '6. Loss of a sense of purpose being home for hours on end.',
  '7. Even loss of physical space, needing to share a room with baby or move out of current living situation.',
  '8. Isolation'
        ],
        options: [{ text: 'Next', nextId: 78 }],
      },   
  
                {
      id: 78,
        text: [
  'It’s important to acknowledge these losses. But let’s also explore the benefits and new opportunities you might gain as a new mother. What are some things you’re looking forward to or think might be positive about this change?'
        ]    },
  
                {
      id: 79,
        text: [
  'That’s great. Staying home with a new baby can allow you to:-',
  'Spend time with your baby.',
  'Watch the growth of your baby.',
  'It’s an opportunity to enjoy your child’s reactions to the world.',
  'Slow your pace, instead of leading a hectic life.',
  'You’ll learn more about yourself as a mother, may discover some hidden talents',
  'Nice excuse out of chores 😊'
        ],
        options: [{ text: 'Next', nextId: 80 }],
      },
      {
      id: 80,
        text: [
  'It’s normal to have a range of feelings with this change - anger, fear, insecurity. What are some of the feelings coming up for you as you think about these losses and gains?'
        ]    },
      {
      id: 81,
        text: [
  'Those are very understandable feelings. It’s important to acknowledge them and not feel guilty. You will still be able to enjoy life but you may need to do it in a different way. The most important rule in surviving motherhood is to do things to take care of your needs. Nurture the mother.'
        ],
        options: [{ text: 'Next', nextId: 82 }],
      },
        {
      id: 82,
        text: [
  'Being a mother is like being a pitcher of water - you keep pouring out, giving and giving as you take care of the needs around you - baby, family, friends, partner.',
  'If you do not take action and fill the pitcher up again, pretty soon it will be empty.',
  'No one is a bottomless pitcher. Being on empty will make you very vulnerable to depression. You cannot be a good parent if your needs are not met. Give yourself permission.'
        ],
        options: [{ text: 'OK', nextId: 83 }],
      },  
        {
      id: 83,
        text: [
  'Thoughts such as I am being cranky, weak, spoiled, selfish will keep jumping in your mind but you must correct yourself and think I am investing in myself for my baby/family. It is important to add positive activities and experiences when you are losing other positive things that made you feel good about yourself. It is natural that you will be less motivated, and tired at this time. But deliberately adding positive activities can reduce the negative feelings-it can energize you.'
        ],
        options: [{ text: 'Next', nextId: 84 }],
      },  
      {
      id: 84,
        text: [
  'There is a direct relationship between pleasant activities and mood, the more enjoyable things you do, the better you are likely to feel. But you have to give yourself permission to do this. In the next session we will discuss some of tjhe positive activities you could see yourself doing once you have the baby. Have a great day! We can chat tomorrow.'
        ],
        options: [{ text: 'OK', nextId: 85 }],
      },  
      {
      id: 85,
        text: [
  'Would you like to proceed ahead to the next session where we will talk about Enjoyable Activities and Close People. Or would you like to repeat the session'
        ],
        options: [{ text: 'Proceed', nextId: 86 },{ text: 'Repeat', nextId: 49 }],
      },
        {
      id: 86,
        text: [
'Welcome to Session 3!!! We will be discussing about Enjoyable Activities and Close People today.',
'What are some positive, pleasant activities you could see yourself doing once you have the baby? Even small things are important.'
        ],
      },
        {
      id: 87,
        text: [
'I understand and acknowledge what you mean. Pleasant activities can be as simple as reading comics, looking at old pictures, watching a favorite TV show, exercising, taking a bubble bath, lighting candles. The key is to treat yourself. You deserve it. Be your own best friend. This is an important time to take care of yourself.'
        ],
        options: [{ text: 'Next',nextId:101}],
      },
        {
      id: 101,
        text: [
'Rest time means doing no chores. Try to plan at least one event involving an adult each day, even just a phone call.',
'Avoid undertaking any major changes right now - better to tackle those when your hormones are more settled.',
'Keep your goals realistic - taking a shower is a great accomplishment in those early weeks! Remember, there’s no such thing as a super-mom.',
'You will make mistakes and that’s okay. Focus on being a “good enough” mother.'
        ],
        options: [{ text: 'OK',nextId:102}],
      },
        {
      id: 102,
        text: [
'It’s important to do things that make you feel confident and productive, even small things.',
'Give yourself credit for whatever you do accomplish - you will be amazed at what you actually do but often don’t give yourself credit for.'
        ],
        options: [{ text: 'OK',nextId:103}],
      },
        {
      id: 103,
        text: [
'Another key aspect is developing support. We know that strong support from other people can protect you from depression.',
'People who can confide in others and receive support are less likely to become depressed. Having people who care about you is critical in easing any distress you have after giving birth.',
'There is evidence that strong support in the face of major stress can significantly reduce the risk of depression. If you are hesitant to ask for help you may become easily overwhelmed.'
        ],
        options: [{ text: 'Next',nextId:104}],
      },
        {
      id: 104,
        text: [
'Now think deeply on these next set of questions and then type in your thoughts.',
'1. Who are some of the people you can ask for help?',
'2. Who do you feel close to, excluding your children?',
'3. Who do you think will still be there for you five years from now?',
'4. What type of help do you think you’ll need?'
],
      },
        {
      id: 105,
        text: [
'Sometimes reaching out doesn’t work. A family member or friend may be unable or unwilling to be supportive. You need not feel like you are a burden.',
'When you have a partner, husband, or family who are not there for you, that is a real problem. But you don’t have to blame yourself.',
'You have a right to reach out, to seek help and support. It is important to look for support from those who are receptive and caring, whoever they may be.',
'If they are not in your home or your present group of friends, then reach out to support groups, places of worship, professionals, etc.'
        ],
        options: [{ text: 'Next',nextId:106}],
      },
        {
      id: 106,
        text: [
'Unless you avoid relationships completely, you will likely experience some disappointment.',
'Be careful that you don’t reject everyone, especially when you are feeling stressed.',
'Most people have faults. In deciding who to accept - flaws and all, ask yourself, “Does the good outweigh the bad.',
'It is up to you to decide what types of faults you wish to accept.'],
        options: [{ text: 'Next',nextId:107}],
      },
        {
      id: 107,
        text: [
'Let’s think of “Close People” that can help you further identify sources of support. We can complete it together. Think about the following and then share your thoughts:',
'1. Who are the people in each area that you see the most often? To whom can you seek for help?',
'2. List 2 people from each category:-',
'-FAMILY',
'-WORK',
'-SPIRITUAL',
'-OTHER']
      },
        {
      id: 108,
        text: [
'Now that ‘close people’ are identified let’s talk about relationship communication - looking at how you communicate with loved ones, ways to improve communication especially around asking for help, and dealing with any abusive relationships.'],
        options: [{ text: 'Next',nextId:109}],
      },
        {
      id: 109,
        text: [
'TIPS FOR ASKING OTHERS FOR HELP TIMING:',
'Choose a quiet time. Introduce the fact that you want to talk about something. NONVERBAL BEHAVIOR:',
'Use an appropriate tone of voice, avoid threatening posture, make eye contact with the other person, face them directly. SAY SOMETHING POSITIVE:',
'Sandwich the negative between positives. Start with a positive and end with a positive.'],
        options: [{ text: 'Next',nextId:110}],
      },      
       {
      id: 110,
        text: [
'Your homework after this session is to make your own Top Twenty list of enjoyable activities. You can start with some ideas of pleasant activities:',
'Thinking about people I like',
'Seeing beautiful scenery',
'Breathing clean air',
'Watching people',
'Having a frank and open conversation',
'Sitting in the sun',
'Wearing clean clothes',
'Doing a project in my own way',
'Listening to music',
'Reading stories, novels, poems, or plays',
'Expressing my love to someone',
'Being with someone I love',
'Complimenting or praising someone',
'Listening to the radio',
'Seeing old friends',
'Soaking in the bathtub',
'Collecting things (coins, shells, etc.)',
'Going to a movie in the middle of the week',
'Laughing',
'Thinking about my past trips',
'Reading magazines or newspapers',
'Taking care of my plants',
'Singing around the house',
'Practicing religion (going to church, group praying, etc.)',
'Painting',
'Doing needlepoint, crewel, etc.',
'Sleeping',
'Doing arts and crafts',
'Making a gift for someone',
'Sewing',
'Going to the beauty parlor',
'Daydreaming',
'Watching TV',
'Thinking about pleasant events',
'Writing diary entries or letters',
'Meditating',
'Playing cards',
'Seeing and/or showing photos or slides',
'Knitting',
'Doing crossword puzzles',
'Dressing up and looking nice',
'Getting a massage',
'Making a jigsaw puzzle'],
        options: [{ text: 'Next',nextId:111}],
      },  
        {
      id: 111,
        text: [
'Hope these activities list will help you as you prepare your Top Twenty list of enjoyable activities.',
'Try to schedule at least three pleasant activities for yourself over the next week.',
'Note your mood on a scale of 1-5 before and after the activity.'],
        options: [{ text: 'Next',nextId:112}],
      },
        {
      id: 112,
        text: [
'Sometimes you might not feel like doing anything, but engaging in a pleasant activity can actually energize you and boost your mood.',
'Also remember to practice the relaxation exercises daily and rate your anxiety before and after.'],
        options: [{ text: 'Next',nextId:113}],
      }, 
         {
      id: 113,
        text: [
'We will be covering more items in the next sessions.'],
        options: [{ text: 'Next',nextId:114}],
      },        
         {
      id: 114,
        text: [
'Would you like to proceed to the next session where we will delve deeper into Relationships or repeat the current session?'],
        options: [{ text: 'Proceed',nextId:115},{ text: 'Repeat',nextId:86}],
      }, 
        {
      id: 115,
        text: ['Welcome back! We will be getting into Relationships today.',
        'Let’s review your homework a bit.',
'Were you able to influence your mood with the pleasant activities?Remember, the more enjoyable things you do, the better you are likely to feel. Pleasurable activities can really energize you.Did you get a chance to do specific pleasant activities since our last interaction?'
        ],
        options: [{ text: 'Yes', nextId: 129 },{ text: 'No', nextId: 129 }],},
    {
      id: 129,
        text: ['Was it easy for you to follow through?'
        ],
        options: [{ text: 'Yes', nextId: 130 },{ text: 'No', nextId: 130 }],},
    {
      id: 130,
        text: ['It’s okay if you weren’t able to complete the homework activity. I understand that life can be unpredictable, and sometimes things don’t go as planned.',
        'Remember, therapy is a journey, and it’s natural to encounter challenges along the way.','Your honesty about your experience is valuable and helps guide our work together.',
'Please take the time to list your Top Twenty activities so this will help you to feel better and this becomes a practice and prepares you well for the baby’s arrival.'
        ],
        options: [{ text: 'Next', nextId: 131 }],},
           {
      id: 131,
        text: ['How did you feel after completing the activities?'
        ]},
   {
      id: 132,
        text: ['I understand and acknowledge what you mean. Let’s have you commit to two or three more activities in the next few days:-',
'1. It’s so important to keep these “appointments” with yourself as faithfully as your most urgent appointments.',
'2. Today our main focus will be on relationships.',
'3. Relationships are difficult and often the people involved have very different needs and expectations.',
'3. A lot of work goes into them. People bring in their own hopes and dreams which don’t always match the other person’s.',
'4. Changes in life circumstances, like having a baby, add even greater and different pressures.'
        ],
        options: [{ text: 'Next', nextId: 133}],},
    {
      id: 133,
        text: ['There’s clear evidence that having a baby places a great deal of stress on partnerships.',
'Many experience more conflict because there are just so many more demands on parents to manage.'
        ],
        options: [{ text: 'Next', nextId: 134 }],},
    {
      id: 134,
        text: ['At the same time, having great support is amazingly protective against sadness and depression during stressful times.',
'So here it is, a time when extra support would be so valuable, and yet it’s harder for many new moms to find.'
        ],
        options: [{ text: 'Next', nextId: 135 }],},
   {
      id: 135,
        text: ['Our goal is to help you think about what you need and want during this time from the people in your life, and how to best manage asking for that extra bit of help you deserve.',
'All in a realistic way - we can’t get you a nanny, but maybe we can help you learn strategies for talking with your partner about new issues, getting support from family and friends, building networks with other new moms, and generally expressing what you need most effectively.'
        ],
        options: [{ text: 'Next', nextId: 136 }],},
   {
      id: 136,
        text: ['Now let’s discuss more on how to manage your relationships by educating you about the three kinds of interpersonal disputes or arguments.'
        ],
        options: [{ text: 'Next', nextId: 137 }],},
   {
      id: 137,
        text: ['Having a baby can strain relationships, making it more difficult for you to get the support you need to cope with distress and changes after giving birth.',
'It’s wise to start thinking now about your relationships - who will be there to help and give you the support you’ll need after the baby arrives.'
        ],
        options: [{ text: 'Next', nextId: 138 }],},
   {
      id: 138,
        text: ['When sizing up relationships, consider what problems you may have, if you want to make changes, and how problems are dealt with in the relationship.'
        ],
        options: [{ text: 'Next', nextId: 139 }],},
   {
      id: 139,
        text: ['Reflect on the following questions and then type in your thoughts:-',
'Write down the name of someone important to you and ask yourself:',
'1. How satisfying is the relationship overall? Is it supportive?',
'2. What changes would you like to see?',
'3. Does it feel safe to talk over concerns?',
'4. Do you think the other person wants to feel close?',
'5. Do you want to feel close to them?'
        ],
      },  
   {
      id: 140,
        text: ['If there’s a basic sense of trust and commitment, then you can figure out helpful ways to talk about problems.',
'How are problems dealt with in this relationship?',
'Are conflicts expressed?'
        ],
        options: [{ text: 'Next', nextId: 141 }],},
   {
      id: 141,
        text: ['Once you size things up, you have to decide if it’s a relationship you want to continue.',
'Sometimes problems can’t be worked out and there are more negatives than positives.',
'If you’ve made every effort to improve things and it hasn’t worked, it may be time to ask if it’s a relationship worth keeping.'
        ],
        options: [{ text: 'Next', nextId: 142 }],},
   {
      id: 142,
        text: ['There are three kinds of interpersonal disputes or arguments:-',
'1. Compromise - “give and take” where people are openly aware of differences and actively trying to bring about changes, even if unsuccessfully.',
'2. Silently resentful - discussion has stopped but resentment may continue bubbling under the surface.',
'3. Cannot be fixed - the relationship can’t be repaired and both parties are going their own way emotionally.'
        ],
        options: [{ text: 'Next', nextId: 143 }],},
   {
      id: 143,
        text: ['In the first kind, there needs to be give and take, reaching a compromise even when there’s no outright conflict.',
'Compromise may be needed when the other person’s behavior is causing you problems or upset, or when you find that what you’re doing is bothering them.'
        ],
        options: [{ text: 'Next', nextId: 144 }],},
   {
      id: 144,
        text: ['For example, once you have the baby, your needs and priorities will change but others may not be aware or may act in ways that are uncomfortable for you.',
'Perhaps your partner expects you to still do all the household chores or your family expects you to visit as often and help them out.'
        ],
        options: [{ text: 'Next', nextId: 145 }],},
   {
      id: 145,
        text: ['Other times there may be an ongoing fight where compromise is necessary, like if you’re fighting daily with the baby’s father about when he can and can’t see the baby.',
'In other relationships, people tend not to talk about concerns.',
'With no talking, resentment can bubble under the surface. The problem is still there negatively impacting the relationship. We call this silently resentful.'
        ],
        options: [{ text: 'Next', nextId: 146 }],},
   {
      id: 146,
        text: ['What are some reasons you might avoid talking about problems? This could be reasons related to family, personal life, other constraints or difficulties with communication.'
        ],},
   {
      id: 147,
        text: ['I understand acknowledge what you mean.',
'Those are very common reasons. In still other relationships, people try to talk about concerns but it might not always work.',
'So they’re talking but not fixing.',
'And sometimes, the relationship seems irreparable - it cannot be fixed and both parties are going their own way emotionally.'
        ],
        options: [{ text: 'Next', nextId: 148 }],},
   {
      id: 148,
        text: ['Think about the different interpersonal disputes we discussed.',
'As we wrap up don’t forget to prioritize two to three more pleasant activities in the next few days.',
'Next session we will discuss more about priorities and assertive communication.'
        ],
        options: [{ text: 'Next', nextId: 150 }],},
   {
      id: 150,
        text: ['Would you like proceed to the next session where we will discuss about Priorities,& Assertive Communication or repeat the current session?'
        ],
        options: [{ text: 'Proceed', nextId: 151 },{ text: 'Repeat', nextId: 115}],},
// Session 5
        {
      id: 151,
        text: ['Welcome back to Session 5 where we will discuss about Priorities,& Assertive Communication.',
'Let’s talk about priorities. Maintaining your priorities, or what’s most important to you, is something many find hard.',
'First, you need to sort out what’s more or less important, say no to some of the less important demands, and ask for help, express feelings and opinions, firmly say no, or ask someone to act differently.'
        ],
        options: [{ text: 'Next', nextId: 164 }],
      },
        {
      id: 164,
        text: ['For example, maybe you could babysit your brother’s toddler while pregnant, but now with your own baby you can’t do it regularly. You need to firmly say no.'
        ],
        options: [{ text: 'Next', nextId: 165 }],
      },
        {
      id: 165,
        text: ['Or maybe you could do everyone’s laundry before but now you need to ask for help in that area.',
        'There are also people you don’t want to reject but you need them to change their behavior.'
        ],
        options: [{ text: 'Next', nextId: 166 }],
      }, 
        {
      id: 166,
        text: ['Have you heard of the Bucket Principle? Many people don’t deal with irritations, they put up with what’s not worth changing.',
        'So they put their irritation in a bucket. These little things build up until the bucket is full.'
        ],
        options: [{ text: 'Next', nextId: 167 }],
      },   
        {
      id: 167,
        text: ['What happens when the next irritation is added? The bucket overflows. You explode or become very angry.',
'If you address small things and gently let people know what’s bothering you, you increase your chance of not getting mad and having the bucket overflow.'
        ],
        options: [{ text: 'Next', nextId: 168 }],
      },  
        {
      id: 168,
        text: ['Now reflect on the following questions and type in your responses:-',
'1. How easy is it for you to ask for things?',
'2. How well can you say no?',
'3. Does it differ with different people?',
'4. What does assertiveness mean to you?'
        ],
      },  
        {
      id: 169,
        text: ['I understand. Now let’s talk about assertivenes:-','1. Assertiveness is the ability to express your thoughts and feelings openly and directly to others, without turning them off.',
'2. Assertiveness skills allow us to set and enforce boundaries. You can feel more in control.'
        ],
        options: [{ text: 'Next', nextId: 170 }],
      },  
        {
      id: 170,
        text: ['You have the right to decide what to do instead of what someone else expects. When you’re assertive, you express your own needs, wants or feelings without violating others’ rights.',
'So you have to recognize that others have rights, as well as yourself.'
        ],
        options: [{ text: 'Next', nextId: 171 }],
      },  
        {
      id: 171,
        text: ['Let’s review some of your rights in relationships:-',
'1. You have the right to get support, to want or need something from someone else.',
'2. You have the right to accept or reject anything others ask of you or say to you.',
'3. You have the right to say NO.'
        ],
        options: [{ text: 'Next', nextId: 172 }],
      },  
        {
      id: 172,
        text: ['4. Being assertive is a skill you can learn. It may be difficult at first. Reaching out for support or setting limits involves taking risks.',
'5. Risk-taking involves self-acceptance - accepting that you have needs, that you have a right to those needs, that you’re vulnerable to another person.'
        ],
        options: [{ text: 'Next', nextId: 173 }],
      }, 
        {
      id: 173,
        text: ['6. Taking risks implies you may fail, make a mistake, or people may disapprove or reject you for your choices.',
'7. You need to accept yourself regardless of the outcome. You don’t become less worthwhile if you make a mistake, fail or get rejected.'
        ],
        options: [{ text: 'Next', nextId: 174 }],
      }, 
        {
      id: 174,
        text: ['8. You have the right to make decisions for yourself, regardless of what others think. Letting other people make decisions for you and judge your choices makes you feel less worthwhile.',
'9. You’re at their mercy if you let your self-worth be determined by others. People feel better about themselves by taking some risks, taking charge of their lives, and being assertive.'
        ],
        options: [{ text: 'Next', nextId: 175 }],
      }, 
        {
      id: 175,
        text: ['Think carefully about your most important adult relationship:-',
'1. What are the difficulties or concerns?',
'2. What are your needs right now?',
'3. What are your short-term and long-term goals for the relationship?',
'4. Are you experiencing conflict at any level we discussed, or dissatisfaction where the other person isn’t aware?',
'I’d like you to complete this:-',
'1. “Communicating with Loved Ones” for your most important adult relationship.',
'2. If after evaluating it, you feel changes are needed and the relationship can be fixed, there are effective ways to discuss those changes.',
'3. That’s where assertiveness comes in.'
        ],
        options: [{ text: 'Next', nextId: 176 }],
      }, 
        {
      id: 176,
        text: ['Think about a person that you’d like to change your communication style with: How is your communication style going with this person? (-10 to 0, communication style is going extremely badly; 1 to 10, extremely well).'
        ],
      }, 
        {
      id: 177,
        text: ['Now, think deeply on the next few questions:-',
        '1. What are the two best things about how you communicate with this person?',
'2. What kinds of things does this person say or do that make you happy?',
'3. What makes this person special to you?', 
'4. What are the two worst things about how you communicate with this person?',
'5. What kinds of things do they do that are hard on you?',
'6. What kinds of things do they forget to do? Do you feel like you get enough time/attention/caring from this person?'
        ],
        options: [{ text: 'Next', nextId: 178 }],
      }, 
       {
      id: 178,
        text: ['Interpersonal goals:-',
'1. What kinds of changes or support would you like from this person, other than what he/she/they does now?',
'2. It is ok to want a person to give more or less of something they already do.'
        ],
        options: [{ text: 'Next', nextId: 179 }],
      }, 
        {
      id: 179,
        text: ['It is also ok to ask for support to mean that a person stops doing something that hurts someone’s feelings. If it is hard to identify kinds of support, sometimes it is helpful to think about what a person could possibly say or do that would be helpful.',' It is also ok to ask for support to mean that a person stops doing something that hurts someone’s feelings.',
'If it is hard to identify kinds of support, sometimes it is helpful to think about what a person could possibly say or do that would be helpful.'
        ],
        options: [{ text: 'Next', nextId: 180 }],
      }, 
        {
      id: 180,
        text: ['We’re going to practice being more assertive through role play.',
        'These skills apply to the issues we’re discussing today as well as what we’ll cover next session.', 
        'After you have a baby, it’s important to have support from others.'
        ],
        options: [{ text: 'Next', nextId: 181 }],
      }, 
        {
      id: 181,
        text: ['To get that support, sometimes you may have to ask for help or make an assertive request.',
        'Let’s practice that now and get your feedback.'
        ],
        options: [{ text: 'Next', nextId: 182 }],
      }, 
        {
      id: 182,
        text: ['To put this practice to the test, use the following template and fill out the following questions:-',
'1. One change I will ask for this week:-',
'2. Because I want them to listen without feeling upset, I need to be careful to:',
'3. Date I will try this on:',
'4. Person with whom I will try this:'
        ],
      }, 
        {
      id: 183,
        text: ['We’ll work on an actual scenario you might encounter.',
        'Remember to use “I” statements - I feel, I think, I want.', 
        'It gives a clear message without putting the other person on the defensive.'
        ],
        options: [{ text: 'Next', nextId: 184 }],
      }, 
        {
      id: 184,
        text: ['There are also some key “do’s and don’ts” to keep in mind which will be outlined here - things like dealing with one issue at a time, criticizing the behavior, not the person, and being willing to give and take.'
        ],
        options: [{ text: 'Next', nextId: 185 }],
      }, 
        {
      id: 185,
        text: ['Here are some Do’s Rules:-',
        '1. Golden Rules for being assertive Take your time (when saying no, “Can I let you know tomorrow?”)',
'. Openly state your own feelings, or changes you would like.',
'3. Avoid any attacks on the other person, any threats, or insulting statements about the other person.'
        ],
        options: [{ text: 'Next', nextId: 186 }],
      }, 
        {
      id: 186,
        text: ['4. Avoid: “Never, should, and always.',
'5. Deal with one issue at a time (in contrast to “kitchen sinking”).',
'6. Criticize the behavior, not the person (say what you want them to do, not what you want them to be).'
        ],
        options: [{ text: 'Next', nextId: 187 }],
      }, 
        {
      id: 187,
        text: ['7. Ask them for what you want (in contrast to telling them what they should do). (“Thanks for being so understanding, I really appreciate it”).',
'8. Be willing to give to get. Turn the table: Ask them, “what do you think we should do?”'
        ],
        options: [{ text: 'Next', nextId: 188 }],
      }, 
        {
      id: 188,
        text: ['Here are some Don’ts:-',,
        '1.Judge or blame',
'2. Over-apologize',
'3. Put yourself down',
'4. Guess the other person’s intentions Mind-read',
'5. Expect people to know what you want them to do if you don’t tell them',
'6. Give a whole list (“kitchen sinking”)',
'7. Beat around the bush',
'8. Tell the other person what they should do'
        ],
        options: [{ text: 'Next', nextId: 189 }],
      }, 
        {
      id: 189,
        text: ['Your homework will be to complete the relationship evaluation for two important relationships and practice making an assertive request we role played.',
'Also, remember to commit to at least three pleasant activities again for this coming week.'
        ],
        options: [{ text: 'Next', nextId: 190 }],
      }, 
        {
      id: 190,
        text: ['Next time we’ll troubleshoot any issues you had in asking for help, discuss negative communication, how to say no, and touch on planning for your future.',
'I’m really proud of the work you’re doing.',

'Have a wonderful day and I’ll see you next time.'
        ],
        options: [{ text: 'Next', nextId: 191 }],
      }, 
        {
      id: 191,
        text: ['Would you like to proceed to the next session or repeat the current session?'
        ],
        options: [{ text: 'Proceed', nextId: 192 },{ text: 'Repeat', nextId: 163 }],
      },             
        {
      id: 192,
        text: ['Welcome back to Session 6 where we will discuss about Relationships!','I hope you had a good time practicing your assertive skills. Before we jump into troubleshooting, I want to take a moment to discuss a very serious topic - abusive relationships.'
        ],
        options: [{ text: 'Next', nextId: 193 }],
      },   
        {
      id: 193,
        text: ['Let’s assess what a healthy relationship looks like: Some information about healthy relationships: I will have a partner in my life who:-',
'1. Supports my time spent with friends and family.',
'2. Supports me doing the things I enjoy and helps me grow as a person.',
'3. Makes me feel good about myself.',
'4. Asks for and listens to my opinions.',
'5. Respects my privacy (I do not need to share my password to my email, Facebook, and phone).',
'6. Respects and trusts me, and treats others with respect.'
        ],
        options: [{ text: 'Next', nextId: 194 }],
      },   
         {
      id: 194,
        text: ['If your relationship is unhealthy, such as your partner giving you constant criticisms, having little caring, not respecting your likes and dislikes, or threatening you, it is important to think about your safety.'
        ],
        options: [{ text: 'Next', nextId: 195 }],
      },        
         {
      id: 195,
        text: ['Consider…',
'1. You cannot force a person to change their behavior.',
'2. Focus on your needs, take care of yourself.',
'3. Do not isolate yourself. Find emotional support.',
'4. Think about breaking up. You deserve to feel safe and accepted in your relationship'
        ],
        options: [{ text: 'Next', nextId: 196 }],
      }, 
         {
      id: 196,
        text: ['If you think your partner might be open to going with you to your therapist, counselor, or doctor to talk about the conflict in your relationship, you should discuss the possibility with your therapist and how best to present it to your partner.',
'It’s not a good time to be assertive or discuss problems when the situation or feelings get out of control.'
        ],
        options: [{ text: 'Next', nextId: 197 }],
      },       
         {
      id: 197,
        text: ['In those cases, you could say something like "This isn’t working out the way I had hoped.',
'Let’s talk another time when things are calmer."',
'During these times, it’s often helpful to take a “time out”.',
'That’s when both people leave the situation, and return to discuss the problem once they have control over their feelings and can talk calmly.'
        ],
        options: [{ text: 'Next', nextId: 198 }],
      },  
         {
      id: 198,
        text: ['Remember your rights, even if other people don’t. Here are some rights to remember: It is OK to want or need something from someone else.',
'I can insist on my rights and still be a good person. I sometimes have a right to assert myself, even if I may inconvenience others.'
        ],
        options: [{ text: 'Next', nextId: 199 }],
      }, 
         {
      id: 199,
        text: ['I may want to please people I care about, but I don’t have time to please them all of the time.',
'The fact that I say no to someone does not make me a selfish person.',
'I can still feel good about myself, even though someone else is annoyed with me.'
        ],
        options: [{ text: 'Next', nextId: 200 }],
      },  
         {
      id: 200,
        text: ['You cannot do everything yourself',
'Speak up for yourself',
'Stay focused on your needs',
'Can you think of any examples where it would be helpful to take a time out?'
        ],
      },  
         {
      id: 201,
        text: ['I understand.',
'Now, if you’re dealing with someone who is becoming aggressive or violent, like starting to throw things, swear at you, or push you, the most important thing is that you get away from the situation.',
'If you stay, things can keep getting worse and may even become dangerous.'
        ],
        options: [{ text: 'Next', nextId: 202 }],
      },  
         {
      id: 202,
        text: ['Your safety is more important than solving a problem immediately.',
'No one deserves to be treated unfairly or abused.',
'If the situation does become violent and your safety or your children’s safety is at risk, it’s crucial that you assert your need for safety and get away.'
        ],
        options: [{ text: 'Next', nextId: 203 }],
      },  
         {
      id: 203,
        text: ['Sometimes it’s necessary to call the police, take legal action like a restraining order, go to family or friends, or go to a shelter to figure out how to protect yourself.',
        'Here is a hotline number to keep it handy. National Domestic Violence Hotline: 1-800-799-SAFE (7233) 24-hour domestic violence hotlines you can call to talk about your situation and get support.'
        ],
        options: [{ text: 'Next', nextId: 204 }],
      },  
         {
      id: 204,
        text: ['If you’re in a relationship that becomes violent, it’s important to think hard about whether it’s in a “give and take”, “silently resentful”, or “cannot be fixed” state.',
'Ask yourself if it’s a relationship you want to continue - can your needs ever be met?'
        ],
        options: [{ text: 'Next', nextId: 205 }],
      }, 
         {
      id: 205,
        text: ['If you’ve identified any risks of physical violence or other abuse, there are things you can do to increase your safety.',
'Although you can’t control someone else’s behavior and can’t always avoid violent incidents, you can control your response and take precautions to reduce the severity of certain risks or the likelihood of experiencing other risks.'
        ],
        options: [{ text: 'Next', nextId: 206 }],
      }, 
         {
      id: 206,
        text: ['Preparing in advance for the possibility of further violence can increase your safety.',
'Safety behaviors outlines things you may already be doing and things you can start doing to protect yourself.',
'I suggest, as homework, identifying which behaviors you already use and which you should begin using more if you are in an abusive situation.'
        ],
        options: [{ text: 'Next', nextId: 207 }],
      }, 
         {
      id: 207,
        text: ['Think of a safe place to go if an argument occurs - avoid rooms with no exits (bathroom), or rooms with weapons (kitchen, garage).', 
        'Think about and make a list of safe people to contact. Keep change with you at all times. Keep car keys (and purse) ready and always put them in a place where you can get them quickly.'
        ],
        options: [{ text: 'Next', nextId: 208 }],
      }, 
         {
      id: 208,
        text: ['Think ahead of safe places you could go (such as staying with a trusted friend or family member)',
'Talk with people you trust such as friends, family, neighbors, co-workers, or your therapist, counselor, or doctor.',
'Use your judgment and intuition. If the situation is very serious, give your partner what he/she wants to calm him/her down.'
        ],
        options: [{ text: 'Next', nextId: 209 }],
      }, 
         {
      id: 209,
        text: ['If your partner has been violent in the past, chances are that it will happen again, even if your partner promised it wouldn’t happen again. ','- Remember you do not have control over your partner’s violence, but you have some control over how you prepare for it and respond to it.'],
        options: [{ text: 'Next', nextId: 210 }],
      }, 
       {
      id: 210,
        text: ['In the worst-case scenario if you have to leave, here is a list of things that you should remember to take:-',
'1. Identification for yourself',
'2. Children’s birth certificates',
'3. Your birth certificate',
'4. Social Security Cards',
'5. School and vaccination records',
'6. Money',
'7. Checkbook, ATM card, Credit cards',
'8. Driver’s license and registration',
'9. Medications',
'10. Eyeglasses',
'11. Lease',
'12. Non-perishable snacks for you and your children (e.g. juice and crackers)',
'13. Baby things (diapers, formula, medication)'],
        options: [{ text: 'Next', nextId: 211 }],
      }, 
         {
      id: 211,
        text: ['Prepare ahead of time just in case you have to leave in a rush. Safety plan for breaking up if situation comes to that:-',
'1. Ending the relationship might be the best decision for you in the long run, but the break up period can be the most dangerous time in an abusive relationship.',
'2. They may try to control you through guilt trips, threats or insults.',
'3. It may be very difficult to have a peaceful or mutual breakup with an abusive partner.'],
        options: [{ text: 'Next', nextId: 212 }],
      }, 
         {
      id: 212,
        text: ['Some Safety Tips:-',
'1. Plan the break up with help from people you trust.',
'2. Speak to your counselor or doctor, or let trusted friends and family know when and how you are breaking up.',
'3. Do not break up in isolated place. Do it where there are people around, especially trusted people. If you do not feel safe, break up over the phone or email.'],
        options: [{ text: 'Next', nextId: 213 }],
      }, 
         {
      id: 213,
        text: ['4. Prepare what you will say to your partner, why you are ending the relationship. Only say it once.',
'5. Avoid being alone with your ex-partner. If your ex comes to your house when you are alone, don’t go to the door.',
'6. Ask for help. Memorize important numbers in case you do not have a cell phone with you.'],
        options: [{ text: 'Next', nextId: 214 }],
      }, 
         {
      id: 214,
        text: ['7. Change your routes and routines, and ask trusted people to be with you.',
'8. Trust your feelings: if you are afraid, you probably have good reasons If you are in immediate danger, call 911.',
'9. If you think you might be in danger, get a restraining order.'],
        options: [{ text: 'Next', nextId: 215 }],
      }, 
         {
      id: 215,
        text: ['Here are some telephone numbers you need to know and keep it handy:-',
'National Domestic Violence Hotline: 1-800-799-SAFE (7233) (24-hour)',
'Police/Sheriff’s Department near home, school, and work:',
'Local Domestic Violence Program:',
'Restraining Order Office:',
'Lawyer Referral Service/Legal Services',
'Agency:',
'Therapist/Counselor:',
'Work number:',
'Supervisor’s home number:',
'Minister/Priest/Rabbi/Faith Leader:',
'Friend:',
'Other:'],
        options: [{ text: 'Next', nextId: 216 }],
      }, 
         {
      id: 216,
        text: ['Some Safety Tips if you left an abusive relationship:-',
'Change your phone number. Screen calls.',
'Save and document all contacts, messages, injuries or other incidents involving the batterer.',
'Change locks, if the batterer has a key. Avoid staying alone.'],
        options: [{ text: 'Next', nextId: 217 }],
      }, 
         {
      id: 217,
        text: ['Plan how to get away if confronted by an abusive partner.',
'If you have to meet your partner, do it in a public place.',
'Vary your routine.',
'Notify school and work contacts.',
'If you have a protection order, always keep it on or near your person.',
'Call a shelter for battered persons.'],
        options: [{ text: 'Next', nextId: 218 }],
      }, 
         {
      id: 218,
        text: ['In case you need to obtain a restraining order: What is a Temporary Restraining Order? (TRO):-',
'1. If you think you might be in any danger, a temporary restraining order is recommended.',
'2. It is NOT a guarantee of safety, but it is a legal court order that says your abuser must stay away from you.',
'3. With a temporary restraining order (TRO), your abuser can be arrested if he/she/they tries to contact you. There is no fee for a TRO, and you do not need an attorney to get a TRO.'],
        options: [{ text: 'Next', nextId: 219 }],
      }, 
         {
      id: 219,
        text: ['How to get a Temporary Restraining Order (TRO):-',
'1. Go to your local family or district court to fill out paperwork.',
'2. After the paperwork is given to a clerk, a judge will review it, and the judge may or may not ask you questions.',
'3. If the judge signs the order, it is valid for up to 21 days.',
'4. A court officer will then serve your abuser with a copy of the restraining order.'],
        options: [{ text: 'Next', nextId: 230 }],
      }, 
         {
      id: 230,
        text: ['5. If you feel you are in danger during evening, weekend, or holiday hours, you may be able to get an emergency restraining order against your abuser.',
'6. Call your local police department. Police have 24-hour access to Family Court and District Court judges who can issue an emergency order of protection.'],
        options: [{ text: 'Next', nextId: 231 }],
      }, 
         {
      id: 231,
        text: ['7. If you get an emergency restraining order, you will need to go to court on the next business day to finalize the restraining order.',
'8. If you are unsure of what to do, you can call a domestic violence agency or a restraining order office for more information.',
'9. Some Helpful Phone Numbers: Coalition Against Domestic Violence (National) 1-800-494-8100 (24-hours).'],
        options: [{ text: 'Next', nextId: 232 }],
      }, 
         {
      id: 232,
        text: ['Do you have any questions about this topic or anything we’ve discussed today? I know it’s not an easy thing to talk about.']
      }, 
         {
      id: 233,
        text: ['Thank You for sharing. I understand acknowledge what you mean.',
'1. Remember, you’re not alone and it’s never your fault if someone is abusive towards you.',
'2. There is help available. For next session, in addition to the safety plan if needed, I’d like you to complete the questions on relationship evaluation and changes for two important relationships.',
'3. Also keep working on practicing assertive requests.'],
        options: [{ text: 'Next', nextId: 234 }],
      }, 
         {
      id: 234,
        text: ['4. And don’t forget to keep up with scheduling pleasant activities for yourself!',
'5. Commit to at least three for the coming week.',
'6. Keep up the great work and remember,',
'I’m always here if you need any extra support. Enjoy your day!'],
        options: [{ text: 'Next', nextId: 235 }],
      }, 
         {
      id: 235,
        text: ['Would you like to proceed to the next session where will discuss more about Assertiveness or repeat the current session?'],
        options: [{ text: 'Proceed', nextId: 236 },{ text: 'Repeat', nextId: 192 }],
      }, 
         {
      id: 236,
        text: ['Welcome back to Session 7 where we will talk about Assertiveness!!!',
        'Let’s dive into today’s topics. How did it go when you made those assertive requests? Were you able to ask for help?'],
        options: [{ text: 'Yes', nextId: 237 },{ text: 'No', nextId: 237 }],
      }, 
         {
      id: 237,
        text: ['Feel free to share more thoughts on the previous question.']
      }, 
          {
      id: 238,
        text: ['- Thank You for sharing. I understand what you mean completely.',
'- Some people may not always be receptive, and just because you assertively ask for something doesn’t mean they will say yes.',
'- But you have the right to ask, just as they have the right to say no.'],
options: [{ text: 'Next', nextId: 239 }],
      },      
          {
      id: 239,
        text: ['Let’s do a quick review of assertiveness. Type in your thoughts.',
'1. What is assertiveness?',
'2. What do you have to remember when being assertive?']
      },    
          {
      id: 240,
        text: ['I understand what you mean. Thank You for sharing.',
'Motherhood will bring many demands and a desire for more support.',
'At the same time, people around you may not know what you would most like.',
'Our goal is to help you think about your interpersonal needs, prioritize the most important ones, and practice skills for being really effective in asking for those needs.'],
options: [{ text: 'Next', nextId: 241 }],
      },   
          {
      id: 241,
        text: ['To better understand healthy assertive behavior, it helps to compare it to nonassertive and aggressive behavior.',
'If you’re nonassertive, you don’t express your own wants, needs or ideas.',
'You ignore your own rights and allow others to take advantage of you.',

'What are some reasons people may be nonassertive?']
      },   
          {
      id: 242,
        text: ['Those are common reasons.',
'When you’re nonassertive, it often leads to anger, resentment, frustration, feeling powerless, used, trapped or out of control.',
'The problem is never solved. On the flip side, when people are aggressive, they express their feelings and needs at the expense of others.'],
options: [{ text: 'Next', nextId: 243 }],
      },   
          {
      id: 243,
        text: ['They violate others’ rights and try to dominate.',
'This might happen out of anger, frustration or repeatedly being nonassertive until you finally blow up.'],
options: [{ text: 'Next', nextId: 244 }],
      },   
          {
      id: 244,
        text: ['But it can destroy relationships and doesn’t solve problems long-term.',
'Being assertive is the best way to keep your self-respect and attempt to solve relationship problems.',
'But there are stumbling blocks that can get in the way.'],
options: [{ text: 'Next', nextId: 245 }],
      },   
          {
      id: 245,
        text: ['Last time we touched on some barriers to being assertive.',
'Let’s explore those a bit more. Some common stumbling blocks are: Assertiveness isn’t always valued in women in different cultures.',
'Fear of retaliation and conflict',
'Lack of knowledge of assertiveness as a skill'],
options: [{ text: 'Next', nextId: 246 }],
      },   
          {
      id: 246,
        text: ['Fear of being called mean or selfish',
'Fear of losing the relationship',
'Fear of stirring up anger within oneself',
'Beliefs that we don’t deserve what we’re asking for or should be able to handle it ourselves',
'Not being ready to hear no or face that a relationship can’t be repaired',
'Lack of trust in yourself'],
options: [{ text: 'Next', nextId: 247 }],
      },   
          {
      id: 247,
        text: ['Often worrying thoughts get in the way of saying no, like “They won’t like me”, “He’ll think I’m demanding”, “She will think I’m stupid”, or “Saying no means I’m inconsiderate or uncaring”.',
'It’s important to evaluate whether these thoughts are realistic and counter them with realistic ones.',
'The best way to overcome these stumbling blocks is to practice and prepare.',
'The information in this class can help you prepare for asking for help, saying no, or communicating your feelings.'],
options: [{ text: 'Next', nextId: 248 }],
      },   
          {
      id: 248,
        text: ['To get the support we need, we have to nurture our relationships.',
        'Pay attention to them to keep them healthy. There needs to be a balance between priorities - things that are important to you, and demands - things others want you to do.',
'When you have a new baby, suddenly there will be a lot of new demands on top of all the old ones.'],
options: [{ text: 'Next', nextId: 249 }],
      },   
          {
      id: 249,
        text: ['Your wants will constantly be pushed aside.',
'So it will be important to make decisions about what’s most important for you to do.',
'Sometimes you’ll have to ask for help to accomplish your priorities.',
'And sometimes you’ll have to say no to people.',
'Saying no can be very hard, just like asking for help.'],
options: [{ text: 'Next', nextId: 250 }],
      }, 
          {
      id: 250,
        text: ['Remember, you have the right to say no.',
'You can’t be everything to everybody.',
'As a new mom, there will be some things you used to do for others that you won’t be able to do with the added responsibilities.',

'Why is saying no so difficult? What are some barriers? Feel free to type in your thoughts.']
      }, 
          {
      id: 251,
        text: ['Those are very common barriers.',
'Sometimes you need to repeat no over and over, especially if others aren’t listening or start arguing.',
'We call this the broken record technique.',
'Saying no requires the same skills as asking for help.',
'Remember the golden rules, use the broken record technique, and get out of the situation if someone doesn’t take no for an answer.'],
options: [{ text: 'Next', nextId: 252 }],
      }, 
          {
      id: 252,
        text: ['Can you think of examples of times you would have liked to say no but didn’t? How could you say no in those situations in the future?']
      }, 
         {
      id: 253,
        text: ['Thank You for sharing. I understand what you’re going through.',
'You’re being assertive but also kind.',
'If you’re unsure about a request or want to say no but don’t have the courage in the moment, you can always ask for time to think about it.',
'Use that time to figure out what you want to say.',
'But it’s important to come back to the person with your final decision'],
options: [{ text: 'Next', nextId: 255 }],
      }, 
          {
      id: 255,
        text: ['You can also offer an alternative if you want to help but can’t do the specific thing they’re asking.',
'Like “I can’t babysit tonight, but I’d be happy to watch the kids next Tuesday or Wednesday evening.”'],
options: [{ text: 'Next', nextId: 256 }],
      }, 
          {
      id: 256,
        text: ['Only offer this if you really mean it though.',
'Assertiveness is a skill that takes practice.',
'The more you do it, the easier it will get, especially in setting healthy boundaries after the baby arrives.'],
options: [{ text: 'Next', nextId: 257 }],
      }, 
          {
      id: 257,
        text: ['Let’s summarize what we’ve learned about assertiveness:- ',
'The baby will take up most of your time with little left over for relationships.',
'This can strain your other relationships if they don’t like the way you choose to spend your time.',
'If you had difficulties with a partner before the baby, having a baby is not likely to bring you closer.'],
options: [{ text: 'Next', nextId: 258 }],
      }, 
          {
      id: 258,
        text: ['There can be disagreements with parents and in-laws over how to care for the baby.',
'The more your relatives disagree or challenge you, the more angry or self-doubting you could become.',
'It’s the lucky women who have supportive relatives.'],
options: [{ text: 'Next', nextId: 259 }],
      }, 
          {
      id: 259,
        text: ['Friends and family may not offer the type of help you need.',
'Sometimes they aren’t around because they think they’ll be in the way or worry about intruding on a very personal time.',
'They may not realize you would like help or support.'],
options: [{ text: 'Next', nextId: 260 }],
      }, 
          {
      id: 260,
        text: ['You need to tell them what you need. Being a single mom is particularly hard, and you need to get all the help you can.',
'If you have a partner, try to take 15 minutes to touch base at least once a week about how these huge changes are affecting each of you.'],
options: [{ text: 'Next', nextId: 261 }],
      }, 
          {
      id: 261,
        text: ['Would you like to proceed to the next session where we will discuss more about Goal Setting or do you wish to repeat the current session?'],
options: [{ text: 'Proceed', nextId: 262 },{ text: 'Repeat', nextId: 236 }],
      },                   
          {
      id: 262,
        text: ['Welcome to your last session where we will discuss about Goal Setting before the baby is here!! Congratulations in completing the sessions so far and we’d like to touch base after the baby arrives.',
        'Let’s talk about planning for the future, which means setting goals.',
'When you have a baby, you may feel like your whole life has shrunk down to dirty diapers, feeding, bathing, burping and laundry.',
'When experiencing change, it’s important to replace lost goals.'],
options: [{ text: 'Next', nextId: 263 }],
      }, 
          {
      id: 263,
        text: ['Human beings are happiest when they have hopes, dreams, and are working towards them.',
'What are some of your dreams or long-term goals? Answer in the chat window.',
'Remember, infancy doesn’t last forever. Type in your thoughts.']
      }, 
          {
      id: 264,
        text: ['What steps do you need to take to work towards your dreams?',
'How will you accomplish them and when?']
      }, 
          {
      id: 265,
        text: ['Thank You for sharing. I acknowledge and understand what you mean.',
'Breaking big goals down like that makes them feel much more achievable. Here are some final tips from the ROSE program.'],
options: [{ text: 'Next', nextId: 266 }],
      }, 
          {
      id: 266,
        text: ['PLEASANT ACTIVITIES:-',
        '1. Remember it is important to nurture yourself, and to develop new ways of doing so (if necessary).',
'2. Adding in positive activities can lessen distress and increase your energy and positive outlook.',
'3. Remember your pleasant activity contract.'],
options: [{ text: 'Next', nextId: 267 }],
      }, 
          {
      id: 267,
        text: ['RELAXATION:-',
'1. Learning how to manage stress will make you feel better, increase your sense of control, and empower you.',
'2. Remember to do your relaxation exercises. Here is the script for your reference:'],
options: [{ text: 'Next', nextId: 268 }],
      }, 
          {
      id: 268,
        text: ['Decreasing Stress:-',
'1. Relaxation exercise as a reminder: I want you to make yourself comfortable. If you want, you can close your eyes.',
'2. We are going to start with your feet - curl your toes, and relax them.',
'3. Now dig your heels into the ground and feel the tightness in your legs, and relax - good.',
'4. Your legs feel loose and heavy. Now tighten your stomach - hold, feel the tightness and release - very good.',
'5. Now I want you to arch your back - feel the tension in your spine and relax your back - just let go - let all the tension leave your body - good'],
options: [{ text: 'Next', nextId: 269 }],
      }, 
          {
      id: 269,
        text: ['Make a fist with your hands and slowly raise them to your shoulder - feel the tightness in your arms and let go - just let your arms drop to your side and be loose and heavy, good.',
'Now raise your shoulders to your ears - feel the tightness in your neck, hold and release - let all the tension go.',
'I want you to now tighten your mouth as tight as you can - feel the tight-ness in your jaw - good and just relax.'],
options: [{ text: 'Next', nextId: 270 }],
      }, 
          {
      id: 270,
        text: ['Take a deep breath in - and exhale - just feel all the tension leave your body.',
'Now tighten your eyes as tight as you can squeeze them - and let go of your body, so it feels nice and relaxed - good, very good.'],
options: [{ text: 'Next', nextId: 271 }],
      }, 
          {
      id: 271,
        text: ['Focus on how calm and relaxed you feel - think of yourself on a soft cloud feeling the soft-ness of the cloud and sink into the cloud - your body feels so loose and heavy.',
'Feel the warm sun on your body and the cool breeze on your face. Just relax - you feel so peaceful and so safe.'],
options: [{ text: 'Next', nextId: 272 }],
      }, 
          {
      id: 272,
        text: ['Now I am going to count backwards from 5 and when I get to 1, you are going to open your eyes slowly and the feeling of calm and peace will stay with you for the rest of the day.',
'Breathe in 5 . . . and exhale calm',
'Breathe in 4 . . . and exhale calm',
'Breathe in 3 . . . and exhale calm',
'Breathe in 2 . . . and exhale calm',
'Breathe in 1 . . . and out . . . . think calm',
'As you slowly open your eyes, the calm and peace will stay with you.'],
options: [{ text: 'Next', nextId: 273 }],
      }, 
          {
      id: 273,
        text: ['CHANGES: BECOMING A NEW PARENT/CAREGIVER:-',
'1. Big changes in life, like having a baby, can be stressful.',
'2. Becoming a new parent/caregiver involves new demands and losses.',
'3. You may feel fearful, sad, tense, & frustrated - remember you are not alone and you’re not to blame.'],
options: [{ text: 'Next', nextId: 274 }],
      }, 
          {
      id: 274,
        text: ['It is important to talk about your feelings, reach out.',
'It is important to replace lost goals when experiencing losses and changes in life. There are both short-term goals (baby steps) and long-term goals.',
'Set ongoing goals. (Infancy does not last forever).'],
options: [{ text: 'Next', nextId: 275 }],
      }, 
          {
      id: 275,
        text: ['And if we have time, I’d like you to complete this “My Resources” handout.',
'Remember, once you have your baby, I’ll check in with you again to see how you and baby are doing and remind you of what you learned in our sessions.'],
options: [{ text: 'Next', nextId: 276 }],
      }, 
          {
      id: 276,
        text: ['1. LEAN ON FAMILY AND FRIENDS:- A few hours of weekly childcare can give you a much needed break. Sharing feelings openly allows family & friends to provide support.',
'2. TALK TO A HEALTH CARE PROFESSIONAL:- Doctors will know what options are available to you for assistance and will be familiar with perinatal depression.'],
options: [{ text: 'Next', nextId: 277 }],
      }, 
          {
      id: 277,
        text: ['1. FIND A SUPPORT GROUP:- There are others in your community suffering in similar situations; sharing your feelings in a group of individuals experiencing the same thing can be helpful. Talk to your health care provider about how to join a group.',
'2. TALK TO A MENTAL HEALTH CARE PROFESSIONAL:- Mental health care professionals can give you a safe place to express your feelings and will be useful in assisting with management of your symptoms.',
'3. FOCUS ON WELLNESS:- Eat a healthy variety of colorful foods, healthy snacks, and avoid alcohol use. Exercise, invite friends to go on walks, try a new activity, take time to stretch muscles.'],
options: [{ text: 'Next', nextId: 278 }],
      }, 
          {
      id: 278,
        text: ['Here is a New Parent/Caregiver’s Survival Kit:-',
        '1. Nurture yourself physically (e.g.,adequate rest, proper food, exercise)',
'2. Take breaks “mini vacations” (rest is rest-no chores during breaks!)',
'3. Give yourself credit, don’t forget to include the small tasks',
'4. Plan at least one event a day involving an adult (e.g., phone call to a friend)',
'5. Plan for something fun (e.g., a TV show, bubble bath, look at old pictures)',
'6. Ask for help-you cannot do it all',
'7. Postpone major changes in your life'],
options: [{ text: 'Next', nextId: 279 }],
      }, 
          {
      id: 279,
        text: ['DEALING WITH RELATIONSHIPS:',
'1. You have the right to ask for help and the right to say NO.',
'2. Relationships need constant work, especially when there are changes like having a new baby in your life.',
'3. Maintain balance in your relationships - demands must not outweigh your needs.',
'4. REMEMBER – Celebrate your efforts and successes, give yourself credit, even for the small things that you do as a partner, family member, friend, or soon-to-be parent/caregiver.'],
options: [{ text: 'Next', nextId: 280 }],
      }, 
          {
      id: 280,
        text: ['For homework, keep practicing assertive behavior.',
'It will be easier to maintain balance in your relationships if you start now, before the baby comes. Work on completing the handout with your goals and steps to accomplish them.'],
options: [{ text: 'Next', nextId: 281 }],
      }, 
          {
      id: 281,
        text: ['And keep up with your pleasant activity schedule aim for at least 3 activities you enjoy each week. It’s been such a pleasure working with you.',
'Remember, you have what it takes to be an amazing mother.',
'Don’t hesitate to reach out for help when you need it.',
'I’ll see you again soon after your little one arrives. Take good care!'],
options: [{ text: 'Next', nextId: 282 }],
      }, 
          {
      id: 282,
        text: ['And with that, we have now concluded the initial 8 sessions. Great job!!! We wish you the very best going ahead and will reconnect after the delivery of your baby to help you navigate through more post pregnancy problems.',
'If you would like to review any of the sessions again, please click on the buttons for the sessions below:-'],
options: [{ text: '1', nextId: 1 },{ text: '2', nextId: 49 },{ text: '3', nextId: 86 },{ text: '4', nextId: 115 },{ text: '5', nextId: 151 },{ text: '6', nextId: 192 },{ text: '7', nextId: 236 },{ text: '8', nextId: 262 }],
      }, 








        
    ],
    'es-MX': [
{
  id: 1,
  text: [
    'Hola, soy Nayomi.',
    '¡Bienvenida a la plataforma nurtur! Entendemos los desafíos únicos que conlleva ser una futura mamá y nuestra plataforma está diseñada para apoyarte en tu camino durante el embarazo al proporcionar un protocolo clínicamente probado adaptado a tus necesidades.'
  ],
  options: [
    { text: 'Siguiente', nextId: 2 }
  ]
},
{
  id: 2,
  text: 'Al completar todos los módulos, estarás equipada con valiosos conocimientos y herramientas para navegar las complejidades del período posparto con mayor facilidad. Nuestro programa se basa en un protocolo probado para prevenir la depresión posparto en hasta un 53%.',
  options: [
    { text: 'Siguiente', nextId: 3 }
  ]
},
{
  id: 3,
  text: 'Tu bienestar es nuestra prioridad, así que no dudes en contactarnos si tienes alguna pregunta o necesitas asistencia adicional en el camino. Vamos a embarcarnos en este viaje juntas y empoderarte para priorizar el autocuidado mientras nutres a tu familia que está creciendo.',
  options: [
    { text: 'Siguiente', nextId: 4 }
  ]
},
{
  id: 4,
  text: 'Déjame saber cuando estés lista para comenzar.',
  options: [{ text: 'Siguiente', nextId: 5 }]
},
{
  id: 5,
  text: [
    'Antes de comenzar la sesión de hoy, permíteme hacerte un par de preguntas para ayudarme a entender mejor cómo puedo ayudarte con cualquier problema.',
    'En las últimas 24 horas, ¿con qué frecuencia te has sentido molesta por tener poco interés o placer en hacer cosas?',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 6 }, { text: '1', nextId: 6 }, { text: '2', nextId: 6 }, { text: '3', nextId: 6 }]
},
{
  id: 6,
  text: [
    'En las últimas 24 horas, ¿con qué frecuencia te has sentido molesta por sentirte triste, deprimida o desesperanzada?',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 7 }, { text: '1', nextId: 7 }, { text: '2', nextId: 7 }, { text: '3', nextId: 7 }]
},
{
  id: 10,
  text: [
    'En las últimas 2 semanas, ¿con qué frecuencia te han molestado alguno de los siguientes problemas?',
    '¿Poco interés o placer en hacer cosas? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 11 }, { text: '1', nextId: 11 }, { text: '2', nextId: 11 }, { text: '3', nextId: 11 }]
},
{
  id: 11,
  text: [
    '¿Sentirse triste, deprimida o desesperanzada? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 12 }, { text: '1', nextId: 12 }, { text: '2', nextId: 12 }, { text: '3', nextId: 12 }]
},
{
  id: 12,
  text: [
    '¿Dificultades para conciliar o mantener el sueño, o dormir demasiado? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 13 }, { text: '1', nextId: 13 }, { text: '2', nextId: 13 }, { text: '3', nextId: 13 }]
},
{
  id: 13,
  text: [
    '¿Sentirse cansada o tener poca energía? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 14 }, { text: '1', nextId: 14 }, { text: '2', nextId: 14 }, { text: '3', nextId: 14 }]
},
{
  id: 14,
  text: [
    '¿Pérdida de apetito o comer en exceso? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 15 }, { text: '1', nextId: 15 }, { text: '2', nextId: 15 }, { text: '3', nextId: 15 }]
},
{
  id: 15,
  text: [
    '¿Sentirse mal contigo misma o pensar que eres un fracaso o que has decepcionado a ti misma o a tu familia? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 16 }, { text: '1', nextId: 16 }, { text: '2', nextId: 16 }, { text: '3', nextId: 16 }]
},
{
  id: 16,
  text: [
    '¿Dificultades para concentrarse en cosas, como leer el periódico o ver televisión? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 17 }, { text: '1', nextId: 17 }, { text: '2', nextId: 17 }, { text: '3', nextId: 17 }]
},
{
  id: 17,
  text: [
    '¿Moverse o hablar tan lentamente que otras personas podrían haberlo notado? O al contrario, ser tan inquieta o agitada que has estado moviéndote mucho más de lo habitual? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 19 }, { text: '1', nextId: 19 }, { text: '2', nextId: 19 }, { text: '3', nextId: 19 }]
},
{
  id: 19,
  text: [
    'La encuesta ha detectado altos niveles de estrés. Ten en cuenta que la ayuda está aquí para ti. Llama o envía un mensaje de texto a la Línea Nacional de Salud Mental Materna al 1-833-TLC-MAMA (1-833-852-6262) antes de continuar con la sesión.'
  ],
  options: [{ text: 'Siguiente', nextId: 47 }]
},
{
  id: 47,
  text: [
    '¿Has contactado el número de teléfono indicado?'
  ],
  options: [{ text: 'Sí', nextId: 7 }, { text: 'No', nextId: 19 }]
},
{
  id: 7,
  text: [
    'Gracias. Comencemos con la sesión de hoy.',
    'Hola, soy Nayomi, tu educadora prenatal virtual aquí. ¡Felicidades por tu embarazo!'
  ],
  options: [{ text: 'Sí', nextId: 8 }]
},
{
  id: 8,
  text: [
    'Estoy aquí para apoyarte a través del Programa ROSE, que significa Alcanzando Apoyo Social y Educación. Es una serie de sesiones diseñadas para enseñarte habilidades para manejar el estrés, especialmente después de que llegue tu bebé.'
  ],
  options: [{ text: 'Siguiente', nextId: 20 }]
},
{
  id: 20,
  text: [
    'Tener un bebé es un momento alegre, pero también puede traer nuevos desafíos. Queremos ayudarte a tener un buen comienzo para tu bebé y para ti misma. Puede ser útil reservar un tiempo específico cada día para completar estas sesiones.',
    'Antes de comenzar, limita cualquier distracción para mantenerte concentrada. En promedio, cada sesión tomará entre 10 y 15 minutos para que puedas planificar adecuadamente.'
  ],
  options: [{ text: 'Siguiente', nextId: 21 }]
},
{
  id: 21,
  text: [
    'Selecciona siguiente cuando estés lista para continuar.'
  ],
  options: [{ text: 'Siguiente', nextId: 22 }]
},
{
  id: 22,
  text: [
    'Las mujeres no son todas iguales. Algunas mujeres están encantadas de tener un bebé y dirán que nunca han sido más felices. Otras pueden tener sentimientos encontrados: a veces se sienten "tan felices" y en otras ocasiones "tan abrumadas". Y para algunas mujeres resulta muy estresante. Sin embargo, nadie habla de lo difícil que puede ser: los cambios que tendrás que hacer y que ser madre puede ser uno de los trabajos más difíciles de tu vida, un trabajo que viene con muy poca capacitación.'
  ],
  options: [{ text: 'Siguiente', nextId: 23 }]
},
{
  id: 23,
  text: [
    'Si lo piensas de manera realista, tienes que dejar de lado tus propias necesidades, tienes que alimentar, acunar, cambiar pañales, vestirte... sin mencionar las tareas regulares de un hogar, además de todos tus otros roles como pareja, hija, amiga, trabajadora. Todo esto mientras estás exhausta por la falta de sueño y tu cuerpo y tus hormonas se han vuelto locos. ¡Suena como una forma de tortura! De hecho, la privación del sueño es una forma de tortura bien conocida. ¿Qué le ha pasado a este maravilloso momento? Nadie habla de lo difícil que puede ser. Las nuevas madres se sienten culpables de quejarse ya que tienen la imagen perfecta de la nueva madre con su bebé sonriendo, llena de alegría y energía.'
  ],
  options: [{ text: 'Siguiente', nextId: 24 }]
},
{
  id: 24,
  text: [
    '¿Tu madre alguna vez te compartió cómo fue en los primeros meses de tenerte como bebé?'
  ],
  options: [{ text: 'Sí', nextId: 25 }, { text: 'No', nextId: 36 }]
},
{
  id: 36,
  text: [
    'Eso es normal y muchas mujeres tienden a no compartir esa fase de su vida debido a lo difícil que pudo haber sido.',
    'Es cierto que algunas mujeres no pasan por dificultades, pero la mayoría de las mujeres, si les haces muchas preguntas, te contarán sobre algunas dificultades durante los primeros tres meses. Te voy a compartir algunas realidades, no para asustarte, sino para prepararte y para asegurarme de que no te sientas culpable y no te culpes a ti misma si enfrentas dificultades una vez que tengas a tu bebé.',
    'Si estás desprevenida, puedes alarmarte y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás.'
  ],
  options: [{ text: 'Siguiente', nextId: 26 }]
},
{
  id: 37,
  text: [
    'Es genial saber que has tenido un viaje emocional relativamente estable durante tu embarazo. La experiencia de cada uno es única y es maravilloso que te hayas sentido constante durante este tiempo. Si tienes alguna pregunta o necesitas apoyo en algún momento, no dudes en contactarnos.',
    '¿Sabes cuáles son las señales de la depresión?'
  ],
  options: [{ text: 'Siguiente', nextId: 38 }]
},
{
  id: 38,
  text: [
    'Puede ser útil para todos conocer las señales, ya sea que hayan experimentado esto personalmente o no. Si alguna vez deseas saber más sobre las señales o tienes alguna preocupación sobre tu salud mental o la de otra persona, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo siempre que lo necesites. Ahora, nos adentraremos en la depresión posparto, que es una condición más seria. Las nuevas mamás deben estar al tanto de esto.'
  ],
  options: [{ text: 'Siguiente', nextId: 39 }]
},
{
  id: 39,
  text: [
    'Después del nacimiento, la depresión posparto generalmente es lenta y gradual. Por lo general, ocurre dentro de los primeros dos meses. Puedes comenzar a sentir que no puedes cuidar de tu bebé de la manera adecuada, piensas que no eres una buena madre, te sientes mal contigo misma, sientes que eres una carga para los demás. Hay una dificultad significativa en la capacidad de cuidar a tu bebé, cuidarte a ti misma y afrontar las relaciones familiares.'
  ],
  options: [{ text: 'Siguiente', nextId: 40 }]
},
{
  id: 40,
  text: [
    'Alrededor del 10-15% de las nuevas madres muestran síntomas de depresión posparto. Esto significa que una de cada siete mujeres experimentará depresión posparto. Para las mujeres que reciben asistencia pública o que enfrentan dificultades financieras, es más común: aproximadamente una de cada tres tales mujeres experimentará depresión posparto. Las mujeres que han tenido episodios anteriores de depresión son más propensas a experimentar depresión después del parto. Aquellas mujeres que han tenido depresión posparto también son más propensas a experimentarla nuevamente.'
  ],
  options: [{ text: 'Siguiente', nextId: 41 }]
},
{
  id: 41,
  text: [
    'Los síntomas pueden comenzar de manera gradual, pero a menudo pueden acumularse, dejando a una nueva madre sintiéndose abrumada, desesperanzada o frustrada. Algunos pensamientos comunes pueden ser “No puedo cuidar a mi bebé de la manera adecuada, no soy una buena madre, soy una carga para los demás.'
  ],
  options: [{ text: 'Siguiente', nextId: 42 }]
},
{
  id: 42,
  text: [
    'Mientras que el “baby blues” es temporal, la depresión posparto se caracteriza por emociones negativas persistentes y una disminución en la capacidad de funcionar. Los síntomas suelen durar al menos dos semanas.'
  ],
  options: [{ text: 'Siguiente', nextId: 43 }]
},
{
  id: 43,
  text: [
    'Algunos signos comunes incluyen sentirte abrumada, desesperanzada o culpable por cuidar a tu bebé. También podrías experimentar dificultades para dormir o comer, o una pérdida de interés en actividades que antes disfrutabas.'
  ],
  options: [{ text: 'Siguiente', nextId: 44 }]
},
{
  id: 44,
  text: [
    'Puede ser aterrador, pero el mensaje importante es que la depresión posparto es tratable. Si experimentas estos síntomas, por favor contacta a tu proveedor de atención médica de inmediato.'
  ],
  options: [{ text: 'Siguiente', nextId: 31 }]
},
{
  id: 25,
  text: [
    'Me alegra mucho saber que tu mamá compartió eso contigo.',
    'Es cierto que algunas mujeres no pasan por dificultades, pero si preguntas muchas cosas, la mayoría de las mujeres te contarán algunas dificultades durante los primeros tres meses. Voy a compartir contigo algunas realidades, no para asustarte, sino para prepararte y para asegurarte de que no sientas culpa ni te culpes a ti misma si este enfrenta dificultades una vez que tengas a tu bebé. Si estás desprevenida, podrías alarmarte, y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás.'
  ],
  options: [{ text: 'Siguiente', nextId: 26 }]
},
{
  id: 26,
  text: [
    'Hablemos sobre el rango de los "blues" posparto. Las mujeres se sienten diferentes en ese momento después de dar a luz. Para algunas es peor que para otras, y dura más. Como discutimos antes, algunas mujeres se enamoran de inmediato de su bebé y están encantadas y se mantienen encantadas, mientras que otras pueden sentirse muy abrumadas.'
  ],
  options: [{ text: 'Siguiente', nextId: 27 }]
},
{
  id: 27,
  text: [
    'La mayoría de las nuevas madres (alrededor del 30-80%) experimentan "baby blues". Los blues del bebé usualmente ocurren de dos a cinco días después del parto. Puedes experimentar períodos de llanto sin razón, cambios de humor, ser muy sensible, sentirte abrumada, irritable y simplemente agotada. Es un momento muy emocional y te sorprende. Piensas que tienes un hermoso, saludable bebé, ¿cómo puedo sentir ganas de llorar? Esta fase generalmente pasa en unos días a unas pocas semanas, a medida que tú y tu cuerpo se ajustan a tu nueva situación. Normalmente, dura alrededor de 10 días.'
  ],
  options: [{ text: 'Siguiente', nextId: 28 }]
},
{
  id: 28,
  text: [
    '¿Has experimentado algún cambio emocional durante tu embarazo hasta ahora?'
  ],
  options: [{ text: 'Sí', nextId: 29 }, { text: 'No', nextId: 37 }]
},
{
  id: 29,
  text: [
    'Es natural que te sientas así y gracias por compartir. Ahora hablemos de la Depresión Posparto. Algunas nuevas madres pasan por un momento más preocupante y las dificultades que hemos discutido son más duraderas y más intensas. La depresión posparto es cuando los síntomas persisten, casi todos los días y no hay alivio durante al menos dos semanas.',
    '¿Sabes cuáles son las señales de la depresión?'
  ],
  options: [{ text: 'Sí', nextId: 30 }, { text: 'No', nextId: 38 }]
},
{
  id: 30,
  text: [
    '¡Genial saberlo! Conocer las señales de la depresión puede ser crucial para entender tu salud mental y buscar apoyo cuando lo necesites. Si tienes alguna pregunta sobre las señales o deseas discutirlas más a fondo, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo. Es genial que seas consciente de tus emociones. Hablaremos de los mecanismos de afrontamiento para cambios emocionales en futuras sesiones.'
  ],
  options: [{ text: 'Siguiente', nextId: 31 }]
},
{
  id: 31,
  text: [
    'Antes de terminar esta sesión, ¿entiendes la diferencia entre los blues del bebé y la depresión posparto?'
  ],
  options: [{ text: 'Sí', nextId: 32 }, { text: 'No', nextId: 45 }]
},
{
  id: 32,
  text: [
    '¡Gran trabajo! Si tienes alguna pregunta sobre las diferencias entre los blues del bebé y la depresión posparto, no dudes en preguntar. Estoy aquí para ayudar a aclarar cualquier duda que puedas tener. Como recordatorio, aquí están los síntomas que debes tener en cuenta:',
    'BLUES DEL BEBÉ - Del 30-80% de las personas experimentan Blues del Bebé. Generalmente ocurre de 2 a 5 días después del parto y suele desaparecer después de unas dos semanas. Algunos síntomas son:',
    'Llanto, Cambios de humor, Agotamiento, Tensión.',
    'Ansiedad, Inquietud.'
  ],
  options: [{ text: 'Siguiente', nextId: 33 }]
},
{
  id: 33,
  text: [
    'DEPRESIÓN POSPARTO: PROBLEMAS de sueño (por ejemplo: no puedes volver a dormir después de alimentar al bebé), Problemas de alimentación - comer demasiado o muy poco, Ansiedad y preocupación, Evitar a las personas, evitar el contacto con el bebé, querer estar sola, Sin energía, Deseos de muerte, pensamientos suicidas, Dificultad para tener sentimientos positivos hacia el bebé, Dificultad para tomar decisiones, Manía - sentirse acelerada, estar excitada, irritable, hablar rápido y tener menos necesidad de dormir, Ataques de pánico, Temores por el bebé, fantasías sobre dañar o matar al bebé.'
  ],
  options: [{ text: 'Siguiente', nextId: 34 }]
},
{
  id: 34,
  text: [
    'Si alguna vez sientes que podrías lastimarte a ti misma, a tu bebé o a alguien más, por favor habla con tu proveedor de atención médica o llama al 911.'
  ],
  options: [{ text: 'Siguiente', nextId: 35 }]
},
{
  id: 35,
  text: [
    'En la próxima sesión, te ayudaremos a comprender los factores de riesgo, los síntomas y las opciones de tratamiento de la depresión posparto. Enfatizaremos la normalidad de los sentimientos, la importancia de buscar ayuda y las estrategias para afrontar y buscar apoyo durante la transición a la maternidad.'
  ],
  options: [{ text: 'Siguiente', nextId: 48 }]
}, 
// Continuing from this point would follow the same logic for translating the remaining sections. 
{id:45,text: ['No te preocupes. Revisemos esto rápidamente. La distinción entre el "baby blues" y la depresión posparto radica en su duración, gravedad e impacto en el funcionamiento diario. El "baby blues" son típicamente sentimientos de tristeza leve y cambios de humor que ocurren en las primeras semanas después del parto y que a menudo se resuelven por sí solos.',]
,options:[{ text: 'Siguiente', nextId: 46 }],},
{id:46,text: ['Por otro lado, la depresión posparto es una afección más grave y duradera caracterizada por sentimientos persistentes de tristeza, desesperanza y ansiedad que pueden interferir significativamente con la capacidad de una madre para cuidar de sí misma y de su bebé. Es esencial reconocer los signos y buscar apoyo si tú o alguien que conoces puede estar experimentando depresión posparto. Aquí hay una lista que podría ser útil.',],options:[{ text: 'Siguiente', nextId: 32 }]},

// Sesión 2

{id:48,text: ['Presiona Siguiente si deseas continuar a la sesión donde realizaremos ejercicios de relajación y transiciones de rol.',],options:[{ text: 'Siguiente', nextId: 49 },{ text: 'Repetir', nextId: 1 }],},

{
id: 49,
text: [
'¡Bienvenido a la Sesión 2! Hoy profundizaremos en el Ejercicio de Relajación y Transición de Rol.',
'De la investigación sabemos que las mujeres que han tenido episodios previos de depresión, especialmente depresión posparto, tienen más probabilidades de experimentar depresión después del parto. Además, aquellas que tienen un historial de depresión o un familiar con depresión o problemas de salud mental, y aquellas que tienen un sistema de apoyo inadecuado. Lo importante es que se puede y se debe tratar. Como recordatorio, aquí están los síntomas de la depresión posparto:',
'Alteraciones en la alimentación: comer demasiado o muy poco. Ansiedad y preocupación',
'Evitar personas, evitar el contacto con el bebé, querer estar sola',
'Sin energía',
'Pensamientos suicidas',
'Dificultad para tener sentimientos positivos hacia el bebé',
'Dificultad para tomar decisiones',
'Manía: sentirse acelerada, ser excitante e irritable, hablar rápido y tener menos necesidad de dormir',
'Atacones de pánico',
'Miedos por el bebé, fantasías sobre hacerle daño o matarlo'
],
options: [{ text: 'Siguiente', nextId: 62 }],
},
{
id: 62,
text: [
'Si tienes alguno de los síntomas que hemos discutido, por favor, habla con tu médico, proveedor de salud, o incluso con el médico de tu bebé y pide una referencia a un profesional de salud mental, terapeuta, consejero, trabajador social o psiquiatra. Aquí hay diferentes recursos y tenlo a mano: Línea de ayuda para la salud mental materna, Violencia doméstica, Servicios legales para padres, Prevención del suicidio',
],
options: [{ text: 'Siguiente', nextId: 63 }],
},
{
id: 63,
text: [
'El mensaje más importante que debes llevarte de estas sesiones es que aunque puedas tener los "baby blues", o incluso depresión tras el parto, no debes sentirte avergonzada por estos sentimientos, sentirte culpable, pensar que eres una mala madre o pensar que hay algo muy mal en ti.',
],
options: [{ text: 'Siguiente', nextId: 64 }],
},
{
id: 64,
text: [
'Necesitas entender que muchos de estos sentimientos son reacciones normales a tener un nuevo bebé. Recuerda: No estás sola, no eres culpable y puedes sentirte mejor.',
],
options: [{ text: 'Siguiente', nextId: 65 }],
},
{
id: 65,
text: [
'También está bien hablar sobre tus dificultades. No te sientas avergonzada o temerosa de discutir tus sentimientos con amigos, familiares y tu proveedor de atención médica. De hecho, te sentirás mucho mejor si hablas con alguien cercano a ti sobre tus sentimientos.',
'Tener un bebé es un evento importante que afecta el cuerpo y la mente. Todo esto ocurre en un momento en que se requiere cuidar de un ser humano necesitado e indefenso, y con poco sueño y a menudo con muy poca ayuda externa. En las próximas sesiones, hablaremos sobre formas de lidiar con el "montaña rusa" que podrías experimentar después de tener a tu bebé.',
],
options: [{ text: 'Siguiente', nextId: 66 }],
},
{
id: 66,
text: [
'No puedo suavizar el camino por ti ni quitarte algunos de los baches malos. Sin embargo, discutiremos formas para que puedas manejar la tristeza o los momentos bajos para que no caigas en una depresión.',
],
options: [{ text: 'Siguiente', nextId: 67 }],
},
{
id: 67,
text: [
'Nuestra aproximación del protocolo ROSE se basa en la idea de que estás enfrentando una gran transición. Sabemos que esto requerirá nuevas habilidades y establecerá nuevas demandas. Un tema importante cuando enfrentamos estrés es que puede ser de gran ayuda tener un buen apoyo. Un buen apoyo puede reducir la probabilidad de que una mujer desarrolle depresión posparto después del parto. Desafortunadamente, al mismo tiempo que mereces una cantidad increíble de apoyo, muchas mujeres se sorprenden al darse cuenta de que no es tan fácil encontrarlo durante este período.',
],
options: [{ text: 'Siguiente', nextId: 68 }],
},
{
id: 68,
text: [
'Las demandas de estar con el bebé pueden reducir el tiempo que pasas con otros socialmente, las demandas del bebé pueden ser muy difíciles para las relaciones, y otros pueden simplemente no darse cuenta de los tipos de apoyo o ayuda que serían agradables para ti. Así que, en Nurtur queremos hacer todo lo posible para ayudarte a sentir que estás recibiendo tanto apoyo como mereces.',
'No podemos garantizar esto, pero ciertamente podemos ayudar a pensar en tus objetivos de apoyo y enseñarte algunas estrategias para ayudar en ese área. Nos enfocaremos en diferentes formas de reducir el estrés en tu vida una vez que tu bebé esté aquí, y hablar sobre las personas que te brindan apoyo en tu vida.'
],
options: [{ text: 'Siguiente', nextId: 69 }],
},
{
id: 69,
text: [
'En una escala del 1 al 10, siendo 1 sin ansiedad y 10 ansiedad alta, ¿dónde calificarías tu nivel de ansiedad en este momento?'
],
options: [{ text: '1', nextId: 70 },{ text: '2', nextId: 70 },{ text: '3', nextId: 70 },{ text: '4', nextId: 70 },{ text: '5', nextId: 70 },{ text: '6', nextId: 70 },{ text: '7', nextId: 70 },{ text: '8', nextId: 70 },{ text: '9', nextId: 70 },{ text: '10', nextId: 70 }],
},
{
id: 70,
text: [
'Voy a proporcionarte el guion para el ejercicio. Recomiendo practicar durante 10-20 minutos diariamente. La práctica regular reforzará tus habilidades de relajación, por lo que es más probable que lo utilices cada vez que necesites controlar tu estrés.',
'Comencemos encontrando una posicón cómoda, ya sea sentada o acostada, cualquiera que te sientas mejor. Toma una respiración profunda, y al exhalar, deja ir cualquier tensión en tu cuerpo.'
],
options: [{ text: 'Siguiente', nextId: 71 }],
},
{
id: 71,
text: [
'Ahora empecemos con un ejercicio de relajación llamado Relajación Muscular Progresiva.',
'1. Encontrar maneras de relajarse y manejar el estrés puede ser muy útil al lidiar con los estreses de la vida, en general, y al lidiar con relaciones estresantes o si tienes dificultad para conciliar el sueño.',
'2. Las técnicas de relajación también han demostrado reducir el estrés y la tensión. La Relajación Muscular Progresiva está diseñada para relajar los músculos de tu cuerpo enseñándote a tensar y luego relajar varios grupos musculares.',
'3. Al crear primero tensión y luego liberar esa tensión de una vez, puedes lograr una mejor reducción de la tensión muscular. La liberación repentina de la tensión crea un tipo especial de energía que permite a los músculos relajarse más allá de lo que serían los niveles normales de reposo.',
'4. Dirige tu atención a tus pantorrillas y muslos. Tensa los músculos de tus piernas inferiores presionando tus talones contra el suelo, y luego suéltalos. Siente la calidez y la relajación expandiéndose a través de tus piernas.',
'5. Luego, tensa los músculos de tus glúteos apretándolos fuertemente, y luego suéltalos. Observa la diferencia entre la tensión y la relajación en tu cuerpo.',
'6. Ahora, enfoca tu atención en tu abdomen. Tensa tus músculos abdominales llevando tu ombligo hacia tu columna, y luego suéltalo. Permite que tu respiración se vuelva profunda y natural.',
'7. Mueve tu atención a tu pecho y espalda. Toma una respiración profunda, llenando tus pulmones de aire, y mantén por un momento. Ahora, exhala lenta y completamente, liberando cualquier tensión en tus músculos del pecho y espalda.',
'8. Cambia tu enfoque a tus hombros. Levántalos hacia tus oídos y luego déjalos caer, liberando cualquier tensión que puedas estar sosteniendo. Siente el peso de tus hombros hundiéndose en la superficie debajo de ti.',
'9. Ahora, ve a tus brazos y manos. Haz puños con tus manos, apretando fuertemente, y luego suéltalos. Siente la tensión salir de tus brazos mientras los dejas pesados y relajados.',
'10. Finalmente, enfócate en tu cara y cuello. Arruga tu cara fuertemente, frunciendo la frente y entrecerrando los ojos, y luego relájate. Deja que tu mandíbula se relaje, permitiendo que tus labios se separen ligeramente.',
'11. Tómate un momento para escanear tu cuerpo de pies a cabeza, notando cualquier área de tensión que quede.',
'12. Si notas alguna tensión, toma una respiración profunda e, al exhalar, imagina esa tensión derritiéndose, dejándote sentir completamente relajada y en calma.',
'13. Continúa respirando profundamente y lentamente por unos momentos más, disfrutando de la sensación de relajación en tu cuerpo.',
'14. Cuando estés lista, abre suavemente los ojos y regresa al momento presente, sintiéndote renovada y revitalizada.'
],
options: [{ text: 'Siguiente', nextId: 72 }],
},
{
id: 72,
text: [
'Ahora, en esa misma escala del 1 al 10, ¿dónde calificarías tu nivel de ansiedad? Muestra números del 1 al 10'
],
options: [{ text: '1', nextId: 73 },{ text: '2', nextId: 73 },{ text: '3', nextId: 73 },{ text: '4', nextId: 73 },{ text: '5', nextId: 73 },{ text: '6', nextId: 73 },{ text: '7', nextId: 73 },{ text: '8', nextId: 73 },{ text: '9', nextId: 73 },{ text: '10', nextId: 73 }],
},
{
id: 73,
text: [
'Ahora, pasemos a nuestro tema principal de hoy que es Transiciones de Rol.',
'Tener un bebé cambiará tu vida. Los cambios pueden ser buenos y malos, y ambos pueden ser estresantes ya que presentan nuevas demandas y nuevos horarios.',
'Piensa en cuán estresante puede ser una boda para una novia. Los grandes cambios, particularmente los estresantes, a menudo se asocian con depresión. Uno de los períodos de alto riesgo para la depresión es cuando las mamás tienen niños muy pequeños en casa.'
],
options: [{ text: 'Siguiente', nextId: 74 }],
},
{
id: 74,
text: [
'La buena noticia es que se sabe mucho sobre cómo atravesar eficazmente grandes cambios. Hoy, vamos a guiarte a través de algunas de las herramientas importantes para pensar sobre estos tipos de cambios/transiciones importantes. Tengo información que anticipa las transiciones de rol de las que hablaremos. ¿Puedes pensar en algún otro ejemplo de cambios que has experimentado, los cambios que tuviste que hacer durante un cambio en los roles y cómo sobreviviste?'
]},

{
id: 75,
text: [
'Entiendo y reconozco lo que quieres decir. Ahora hablemos sobre los cambios que un nuevo bebé traerá a tu vida. Al aumentar nuestra comprensión, podemos esperar hacer un mejor trabajo en satisfacer nuestras necesidades durante este tiempo de agitación.'
],
options: [{ text: 'Siguiente', nextId: 76 }],
},
{
id: 76,
text: [
'Comencemos explorando algunas de las pérdidas o cosas que podrías extrañar o tener que cambiar cuando llegue el bebé. ¿Cuáles son algunas pérdidas que te vienen a la mente?'
]
},
{
id: 77,
text: [
'Entiendo y reconozco lo que quieres decir. Algunos ejemplos de pérdidas son:-',
'1. Pérdida de rutina, ya que un bebé interrumpe el horario de 24 horas',
'2. Quehaceres del hogar que no se realizan',
'3. Menos tiempo para la pareja, otros hijos, amigos, etc.',
'4. Pérdida de productividad con la colada, las compras.',
'5. Menos oportunidades de socializar, lo que lleva a la aislamiento.',
'6. Pérdida de un sentido de propósito al estar en casa durante horas.',
'7. Incluso pérdida de espacio físico, necesitando compartir una habitación con el bebé o mudarse de la situación de vivienda actual.',
'8. Aislamiento'
],
options: [{ text: 'Siguiente', nextId: 78 }],
},

{
id: 78,
text: [
'Es importante reconocer estas pérdidas. Pero también exploremos los beneficios y nuevas oportunidades que podrías ganar como nueva madre. ¿Cuáles son algunas cosas que esperas o crees que podrían ser positivas acerca de este cambio?'
]
},

{
id: 79,
text: [
'Eso es genial. Quedarse en casa con un nuevo bebé puede obligarte a:-',
'Pasar tiempo con tu bebé.',
'Observar el crecimiento de tu bebé.',
'Es una oportunidad para disfrutar de las reacciones de tu hijo hacia el mundo.',
'Reducir tu ritmo, en lugar de llevar una vida agitada.',
'Aprenderás más sobre ti misma como madre, podrás descubrir algunos talentos ocultos',
'¡Una buena excusa para no hacer quehaceres 😊!'
],
options: [{ text: 'Siguiente', nextId: 80 }],
},
{
id: 80,
text: [
'Es normal tener una variedad de sentimientos con este cambio: ira, miedo, inseguridad. ¿Cuáles son algunos de los sentimientos que surgen para ti al pensar en estas pérdidas y ganancias?'
]
},
{
id: 81,
text: [
'Son sentimientos muy comprensibles. Es importante reconocerlos y no sentirse culpable. Aún podrás disfrutar de la vida, pero es posible que debas hacerlo de una manera diferente. La regla más importante para sobrevivir a la maternidad es hacer cosas para cuidar de tus necesidades. Nutre a la madre.'
],
options: [{ text: 'Siguiente', nextId: 82 }],
},
{
id: 82,
text: [
'Ser madre es como ser una jarra de agua: sigues vertiendo, dando y dando mientras cuidas las necesidades a tu alrededor: bebé, familia, amigos, pareja.',
'Si no tomas medidas y llenas la jarra de nuevo, pronto estará vacía.',
'No hay nadie que sea una jarra sin fondo. Estar vacía te hará muy vulnerable a la depresión. No puedes ser una buena madre si no se satisfacen tus necesidades. Date permiso.'
],
options: [{ text: 'Siguiente', nextId: 83 }],
},
{
id: 83,
text: [
'Pensamientos como "estoy siendo malhumorada, débil, consentida, egoísta" seguirán saltando en tu mente, pero debes corregirte y pensar "estoy invirtiendo en mí para mi bebé/familia". Es importante agregar actividades y experiencias positivas cuando estás perdiendo otras cosas positivas que te hacían sentir bien contigo misma. Es natural que te sientas menos motivada y cansada en este momento. Pero agregar intencionadamente actividades positivas puede reducir los sentimientos negativos; puede energizarte.'
],
options: [{ text: 'Siguiente', nextId: 84 }],
},
{
id: 84,
text: [
'Hay una relación directa entre las actividades agradables y el estado de ánimo; cuantas más cosas agradables hagas, mejor te sentirás. Pero tienes que darte permiso para hacer esto. En la próxima sesión, hablaremos de algunas de las actividades positivas que podrías ver haciendo una vez que tengas al bebé. ¡Que tengas un gran día! Podemos charlar mañana.'
],
options: [{ text: 'Siguiente', nextId: 85 }],
},
{
id: 85,
text: [
'¿Te gustaría proceder a la siguiente sesión donde hablaremos sobre Actividades Placenteras y Personas Cercanas? O ¿te gustaría repetir la sesión actual?'
],
options: [{ text: 'Proceder', nextId: 86 },{ text: 'Repetir', nextId: 49 }],
},
{
id: 86,
text: [
'¡Bienvenido a la Sesión 3! Hoy estaremos hablando sobre Actividades Placenteras y Personas Cercanas.',
'¿Cuáles son algunas actividades positivas y agradables que podrías imaginar haciendo una vez que tengas al bebé? Incluso las cosas pequeñas son importantes.'
],
},
{
id: 87,
text: [
'Entiendo y reconozco lo que quieres decir. Las actividades placenteras pueden ser tan simples como leer cómics, mirar fotos antiguas, ver un programa de televisión favorito, hacer ejercicio, tomar un baño de burbujas, encender velas. La clave es regalarte algo. Te lo mereces. Sé tu mejor amiga. Este es un momento importante para cuidar de ti misma.'
],
options: [{ text: 'Siguiente',nextId:101}],
},
{
id: 101,
text: [
'El tiempo de descanso significa no hacer quehaceres. Intenta planear al menos un evento que involucre a un adulto cada día, incluso si solo es una llamada telefónica.',
'Evita emprender cambios grandes en este momento: es mejor abordar esos cuando tus hormonas estén más equilibradas.',
'Mantén tus objetivos realistas: ¡tomar una ducha es un gran logro en esas primeras semanas! Recuerda, no existe tal cosa como una super-madre.',
'Cometerás errores y eso está bien. Concéntrate en ser una madre "suficientemente buena".'
],
options: [{ text: 'Siguiente',nextId:102}],
},
{
id: 102,
text: [
'Es importante hacer cosas que te hagan sentir segura y productiva, incluso cosas pequeñas.',
'Dale crédito a lo que logres, te sorprenderás de lo que realmente haces, pero a menudo no te das crédito por ello.'
],
options: [{ text: 'Siguiente',nextId:103}],
},
{
id: 103,
text: [
'Otro aspecto clave es desarrollar apoyo. Sabemos que el fuerte apoyo de otras personas puede protegerte de la depresión.',
'Las personas que pueden confiar en otros y recibir apoyo tienen menos probabilidades de deprimirse. Tener personas que se preocupan por ti es fundamental para aliviar cualquier angustia que tengas después de dar a luz.',
'Hay evidencia de que el fuerte apoyo frente a un estrés importante puede reducir significativamente el riesgo de depresión. Si eres reacia a pedir ayuda, podrías sentirte fácilmente abrumada.'
],
options: [{ text: 'Siguiente',nextId:104}],
},
{
id: 104,
text: [
'Ahora piensa profundamente en el siguiente conjunto de preguntas y luego escribe tus pensamientos.',
'1. ¿Quiénes son algunas de las personas que puedes pedir ayuda?',
'2. ¿A quién te sientes cercana, excluyendo a tus hijos?',
'3. ¿Quién crees que seguirá estando ahí para ti dentro de cinco años?',
'4. ¿Qué tipo de ayuda crees que necesitarás?'
],
},
{
id: 105,
text: [
'A veces, llegar a alguien no funciona. Un familiar o amigo puede no estar en condiciones de brindar apoyo. No debes sentirte como una carga.',
'Cuando tienes un compañero, esposo o familia que no está ahí para ti, eso es un verdadero problema. Pero no tienes que culparte.',
'Tienes derecho a buscar ayuda y apoyo. Es importante buscar apoyo de aquellos que son receptivos y cariñosos, sean quienes sean.',
'Si no están en tu hogar o en tu grupo actual de amigos, entonces acude a grupos de apoyo, lugares de culto, profesionales, etc.'
],
options: [{ text: 'Siguiente',nextId:106}],
},
{
id: 106,
text: [
'A menos que evites completamente las relaciones, probablemente experimentarás alguna decepción.',
'Ten cuidado de no rechazar a todos, especialmente cuando te sientas estresada.',
'La mayoría de las personas tiene defectos. Al decidir a quién aceptar —con sus fallos y todo— pregúntate: "¿El bien supera a lo malo?"',
'Depende de ti decidir qué tipos de fallos deseas aceptar.'
],
options: [{ text: 'Siguiente',nextId:107}],
},
{
id: 107,
text: [
'Pensemos en “Personas Cercanas” que puedan ayudarte a identificar fuentes de apoyo. Podemos completarlo juntos. Piensa en lo siguiente y luego comparte tus pensamientos:',
'1. ¿Cuáles son las personas en cada área que ves con más frecuencia? ¿A quién puedes acudir para pedir ayuda?',
'2. Enumera 2 personas de cada categoría:-',
'-FAMILIA',
'-TRABAJO',
'-ESPIRITUAL',
'-OTRO']
},
{
id: 108,
text: [
'Ahora que se han identificado las "personas cercanas", hablemos sobre la comunicación en las relaciones: cómo te comunicas con tus seres queridos, formas de mejorar la comunicación, especialmente al pedir ayuda, y cómo lidiar con relaciones abusivas.'
],
options: [{ text: 'Siguiente', nextId: 109 }],
},
{
id: 109,
text: [
'TIPS PARA PEDIR AYUDA A OTROS',
'TIMING:',
'Elige un momento tranquilo. Introduce el hecho de que quieres hablar sobre algo. COMPORTAMIENTO NO VERBAL:',
'Usa un tono de voz apropiado, evita posturas amenazantes, mantén contacto visual con la otra persona, míralos directamente. DICE ALGO POSITIVO:',
'Coloca lo negativo entre lo positivo. Comienza con un positivo y termina con un positivo.'
],
options: [{ text: 'Siguiente', nextId: 110 }],
},
{
id: 110,
text: [
'Tu tarea después de esta sesión es hacer tu propia lista de las veinte actividades placenteras. Puedes comenzar con algunas ideas de actividades agradables:',
'Pensar en personas que me gustan',
'Ver paisajes hermosos',
'Respirar aire limpio',
'Observar a las personas',
'Tener una conversación sincera y abierta',
'Sentarme al sol',
'Usar ropa limpia',
'Hacer un proyecto a mi manera',
'Escuchar música',
'Leer cuentos, novelas, poemas o obras de teatro',
'Expresar mi amor a alguien',
'Estar con alguien a quien amo',
'Halagar o elogiar a alguien',
'Escuchar la radio',
'Ver a viejos amigos',
'Relajarme en la bañera',
'Coleccionar cosas (monedas, conchas, etc.)',
'Ir al cine a mitad de semana',
'Reír',
'Pensar en mis viajes pasados',
'Leer revistas o periódicos',
'Cuidar de mis plantas',
'Cantar por la casa',
'Practicar religión (ir a la iglesia, orar en grupo, etc.)',
'Pintar',
'Hacer bordado, trabajos manuales, etc.',
'Dormir',
'Hacer manualidades',
'Hacer un regalo para alguien',
'Coser',
'Ir a la peluquería',
'Soñar despierta',
'Ver televisión',
'Pensar en eventos agradables',
'Escribir entradas en mi diario o cartas',
'Meditar',
'Jugar a las cartas',
'Ver y/o mostrar fotos o diapositivas',
'Tejer',
'Hacer crucigramas',
'Dress up and loSiguiente nice',
'Recibir un masaje',
'Hacer un rompecabezas',
],
options: [{ text: 'Siguiente', nextId: 111 }],
},
{
id: 111,
text: [
'Espero que esta lista de actividades te ayude mientras preparas tu lista de las veinte actividades placenteras.',
'Intenta programar al menos tres actividades agradables para ti en la próxima semana.',
'Nota tu estado de ánimo en una escala del 1 al 5 antes y después de la actividad.'
],
options: [{ text: 'Siguiente', nextId: 112 }],
},
{
id: 112,
text: [
'A veces, puede que no sientas ganas de hacer nada, pero participar en una actividad placentera puede realmente energizarte y mejorar tu estado de ánimo.',
'También recuerda practicar los ejercicios de relajación diariamente y calificar tu ansiedad antes y después.'
],
options: [{ text: 'Siguiente', nextId: 113 }],
},
{
id: 113,
text: [
'En las próximas sesiones abordaremos más temas.'
],
options: [{ text: 'Siguiente', nextId: 114 }],
},
{
id: 114,
text: [
'¿Te gustaría proceder a la siguiente sesión donde profundizaremos en las Relaciones o repetir la sesión actual?'
],
options: [{ text: 'Proceder',nextId:115},{ text: 'Repetir',nextId:86}],
},
{
id: 115,
text: ['¡Bienvenido de nuevo! Hoy nos adentraremos en las Relaciones.',
'Vamos a revisar un poco tus tareas.',
'¿Pudiste influir en tu estado de ánimo con las actividades agradables? Recuerda, cuanto más disfrutes las cosas que hagas, mejor te sentirás. Las actividades placenteras realmente pueden energizarte. ¿Tuviste la oportunidad de realizar actividades específicas desde nuestra última interacción?'
],
options: [{ text: 'Sí', nextId: 129 },{ text: 'No', nextId: 129 }],
},
{
id: 129,
text: ['¿Te fue fácil seguir adelante?'
],
options: [{ text: 'Sí', nextId: 130 },{ text: 'No', nextId: 130 }],
},
{
id: 130,
text: ['Está bien si no pudiste completar la actividad de tarea. Entiendo que la vida puede ser impredecible y a veces las cosas no salen como se planean.',
'Recuerda, la terapia es un viaje y es natural encontrar desafíos en el camino. Tu honestidad sobre tu experiencia es valiosa y ayuda a guiar nuestro trabajo juntos.',
'Tómate el tiempo para enumerar tus veinte principales actividades, de modo que esto te ayude a sentirte mejor y se convierta en una práctica que te prepara bien para la llegada del bebé.'
],
options: [{ text: 'Siguiente', nextId: 131 }],
},
{
id: 131,
text: ['¿Cómo te sentiste después de completar las actividades?'
]},
{
id: 132,
text: ['Entiendo y reconozco lo que quieres decir. Ahora quiero que te comprometas con dos o tres actividades más en los próximos días:-',
'1. Es muy importante mantener estas “citas” contigo misma con la misma fidelidad que tus citas más urgentes.',
'2. Hoy nuestro enfoque principal será en las relaciones.',
'3. Las relaciones son difíciles y a menudo las personas involucradas tienen necesidades y expectativas muy diferentes.',
'4. Hay mucho trabajo detrás de ellas. Las personas traen sus propias esperanzas y sueños que no siempre coinciden con los de la otra persona.',
'5. Los cambios en las circunstancias de la vida, como tener un bebé, añaden presiones aún mayores y diferentes.'
],
options: [{ text: 'Siguiente', nextId: 133 }],
},
{
id: 133,
text: ['Hay evidencia clara de que tener un bebé ejerce una gran cantidad de estrés en las asociaciones.',
'Muchos experimentan más conflictos porque hay muchas más demandas sobre los padres para gestionar.'
],
options: [{ text: 'Siguiente', nextId: 134 }],
},
{
id: 134,
text: ['Al mismo tiempo, contar con un gran apoyo es increíblemente protector contra la tristeza y la depresión durante momentos estresantes.',
'Así que aquí está, un momento cuando el apoyo adicional sería muy valioso, y aún así es más difícil para muchas madres nuevas de encontrar.'
],
options: [{ text: 'Siguiente', nextId: 135 }],
},
{
id:135,
text:['Nuestro objetivo es ayudarte a pensar en lo que necesitas y deseas durante este tiempo de las personas en tu vida, y cómo manejar de la mejor manera pedir un poco de ayuda adicional que mereces.',
'Todo de manera realista: no podemos conseguirte una niñera, pero quizás podamos ayudarte a aprender estrategias para hablar con tu pareja sobre nuevos problemas, obtener apoyo de familiares y amigos, construir redes con otras nuevas mamás y, en general, expresar lo que necesitas de la manera más efectiva.'
],
options: [{ text: 'Siguiente', nextId: 136 }],
},
{
id: 136,
text: ['Ahora hablemos más sobre cómo gestionar tus relaciones educándote acerca de los tres tipos de disputas interpersonales o argumentos.'],
options: [{ text: 'Siguiente', nextId: 137 }],
},
{
id: 137,
text: ['Tener un bebé puede tensionar las relaciones, dificultando que obtengas el apoyo que necesitas para afrontar el estrés y los cambios tras dar a luz.',
'Es prudente comenzar a pensar ahora sobre tus relaciones: quién estará allí para ayudar y brindarte el apoyo que necesitarás cuando llegue el bebé.'
],
options: [{ text: 'Siguiente', nextId: 138 }],
},
{
id: 138,
text: ['Al evaluar las relaciones, considera qué problemas puedes tener, si deseas hacer cambios y cómo se manejan los problemas en la relación.'],
options: [{ text: 'Siguiente', nextId: 139 }],
},
{
id: 139,
text: ['Reflexiona sobre las siguientes preguntas y luego escribe tus pensamientos:-',
'Escribe el nombre de alguien importante para ti y pregúntate:',
'1. ¿Qué tan satisfactoria es la relación en general? ¿Es de apoyo?',
'2. ¿Qué cambios te gustaría ver?',
'3. ¿Siente seguridad para analizar tus preocupaciones?',
'4. ¿Crees que la otra persona quiere sentirse cercana?',
'5. ¿Quieres sentirte cercana a ellos?'
],
},
{
id: 140,
text: ['Si hay una sensación básica de confianza y compromiso, entonces puedes encontrar maneras útiles de hablar sobre los problemas.',
'¿Cómo se manejan los problemas en esta relación?',
'¿Se expresan los conflictos?'
],
options: [{ text: 'Siguiente', nextId: 141 }],
},
{
id: 141,
text: ['Una vez que evalúes la situación, debes decidir si es una relación que deseas continuar.',
'A veces los problemas no se pueden resolver y hay más negativos que positivos.',
'Si has hecho todos los esfuerzos para mejorar las cosas y no ha funcionado, puede ser hora de preguntarte si es una relación que vale la pena mantener.'
],
options: [{ text: 'Siguiente', nextId: 142 }],
},
{
id: 142,
text: ['Hay tres tipos de disputas interpersonales o argumentos:-',
'1. Compromiso - "dar y recibir" donde las personas son consciente de las diferencias y están tratando activamente de realizar cambios, incluso si no tienen éxito.',
'2. Resentimiento silencioso - la discusión se ha detenido pero el resentimiento puede seguir burbujeando bajo la superficie.',
'3. No se puede arreglar - la relación no se puede reparar y ambas partes están yendo en su propio camino emocionalmente.'
],
options: [{ text: 'Siguiente', nextId: 143 }],
},
{
id: 143,
text: ['En el primer tipo, debe haber dar y recibir, alcanzando un compromiso incluso cuando no hay un conflicto directo.',
'Puede ser necesario llegar a un compromiso cuando el comportamiento de la otra persona te está causando problemas o malestar, o cuando te das cuenta de que lo que haces les molesta.'
],
options: [{ text: 'Siguiente', nextId: 144 }],
},
{
id: 144,
text: ['Por ejemplo, una vez que tengas al bebé, tus necesidades y prioridades cambiarán, pero otros pueden no estar al tanto o pueden actuar de maneras que te resultan incómodas.',
'Quizás tu pareja espera que aún hagas todos los quehaceres del hogar o tu familia espera que los visites con tanta frecuencia y los ayudes.'
],
options: [{ text: 'Siguiente', nextId: 145 }],
},
{
id: 145,
text: ['A veces puede haber una pelea continua donde es necesario llegar a un compromiso, como si estás peleando diariamente con el padre del bebé sobre cuándo puede y cuándo no puede ver al bebé.',
'En otras relaciones, las personas tienden a no hablar sobre las inquietudes.',
'Sin hablar, el resentimiento puede burbujear bajo la superficie. El problema sigue allí impactando negativamente la relación. Llamamos a esto resentimiento silencioso.'
],
options: [{ text: 'Siguiente', nextId: 146 }],
},
{
id: 146,
text: ['¿Cuáles son algunas razones por las que podrías evitar hablar sobre problemas? Esto podría ser por razones relacionadas con la familia, vida personal, otras limitaciones o dificultades con la comunicación.'],
},
{
id: 147,
text: ['Entiendo y reconozco lo que quieres decir.',
'Son razones muy comunes. En otras relaciones, las personas intentan hablar sobre las preocupaciones, pero puede que no siempre funcione.',
'Entonces están hablando pero sin resolver.',
'Y a veces, la relación parece irreparable; no se puede solucionar y ambas partes están yendo en su propio camino emocionalmente.'
],
options: [{ text: 'Siguiente', nextId: 148 }],
},
{
id: 148,
text: ['Piensa en las diferentes disputas interpersonales que discutimos.',
'Al concluir, no olvides priorizar dos o tres actividades agradables en los próximos días.',
'En la próxima sesión discutiremos más sobre prioridades y comunicación asertiva.'
],
options: [{ text: 'Siguiente', nextId: 150 }],
},
{
id: 150,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Prioridades y Comunicación Asertiva o repetir la sesión actual?'
],
options: [{ text: 'Repetir', nextId: 115},{ text: 'Proceder', nextId: 151 }],
},
// Sesión 5
{
id: 151,
text: ['¡Bienvenido de nuevo a la Sesión 5 donde hablaremos sobre Prioridades y Comunicación Asertiva!',
'Hablemos sobre prioridades. Mantener tus prioridades, o lo que es más importante para ti, es algo que muchos encuentran difícil.',
'Primero, debes determinar qué es más o menos importante, decir que no a algunas de las demandas menos importantes, y pedir ayuda, expresar emociones y opiniones, decir firmemente que no, o pedir a alguien que actúe de manera diferente.'
],
options: [{ text: 'Siguiente', nextId: 164 }],
},
{
id: 164,
text: ['Por ejemplo, tal vez podrías cuidar al hijo pequeño de tu hermano mientras estabas embarazada, pero ahora, con tu propio bebé, no puedes hacerlo de manera regular. Necesitas decir firmemente que no.'
],
options: [{ text: 'Siguiente', nextId: 165 }],
},
{
id: 165,
text: ['O tal vez podrías hacer la colada de todos antes, pero ahora necesitas pedir ayuda en esa área.',
'También hay personas que no quieres rechazar, pero necesitas que cambien su comportamiento.'
],
options: [{ text: 'Siguiente', nextId: 166 }],
},
{
id: 166,
text: ['¿Has oído hablar del Principio del Cubo? Muchas personas no manejan las irritaciones, aguantan lo que no vale la pena cambiar.',
'Así que ponen su irritación en ese cubo. Estas pequeñas cosas se acumulan hasta que el cubo está lleno.'
],
options: [{ text: 'Siguiente', nextId: 167 }],
},
{
id: 167,
text: ['¿Qué pasa cuando se agrega la siguiente irritación? El cubo rebosa. Estallas o te enojas mucho.',
'Si abordas las pequeñas cosas y dejas que las personas sepan qué te molesta, aumentas tus posibilidades de no enojarte y de que el cubo no rebose.'
],
options: [{ text: 'Siguiente', nextId: 168 }],
},
{
id: 168,
text: ['Ahora reflexiona sobre las siguientes preguntas y escribe tus respuestas:-',
'1. ¿Qué tan fácil es para ti pedir cosas?',
'2. ¿Qué tan bien puedes decir no?',
'3. ¿Varía con diferentes personas?',
'4. ¿Qué significa para ti la asertividad?'
],
},
{
id: 169,
text: ['Entiendo. Ahora hablemos sobre la asertividad:-','1. La asertividad es la capacidad de expresar tus pensamientos y sentimientos de manera abierta y directa a los demás, sin alejarlos.',
'2. Las habilidades asertivas nos permiten establecer y hacer cumplir límites. Te sentirás más en control.'
],
options: [{ text: 'Siguiente', nextId: 170 }],
},
{
id: 170,
text: ['Tienes el derecho a decidir qué hacer en lugar de lo que alguien más espera. Cuando eres asertiva, expresas tus propias necesidades, deseos o sentimientos sin violar los derechos de los demás.',
'Así que debes reconocer que los demás también tienen derechos, al igual que tú.'
],
options: [{ text: 'Siguiente', nextId: 171 }],
},
{
id: 171,
text: ['Repasemos algunos de tus derechos en las relaciones:-',
'1. Tienes derecho a obtener apoyo, a querer o necesitar algo de alguien más.',
'2. Tienes derecho a aceptar o rechazar cualquier cosa que otros te pidan o digan.',
'3. Tienes derecho a decir NO.'
],
options: [{ text: 'Siguiente', nextId: 172 }],
},
{
id: 172,
text: ['4. Ser asertivo es una habilidad que puedes aprender. Puede ser difícil al principio. Pedir apoyo o establecer límites implica asumir riesgos.',
'5. Tomar riesgos implica autoaceptación: aceptar que tienes necesidades, que tienes derecho a esas necesidades, y que eres vulnerable ante otra persona.'
],
options: [{ text: 'Siguiente', nextId: 173 }],
},
{
id: 173,
text: ['6. Tomar riesgos implica que puedes fallar, cometer un error o que las personas pueden desaprobarte o rechazarte por tus decisiones.',
'7. Debes aceptarte sin importar el resultado. No te vuelves menos valiosa si cometes un error, fallas o te rechazan.'
],
options: [{ text: 'Siguiente', nextId: 174 }],
},
{
id: 174,
text: ['8. Tienes derecho a tomar decisiones por ti misma, sin importar lo que piensen los demás. Dejar que otras personas tomen decisiones por ti y juzguen tus elecciones te hace sentir menos valiosa.',
'9. Estás a su merced si dejas que tu autoestima sea determinada por otros. Las personas se sienten mejor consigo mismas al tomar algunos riesgos, asumir el control de sus vidas y ser asertivas.'
],
options: [{ text: 'Siguiente', nextId: 175 }],
},
{
id: 175,
text: ['Piensa cuidadosamente sobre tu relación adulta más importante:-',
'1. ¿Cuáles son las dificultades o preocupaciones?',
'2. ¿Cuáles son tus necesidades en este momento?',
'3. ¿Cuáles son tus objetivos a corto y largo plazo para la relación?',
'4. ¿Estás experimentando conflicto en algún nivel que discutimos o insatisfacción donde la otra persona no está al tanto?',
'Me gustaría que completaras esto:-',
'1. "Comunicándome con mis seres queridos" para tu relación adulta más importante.',
'2. Si después de evaluarlo sientes que se necesitan cambios y la relación se puede arreglar, hay formas efectivas de discutir esos cambios.',
'3. Ahí es donde entra la asertividad.'
],
options: [{ text: 'Siguiente', nextId: 176 }],
},
{
id: 176,
text: ['Piensa en una persona con la que te gustaría cambiar tu estilo de comunicación: ¿cómo está yendo tu estilo de comunicación con esta persona? (-10 a 0, el estilo de comunicación va extremadamente mal; 1 a 10, extremadamente bien).'
],
},
{
id: 177,
text: ['Ahora, piensa profundamente en las siguientes preguntas:-',
'1. ¿Cuáles son las dos mejores cosas sobre cómo te comunicas con esta persona?',
'2. ¿Qué tipo de cosas dice o hace esta persona que te hacen feliz?',
'3. ¿Qué hace a esta persona especial para ti?',
'4. ¿Cuáles son las dos peores cosas sobre cómo te comunicas con esta persona?',
'5. ¿Qué tipo de cosas hacen ellos que son difíciles para ti?',
'6. ¿Qué tipo de cosas olvidan hacer? ¿Sientes que obtienes suficiente tiempo/atención/cuidado de esta persona?'
],
options: [{ text: 'Siguiente', nextId: 178 }],
},
{
id: 178,
text: ['Objetivos interpersonales:-',
'1. ¿Qué tipo de cambios o apoyo te gustaría de esta persona, además de lo que hace ahora?',
'2. Está bien querer que una persona dé más o menos de algo que ya hacen.'
],
options: [{ text: 'Siguiente', nextId: 179 }],
},
{
id: 179,
text: ['También está bien pedir apoyo para que una persona deje de hacer algo que lastima los sentimientos de alguien. Si es difícil identificar qué tipo de apoyo, a veces es útil pensar en lo que una persona podría decir o hacer que sería útil.',
'Está también bien pedir apoyo para que una persona deje de hacer algo que lastima los sentimientos de alguien.',
'Si es difícil identificar tipos de apoyo, a veces es útil pensar en lo que una persona podría decir o hacer que sería útil.'
],
options: [{ text: 'Siguiente', nextId: 180 }],
},
{
id: 180,
text: ['Vamos a practicar ser más asertivos a través de un juego de roles.',
'Estas habilidades se aplican a los problemas que estamos discutiendo hoy, así como a lo que cubriremos en la próxima sesión.',
'Después de tener un bebé, es importante contar con el apoyo de otros.'
],
options: [{ text: 'Siguiente', nextId: 181 }],
},
{
id: 181,
text: ['Para obtener ese apoyo, a veces puede que tengas que pedir ayuda o hacer una solicitud asertiva.',
'Practiquemos eso ahora y obtengamos tu opinión.'
],
options: [{ text: 'Siguiente', nextId: 182 }],
},
{
id: 182,
text: ['Para poner esta práctica a prueba, usa la siguiente plantilla y completa las siguientes preguntas:-',
'1. Un cambio que pediré esta semana:-',
'2. Porque quiero que escuchen sin sentirse molestos, necesito tener cuidado con:',
'3. Fecha en la que intentaré esto:',
'4. Persona con la que intentaré esto:']
},
{
id: 183,
text: ['Vamos a trabajar en un escenario real que podrías encontrar.',
'Recuerda usar declaraciones "yo": "Yo siento", "yo pienso", "yo quiero".',
'Esto brinda un mensaje claro sin poner a la otra persona a la defensiva.'
],
options: [{ text: 'Siguiente', nextId: 184 }],
},
{
id: 184,
text: ['También hay algunas "cosas que hacer" y "no hacer" clave que debes tener en cuenta y que se describirán aquí: cosas como abordar un solo problema a la vez, criticar el comportamiento, no a la persona, y estar dispuesto a dar y recibir.'
],
options: [{ text: 'Siguiente', nextId: 185 }],
},
{
id: 185,
text: ['Aquí están algunas Reglas que Hacer:-',
'1. Reglas de Oro para ser asertivo: Tómate tu tiempo (cuando digas que no, "¿Puedo informarte mañana?")',
'2. Declara abiertamente tus propios sentimientos, o los cambios que te gustaría.',
'3. Evita cualquier ataque a la otra persona, amenazas o declaraciones insultantes sobre la otra persona.'
],
options: [{ text: 'Siguiente', nextId: 186 }],
},
{
id: 186,
text: ['4. Evita: "Nunca, deberías y siempre."',
'5. Aborda un solo problema a la vez (en contraste con el “hundimiento de la cocina”).',
'6. Critica el comportamiento, no a la persona (di lo que quieres que hagan, no lo que deseas que sean).'
],
options: [{ text: 'Siguiente', nextId: 187 }],
},
{
id: 187,
text: ['7. Pídeles lo que quieres (en contraste con decirles lo que deberían hacer). ("Gracias por ser tan comprensivos, lo aprecio mucho").',
'8. Esté dispuesto a dar para recibir. Cambia la mesa: Pregúntales, "¿qué piensas que deberíamos hacer?"'
],
options: [{ text: 'Siguiente', nextId: 188 }],
},
{
id: 188,
text: ['Aquí algunas Cosas que No Hacer:-',
'1. Juzgar o culpar',
'2. Pedir disculpas excesivamente',
'3. Degradarte',
'4. Adivinar las intenciones del otro (saber lo que piensan)',
'5. Esperar que las personas sepan lo que quieres que hagan si no se lo dices',
'6. Dar toda una lista (“hundimiento de la cocina”)',
'7. Hablar sin decir nada',
'8. Decirle a la otra persona lo que deberían hacer'
],
options: [{ text: 'Siguiente', nextId: 189 }],
},
{
id: 189,
text: ['Tu tarea será completar la evaluación de relaciones para dos relaciones importantes y practicar hacer una solicitud asertiva que ensayamos.',
'También, recuerda comprometerte a al menos tres actividades placenteras nuevamente para esta semana.'
],
options: [{ text: 'Siguiente', nextId: 190 }],
},
{
id: 190,
text: ['La próxima vez abordaremos cualquier problema que hayas tenido al pedir ayuda, discutiremos la comunicación negativa, cómo decir que no, y tocaremos planificación para tu futuro.',
'Me siento realmente orgulloso del trabajo que estás haciendo.',
'Que tengas un maravilloso día y te veré la próxima vez.'
],
options: [{ text: 'Siguiente', nextId: 191 }],
},
{
id: 191,
text: ['¿Te gustaría proceder a la siguiente sesión o repetir la sesión actual?'
],
options: [{ text: 'Proceder', nextId: 192 },{ text: 'Repetir', nextId: 163 }],
},
{
id: 192,
text: ['¡Bienvenido de nuevo a la Sesión 6 donde hablaremos sobre Relaciones!',
'Espero que hayas tenido un buen rato practicando tus habilidades asertivas. Antes de comenzar a resolver problemas, quiero tomar un momento para hablar sobre un tema muy serio: las relaciones abusivas.'
],
options: [{ text: 'Siguiente', nextId: 193 }],
},
{
id: 193,
text: ['Evaluemos cómo es una relación sana: Algunos datos sobre relaciones saludables: Tendré un compañero en mi vida que:-',
'1. Apoya mi tiempo con amigos y familia.',
'2. Me apoya para hacer las cosas que disfruto y me ayuda a crecer como persona.',
'3. Me hace sentir bien conmigo misma.',
'4. Pide y escucha mis opiniones.',
'5. Respeta mi privacidad (no necesito compartir mi contraseña de mi email, FaceboSiguiente, y teléfono).',
'6. Me respeta y confía en mí, y trata a los demás con respeto.'
],
options: [{ text: 'Siguiente', nextId: 194 }],
},
{
id: 194,
text: ['Si tu relación es poco saludable, como si tu pareja te da críticas constantes, no muestra cuidado, no respeta tus gustos y disgustos, o te amenaza, es importante pensar en tu seguridad.'
],
options: [{ text: 'Siguiente', nextId: 195 }],
},
{
id: 195,
text: ['Considera…',
'1. No puedes forzar a una persona a cambiar su comportamiento.',
'2. Concéntrate en tus necesidades, cuídate.',
'3. No te aísles. Encuentra apoyo emocional.',
'4. Piensa en romper. Mereces sentirte segura y aceptada en tu relación.'
],
options: [{ text: 'Siguiente', nextId: 196 }],
},
{
id: 196,
text: ['Si crees que tu pareja podría estar abierta a ir contigo a tu terapeuta, consejero o médico para hablar sobre el conflicto en tu relación, deberías discutir la posibilidad con tu terapeuta y cómo presentárselo a tu pareja.',
'No es un buen momento para ser asertivo o discutir problemas cuando la situación o los sentimientos se descontrolan.'
],
options: [{ text: 'Siguiente', nextId: 197 }],
},
{
id: 197,
text: ['En esos casos, podrías decir algo como "Esto no está funcionando como esperaba.',
'Hablemos en otro momento cuando las cosas estén más calmadas."',
'Durante estos tiempos, a menudo es útil tomar un “tiempo fuera”.',
'Eso es cuando ambas personas abandonan la situación y regresan para discutir el problema una vez que hayan recuperado el control sobre sus sentimientos y puedan hablar con calma.'
],
options: [{ text: 'Siguiente', nextId: 198 }],
},
{
id: 198,
text: ['Recuerda tus derechos, incluso si los demás no lo hacen. Aquí hay algunos derechos que recordar: Está bien querer o necesitar algo de otra persona.',
'Puedo insistir en mis derechos y aún ser una buena persona. A veces tengo derecho a afirmar mis necesidades, incluso si eso puede incomodar a otros.'
],
options: [{ text: 'Siguiente', nextId: 199 }],
},
{
id: 199,
text: ['Puedo querer complacer a las personas que me importan, pero no tengo tiempo para complacerlas todo el tiempo.',
'El hecho de que le diga no a alguien no me convierte en una persona egoísta.',
'Aún puedo sentirme bien conmigo misma, incluso si alguien más está molesto conmigo.'
],
options: [{ text: 'Siguiente', nextId: 200 }],
},
{
id: 200,
text: ['No puedes hacerlo todo tú misma.',
'Defiéndete.',
'Mantén el enfoque en tus necesidades.',
'¿Puedes pensar en algún ejemplo en el que sería útil tomarte un tiempo fuera?'
],
},
{
id: 201,
text: ['Entiendo.',
'Ahora, si estás lidiando con alguien que se está volviendo agresivo o violento, como empezar a lanzar cosas, maldecirte o empujarte, lo más importante es que te alejes de la situación.',
'Si te quedas, las cosas pueden empeorar y pueden volverse peligrosas.'
],
options: [{ text: 'Siguiente', nextId: 202 }],
},
{
id: 202,
text: ['Tu seguridad es más importante que resolver un problema de inmediato.',
'No merece ser tratado injustamente o ser abusado.',
'Si la situación se vuelve violenta y tu seguridad o la de tus hijos está en riesgo, es crucial que afirmes tu necesidad de seguridad y te alejes.'
],
options: [{ text: 'Siguiente', nextId: 203 }],
},
{
id: 203,
text: ['A veces es necesario llamar a la policía, tomar medidas legales como una orden de restricción, acudir a amigos o familiares, o ir a un refugio para averiguar cómo protegerte.',
'Aquí hay un número de línea de ayuda para tenerlo a mano. Línea Nacional contra la Violencia Doméstica: 1-800-799-SAFE (7233) 24 horas de líneas de ayuda por violencia doméstica que puedes llamar para hablar sobre tu situación y obtener apoyo.'
],
options: [{ text: 'Siguiente', nextId: 204 }],
},
{
id: 204,
text: ['Si estás en una relación que se vuelve violenta, es importante pensar cuidadosamente si está en un estado de "dar y recibir", "resentido en silencio", o "no se puede arreglar".',
'Pregúntate si es una relación que deseas continuar: ¿se pueden satisfacer alguna vez tus necesidades?'
],
options: [{ text: 'Siguiente', nextId: 205 }],
},
{
id: 205,
text: ['Si has identificado algún riesgo de violencia física u otro abuso, hay cosas que puedes hacer para aumentar tu seguridad.',
'Aunque no puedes controlar el comportamiento de otra persona y no siempre puedes evitar incidentes violentos, puedes controlar tu respuesta y tomar precauciones para reducir la gravedad de ciertos riesgos o la probabilidad de experimentar otros riesgos.'
],
options: [{ text: 'Siguiente', nextId: 206 }],
},
{
id: 206,
text: ['Prepararte con anticipación para la posibilidad de más violencia puede aumentar tu seguridad.',
'Las conductas de seguridad delinean cosas que ya podrías estar haciendo y cosas que podrías comenzar a hacer para protegerte.',
'Sugiero, como tarea, identificar qué conductas ya usas y cuáles deberías comenzar a usar más si estás en una situación abusiva.'
],
options: [{ text: 'Siguiente', nextId: 207 }],
},
{
id: 207,
text: ['Piensa en un lugar seguro al que ir si ocurre una discusión: evita habitaciones sin salidas (baño) o habitaciones con armas (cocina, garaje).',
'Piensa y haz una lista de personas seguras a las que contactar. Lleva contigo algo de dinero en todo momento. Ten las llaves del auto (y la bolsa) listas y siempre ponlas en un lugar donde puedas acceder rápidamente.'
],
options: [{ text: 'Siguiente', nextId: 208 }],
},
{
id: 208,
text: ['Piensa de antemano en lugares seguros a los que podrías ir (como quedarte con un amigo o familiar de confianza)',
'Habla con personas en las que confíes, como amigos, familiares, vecinos, compañeros de trabajo o tu terapeuta, consejero o médico.',
'Usa tu criterio e intuición. Si la situación es muy seria, dale a tu pareja lo que quiere para calmarlo.'
],
options: [{ text: 'Siguiente', nextId: 209 }],
},
{
id: 209,
text: ['Si tu pareja ha sido violenta en el pasado, es probable que vuelva a suceder, incluso si tu pareja prometió que no sucedería de nuevo.',
'- Recuerda que no tienes control sobre la violencia de tu pareja, pero tienes cierto control sobre cómo te preparas para ello y respondes ante eso.'
],
options: [{ text: 'Siguiente', nextId: 210 }],
},
{
id: 210,
text: ['En el peor de los casos, si tienes que irte, aquí hay una lista de cosas que debes recordar llevar:-',
'1. Identificación para ti',
'2. Actas de nacimiento de los niños',
'3. Tu acta de nacimiento',
'4. Tarjetas de Seguro Social',
'5. Registros escolares y de vacunación',
'6. Dinero',
'7. Chequera, tarjeta de cajero automático, tarjetas de crédito',
'8. Licencia de conducir y registro',
'9. Medicamentos',
'10. Gafas',
'11. Contrato de arrendamiento',
'12. Snacks no perecederos para ti y tus hijos (por ejemplo, jugo y galletas)',
'13. Cosas para el bebé (pañales, fórmula, medicamentos)'],
options: [{ text: 'Siguiente', nextId: 211 }],
},
{
id: 211,
text: ['Prepárate con anticipación por si tienes que salir apresuradamente. Plan de seguridad para romper si la situación llega a eso:-',
'1. Terminar la relación puede ser la mejor decisión para ti a largo plazo, pero el periodo de separación puede ser el más peligroso en una relación abusiva.',
'2. Pueden intentar controlarte mediante culpas, amenazas o insultos.',
'3. Puede ser muy difícil tener una ruptura pacífica o mutua con una pareja abusiva.'],
options: [{ text: 'Siguiente', nextId: 212 }],
},
{
id: 212,
text: ['Algunos consejos de seguridad:-',
'1. Planea la ruptura con ayuda de personas en las que confíes.',
'2. Habla con tu consejero o médico, o comunícale a amigos y familiares de confianza cuándo y cómo vas a romper.',
'3. No rompas en un lugar aislado. Házlo donde haya personas alrededor, especialmente personas de confianza. Si no te sientes segura, rompe por teléfono o correo electrónico.'],
options: [{ text: 'Siguiente', nextId: 213 }],
},
{
id: 213,
text: ['4. Prepara lo que le dirás a tu pareja, por qué estás terminando la relación. Dilo solo una vez.',
'5. Evita estar sola con tu expareja. Si tu expareja llega a tu casa cuando estás sola, no vayas a abrir la puerta.',
'6. Pide ayuda. Memoriza números importantes en caso de que no tengas un teléfono celular contigo.'],
options: [{ text: 'Siguiente', nextId: 214 }],
},
{
id: 214,
text: ['7. Cambia tus rutas y rutinas, y pide a personas de confianza que te acompañen.',
'8. Confía en tus sentimientos: si tienes miedo, probablemente tengas buenas razones. Si estás en peligro inminente, llama al 911.',
'9. Si crees que podrías estar en peligro, obtén una orden de restricción.'],
options: [{ text: 'Siguiente', nextId: 215 }],
},
{
id: 215,
text: ['Aquí hay algunos números de teléfono que necesitas conocer y mantener a mano:-',
'Línea Nacional de Violencia Doméstica: 1-800-799-SAFE (7233) (24 horas)',
'Departamento de Policía/Alguacil cerca de casa, escuela y trabajo:',
'Programa local de Violencia Doméstica:',
'Oficina de Órdenes de Restricción:',
'Servicio de Referencia Legal/Servicios Legales',
'Agencia:',
'Terapista/Consejero:',
'Número de trabajo:',
'Número de casa del supervisor:',
'Ministro/Sacerdote/Rabino/Líder de Fe:',
'Amigo:',
'Otro:'],
options: [{ text: 'Siguiente', nextId: 216 }],
},
{
id: 216,
text: ['Algunos Consejos de Seguridad si dejaste una relación abusiva:-',
'Cambia tu número de teléfono. Filtra las llamadas.',
'Guarda y documenta todos los contactos, mensajes, lesiones u otros incidentes que involucren al maltratador.',
'Cambia las cerraduras, si el maltratador tiene una llave. Evita quedarte sola.'],
options: [{ text: 'Siguiente', nextId: 217 }],
},
{
id: 217,
text: ['Planea cómo escapar si te enfrenta un compañero abusivo.',
'Si tienes que reunirte con tu pareja, hazlo en un lugar público.',
'Varía tu rutina.',
'Notifica a la escuela y a contactos laborales.',
'Si tienes una orden de protección, mantenla siempre contigo o cerca de ti.',
'Llama a un refugio para personas maltratadas.'],
options: [{ text: 'Siguiente', nextId: 218 }],
},
{
id: 218,
text: ['En caso de que necesites obtener una orden de restricción: ¿Qué es una Orden de Restricción Temporal? (TRO):-',
'1. Si crees que podrías estar en peligro, se recomienda una orden de restricción temporal.',
'2. NO es una garantía de seguridad, pero es una orden legal que dice que tu abusador debe mantenerse alejado de ti.',
'3. Con una orden de restricción temporal (TRO), tu abusador puede ser arrestado si intenta contactarte. No hay tarifa por una TRO, y no necesitas un abogado para obtener una TRO.'],
options: [{ text: 'Siguiente', nextId: 219 }],
},
{
id: 219,
text: ['Cómo obtener una Orden Temporal de Restricción (TRO):-',
'1. Ve a tu tribunal local de familia o distrito para llenar el papeleo.',
'2. Después de que el papeleo sea entregado a un secretario, un juez lo revisará, y el juez puede o no hacerte preguntas.',
'3. Si el juez firma la orden, es válida por hasta 21 días.',
'4. Un oficial de la corte luego entregará a tu abusador una copia de la orden de restricción.'],
options: [{ text: 'Siguiente', nextId: 230 }],
},
{
id: 230,
text: ['5. Si crees que estás en peligro durante la tarde, el fin de semana o feriados, es posible que puedas obtener una orden de restricción de emergencia contra tu abusador.',
'6. Llama a tu departamento de policía local. La policía tiene acceso las 24 horas a jueces de la Corte de Familia y del Distrito que pueden emitir una orden de protección de emergencia.'],
options: [{ text: 'Siguiente', nextId: 231 }],
},
{
id: 231,
text: ['7. Si obtienes una orden de restricción de emergencia, deberás ir a la corte el siguiente día hábil para finalizar la orden de restricción.',
'8. Si no estás segura de qué hacer, puedes llamar a una agencia de violencia doméstica o a una oficina de órdenes de restricción para obtener más información.',
'9. Algunos Números de Teléfono Útiles: Coalición Contra la Violencia Doméstica (Nacional) 1-800-494-8100 (24 horas).'],
options: [{ text: 'Siguiente', nextId: 232 }],
},
{
id: 232,
text: ['¿Tienes alguna pregunta sobre este tema o sobre algo que hemos discutido hoy? Sé que no es un tema fácil de hablar.']
},
{
id: 233,
text: ['Gracias por compartir. Entiendo y reconozco lo que quieres decir.',
'1. Recuerda, no estás sola y nunca es tu culpa si alguien abusa de ti.',
'2. Hay ayuda disponible. Para la próxima sesión, además del plan de seguridad si es necesario, me gustaría que completarás las preguntas sobre la evaluación de relaciones y cambios para dos relaciones importantes.',
'3. Además, sigue trabajando en la práctica de solicitudes asertivas.'],
options: [{ text: 'Siguiente', nextId: 234 }],
},
{
id: 234,
text: ['4. Y no olvides seguir programando actividades placenteras para ti.',
'5. Comprométete a al menos tres para la próxima semana.',
'6. Sigue con el gran trabajo y recuerda,',
'Siempre estoy aquí si necesitas apoyo adicional. ¡Disfruta tu día!'],
options: [{ text: 'Siguiente', nextId: 235 }],
},
{
id: 235,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Asertividad o repetir la sesión actual?'],
options: [{ text: 'Proceder', nextId: 236 },{ text: 'Repetir', nextId: 192 }],
},
{
id: 236,
text: ['¡Bienvenido de nuevo a la Sesión 7 donde hablaremos sobre Asertividad!',
'Profundicemos en los temas de hoy. ¿Cómo te fue cuando hiciste esas solicitudes asertivas? ¿Pudiste pedir ayuda?'],
options: [{ text: 'Sí', nextId: 237 },{ text: 'No', nextId: 237 }],
},
{
id: 237,
text: ['Siéntete libre de compartir más pensamientos sobre la pregunta anterior.']
},
{
id: 238,
text: ['- Gracias por compartir. Entiendo perfectamente lo que quieres decir.',
'- Algunas personas pueden no ser siempre receptivas, y solo porque pidas algo de manera asertiva no significa que dirán que sí.',
'- Pero tienes el derecho de preguntar, así como ellos tienen el derecho de decir que no.'],
options: [{ text: 'Siguiente', nextId: 239 }],
},
{
id: 239,
text: ['Hagamos un repaso rápido sobre la asertividad. Escribe tus pensamientos.',
'1. ¿Qué es la asertividad?',
'2. ¿Qué debes recordar al ser asertivo?']
},
{
id: 240,
text: ['Entiendo lo que quieres decir. Gracias por compartir.',
'La maternidad traerá muchas demandas y un deseo de más apoyo.',
'Al mismo tiempo, las personas a tu alrededor pueden no saber lo que más deseas.',
'Nuestro objetivo es ayudarte a pensar sobre tus necesidades interpersonales, priorizar las más importantes y practicar habilidades para ser realmente efectiva al pedir esas necesidades.'],
options: [{ text: 'Siguiente', nextId: 241 }],
},
{
id: 241,
text: ['Para entender mejor el comportamiento asertivo saludable, ayuda compararlo con la falta de asertividad y el comportamiento agresivo.',
'Si no eres asertiva, no expresas tus propios deseos, necesidades o ideas.',
'Ignoras tus propios derechos y permites que otros se aprovechen de ti.',
'¿Cuáles son algunas razones por las que las personas pueden no ser asertivas?']
},
{
id: 242,
text: ['Esas son razones comunes.',
'Cuando no eres asertiva, a menudo conduce a la ira, resentimiento, frustración, sentirte impotente, usada, atrapada o fuera de control.',
'El problema nunca se resuelve. Por otro lado, cuando las personas son agresivas, expresan sus sentimientos y necesidades a expensas de los demás.'],
options: [{ text: 'Siguiente', nextId: 243 }],
},
{
id: 243,
text: ['Violan los derechos de los demás y tratan de dominar.',
'Esto puede suceder por ira, frustración o repetidamente no ser asertiva hasta que finalmente estallas.'],
options: [{ text: 'Siguiente', nextId: 244 }],
},
{
id: 244,
text: ['Pero puede destruir relaciones y no resolver problemas a largo plazo.',
'Ser asertiva es la mejor manera de mantener tu respeto personal y intentar resolver problemas en las relaciones.',
'Pero hay obstáculos que pueden interponerse en el camino.'],
options: [{ text: 'Siguiente', nextId: 245 }],
},
{
id: 245,
text: ['La última vez tocamos algunos obstáculos para ser asertiva.',
'Exploremos eso un poco más. Algunos obstáculos comunes son: La asertividad no siempre se valora en las mujeres en diferentes culturas.',
'Miedo a la represalia y el conflicto.',
'Desconocimiento de la asertividad como habilidad.'],
options: [{ text: 'Siguiente', nextId: 246 }],
},
{
id: 246,
text: ['Miedo a ser llamada mala o egoísta.',
'Miedo a perder la relación.',
'Miedo a provocar enojo en uno mismo.',
'Creencias de que no merecemos lo que pedimos o que deberíamos ser capaces de manejarlo por nosotros mismos.',
'No estar lista para escuchar un no o enfrentarse a que una relación no se puede reparar.',
'Falta de confianza en uno misma.'],
options: [{ text: 'Siguiente', nextId: 247 }],
},
{
id: 247,
text: ['A menudo, los pensamientos preocupantes se interponen en el camino de decir que no, como "No les gustaré", "Él pensará que soy exigente", "Ella pensará que soy estúpida", o "Decir que no significa que no me importa o que no tengo consideración".',
'Es importante evaluar si estos pensamientos son realistas y contrarrestarlos con otros realistas.',
'La mejor manera de superar estos obstáculos es practicar y prepararte.',
'La información de esta clase puede ayudarte a prepararte para pedir ayuda, decir que no o comunicar tus sentimientos.'],
options: [{ text: 'Siguiente', nextId: 248 }],
},
{
id: 248,
text: ['Para obtener el apoyo que necesitamos, debemos nutrir nuestras relaciones.',
'Presta atención a ellas para mantenerlas saludables. Debe haber un equilibrio entre prioridades: cosas que son importantes para ti y demandas: cosas que otros quieren que hagas.',
'Cuando tengas un nuevo bebé, de repente habrá muchas nuevas demandas además de todas las viejas.'],
options: [{ text: 'Siguiente', nextId: 249 }],
},
{
id: 249,
text: ['Tus deseos serán constantemente relegados.',
'Por lo que será importante que tomes decisiones sobre qué es más importante para ti hacer.',
'A veces tendrás que pedir ayuda para lograr tus prioridades.',
'Y a veces tendrás que decir que no a las personas.',
'Dicho no puede ser muy difícil, al igual que pedir ayuda.'],
options: [{ text: 'Siguiente', nextId: 250 }],
},
{
id: 250,
text: ['Recuerda, tienes el derecho de decir que no.',
'No puedes ser todo para todos.',
'Como nueva mamá, habrá algunas cosas que solías hacer por otros que no podrás hacer con las responsabilidades adicionales.',
'¿Por qué es tan difícil decir que no? ¿Cuáles son algunos obstáculos? Siéntete libre de escribir tus pensamientos.']
},
{
id: 251,
text: ['Esos son obstáculos muy comunes.',
'A veces necesitas repetir no una y otra vez, especialmente si los demás no están escuchando o comienzan a discutir.',
'Llamamos a esto la técnica del disco rayado.',
'Decir que no requiere las mismas habilidades que pedir ayuda.',
'Recuerda las reglas de oro, utiliza la técnica del disco rayado y sal de la situación si alguien no acepta un no como respuesta.'],
options: [{ text: 'Siguiente', nextId: 252 }],
},
{
id: 252,
text: ['¿Puedes pensar en ejemplos de veces que te hubiera gustado decir que no, pero no lo hiciste? ¿Cómo podrías decir que no en esas situaciones en el futuro?']
},
{
id: 253,
text: ['Gracias por compartir. Entiendo por lo que estás pasando.',
'Estás siendo asertiva, pero también amable.',
'Si no estás segura respecto a una solicitud o quieres decir que no, pero no tienes valor en ese momento, siempre puedes pedir tiempo para pensar en ello.',
'Usa ese tiempo para averiguar qué quieres decir.',
'Pero es importante que regreses con la persona con tu decisión final.'],
options: [{ text: 'Siguiente', nextId: 255 }],
},
{
id: 255,
text: ['También puedes ofrecer una alternativa si deseas ayudar pero no puedes hacer lo que te están pidiendo específicamente.',
'Como "no puedo cuidar niños esta noche, pero me encantaría cuidar a los niños el próximo martes o miércoles por la noche."'],
options: [{ text: 'Siguiente', nextId: 256 }],
},
{
id: 256,
text: ['Solo ofrece esto si realmente lo sientes.',
'La asertividad es una habilidad que requiere práctica.',
'Cuanto más lo hagas, más fácil se hará, especialmente en establecer límites saludables después de que llegue el bebé.'],
options: [{ text: 'Siguiente', nextId: 257 }],
},
{
id: 257,
text: ['Resumamos lo que hemos aprendido sobre la asertividad:-',
'El bebé ocupará la mayor parte de tu tiempo con poco sobrante para relaciones.',
'Esto puede tensar tus otras relaciones si no les gusta cómo eliges gastar tu tiempo.',
'Si tenías dificultades con una pareja antes del bebé, tener un bebé no es probable que los acerque.'],
options: [{ text: 'Siguiente', nextId: 258 }],
},
{
id: 258,
text: ['Puede haber desacuerdos con padres y suegros sobre cómo cuidar al bebé.',
'Cuanto más desacuerden o te desafíen tus familiares, más enojada o llena de dudas podrías sentirte.',
'Son afortunadas las mujeres que tienen familiares solidarios.'],
options: [{ text: 'Siguiente', nextId: 259 }],
},
{
id: 259,
text: ['Los amigos y la familia pueden no ofrecer el tipo de ayuda que necesitas.',
'A veces no están disponibles porque piensan que estarán de más o temen interrumpir un tiempo muy personal.',
'Quizás no se den cuenta de que te gustaría recibir ayuda o apoyo.'],
options: [{ text: 'Siguiente', nextId: 260 }],
},
{
id: 260,
text: ['Necesitas decirles lo que necesitas. Ser madre soltera es particularmente difícil, y necesitas obtener toda la ayuda que puedas.',
'Si tienes una pareja, intenta tomarte 15 minutos para comunicarte al menos una vez a la semana sobre cómo estos enormes cambios están afectando a ambos.'],
options: [{ text: 'Siguiente', nextId: 261 }],
},
{
id: 261,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Establecimiento de Objetivos o deseas repetir la sesión actual?'],
options: [{ text: 'Proceder', nextId: 262 },{ text: 'Repetir', nextId: 236 }],
},
{
id: 262,
text: ['¡Bienvenido a tu última sesión donde hablaremos sobre Establecimiento de Objetivos antes de que el bebé llegue! ¡Felicitaciones por completar las sesiones hasta ahora y nos gustaría volver a conectar después de que llegue el bebé!',
'Hablemos sobre la planificación para el futuro, que significa establecer objetivos.',
'Cuando tienes un bebé, puedes sentir que toda tu vida se ha reducido a pañales sucios, alimentación, baño, eructos y lavandería.',
'Cuando experimentamos cambios, es importante reemplazar los objetivos perdidos.'],
options: [{ text: 'Siguiente', nextId: 263 }],
},
{
id: 263,
text: ['Los seres humanos son más felices cuando tienen esperanzas, sueños y trabajan para lograrlos.',
'¿Cuáles son algunos de tus sueños u objetivos a largo plazo? Responde en la ventana de chat.',
'Recuerda, la infancia no dura para siempre. Escribe tus pensamientos.']
},
{
id: 264,
text: ['¿Qué pasos necesitas tomar para trabajar hacia tus sueños?',
'¿Cómo los lograrás y cuándo?']
},
{
id: 265,
text: ['Gracias por compartir. Reconozco y entiendo lo que quieres decir.',
'Desglosar los grandes objetivos así los hace sentir mucho más alcanzables. Aquí hay algunos consejos finales del programa ROSE.'],
options: [{ text: 'Siguiente', nextId: 266 }],
},

{
  id: 266,
  text: ['ACTIVIDADES AGRADABLES:-',
    '1. Recuerda que es importante cuidar de ti mismo y desarrollar nuevas formas de hacerlo (si es necesario).',
    '2. Incluir actividades positivas puede reducir el malestar y aumentar tu energía y perspectiva positiva.',
    '3. Recuerda tu contrato de actividades agradables.'],
  options: [{ text: 'Siguiente', nextId: 267 }],
}, 
{
  id: 267,
  text: ['RELajACION:-',
    '1. Aprender a gestionar el estrés te hará sentir mejor, aumentará tu sentido de control y te empoderará.',
    '2. Recuerda hacer tus ejercicios de relajación. Aquí está el guion para tu referencia:'],
  options: [{ text: 'Siguiente', nextId: 268 }],
}, 
{
  id: 268,
  text: ['Disminuyendo el Estrés:-',
    '1. Ejercicio de relajación como recordatorio: quiero que te pongas cómodo. Si quieres, puedes cerrar los ojos.',
    '2. Vamos a empezar con tus pies - riza tus dedos, y relájalos.',
    '3. Ahora clava tus talones en el suelo y siente la tensión en tus piernas, y relájate - bien.',
    '4. Tus piernas se sienten sueltas y pesadas. Ahora tensa tu estómago - mantén, siente la tensión y suéltala - muy bien.',
    '5. Ahora quiero que arquees tu espalda - siente la tensión en tu columna vertebral y relaja tu espalda - simplemente suéltate - deja que toda la tensión salga de tu cuerpo - bien.'],
  options: [{ text: 'Siguiente', nextId: 269 }],
}, 
{
  id: 269,
  text: ['Haz un puño con tus manos y levántalas lentamente hacia tu hombro - siente la tensión en tus brazos y suéltalos - simplemente deja que tus brazos caigan a los lados y estén sueltos y pesados, bien.',
    'Ahora levanta tus hombros hacia tus oídos - siente la tensión en tu cuello, mantén y suelta - deja que toda la tensión se vaya.',
    'Quiero que ahora tenses tu boca lo más que puedas - siente la tensión en tu mandíbula - bien y simplemente relájate.'],
  options: [{ text: 'Siguiente', nextId: 270 }],
}, 
{
  id: 270,
  text: ['Toma una respiración profunda - y exhala - simplemente siente toda la tensión salir de tu cuerpo.',
    'Ahora tensa tus ojos lo más que puedas - y suelta tu cuerpo, para que se sienta bien y relajado - bien, muy bien.'],
  options: [{ text: 'Siguiente', nextId: 271 }],
}, 
{
  id: 271,
  text: ['Concéntrate en lo tranquilo y relajado que te sientes - piensa en ti mismo en una nube suave sintiendo la suavidad de la nube y hundiéndote en la nube - tu cuerpo se siente tan suelto y pesado.',
    'Siente el cálido sol en tu cuerpo y la fresca brisa en tu cara. Simplemente relájate - te sientes tan en paz y tan seguro.'],
  options: [{ text: 'Siguiente', nextId: 272 }],
}, 
{
  id: 272,
  text: ['Ahora voy a contar hacia atrás desde 5 y cuando llegue a 1, abrirás los ojos lentamente y la sensación de calma y paz permanecerá contigo durante el resto del día.',
    'Inhala 5 . . . y exhala calma',
    'Inhala 4 . . . y exhala calma',
    'Inhala 3 . . . y exhala calma',
    'Inhala 2 . . . y exhala calma',
    'Inhala 1 . . . y fuera . . . . piensa en calma',
    'Al abrir lentamente tus ojos, la calma y la paz permanecerán contigo.'],
  options: [{ text: 'Siguiente', nextId: 273 }],
}, 
{
  id: 273,
  text: ['CAMBIOS: CONVERTIRSE EN UN NUEVO PADRE/CUIDADOR:-',
    '1. Los grandes cambios en la vida, como tener un bebé, pueden ser estresantes.',
    '2. Convertirse en un nuevo padre/cuidadores implica nuevas demandas y pérdidas.',
    '3. Puedes sentirte temeroso, triste, tenso y frustrado - recuerda que no estás solo y que no tienes la culpa.'],
  options: [{ text: 'Siguiente', nextId: 274 }],
}, 
{
  id: 274,
  text: ['Es importante hablar sobre tus sentimientos, buscar apoyo.',
    'Es importante reemplazar las metas perdidas al experimentar pérdidas y cambios en la vida. Hay metas a corto plazo (pequeños pasos) y metas a largo plazo.',
    'Establece metas continuas. (La infancia no dura para siempre).'],
  options: [{ text: 'Siguiente', nextId: 275 }],
}, 
{
  id: 275,
  text: ['Y si tenemos tiempo, me gustaría que completaras este folleto de "Mis Recursos".',
    'Recuerda, una vez que tengas tu bebé, volveré a contactarte para ver cómo estás tú y el bebé y recordarte lo que aprendiste en nuestras sesiones.'],
  options: [{ text: 'Siguiente', nextId: 276 }],
}, 
{
  id: 276,
  text: ['1. APOYATE EN FAMILIA Y AMIGOS:- Unas pocas horas de cuidado infantil a la semana pueden darte un merecido descanso. Compartir sentimientos abiertamente permite a la familia y amigos brindar apoyo.',
    '2. HABLA CON UN PROFESIONAL DE LA SALUD:- Los doctores sabrán qué opciones tienes disponibles para asistencia y estarán familiarizados con la depresión perinatal.'],
  options: [{ text: 'Siguiente', nextId: 277 }],
}, 
{
  id: 277,
  text: ['1. ENCUENTRA UN GRUPO DE APOYO:- Hay otros en tu comunidad que sufren en situaciones similares; compartir tus sentimientos en un grupo de personas que están pasando por lo mismo puede ser útil. Habla con tu proveedor de salud sobre cómo unirte a un grupo.',
    '2. HABLA CON UN PROFESIONAL DE SALUD MENTAL:- Los profesionales de salud mental pueden brindarte un espacio seguro para expresar tus sentimientos y serán útiles para ayudarte a manejar tus síntomas.',
    '3. CONCENTRATE EN EL BIENESTAR:- Come una variedad saludable de alimentos coloridos, bocadillos saludables y evita el consumo de alcohol. Haz ejercicio, invita a amigos a caminar, prueba una nueva actividad, tómate tiempo para estirar los músculos.'],
  options: [{ text: 'Siguiente', nextId: 278 }],
}, 
{
  id: 278,
  text: ['Aquí hay un Kit de Supervivencia para Nuevos Padres/Cuidadores:-',
    '1. Cuida de ti físicamente (por ej., suficiente descanso, buena alimentación, ejercicio)',
    '2. Tómate descansos “mini vacaciones” (el descanso es descanso - ¡sin tareas durante los descansos!)',
    '3. Date crédito, no olvides incluir las pequeñas tareas',
    '4. Planea al menos un evento al día que involucre a un adulto (por ej., una llamada a un amigo)',
    '5. Planea algo divertido (por ej., un programa de televisión, un baño de burbujas, ver fotos antiguas)',
    '6. Pide ayuda - no puedes hacerlo todo',
    '7. Posponer cambios importantes en tu vida'],
  options: [{ text: 'Siguiente', nextId: 279 }],
}, 
{
  id: 279,
  text: ['ENFRENTANDO RELACIONES:',
    '1. Tienes derecho a pedir ayuda y derecho a decir NO.',
    '2. Las relaciones necesitan trabajo constante, especialmente cuando hay cambios como tener un nuevo bebé en tu vida.',
    '3. Mantén el equilibrio en tus relaciones - las demandas no deben superar tus necesidades.',
    '4. RECUERDA – Celebra tus esfuerzos y éxitos, date crédito, incluso por las pequeñas cosas que haces como pareja, miembro de la familia, amigo o futuro padre/cuidor.'],
  options: [{ text: 'Siguiente', nextId: 280 }],
}, 
{
  id: 280,
  text: ['Para la tarea, sigue practicando el comportamiento asertivo.',
    'Será más fácil mantener el equilibrio en tus relaciones si comienzas ahora, antes de que llegue el bebé. Trabaja en completar el folleto con tus metas y pasos para lograrlas.'],
  options: [{ text: 'Siguiente', nextId: 281 }],
}, 
{
  id: 281,
  text: ['Y sigue con tu horario de actividades agradables, apunta a al menos 3 actividades que disfrutes cada semana. Ha sido un placer trabajar contigo.',
    'Recuerda, tienes lo que se necesita para ser una madre increíble.',
    'No dudes en pedir ayuda cuando la necesites.',
    'Nos veremos de nuevo pronto después de que llegue tu pequeño. ¡Cuídate bien!'],
  options: [{ text: 'Siguiente', nextId: 282 }],
}, 
{
  id: 282,
  text: ['Y con eso, hemos concluido las 8 sesiones iniciales. ¡Excelente trabajo!!! Te deseamos lo mejor en el futuro y nos reconectaremos después del nacimiento de tu bebé para ayudarte a navegar a través de más problemas post embarazo.',
    'Si deseas revisar alguna de las sesiones nuevamente, haz clic en los botones de las sesiones a continuación:-'],
  options: [{ text: '1', nextId: 1 },{ text: '2', nextId: 49 },{ text: '3', nextId: 86 },{ text: '4', nextId: 115 },{ text: '5', nextId: 151 },{ text: '6', nextId: 192 },{ text: '7', nextId: 236 },{ text: '8', nextId: 262 }],
}


        
    ],
   

    'zh-TW': [
  {
  id: 1,
  text: [
    '您好，我是 Nayomi。',
    '歡迎來到 nurtur 平台！我們了解成為準媽媽所面臨的獨特挑戰，我們的平台旨在通過提供專門為您需求量身定制的臨床驗證協議來支持您度過懷孕之旅。',
  ],
  options: [
    { text: '下一步', nextId: 2 }
  ],
},
{
  id: 2,
  text: '完成所有模塊後，您將掌握有價值的見解和工具，以更輕鬆地應對產後階段的複雜性。我們的計劃基於經過驗證的協議，證明可以減少高達 53% 的產後抑鬱症。',
  options: [
    { text: '下一步', nextId: 3 }
  ],
},
{
  id: 3,
  text: '您的健康是我們的首要任務，因此如果您有任何問題或在此過程中需要進一步幫助，請隨時聯繫我們。讓我們一起踏上這段旅程，並在照顧您成長中的家庭時，賦予您優先考慮自我護理的力量。',
  options: [
    { text: '下一步', nextId: 4 }
  ],
},
{
  id: 4,
  text: '讓我知道您什麼時候準備好開始。',
  options: [{ text: '下一步', nextId: 5 }],
},
{
  id: 5,
  text: [
    '在我們開始今天的會議之前，請允許我問幾個問題，以幫助我更好地了解如何更好地幫助您解決任何問題。',
    '在過去的 24 小時內，您是否經常因對事物興趣索然或樂趣減少而困擾？',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 6 },{ text: '1', nextId: 6 },{ text: '2', nextId: 6 },{ text: '3', nextId: 6 }],
},
{
  id: 6,
  text: [
    '在過去的 24 小時內，您是否經常感到沮喪、抑鬱或絕望？',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 7 },{ text: '1', nextId: 7 },{ text: '2', nextId: 7 },{ text: '3', nextId: 7 }],
},
{
id: 10,
  text: [
    '在過去的兩週內，您是否經常因以下問題而困擾？',
    '對事物興趣索然或樂趣減少？請根據 0 到 3 的等級回答：',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 11 },{ text: '1', nextId: 11 },{ text: '2', nextId: 11 },{ text: '3', nextId: 11 }],
},
{
id: 11,
  text: [
    '感到沮喪、抑鬱或絕望？請根據 0 到 3 的等級回答：',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 12 },{ text: '1', nextId: 12 },{ text: '2', nextId: 12 },{ text: '3', nextId: 12 }],
},                        
{
id: 12,
  text: [
    '入睡困難或難以保持睡眠，或睡得太多？請根據 0 到 3 的等級回答：',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 13 },{ text: '1', nextId: 13 },{ text: '2', nextId: 13 },{ text: '3', nextId: 13 }],
},
{
id: 13,
  text: [
    '感到疲倦或精力不足？請根據 0 到 3 的等級回答：',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 14 },{ text: '1', nextId: 14 },{ text: '2', nextId: 14 },{ text: '3', nextId: 14 }],
},
{
id: 14,
  text: [
    '食慾不振或過度飲食？請根據 0 到 3 的等級回答：',
    '請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 15 },{ text: '1', nextId: 15 },{ text: '2', nextId: 15 },{ text: '3', nextId: 15 }],
},
{
id: 15,
  text: [
    '對自己感到不好，覺得自己是個失敗者，或讓自己或家人失望了？請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 16 },{ text: '1', nextId: 16 },{ text: '2', nextId: 16 },{ text: '3', nextId: 16 }],
},  
{
id: 16,
  text: [
    '難以集中注意力，例如閱讀報紙或看電視？請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 17 },{ text: '1', nextId: 17 },{ text: '2', nextId: 17 },{ text: '3', nextId: 17 }],
},
{
id: 17,
  text: [
    '行動或說話速度緩慢，其他人可能會注意到。或相反地，表現得非常急躁或坐立不安，您比平時更多地走動？請根據 0 到 3 的等級回答：',
    '0 - 完全沒有。',
    '1 - 有時。',
    '2 - 一半時間。',
    '3 - 大部分時間。'
  ],
  options: [{ text: '0', nextId: 19 },{ text: '1', nextId: 19 },{ text: '2', nextId: 19 },{ text: '3', nextId: 19 }],
},
{
id: 19,
  text: [
    '調查發現高壓力水平。請知道這裡有幫助。繼續會話之前，請致電或發短信至全國孕產婦心理健康熱線 1-833-TLC-MAMA (1-833-852-6262)。'
  ],
  options: [{ text: '好', nextId: 47 }],
},
{
id: 47,
  text: [
    '您有聯繫上述電話號碼嗎？'
  ],
  options: [{ text: '是', nextId: 7 },{ text: '否', nextId: 19 }],
},
{
id: 7,
  text: [
    '謝謝。我們開始今天的會議吧。',
    '您好，我是您的虛擬產前教育者 Nayomi。恭喜您懷孕了！',
  ],
  options: [{ text: '是', nextId: 8 }],
},
{
id: 8,
  text: [
    '我在這裡通過 ROSE 計劃支持您，這是一系列旨在教您如何管理壓力的課程，特別是在您的寶寶出生後。',
  ],
  options: [{ text: '好', nextId: 20 }],
},
{
id:20,
text: [
  '擁有一個寶寶是快樂的時光，但也會帶來新的挑戰。我們希望幫助您為自己和寶寶奠定良好的開端。每天安排一個特定時間來完成這些會話可能會有所幫助。',
  '在我們開始之前，請限制任何分心以保持專注。平均每節課需要 10 到 15 分鐘，這樣您可以進行相應的計劃。'
],
options:[{text: '好', nextId: 21 }],
},    
{
id:21,
text:[ '準備好繼續時，請選擇下一步。'],
options:[{text: '下一步', nextId: 22 }],
},
{
id:22,
text: [
  '女性並不都是一樣的。有些女性對擁有一個寶寶感到非常高興，並表示她們從未如此快樂。其他人可能會有混合的感覺——有時他們感到“如此幸福”，而有時感到“如此不知所措”。對一些女性來說，這是非常壓力的。然而，沒有人談論這有多難——您需要做出的改變，以及成為父母可能是您一生中最艱難的工作之一——一個幾乎沒有培訓的工作。',
],
options:[{text: '下一步', nextId: 23}],
},
{
id:23,
text: [
  '如果您現實地思考——您必須把自己的需求放在一邊，您必須餵養、搖晃、換尿布、換衣服，更不用說家務活——再加上您作為伴侶、女兒、朋友、工作者的所有其他角色。所有這些都在您因睡眠不足而感到疲憊不堪且您的身體和荷爾蒙變得不正常的情況下進行。聽起來像是一種酷刑——實際上，睡眠剝奪是一種眾所周知的酷刑。這段美好時光怎麼了！沒有人談論它有多困難。新媽媽們覺得抱怨很內疚，因為她們心中有一幅完美母親和她的寶寶微笑、充滿喜悅和活力的畫面。',
],
options:[{text: '好', nextId: 24}],
},
{
id:24,
text:['您的母親曾經與您分享過她在您出生後頭幾個月的經歷嗎？'],
options:[{ text: '是', nextId: 25 },{ text: '否', nextId: 36 }],
},
{
id:36,
text:[
  '這是正常的，許多女性往往不會分享她們生活中的那個階段，因為它可能非常困難。',
  '確實，有些女性不會有困難，但大多數女性如果您問很多問題，她們會告訴您在頭三個月期間的一些困難。我將與您分享一些現實——不是要嚇唬您，而是為了讓您做好準備，並確保如果您在擁有寶寶後感到困難，您不會感到內疚和責備自己。',
  '如果您沒有準備，您可能會感到驚慌，這會增加您的負面情緒。了解越多，對您越有利。'
],
options:[{ text: '好', nextId: 26 }],
},
{
id:37,
text:[
  '很高興聽到您的懷孕過程中情緒相對穩定。每個人的經歷都是獨特的，很高興您在這段時間裡感覺穩定。如果您在任何時候有任何問題或需要支持，請隨時聯繫我們。',
  '您知道抑鬱症的症狀嗎？'
],
options:[{ text: '下一步', nextId: 38 }],
},
{
id:38,
text: [
  '無論是否親身經歷，了解抑鬱症的症狀對每個人都是有幫助的。如果您想了解更多關於症狀的信息或對自己的心理健康或他人的心理健康有任何擔憂，請隨時詢問。我在這裡隨時提供信息和支持。現在，我們將深入探討產後抑鬱症，這是一種更嚴重的情況。新媽媽必須了解這一點。'
],
options:[{ text: '下一步', nextId: 39 }],
},
{
id:39,
text: [
  '產後抑鬱症通常是緩慢而逐漸發生的。通常在頭兩個月內發生，您可能會開始感到無法正確照顧寶寶，認為自己不是一個好母親，對自己感到不好，覺得自己是別人的負擔。在照顧寶寶、照顧自己和應對家庭關係方面存在顯著困難。'
],
options:[{ text: '下一步', nextId: 40 }],
},
{
id:40,
text:[
  '大約 10-15% 的新媽媽會出現產後抑鬱症的症狀。這意味著每七個女性中就有一個會經歷產後抑鬱症。對於接受公共援助或經濟困難的女性來說，這種情況更為常見：大約每三個這樣的女性中就有一個會經歷產後抑鬱症。以前有過抑鬱症發作的女性在分娩後更可能出現抑鬱症。那些曾經有過產後抑鬱症的女性也更可能再次經歷。'
],
options:[{ text: '下一步', nextId: 41 }],
},
{
id:41,
text:[
  '症狀可能會逐漸開始，但它們經常會積累，讓新媽媽感到不知所措、絕望或沮喪。一些常見的想法可能是“我無法正確照顧我的寶寶，我不是一個好母親，我是別人的負擔。”'
],
options:[{ text: '下一步', nextId: 42 }],
},
{
id:42,
text:[
  '雖然產後抑鬱症是一種暫時的情況，但其特點是持續的負面情緒和功能下降。症狀通常持續至少兩週。'
],
options:[{ text: '下一步', nextId: 43 }],
},
{
id:43,
text: [
  '一些常見的跡象包括感到不知所措、絕望或對照顧寶寶感到內疚。您可能還會經歷睡眠或飲食困難，或對曾經喜歡的活動失去興趣。'
],
options:[{ text: '下一步', nextId: 44 }],
},
{
id:44,
text:[
  '這可能令人害怕，但重要的信息是產後抑鬱症是可以治療的。如果您出現這些症狀，請立即聯繫您的醫療提供者。'
],
options:[{ text: '下一步', nextId: 31 }],
},
{
id:25,
text: [
  '很高興聽到您的母親與您分享了這一點。',
  '確實，有些女性不會有困難，但大多數女性如果您問很多問題，她們會告訴您在頭三個月期間的一些困難。我將與您分享一些現實——不是要嚇唬您，而是為了讓您做好準備，並確保如果您在擁有寶寶後感到困難，您不會感到內疚和責備自己。如果您沒有準備，您可能會感到驚慌，這會增加您的負面情緒。了解越多，對您越有利。'
],
options:[{ text: '好', nextId: 26 }],
},
{
id:26,
text: [
  '讓我們來討論一下產後“憂鬱”的範圍。女性在產後會有不同的感覺。對一些人來說，這種感覺比其他人更嚴重，持續的時間更長。正如我們之前討論過的，有些女性會立即愛上她們的寶寶，感到非常高興並且持續感到高興，而其他人可能會感到非常不知所措。'
],
options:[{ text: '下一步', nextId: 27 }],
},
{
id:27,
text: [
  '大多數新媽媽（約 30-80%）會有“產後憂鬱”。產後憂鬱通常在分娩後的兩到五天發生。您可能會無故哭泣、情緒波動、非常敏感、感到不知所措、易怒和疲憊不堪。這是一段非常情緒化的時期，您會感到措手不及。您會想，我有一個健康漂亮的寶寶，怎麼會感到哭泣呢？這個階段通常會在幾天到幾週內過去，隨著您和您的身體適應新情況。通常，它持續約 10 天。'
],
options:[{ text: '下一步', nextId: 28 }],
},
{
id:28,
text: [
  '到目前為止，您在懷孕期間是否經歷過任何情緒變化？'
],
options:[{ text: '是', nextId: 29 },{ text: '否', nextId: 37 }],
},
{
id:29,
text: [
  '這是自然的，感謝您的分享。現在讓我們談談產後抑鬱症。某些新媽媽的情況更為令人沮喪，我們討論過的困難更持久、更強烈。產後抑鬱症是指症狀持續存在，幾乎每天都沒有緩解，持續至少兩週。',
  '您知道抑鬱症的症狀嗎？'
],
options:[{ text: '是', nextId: 30 },{ text: '否', nextId: 38 }],
},
{
id:30,
text: [
  '太好了！了解抑鬱症的跡象對理解自己的心理健康和尋求支持非常重要。如果您對跡象有任何疑問或想進一步討論，請隨時詢問。我在這裡隨時提供信息和支持。您對自己的情緒很了解。未來的會話中，我們將討論應對情緒變化的方法。',
],
options:[{ text: '下一步', nextId: 31 }],
},
{
id:31,
text: [
  '在我們結束這次會話之前，您是否了解產後憂鬱和產後抑鬱症之間的區別？'
],
options:[{ text: '是', nextId: 32 },{ text: '否', nextId: 45 }],
},
{
id:32,
text: [
  '做得好！如果您對產後憂鬱和產後抑鬱症之間的區別有任何疑問，請隨時詢問。我在這裡幫助解決您可能有的任何疑慮。提醒您，這裡有一些應注意的症狀：',
  '產後憂鬱——30-80% 的人會經歷產後憂鬱。它通常在分娩後的 2-5 天發生，通常在大約兩週後消失。一些症狀包括',
  '哭泣、情緒波動、疲憊、緊張、焦慮、坐立不安',
],
options:[{ text: '下一步', nextId: 33 }],
},
{
id:33,
text:[
  '產後抑鬱症階段 睡眠問題（例如：在餵奶後無法再次入睡）',
  '飲食問題——吃得太多或太少',
  '焦慮和擔憂避免人群，避免與寶寶接觸，想要獨處',
  '沒有精力',
  '死亡願望，自殺想法',
  '難以對寶寶產生積極的感情',
  '難以做出決策',
  '狂躁——感到快速、興奮和易怒，說話快，需要的睡眠時間少',
  '恐慌發作',
  '對寶寶的擔憂，對傷害或殺死寶寶的幻想'
],
options:[{ text: '下一步', nextId: 34 }],
},
{
id:34,
text: [
  '如果您曾經感到自己可能會傷害自己、寶寶或其他任何人，請與您的醫療提供者交談或撥打 911。',
],
options:[{ text: '下一步', nextId: 35 }],
},
{
id:35,
text: [
  '在下一次會話中，我們將幫助您了解產後抑鬱症的風險因素、症狀和治療選擇。',
  '強調感受的正常性，尋求幫助的重要性，以及在過渡到母親身份期間應對和尋求支持的策略。'
],
options:[{ text: '下一步', nextId: 48 }],
},
{
id:45,
text: [
  '沒關係。讓我們快速回顧一下。產後憂鬱和產後抑鬱症之間的區別在於其持續時間、嚴重程度和對日常功能的影響。產後憂鬱通常是輕微的、短暫的悲傷情緒和情緒波動，通常在分娩後的前幾週內發生，並且通常會自行消失。',
],
options:[{ text: '好', nextId: 46 }],
},
{
id:46,
text: [
  '另一方面，產後抑鬱症是一種更嚴重且持續時間更長的情況，其特點是持續的悲傷、絕望和焦慮，這可能會顯著干擾母親照顧自己和寶寶的能力。了解跡象並尋求支持對您或您認識的任何人都至關重要。以下是可能有幫助的列表。',
],
options:[{ text: '好', nextId: 32 }],
},
// Session 2
{
id:48,
text: [
  '如果您想繼續會話，我們將進行放鬆練習和角色轉變。請按確定。',
],
options:[{ text: '好', nextId: 49 },{ text: '重複', nextId: 1 }],
},
{
id: 49,
text: [
  '歡迎來到第二節課！！！今天我們將深入探討放鬆練習和角色轉變。',
  '從研究中我們知道，曾經有過抑鬱症發作的女性，尤其是產後抑鬱症，在分娩後更有可能出現抑鬱症。還有那些有抑鬱症或家人有抑鬱症或心理健康問題的歷史，以及那些缺乏支持系統的女性。重要的是，這是可以治療的。這裡提醒您產後抑鬱症的症狀：',
  '飲食問題——吃得太多或太少。焦慮和擔憂',
  '避免人群，避免與寶寶接觸，想要獨處',
  '沒有精力',
  '自殺想法',
  '難以對寶寶產生積極的感情',
  '難以做出決策',
  '狂躁——感到快速、興奮和易怒，說話快，需要的睡眠時間少',
  '恐慌發作',
  '對寶寶的擔憂，對傷害或殺死寶寶的幻想'
],
options: [{ text: '好', nextId: 62 }],
},
{
id: 62,
text: [
  '如果您有我們討論過的任何症狀，請與您的醫生、醫療提供者或甚至您的寶寶醫生討論，並要求轉診到心理健康專業人士、治療師、輔導員、社工或精神科醫生。這裡有不同的資源，請隨時備用：孕產婦心理健康熱線、家庭暴力、育兒、法律服務、自殺預防',
],
options: [{ text: '好', nextId: 63 }],
},
{
id: 63,
text: [
  '這些會話最重要的信息是，儘管您可能會有“產後憂鬱”或甚至產後抑鬱症，您不應該對這些感覺感到羞恥、內疚、認為自己是一個壞母親，或認為自己有什麼問題。',
],
options: [{ text: '好', nextId: 64 }],
},
{
id: 64,
text: [
  '您需要了解，許多這些感受是對擁有新寶寶的正常反應。記住：您不是孤單的，您不是被責備的，您可以感覺好起來。',
],
options: [{ text: '好', nextId: 65 }],
},   
{
id: 65,
text: [
  '另外，談論您的困難是可以的。不要因為談論您的感覺而感到尷尬或害怕。實際上，如果您與親近的人談論您的感覺，您會感覺好很多。',
  '擁有寶寶是一個重大的事件，會影響身體和心理。這一切都發生在您需要照顧一個需要、無助的人的時候，並且通常睡眠不足，外界幫助很少。在接下來的幾節課中，我們將討論在寶寶出生後應對“過山車般的”情況的方法。'
],
options: [{ text: '好', nextId: 66 }],
},   
{
id: 66,
text: [
  '我無法為您鋪平道路或消除一些糟糕的顛簸。然而，我們將討論一些方法，幫助您管理憂鬱或低落的時刻，這樣您就不會陷入抑鬱。',
],
options: [{ text: '好', nextId: 67 }],
},   
{
id: 67,
text: [
  '我們的 ROSE 協議方法基於這樣一個理念：您正在面臨一個非常重大的過渡。我們知道這需要新的技能並帶來新的需求。當我們面臨壓力時，一個主要問題是，擁有良好的支持可以真的有幫助。良好的支持可以減少女性在分娩後發展產後抑鬱症的可能性。不幸的是，當您應該得到大量支持時，許多女性會驚訝地發現，在這段時間內獲得支持並不容易。',
],
options: [{ text: '好', nextId: 68 }],
},   
{
id: 68,
text: [
  '照顧寶寶的需求可能會減少與其他人的社交時間，寶寶的需求可能對人際關係造成很大壓力，其他人可能根本不知道您需要什麼樣的支持或幫助。因此，我們在 nurtur 希望盡一切可能幫助您感覺到您得到了應得的支持。',
  '我們不能保證這一點，但我們可以幫助您考慮對支持的目標，並教您一些在這方面的策略。我們將重點放在不同的方式上，以減少寶寶出生後您的生活壓力，並討論您生活中的支持者。'
],
options: [{ text: '好', nextId: 69 }],
},
{
id: 69,
text: [
  '從 1 到 10 的等級中，1 表示沒有焦慮，10 表示高度焦慮，您現在的焦慮水平是多少？'
],
options: [{ text: '1', nextId: 70 },{ text: '2', nextId: 70 },{ text: '3', nextId: 70 },{ text: '4', nextId: 70 },{ text: '5', nextId: 70 },{ text: '6', nextId: 70 },{ text: '7', nextId: 70 },{ text: '8', nextId: 70 },{ text: '9', nextId: 70 },{ text: '10', nextId: 70 }],
},
{
id: 70,
text: [
  '我將為您提供練習的腳本。我建議每天練習 10-20 分鐘。定期練習將強化您的放鬆技能，這樣您在需要控制壓力時更可能使用它。',
  '讓我們開始，找到一個舒適的姿勢，無論是坐著還是躺著，選擇您覺得最舒服的姿勢。深吸一口氣，然後呼氣，放鬆身體的任何緊張感。'
],
options: [{ text: '好', nextId: 71 }],
},
{
id: 71,
text: [
  '現在讓我們開始一個叫做漸進性肌肉放鬆的放鬆練習。',
  '1. 當處理生活壓力，特別是處理緊張的關係或難以入睡時，找到放鬆和管理壓力的方法非常有幫助。',
  '2. 放鬆技巧也被證明可以減少壓力和緊張。漸進性肌肉放鬆旨在通過教您緊張然後放鬆各種肌肉群來放鬆身體的肌肉。',
  '3. 先創造緊張然後立即釋放這種緊張，您可以更好地減少肌肉緊張。突然的緊張釋放會產生一種特殊的能量，使肌肉能夠放鬆到超過正常的休息水平。',
  '4. 將注意力轉移到小腿和大腿。通過將腳後跟壓在地上來收緊下腿的肌肉，然後釋放。感受腿部的溫暖和放鬆。',
  '5. 接下來，通過緊緊擠壓臀部肌肉來收緊臀部肌肉，然後放鬆。注意身體緊張和放鬆之間的區別。',
  '6. 現在，集中注意力在胃部。通過將肚臍朝脊柱方向拉來收緊腹肌，然後放鬆。讓呼吸變得深沉而自然。',
  '7. 將注意力轉移到胸部和背部。深吸一口氣，充滿肺部空氣，然後保持片刻。現在，慢慢而徹底地呼氣，釋放胸部和背部肌肉的任何緊張感。',
  '8. 將注意力轉移到肩膀。聳肩至耳朵處，然後讓它們下垂，釋放您可能攜帶的任何緊張感。感受肩膀的重量沉入下方的表面。',
  '9. 現在，轉移注意力到手臂和手。握拳，緊緊握住，然後放鬆。感受放鬆後手臂的緊張感。',
  '10. 最後，集中注意力在臉部和頸部。緊緊皺起臉，皺起額頭和眯起眼睛，然後放鬆。讓下巴放鬆，讓嘴唇微微張開。',
  '11. 花點時間從頭到腳掃描您的身體，注意任何剩餘的緊張區域。',
  '12. 如果您注意到任何緊張，深吸一口氣，然後呼氣時，想像這種緊張融化，讓您感覺完全放鬆和輕鬆。',
  '13. 再深深而緩慢地呼吸幾次，享受身體放鬆的感覺。',
  '14. 當您準備好時，輕輕睜開眼睛，回到當下，感到神清氣爽。'
],
options: [{ text: '好', nextId: 72 }],
},
{
id: 72,
text: [
  '現在，請根據同樣的等級從 1 到 10 評估您的焦慮水平。顯示 1 到 10 的數字'
],
options: [{ text: '1', nextId: 73 },{ text: '2', nextId: 73 },{ text: '3', nextId: 73 },{ text: '4', nextId: 73 },{ text: '5', nextId: 73 },{ text: '6', nextId: 73 },{ text: '7', nextId: 73 },{ text: '8', nextId: 73 },{ text: '9', nextId: 73 },{ text: '10', nextId: 73 }],
},
{
id: 73,
text: [
  '現在，讓我們進入今天的主要話題——角色轉變。',
  '擁有寶寶將改變您的生活。無論好壞的變化都會帶來壓力，因為這些變化都帶來了新的需求、新的時間表。',
  '想想新娘婚禮的壓力。大變化，特別是壓力大的變化，往往與抑鬱相關。當媽媽家中有非常小的孩子時，是抑鬱的高風險期之一。'
],
options: [{ text: '好', nextId: 74 }],
},
{
id: 74,
text: [
  '好消息是，有很多關於如何有效度過大變化的信息。今天，我們將指導您了解一些重要的工具，幫助您思考這些重大變化/過渡。我有一些信息，預覽我們將討論的角色轉變。您能想到其他例子嗎？這些例子是您在角色轉變過程中經歷的變化，您是如何度過的？'
]},
{
id: 75,
text: [
  '我理解並承認您的意思。現在讓我們談談寶寶來臨後您的生活將會帶來的變化。通過增加我們的理解，我們可以希望在這段動盪時期更好地滿足我們的需求。'
],
options: [{ text: '好', nextId: 76 }],
},
{
id: 76,
text: [
  '讓我們首先探索一些在寶寶到來時您可能會失去或需要改變的東西。有哪些損失是您想到的？'
]
},
{
id: 77,
text: [
  '我理解並承認您的意思。一些損失的例子包括：-',
  '1. 失去常規，因為寶寶會打亂 24 小時的時間表',
  '2. 家務活無法完成',
  '3. 與伴侶、其他孩子、朋友的時間減少',
  '4. 減少洗衣、購物的效率。',
  '5. 減少社交機會，導致孤立。',
  '6. 失去目的感，長時間待在家裡。',
  '7. 甚至失去物理空間，需要與寶寶分享房間或搬出目前的住處。',
  '8. 孤立'
],
options: [{ text: '下一步', nextId: 78 }],
},   
{
id: 78,
text: [
  '承認這些損失是很重要的。但讓我們也探索一下作為新媽媽您可能獲得的好處和新機會。有哪些您期待的或認為這次變化可能帶來的積極方面？'
]
},
{
id: 79,
text: [
  '太好了。留在家裡與新生兒在一起可以強迫您：-',
  '與寶寶共度時光。',
  '觀察寶寶的成長。',
  '這是一個享受孩子對世界反應的機會。',
  '放慢速度，而不是過著忙碌的生活。',
  '您將更多了解自己作為母親，可能會發現一些隱藏的才能',
  '做家務的好藉口 😊'
],
options: [{ text: '下一步', nextId: 80 }],
},
{
id: 80,
text: [
  '這次變化帶來的各種感受是正常的——憤怒、恐懼、不安全感。當您想到這些損失和收益時，有哪些感受浮現在您的腦海中？'
]
},
{
id: 81,
text: [
  '這些都是非常可以理解的感覺。承認它們並不感到內疚是很重要的。您仍然可以享受生活，但您可能需要以不同的方式進行。生存母親身份的最重要法則是照顧自己的需求。照顧母親。'
],
options: [{ text: '下一步', nextId: 82 }],
},
{
id: 82,
text: [
  '作為母親就像是一個水壺——您不斷倒出，給予和給予，因為您需要照顧周圍的需求——寶寶、家人、朋友、伴侶。',
  '如果您不採取行動並重新填滿水壺，很快它就會空了。',
  '沒有人是無底的水壺。空的狀態會讓您非常容易抑鬱。如果您的需求沒有得到滿足，您無法成為一個好的父母。給自己許可。'
],
options: [{ text: '好', nextId: 83 }],
},  
{
id: 83,
text: [
  '想法如“我在發脾氣、軟弱、被寵壞、自私”會不斷跳入您的腦海，但您必須糾正自己，認為“我正在為我的寶寶/家人投資自己”。當您失去其他讓您感覺良好的積極事物時，增加積極的活動和經歷是很重要的。這段時間您自然會缺乏動力，並且感到疲倦。但有意地增加積極的活動可以減少負面情緒——它可以為您提供能量。'
],
options: [{ text: '下一步', nextId: 84 }],
},  
{
id: 84,
text: [
  '愉快活動與情緒之間存在直接關係，您做的愉快事情越多，您感覺就越好。但您必須給自己這樣做的許可。在下一次會話中，我們將討論一些寶寶出生後您可以做的積極活動。祝您有個美好的一天！我們明天可以聊聊。'
],
options: [{ text: '好', nextId: 85 }],
},  
{
id: 85,
text: [
  '您想繼續進入下一次會話，我們將討論愉快的活動和親密的人，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 86 },{ text: '重複', nextId: 49 }],
},
{
id: 86,
text: [
  '歡迎來到第三節課！！！今天我們將討論愉快的活動和親密的人。',
  '在寶寶出生後，您能看到自己做的哪些積極愉快的活動？即使是小事也很重要。'
],
},
{
id: 87,
text: [
  '我理解並承認您的意思。愉快的活動可以像閱讀漫畫、看舊照片、看喜愛的電視節目、鍛煉、泡泡浴、點燃蠟燭這樣簡單。關鍵是要對自己好。您值得這樣做。做自己的好朋友。這是一個重要的時期來照顧自己。'
],
options: [{ text: '下一步',nextId:101}],
},
{
id: 101,
text: [
  '休息時間意味著不做家務。試著每天安排至少一次涉及成人的活動，即使只是電話。',
  '避免現在進行任何重大變化——最好在您的荷爾蒙更加穩定時再處理這些變化。',
  '保持目標現實——在早期的幾週內，能洗個澡就是一個偉大的成就！記住，沒有超級媽媽這回事。',
  '您會犯錯，這沒關係。重點是要做一個“足夠好的”母親。'
],
options: [{ text: '好',nextId:102}],
},
{
id: 102,
text: [
  '做一些讓您感覺自信和有成就感的事情，即使是小事。',
  '給自己所做的事情予以肯定——您會對自己實際上做的事情感到驚訝，但通常不會給自己肯定。'
],
options: [{ text: '好',nextId:103}],
},
{
id: 103,
text: [
  '另一個關鍵方面是發展支持。我們知道來自他人的強大支持可以保護您免受抑鬱。',
  '能夠向他人傾訴並得到支持的人不太可能變得抑鬱。擁有關心您的人對緩解分娩後的任何困擾至關重要。',
  '有證據表明，在面臨重大壓力時，強大的支持可以顯著降低抑鬱風險。如果您猶豫是否尋求幫助，您可能會變得容易不知所措。'
],
options: [{ text: '下一步',nextId:104}],
},
{
id: 104,
text: [
  '現在仔細思考這些問題，然後輸入您的想法。',
  '1. 您可以尋求幫助的人是誰？',
  '2. 除了孩子，您感到親近的人是誰？',
  '3. 五年後，您認為誰還會在您身邊？',
  '4. 您認為您會需要什麼樣的幫助？'
],
},
{
id: 105,
text: [
  '有時尋求幫助並不起作用。家庭成員或朋友可能無法或不願意提供支持。您不需要感覺自己是個負擔。',
  '當您有伴侶、丈夫或家人不在您身邊時，這是一個真正的問題。但您不必責怪自己。',
  '您有權尋求幫助和支持。重要的是從那些樂於接受和關心您的人那裡尋求支持，無論他們是誰。',
  '如果他們不在您的家中或您目前的朋友圈中，那麼請向支持團體、宗教場所、專業人士等尋求幫助。'
],
options: [{ text: '下一步',nextId:106}],
},
{
id: 106,
text: [
  '除非您完全避免人際關係，否則您可能會遇到一些失望。',
  '請注意，不要在感到壓力時拒絕所有人。',
  '大多數人都有缺點。在決定接受誰——包括缺點——時，問問自己，“好處是否超過壞處。”',
  '由您決定接受哪些類型的缺點。'
],
options: [{ text: '下一步',nextId:107}],
},
{
id: 107,
text: [
  '讓我們來考慮“親密的人”，這可以幫助您進一步確定支持來源。我們可以一起完成。考慮以下問題，然後分享您的想法：',
  '1. 在每個領域中，您最常見到的人是誰？您可以尋求誰的幫助？',
  '2. 從每個類別中列出 2 個人：-',
  '-家庭',
  '-工作',
  '-靈性',
  '-其他'
]
},
{
id: 108,
text: [
  '現在“親密的人”已經確定，讓我們談談關係溝通——看看您如何與親人交流，改善交流的方式，特別是如何尋求幫助，以及處理任何虐待關係。'
],
options: [{ text: '下一步',nextId:109}],
},
{
id: 109,
text: [
  '請求他人幫助的小貼士 時機：',
  '選擇一個安靜的時間。引入您想要談論的事實。非語言行為：',
  '使用適當的語氣，避免威脅性的姿態，與對方保持眼神交流，面對他們。說些積極的話：',
  '將負面夾在積極之間。以積極開始並以積極結束。'
],
options: [{ text: '下一步',nextId:110}],
},      
{
id: 110,
text: [
  '這次會議後的作業是製作您自己的愉快活動前二十名清單。您可以從一些愉快活動的想法開始：',
  '想想我喜歡的人',
  '看美麗的風景',
  '呼吸清新的空氣',
  '觀察人群',
  '進行坦誠和開放的對話',
  '曬太陽',
  '穿乾淨的衣服',
  '以自己的方式做一個項目',
  '聽音樂',
  '閱讀故事、小說、詩歌或戲劇',
  '向某人表達我的愛意',
  '與我愛的人在一起',
  '讚美或表揚某人',
  '聽收音機',
  '見老朋友',
  '泡澡',
  '收藏東西（硬幣、貝殼等）',
  '在週中去看電影',
  '笑',
  '想起我的過去旅行',
  '閱讀雜誌或報紙',
  '照顧我的植物',
  '在家裡唱歌',
  '做宗教活動（去教堂、團體祈禱等）',
  '畫畫',
  '做針線活',
  '睡覺',
  '做手工藝品',
  '為某人做禮物',
  '縫紉',
  '去美容院',
  '白日夢',
  '看電視',
  '想像愉快的事件',
  '寫日記或信件',
  '冥想',
  '玩牌',
  '看和/或展示照片或幻燈片',
  '編織',
  '做填字遊戲',
  '打扮和看起來不錯',
  '做按摩',
  '拼拼圖'
],
options: [{ text: '下一步',nextId:111}],
},  
{
id: 111,
text: [
  '希望這些活動清單能幫助您準備愉快活動前二十名清單。',
  '試著在接下來的一週內安排至少三次愉快的活動。',
  '在活動前後根據 1 到 5 的等級評估您的情緒。'
],
options: [{ text: '下一步',nextId:112}],
},
{
id: 112,
text: [
  '有時您可能不想做任何事情，但參與愉快的活動可以實際上提供能量並提升您的情緒。',
  '還要記得每天練習放鬆練習，並在前後評估您的焦慮水平。'
],
options: [{ text: '下一步',nextId:113}],
}, 
{
id: 113,
text: [
  '我們將在下一次會話中涵蓋更多內容。'
],
options: [{ text: '下一步',nextId:114}],
},        
{
id: 114,
text: [
  '您想繼續進入下一次會話，我們將深入討論關係，還是重複這次會話？'
],
options: [{ text: '繼續',nextId:115},{ text: '重複',nextId:86}],
}, 
{
id: 115,
text: [
  '歡迎回來！今天我們將深入討論關係。',
  '讓我們稍微回顧一下您的作業。',
  '您能否通過愉快的活動影響您的情緒？記住，您做的愉快事情越多，您感覺就越好。愉快的活動可以真正為您提供能量。您自上次互動以來有做過特定的愉快活動嗎？'
],
options: [{ text: '是', nextId: 129 },{ text: '否', nextId: 129 }],
},
{
id: 129,
text: [
  '您是否容易跟隨？'
],
options: [{ text: '是', nextId: 130 },{ text: '否', nextId: 130 }],
},
{
id: 130,
text: [
  '如果您未能完成作業活動，也沒關係。我理解生活可能是不可預測的，有時事情並不如計劃進行。',
  '記住，治療是一段旅程，途中遇到挑戰是很自然的。您對經歷的坦誠很有價值，有助於指導我們的工作。',
  '請花時間列出您的愉快活動前二十名，這將幫助您感覺更好，這成為一種練習，為寶寶的到來做好準備。'
],
options: [{ text: '下一步', nextId: 131 }],
},
{
id: 131,
text: [
  '完成活動後，您感覺如何？'
]
},
{
id: 132,
text: [
  '我理解並承認您的意思。讓我們讓您在接下來的幾天內承諾再做兩三項活動：-',
  '1. 對自己安排這些“約會”要像對待您最緊急的約會一樣忠實。',
  '2. 今天我們的主要重點將是關係。',
  '3. 關係很難，通常參與其中的人有非常不同的需求和期望。',
  '3. 需要付出很多努力。人們帶來他們自己的希望和夢想，這些希望和夢想並不總是與另一個人的相符。',
  '4. 生活環境的變化，例如有了寶寶，增加了更大的壓力和不同的壓力。'
],
options: [{ text: '下一步', nextId: 133}],
},
{
id: 133,
text: [
  '有證據表明，有寶寶會給伴侶關係帶來極大的壓力。',
  '許多人經歷更多的衝突，因為父母需要管理的需求太多。'
],
options: [{ text: '下一步', nextId: 134 }],
},
{
id: 134,
text: [
  '同時，擁有強大的支持在壓力大的時期對抗悲傷和抑鬱是非常有幫助的。',
  '所以這是一個額外支持非常有價值的時期，然而對於許多新媽媽來說更難找到支持。'
],
options: [{ text: '下一步', nextId: 135 }],
},
{
id: 135,
text: [
  '讓我們花點時間來探索您當前的支持系統。',
  '支持來自朋友和家人是很重要的。他們不必是完美的，只要能讓您感到支持。'
],
options: [{ text: '下一步', nextId: 136 }],
},
{
id: 136,
text: [
  '花點時間想一想：-',
  '1. 支持系統是什麼意思？',
  '2. 您感到支持嗎？',
  '3. 當您考慮親密的人時，哪些人會聽您說話？',
  '4. 誰在您需要幫助時會幫助您？'
],
options: [{ text: '下一步', nextId: 137 }],
},
{
id: 137,
text: [
  '記下來。在這個過程中，重要的是要有一個由家人和朋友組成的支持系統來幫助您。無論您身邊有誰來支持您，他們不必是完美的。重要的是他們對您表示同情。'
],
options: [{ text: '下一步', nextId: 138 }],
},
{
id: 138,
text: [
  '如果您感到孤立或缺乏支持，請告訴我。我們可以探索一些策略來幫助您建立或增強支持系統。'
],
options: [{ text: '下一步', nextId: 139 }],
},
{
id: 139,
text: [
  '有誰您覺得可以在這段過渡期中依賴？請分享您的想法。'
]
},
{
id: 140,
text: [
  '讓我們探討一些策略來幫助您增強這些支持關係：-',
  '1. 定期與您的支持者聯繫，即使只是簡短的電話或信息。',
  '2. 計劃一些與支持者的愉快活動，即使是簡單的咖啡聚會。',
  '3. 向他們表達您的感謝，讓他們知道他們對您的重要性。',
  '4. 開放並誠實地與他們分享您的感受和需求。',
  '5. 不要害怕尋求他們的幫助和支持。'
],
options: [{ text: '下一步', nextId: 141 }],
},
{
id: 141,
text: [
  '在探索了您的支持系統之後，讓我們回到關係的主題。',
  '重要的是要意識到您的需求和期望可能會改變，特別是在寶寶出生後。',
  '與您的伴侶、家人和朋友進行開放和誠實的溝通是非常重要的。'
],
options: [{ text: '下一步', nextId: 142 }],
},
{
id: 142,
text: [
  '請花些時間思考以下問題：-',
  '1. 您的需求和期望是什麼？',
  '2. 您如何與伴侶、家人和朋友分享這些需求和期望？',
  '3. 您如何確保他們理解並尊重您的需求和期望？'
],
options: [{ text: '下一步', nextId: 143 }],
},
{
id: 143,
text: [
  '分享您的想法和感受，並讓我知道我們可以如何進一步支持您。'
],
options: [{ text: '下一步', nextId: 144 }],
},
{
id: 144,
text: [
  '在寶寶出生後，您可能會發現自己在處理新的挑戰和壓力源。',
  '重要的是要記住，您不是孤單的。尋求支持並與他人分享您的經歷是非常有幫助的。'
],
options: [{ text: '下一步', nextId: 145 }],
},
{
id: 145,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 愉快的活動可以幫助提升您的情緒。',
  '2. 建立和增強支持系統是非常重要的。',
  '3. 與您的伴侶、家人和朋友進行開放和誠實的溝通是關鍵。'
],
options: [{ text: '下一步', nextId: 146 }],
},
{
id: 146,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
]
},
{
id: 147,
text: [
  '您想繼續進入下一次會話，我們將討論壓力管理，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 148 },{ text: '重複', nextId: 115 }],
},
{
id: 148,
text: [
  '歡迎回來！今天我們將深入討論壓力管理。',
  '壓力是生活的一部分，特別是在寶寶出生後。',
  '了解如何有效管理壓力對您的心理健康和幸福非常重要。'
],
options: [{ text: '下一步', nextId: 149 }],
},
{
id: 149,
text: [
  '讓我們首先探索一些常見的壓力來源：-',
  '1. 照顧寶寶的需求',
  '2. 缺乏睡眠',
  '3. 家庭責任',
  '4. 工作壓力',
  '5. 經濟壓力',
  '6. 健康問題'
],
options: [{ text: '下一步', nextId: 150 }],
},
{
id: 150,
text: [
  '您目前面臨的主要壓力來源是什麼？請分享您的想法。'
],
options: [{ text: '下一步', nextId: 151 }],
},
{
id: 151,
text: [
  '了解壓力來源後，讓我們討論一些有效的壓力管理策略：-',
  '1. 深呼吸練習',
  '2. 正念冥想',
  '3. 體育鍛煉',
  '4. 保持健康的飲食',
  '5. 與朋友和家人分享您的感受',
  '6. 尋求專業支持'
],
options: [{ text: '下一步', nextId: 152 }],
},
{
id: 152,
text: [
  '讓我們進行一個簡單的深呼吸練習：-',
  '1. 找一個舒適的位置坐下或躺下。',
  '2. 閉上眼睛，專注於您的呼吸。',
  '3. 深深地吸氣，感覺您的腹部上升。',
  '4. 慢慢地呼氣，感覺您的腹部下降。',
  '5. 重複這個過程幾次，讓您的身體和心靈放鬆。'
],
options: [{ text: '好', nextId: 153 }],
},
{
id: 153,
text: [
  '現在，請根據同樣的等級從 1 到 10 評估您的壓力水平。顯示 1 到 10 的數字'
],
options: [{ text: '1', nextId: 154 },{ text: '2', nextId: 154 },{ text: '3', nextId: 154 },{ text: '4', nextId: 154 },{ text: '5', nextId: 154 },{ text: '6', nextId: 154 },{ text: '7', nextId: 154 },{ text: '8', nextId: 154 },{ text: '9', nextId: 154 },{ text: '10', nextId: 154 }],
},
{
id: 154,
text: [
  '太好了！我很高興它幫助您降低了壓力水平。',
  '讓我們繼續討論壓力管理策略。'
],
options: [{ text: '下一步', nextId: 155 }],
},
{
id: 155,
text: [
  '正念冥想是一種有效的減壓工具。',
  '它幫助您專注於當下，減少焦慮和壓力。',
  '讓我們進行一個簡單的正念冥想練習：-',
  '1. 找一個安靜的地方坐下或躺下。',
  '2. 閉上眼睛，專注於您的呼吸。',
  '3. 注意您的呼吸，感受空氣進出您的鼻子和肺部。',
  '4. 如果您的思緒漂移，輕輕地將注意力拉回到您的呼吸上。',
  '5. 持續這樣幾分鐘，讓您的心靈平靜下來。'
],
options: [{ text: '好', nextId: 156 }],
},
{
id: 156,
text: [
  '正念冥想可以幫助您減少壓力，提升情緒。',
  '定期練習將強化這種技能，讓您在需要時更容易使用它。',
  '在接下來的幾天內，嘗試每天練習幾分鐘正念冥想，並觀察它對您的壓力水平的影響。'
],
options: [{ text: '好', nextId: 157 }],
},
{
id: 157,
text: [
  '體育鍛煉也是一種有效的減壓策略。',
  '它幫助釋放壓力，提升心情，增強體力。',
  '每天進行適量的運動，即使只是散步，也可以幫助您減少壓力，提升幸福感。'
],
options: [{ text: '好', nextId: 158 }],
},
{
id: 158,
text: [
  '保持健康的飲食對壓力管理也很重要。',
  '吃均衡的飲食，確保獲得足夠的營養，這樣您的身體和心靈都能更好地應對壓力。'
],
options: [{ text: '好', nextId: 159 }],
},
{
id: 159,
text: [
  '與朋友和家人分享您的感受是另一個減壓策略。',
  '尋求支持和理解，與他人分享您的經歷，這樣可以減少壓力，增強您的支持系統。'
],
options: [{ text: '好', nextId: 160 }],
},
{
id: 160,
text: [
  '如果您感到壓力過大，無法應對，請尋求專業支持。',
  '心理健康專業人士可以提供工具和策略，幫助您管理壓力，提升心理健康。'
],
options: [{ text: '好', nextId: 161 }],
},
{
id: 161,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 深呼吸練習',
  '2. 正念冥想',
  '3. 體育鍛煉',
  '4. 健康飲食',
  '5. 與朋友和家人分享您的感受',
  '6. 尋求專業支持'
],
options: [{ text: '下一步', nextId: 162 }],
},
{
id: 162,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的壓力管理策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
],
options: [{ text: '下一步', nextId: 163 }],
},
{
id: 163,
text: [
  '您想繼續進入下一次會話，我們將討論自我照顧，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 164 },{ text: '重複', nextId: 148 }],
},
{
id: 164,
text: [
  '歡迎回來！今天我們將深入討論自我照顧。',
  '自我照顧是維持心理健康和幸福的關鍵。',
  '讓我們首先探索一些常見的自我照顧活動：-',
  '1. 健康飲食',
  '2. 體育鍛煉',
  '3. 充足的睡眠',
  '4. 正念冥想',
  '5. 與朋友和家人共度時光',
  '6. 參與愉快的活動'
],
options: [{ text: '下一步', nextId: 165 }],
},
{
id: 165,
text: [
  '您目前的自我照顧活動是什麼？請分享您的想法。'
],
options: [{ text: '下一步', nextId: 166 }],
},
{
id: 166,
text: [
  '了解自我照顧活動後，讓我們討論一些有效的自我照顧策略：-',
  '1. 計劃和安排自我照顧時間',
  '2. 優先考慮您的需求',
  '3. 設定健康的界限',
  '4. 向他人尋求幫助',
  '5. 練習感恩和積極思維'
],
options: [{ text: '下一步', nextId: 167 }],
},
{
id: 167,
text: [
  '讓我們進行一個簡單的感恩練習：-',
  '1. 找一個安靜的地方坐下或躺下。',
  '2. 閉上眼睛，專注於您的呼吸。',
  '3. 花些時間思考今天讓您感到感激的三件事。',
  '4. 將這些感恩的感覺放在心中，讓它們充滿您的身體和心靈。',
  '5. 當您準備好時，輕輕睜開眼睛，回到當下，感到感恩和幸福。'
],
options: [{ text: '好', nextId: 168 }],
},
{
id: 168,
text: [
  '現在，請根據同樣的等級從 1 到 10 評估您的幸福水平。顯示 1 到 10 的數字'
]
},
{
id: 169,
text: [
  '太好了！我很高興它幫助您提升了幸福水平。',
  '讓我們繼續討論自我照顧策略。'
],
options: [{ text: '下一步', nextId: 170 }],
},
{
id: 170,
text: [
  '計劃和安排自我照顧時間是維持自我照顧的關鍵。',
  '在您的日程安排中劃出特定的時間來進行自我照顧活動，這樣您就更有可能堅持下去。'
],
options: [{ text: '好', nextId: 171 }],
},
{
id: 171,
text: [
  '優先考慮您的需求是另一個重要的自我照顧策略。',
  '確保您每天都有時間照顧自己的需求，這樣您才能更好地照顧寶寶和家人。'
],
options: [{ text: '好', nextId: 172 }],
},
{
id: 172,
text: [
  '設定健康的界限是維持自我照顧的關鍵。',
  '學會說“不”，並確保您不會被過多的責任和壓力壓垮。'
],
options: [{ text: '好', nextId: 173 }],
},
{
id: 173,
text: [
  '向他人尋求幫助是另一個重要的自我照顧策略。',
  '不要害怕向家人、朋友或專業人士尋求幫助，這樣您就不會感到孤立和不知所措。'
],
options: [{ text: '好', nextId: 174 }],
},
{
id: 174,
text: [
  '練習感恩和積極思維可以幫助您提升幸福感。',
  '每天花些時間思考讓您感到感激的事情，這樣您就能更好地應對壓力和挑戰。'
],
options: [{ text: '好', nextId: 175 }],
},
{
id: 175,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 自我照顧活動',
  '2. 計劃和安排自我照顧時間',
  '3. 優先考慮您的需求',
  '4. 設定健康的界限',
  '5. 向他人尋求幫助',
  '6. 練習感恩和積極思維'
],
options: [{ text: '下一步', nextId: 176 }],
},
{
id: 176,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的自我照顧策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
]
},
{
id: 177,
text: [
  '您想繼續進入下一次會話，我們將討論母乳餵養，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 178 },{ text: '重複', nextId: 164 }],
},
{
id: 178,
text: [
  '歡迎回來！今天我們將深入討論母乳餵養。',
  '母乳餵養是為您的寶寶提供營養和免疫保護的重要方式。',
  '讓我們首先探索一些母乳餵養的好處：-',
  '1. 提供寶寶所需的所有營養',
  '2. 增強寶寶的免疫系統',
  '3. 促進母嬰親密關係',
  '4. 幫助母親恢復健康',
  '5. 減少寶寶患病風險'
],
options: [{ text: '下一步', nextId: 179 }],
},
{
id: 179,
text: [
  '您目前的母乳餵養情況如何？請分享您的經驗和挑戰。'
],
options: [{ text: '下一步', nextId: 180 }],
},
{
id: 180,
text: [
  '了解母乳餵養的好處後，讓我們討論一些有效的母乳餵養技巧：-',
  '1. 確保寶寶正確含乳',
  '2. 採用舒適的餵養姿勢',
  '3. 經常餵養寶寶',
  '4. 保持健康的飲食和充足的水分',
  '5. 尋求支持和指導'
],
options: [{ text: '下一步', nextId: 181 }],
},
{
id: 181,
text: [
  '讓我們進行一個簡單的母乳餵養練習：-',
  '1. 找一個舒適的位置坐下或躺下。',
  '2. 將寶寶放在您的胸前，確保寶寶的頭部和身體對齊。',
  '3. 輕輕地將寶寶的嘴巴引導到乳頭，確保寶寶正確含乳。',
  '4. 讓寶寶自然吸吮，確保寶寶感到舒適和滿足。'
],
options: [{ text: '好', nextId: 182 }],
},
{
id: 182,
text: [
  '現在，請分享您的母乳餵養經驗和感受。'
]
},
{
id: 183,
text: [
  '太好了！我很高興您分享了您的經驗和感受。',
  '母乳餵養是建立母嬰親密關係的重要方式。',
  '請繼續練習母乳餵養技巧，並尋求支持和指導，以確保寶寶獲得最佳營養和健康。'
],
options: [{ text: '好', nextId: 184 }],
},
{
id: 184,
text: [
  '如果您在母乳餵養過程中遇到任何困難或挑戰，請隨時尋求支持和指導。',
  '您的醫療提供者、哺乳顧問和母乳餵養支持團體可以提供有價值的幫助和建議。'
],
options: [{ text: '好', nextId: 185 }],
},
{
id: 185,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 母乳餵養的好處',
  '2. 有效的母乳餵養技巧',
  '3. 母乳餵養經驗和感受',
  '4. 尋求支持和指導'
],
options: [{ text: '下一步', nextId: 186 }],
},
{
id: 186,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的母乳餵養技巧和策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
],
options: [{ text: '下一步', nextId: 187 }],
},
{
id: 187,
text: [
  '您想繼續進入下一次會話，我們將討論寶寶護理，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 188 },{ text: '重複', nextId: 178 }],
},
{
id: 188,
text: [
  '歡迎回來！今天我們將深入討論寶寶護理。',
  '寶寶護理是確保寶寶健康和幸福的關鍵。',
  '讓我們首先探索一些寶寶護理的基本原則：-',
  '1. 保持寶寶的清潔和衛生',
  '2. 確保寶寶的安全',
  '3. 提供寶寶所需的營養',
  '4. 促進寶寶的睡眠和休息',
  '5. 確保寶寶的舒適和幸福'
],
options: [{ text: '下一步', nextId: 189 }],
},
{
id: 189,
text: [
  '您目前的寶寶護理情況如何？請分享您的經驗和挑戰。'
],
options: [{ text: '下一步', nextId: 190 }],
},
{
id: 190,
text: [
  '了解寶寶護理的基本原則後，讓我們討論一些有效的寶寶護理技巧：-',
  '1. 每天為寶寶洗澡，保持寶寶的清潔和衛生',
  '2. 確保寶寶的睡眠環境安全，減少窒息風險',
  '3. 按需餵養寶寶，確保寶寶獲得足夠的營養',
  '4. 採用舒適的睡姿，促進寶寶的睡眠和休息',
  '5. 提供適當的刺激，促進寶寶的發展和成長'
],
options: [{ text: '下一步', nextId: 191 }],
},
{
id: 191,
text: [
  '讓我們進行一個簡單的寶寶護理練習：-',
  '1. 找一個舒適的位置坐下或躺下。',
  '2. 將寶寶放在您的胸前，確保寶寶感到舒適和安全。',
  '3. 輕輕地撫摸寶寶，與寶寶進行眼神交流和語言交流。',
  '4. 注意寶寶的反應，確保寶寶感到滿足和幸福。'
],
options: [{ text: '好', nextId: 192 }],
},
{
id: 192,
text: [
  '現在，請分享您的寶寶護理經驗和感受。'
],
options: [{ text: '下一步', nextId: 193 }],
},
{
id: 193,
text: [
  '太好了！我很高興您分享了您的經驗和感受。',
  '寶寶護理是確保寶寶健康和幸福的重要方式。',
  '請繼續練習寶寶護理技巧，並尋求支持和指導，以確保寶寶獲得最佳照顧。'
],
options: [{ text: '好', nextId: 194 }],
},
{
id: 194,
text: [
  '如果您在寶寶護理過程中遇到任何困難或挑戰，請隨時尋求支持和指導。',
  '您的醫療提供者、育兒顧問和寶寶護理支持團體可以提供有價值的幫助和建議。'
],
options: [{ text: '好', nextId: 195 }],
},
{
id: 195,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 寶寶護理的基本原則',
  '2. 有效的寶寶護理技巧',
  '3. 寶寶護理經驗和感受',
  '4. 尋求支持和指導'
],
options: [{ text: '下一步', nextId: 196 }],
},
{
id: 196,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的寶寶護理技巧和策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
],
options: [{ text: '下一步', nextId: 197 }],
},
{
id: 197,
text: [
  '您想繼續進入下一次會話，我們將討論寶寶的健康和發展，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 198 },{ text: '重複', nextId: 188 }],
},
{
id: 198,
text: [
  '歡迎回來！今天我們將深入討論寶寶的健康和發展。',
  '寶寶的健康和發展是確保寶寶成長和幸福的關鍵。',
  '讓我們首先探索一些寶寶健康的基本原則：-',
  '1. 定期體檢和免疫接種',
  '2. 確保寶寶獲得足夠的營養',
  '3. 保持寶寶的清潔和衛生',
  '4. 提供適當的刺激和活動',
  '5. 確保寶寶的安全和舒適'
],
options: [{ text: '下一步', nextId: 199 }],
},
{
id: 199,
text: [
  '您目前的寶寶健康和發展情況如何？請分享您的經驗和挑戰。'
],
options: [{ text: '下一步', nextId: 200 }],
},
{
id: 200,
text: [
  '了解寶寶健康的基本原則後，讓我們討論一些有效的寶寶健康和發展技巧：-',
  '1. 定期帶寶寶去體檢，確保寶寶健康狀況良好',
  '2. 按需餵養寶寶，確保寶寶獲得足夠的營養',
  '3. 每天為寶寶洗澡，保持寶寶的清潔和衛生',
  '4. 提供適當的刺激和活動，促進寶寶的發展',
  '5. 確保寶寶的睡眠環境安全，減少窒息風險'
]
},
{
id: 201,
text: [
  '讓我們進行一個簡單的寶寶健康和發展練習：-',
  '1. 找一個舒適的位置坐下或躺下。',
  '2. 將寶寶放在您的胸前，確保寶寶感到舒適和安全。',
  '3. 輕輕地撫摸寶寶，與寶寶進行眼神交流和語言交流。',
  '4. 注意寶寶的反應，確保寶寶感到滿足和幸福。'
],
options: [{ text: '好', nextId: 202 }],
},
{
id: 202,
text: [
  '現在，請分享您的寶寶健康和發展經驗和感受。'
],
options: [{ text: '下一步', nextId: 203 }],
},
{
id: 203,
text: [
  '太好了！我很高興您分享了您的經驗和感受。',
  '寶寶的健康和發展是確保寶寶成長和幸福的重要方式。',
  '請繼續練習寶寶健康和發展技巧，並尋求支持和指導，以確保寶寶獲得最佳照顧。'
],
options: [{ text: '好', nextId: 204 }],
},
{
id: 204,
text: [
  '如果您在寶寶健康和發展過程中遇到任何困難或挑戰，請隨時尋求支持和指導。',
  '您的醫療提供者、育兒顧問和寶寶健康支持團體可以提供有價值的幫助和建議。'
],
options: [{ text: '好', nextId: 205 }],
},
{
id: 205,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 寶寶健康的基本原則',
  '2. 有效的寶寶健康和發展技巧',
  '3. 寶寶健康和發展經驗和感受',
  '4. 尋求支持和指導'
],
options: [{ text: '下一步', nextId: 206 }],
},
{
id: 206,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的寶寶健康和發展技巧和策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
],
options: [{ text: '下一步', nextId: 207 }],
},
{
id: 207,
text: [
  '您想繼續進入下一次會話，我們將討論家庭動力和支持系統，還是重複這次會話？'
],
options: [{ text: '繼續', nextId: 208 },{ text: '重複', nextId: 198 }],
},
{
id: 208,
text: [
  '歡迎回來！今天我們將深入討論家庭動力和支持系統。',
  '家庭動力和支持系統是確保您和寶寶健康和幸福的關鍵。',
  '讓我們首先探索一些家庭動力的基本原則：-',
  '1. 開放和誠實的溝通',
  '2. 互相尊重和理解',
  '3. 分擔家庭責任',
  '4. 提供情感支持',
  '5. 共同解決問題'
],
options: [{ text: '下一步', nextId: 209 }],
},
{
id: 209,
text: [
  '您目前的家庭動力和支持系統情況如何？請分享您的經驗和挑戰。'
],
options: [{ text: '下一步', nextId: 210 }],
},
{
id: 210,
text: [
  '了解家庭動力的基本原則後，讓我們討論一些有效的家庭動力和支持系統技巧：-',
  '1. 定期進行家庭會議，討論家庭問題和計劃',
  '2. 分擔家庭責任，確保每個成員都能參與',
  '3. 提供情感支持，確保每個成員都感到被理解和尊重',
  '4. 共同解決問題，尋找最佳解決方案',
  '5. 確保家庭成員之間的溝通暢通無阻'
],
options: [{ text: '下一步', nextId: 211 }],
},
{
id: 211,
text: [
  '讓我們進行一個簡單的家庭動力練習：-',
  '1. 找一個舒適的位置坐下或躺下。',
  '2. 與您的家庭成員進行眼神交流和語言交流。',
  '3. 輕輕地撫摸寶寶，確保寶寶感到舒適和安全。',
  '4. 注意家庭成員的反應，確保每個成員都感到滿足和幸福。'
],
options: [{ text: '好', nextId: 212 }],
},
{
id: 212,
text: [
  '現在，請分享您的家庭動力和支持系統經驗和感受。'
],
options: [{ text: '下一步', nextId: 213 }],
},
{
id: 213,
text: [
  '太好了！我很高興您分享了您的經驗和感受。',
  '家庭動力和支持系統是確保您和寶寶健康和幸福的重要方式。',
  '請繼續練習家庭動力和支持系統技巧，並尋求支持和指導，以確保家庭成員之間的溝通暢通無阻。'
],
options: [{ text: '好', nextId: 214 }],
},
{
id: 214,
text: [
  '如果您在家庭動力和支持系統過程中遇到任何困難或挑戰，請隨時尋求支持和指導。',
  '您的醫療提供者、家庭顧問和家庭支持團體可以提供有價值的幫助和建議。'
],
options: [{ text: '好', nextId: 215 }],
},
{
id: 215,
text: [
  '在我們結束這次會話之前，讓我們回顧一下我們今天討論的重點：-',
  '1. 家庭動力的基本原則',
  '2. 有效的家庭動力和支持系統技巧',
  '3. 家庭動力和支持系統經驗和感受',
  '4. 尋求支持和指導'
],
options: [{ text: '下一步', nextId: 216 }],
},
{
id: 216,
text: [
  '請花些時間反思今天的會話，並在接下來的幾天內嘗試應用我們討論的家庭動力和支持系統技巧和策略。',
  '如果您有任何問題或需要進一步的支持，請隨時聯繫我。'
],
options: [{ text: '下一步', nextId: 217 }],
},
{
  id: 217,
  text: [
    '計劃如何在面對虐待伴侶時逃跑。',
    '如果您必須見伴侶，請在公共場所進行。',
    '改變您的日常安排。',
    '通知學校和工作的聯繫人。',
    '如果您有保護令，請隨時隨身攜帶或放在附近。',
    '致電專為受虐者提供庇護所的機構。'
  ],
  options: [{ text: '下一步', nextId: 218 }],
}, 
{
  id: 218,
  text: [
    '如果您需要獲取限制令：什麼是臨時限制令？（TRO）',
    '1. 如果您認為自己可能面臨任何危險，建議申請臨時限制令。',
    '2. 這不是安全的保證，但這是一項法律法院命令，要求您的施虐者必須遠離您。',
    '3. 擁有臨時限制令（TRO），如果施虐者試圖與您聯繫，可以被逮捕。申請TRO不需支付費用，您不需要律師即可獲得TRO。'
  ],
  options: [{ text: '下一步', nextId: 219 }],
}, 
{
  id: 219,
  text: [
    '如何獲取臨時限制令（TRO）：',
    '1. 前往您當地的家庭或地方法院填寫文件。',
    '2. 文件交給文書後，法官將審查，法官可能會問您問題，也可能不會。',
    '3. 如果法官簽署了該命令，則有效期為21天。',
    '4. 法院官員將向施虐者送達限制令副本。'
  ],
  options: [{ text: '下一步', nextId: 230 }],
}, 
{
  id: 230,
  text: [
    '5. 如果您在晚上、周末或假日時間感到有危險，您可以獲得針對施虐者的緊急限制令。',
    '6. 聯繫您當地的警察部門。警察可隨時聯繫家庭法院和地方法院法官，他們可以簽發緊急保護令。'
  ],
  options: [{ text: '下一步', nextId: 231 }],
}, 
{
  id: 231,
  text: [
    '7. 如果您獲得了緊急限制令，您需要在下一個工作日到法院去正式化該限制令。',
    '8. 如果您不確定該怎麼做，可以致電家庭暴力機構或限制令辦公室以獲取更多信息。',
    '9. 一些有用的電話號碼：全國家庭暴力聯盟 1-800-494-8100（24小時服務）。'
  ],
  options: [{ text: '下一步', nextId: 232 }],
}, 
{
  id: 232,
  text: [
    '您對這個主題或我們今天討論的內容有任何問題嗎？我知道這不是一件容易的事。'
  ]
}, 
{
  id: 233,
  text: [
    '謝謝您的分享。我了解您的意思。',
    '1. 記住，您並不孤單，若有人對您施虐，絕不是您的錯。',
    '2. 有幫助可供您利用。下次會議中，除了安全計劃如有需要外，我希望您完成有關兩個重要關係的關係評估和變化的問題。',
    '3. 還要繼續練習堅定要求的技巧。'
  ],
  options: [{ text: '下一步', nextId: 234 }],
}, 
{
  id: 234,
  text: [
    '4. 別忘了持續安排愉快的活動！',
    '5. 在下週內承諾至少三項活動。',
    '6. 繼續保持優秀的工作，並記住，',
    '如果您需要任何額外的支持，我一直在這裡。祝您有美好的一天！'
  ],
  options: [{ text: '下一步', nextId: 235 }],
}, 
{
  id: 235,
  text: [
    '您想進入下一次會議討論更多關於堅定要求的內容，還是重複當前會議？'
  ],
  options: [{ text: '進行', nextId: 236 },{ text: '重複', nextId: 192 }],
}, 
{
  id: 236,
  text: [
    '歡迎回到第七次會議，我們將討論堅定要求！！！',
    '讓我們深入探討今天的主題。當您提出這些堅定要求時情況如何？您能要求幫助嗎？'
  ],
  options: [{ text: '是', nextId: 237 },{ text: '否', nextId: 237 }],
}, 
{
  id: 237,
  text: [
    '隨時分享您對前一問題的更多想法。'
  ]
}, 
{
  id: 238,
  text: [
    '- 謝謝您的分享。我完全理解您的意思。',
    '- 有些人可能並不總是接受，僅僅因為您堅定地要求某樣東西並不意味著他們會答應。',
    '- 但您有權要求，就像他們有權說不一樣。'
  ],
  options: [{ text: '下一步', nextId: 239 }],
},      
{
  id: 239,
  text: [
    '讓我們快速回顧一下堅定要求的內容。請輸入您的想法。',
    '1. 什麼是堅定要求？',
    '2. 當您堅定要求時，您需要記住什麼？'
  ]
},    
{
  id: 240,
  text: [
    '我理解您的意思。謝謝您的分享。',
    '母親身份將帶來許多需求和對更多支持的渴望。',
    '同時，您周圍的人可能不知道您最想要的東西是什麼。',
    '我們的目標是幫助您思考人際需求，優先考慮最重要的需求，並練習有效要求這些需求的技巧。'
  ],
  options: [{ text: '下一步', nextId: 241 }],
},   
{
  id: 241,
  text: [
    '為了更好地理解健康的堅定行為，與不堅定和侵略性行為進行比較是有幫助的。',
    '如果您不堅定，您不會表達自己的想法、需求或觀點。',
    '您忽視自己的權利，讓他人佔便宜。',
    
    '人們可能不堅定的原因有哪些？'
  ]
},   
{
  id: 242,
  text: [
    '這些都是常見原因。',
    '當您不堅定時，通常會導致憤怒、怨恨、挫折，感到無助、被利用、被困住或失去控制。',
    '問題從未得到解決。另一方面，當人們表現得很侵略性時，他們表達自己的感受和需求以犧牲他人為代價。'
  ],
  options: [{ text: '下一步', nextId: 243 }],
},   
{
  id: 243,
  text: [
    '他們侵犯他人的權利並試圖主宰。',
    '這可能出於憤怒、挫折或不斷不堅定，直到您終於爆發。'
  ],
  options: [{ text: '下一步', nextId: 244 }],
},   
{
  id: 244,
  text: [
    '但這可能會毀壞關係，並不會長期解決問題。',
    '堅定要求是保持自尊並試圖解決關係問題的最佳方式。',
    '但是，會有一些障礙阻礙著您。'
  ],
  options: [{ text: '下一步', nextId: 245 }],
},   
{
  id: 245,
  text: [
    '上次我們提到了一些堅定要求的障礙。',
    '讓我們進一步探討這些。常見的障礙包括：在不同文化中，堅定要求不一定受到重視。',
    '恐懼報復和衝突',
    '缺乏將堅定要求視為技能的知識'
  ],
  options: [{ text: '下一步', nextId: 246 }],
},   
{
  id: 246,
  text: [
    '害怕被稱為刻薄或自私',
    '害怕失去關係',
    '害怕激起自己內心的憤怒',
    '相信我們不配得到我們所要求的或應該能夠自己處理',
    '尚未準備好聽到拒絕或面對關係無法修復',
    '缺乏對自己的信任'
  ],
  options: [{ text: '下一步', nextId: 247 }],
},   
{
  id: 247,
  text: [
    '經常擔憂的想法會妨礙您說不，例如“他們不會喜歡我”、“他會認為我很苛刻”、“她會認為我很愚蠢”，或“說不意味著我不體貼或不關心”。',
    '評估這些想法是否現實，並用現實的想法來反駁它們是很重要的。',
    '克服這些障礙的最佳方法是練習和準備。',
    '這門課程中的信息可以幫助您準備尋求幫助、說不或傳達您的感受。'
  ],
  options: [{ text: '下一步', nextId: 248 }],
},   
{
  id: 248,
  text: [
    '為了獲得我們所需的支持，我們必須培養我們的關係。',
    '注意他們，以保持健康。需要在優先事項（對您重要的事物）和需求（他人希望您做的事）之間保持平衡。',
    '當您有一個新生嬰兒時，突然會有許多新需求，加上所有舊的需求。'
  ],
  options: [{ text: '下一步', nextId: 249 }],
},   
{
  id: 249,
  text: [
    '您的想要的東西將不斷被推到一邊。',
    '因此，對於您最重要的事情，做出決定將非常重要。',
    '有時您必須要求幫助以實現您的優先事項。',
    '有時您必須對人們說不。',
    '說不可能是非常困難的，就像要求幫助一樣。'
  ],
  options: [{ text: '下一步', nextId: 250 }],
}, 
{
  id: 250,
  text: [
    '記住，您有權說不。',
    '您不可能成為每個人的一切。',
    '作為一名新媽媽，您將有一些以前為他人做的事情，由於新增的責任而無法完成。',
    
    '為什麼說不這麼困難？有哪些障礙？隨時輸入您的想法。'
  ]
}, 
{
  id: 251,
  text: [
    '這些都是非常常見的障礙。',
    '有時您需要重複說不，特別是如果其他人沒有聽到或開始爭論。',
    '我們稱之為斷片記錄技術。',
    '說不需要與要求幫助相同的技能。',
    '記住黃金法則，使用斷片記錄技術，並在有人不接受拒絕時離開該情況。'
  ],
  options: [{ text: '下一步', nextId: 252 }],
}, 
{
  id: 252,
  text: [
    '您能想出什麼時候您希望說不但卻沒有做到的例子嗎？未來在那些情況下您該如何拒絕？'
  ]
}, 
{
  id: 253,
  text: [
    '謝謝您的分享。我了解您正在經歷的事情。',
    '您正在堅定要求，但也很友善。',
    '如果您對請求不確定或想說不，但在當時卻沒有勇氣，您總是可以要求時間考慮一下。',
    '利用這段時間想一想您想要說什麼。',
    '但重要的是在回到那個人時給出最終決定'
  ],
  options: [{ text: '下一步', nextId: 255 }],
}, 
{
  id: 255,
  text: [
    '如果您想提供替代方案，卻不能滿足他們的特定要求。',
    '例如：“我今晚不能照顧孩子，但下週二或週三晚上我很樂意照顧孩子。”'
  ],
  options: [{ text: '下一步', nextId: 256 }],
}, 
{
  id: 256,
  text: [
    '不過，只有在您真正意味著這樣做時才提供這樣的建議。',
    '堅定要求是一項需要練習的技能。',
    '您做得越多，就越容易，尤其是在寶寶出生後設置健康的界限。'
  ],
  options: [{ text: '下一步', nextId: 257 }],
}, 
{
  id: 257,
  text: [
    '讓我們總結一下我們學到的關於堅定要求的內容：',
    '寶寶將佔用您大部分時間，幾乎沒有時間留給人際關係。',
    '如果他們不喜歡您選擇的度過時間的方式，這可能會對您的其他關係造成壓力。',
    '如果您在寶寶出生前與伴侶有過困難，那麼擁有寶寶不太可能使您們更加親近。'
  ],
  options: [{ text: '下一步', nextId: 258 }],
}, 
{
  id: 258,
  text: [
    '與父母和公婆在如何照顧寶寶的問題上可能會產生分歧。',
    '親戚越是不同意或挑戰您，您可能會越感到生氣或懷疑自己。',
    '擁有支持性的親戚是幸運的女性。'
  ],
  options: [{ text: '下一步', nextId: 259 }],
}, 
{
  id: 259,
  text: [
    '朋友和家人可能無法提供您所需的幫助。',
    '有時他們並不在身邊，因為他們認為會妨礙您，或擔心會打擾到非常私人的時刻。',
    '他們可能沒有意識到您希望獲得幫助或支持。'
  ],
  options: [{ text: '下一步', nextId: 260 }],
}, 
{
  id: 260,
  text: [
    '您需要告訴他們您需要什麼。作為單親媽媽尤其困難，您需要獲得所有能夠得到的幫助。',
    '如果您有伴侶，試著每週抽出15分鐘進行一次聯繫，了解這些巨大的變化如何影響您們每一個人。'
  ],
  options: [{ text: '下一步', nextId: 261 }],
}, 
{
  id: 261,
  text: [
    '您想進入下一次會議，討論更多關於目標設定的內容，還是希望重複當前會議？'
  ],
  options: [{ text: '進行', nextId: 262 },{ text: '重複', nextId: 236 }],
},                   
{
  id: 262,
  text: [
    '歡迎來到您的最後一次會議，我們將討論寶寶出生前的目標設定！！恭喜您完成了目前的會議，我們希望在寶寶出生後與您再次聯繫。',
    '讓我們談談未來的規劃，這意味著設定目標。',
    '當您有寶寶時，您可能會感覺整個生活縮小到換尿布、餵食、洗澡、拍嗝和洗衣服上。',
    '在經歷變化時，重要的是要取代失去的目標。'
  ],
  options: [{ text: '下一步', nextId: 263 }],
}, 
{
  id: 263,
  text: [
    '人類在擁有希望、夢想並朝著它們努力時最為快樂。',
    '您的一些夢想或長期目標是什麼？請在聊天窗口中回答。',
    '請記住，嬰兒期不會持續永遠。請輸入您的想法。'
  ]
}, 
{
  id: 264,
  text: [
    '為了實現您的夢想，您需要採取哪些步驟？',
    '您將如何實現它們，並在什麼時候？'
  ]
}, 
{
  id: 265,
  text: [
    '謝謝您的分享。我承認並理解您的意思。',
    '這樣劃分大目標使它們感覺更容易實現。以下是ROSE計劃的一些最後提示。'
  ],
  options: [{ text: '下一步', nextId: 266 }],
}, 
{
  id: 266,
  text: [
    '愉快的活動：',
    '1. 記住，培養自己是重要的，如果需要，發展新的方法。',
    '2. 添加積極活動可以減少痛苦，增加您的精力和積極的前景。',
    '3. 記住您的愉快活動合同。'
  ],
  options: [{ text: '下一步', nextId: 267 }],
}, 
{
  id: 267,
  text: [
    '放鬆：',
    '1. 學會管理壓力會讓您感覺更好，增加您的控制感，並賦予您權力。',
    '2. 記得進行放鬆練習。這裡是供您參考的腳本：'
  ],
  options: [{ text: '下一步', nextId: 268 }],
}, 
{
  id: 268,
  text: [
    '減少壓力：',
    '1. 放鬆練習作為提醒：我希望您讓自己舒適。如果您願意，可以閉上眼睛。',
    '2. 我們將從您的雙腳開始 - 捲起您的腳趾，然後放鬆它們。',
    '3. 現在把您的腳跟壓進地面，感受腿部的緊張，然後放鬆 - 很好。',
    '4. 您的腿感覺鬆弛而沉重。現在收緊您的腹部 - 保持，感受緊張然後釋放 - 非常好。',
    '5. 現在我希望您拱起背部 - 感受脊椎的緊張，然後放鬆您的背部 - 讓一切放開 - 讓所有緊張感離開您的身體 - 很好'
  ],
  options: [{ text: '下一步', nextId: 269 }],
}, 
{
  id: 269,
  text: [
    '用手握拳，慢慢地將它們抬到肩膀 - 感受手臂的緊張，然後放鬆 - 讓手臂自然垂落，鬆弛而沉重，很好。',
    '現在將肩膀抬到耳朵的位置 - 感受頸部的緊張，保持並釋放 - 讓所有的緊張消失。',
    '我希望您現在緊緊地閉上嘴巴 - 感受下巴的緊張 - 很好，然後放鬆。'
  ],
  options: [{ text: '下一步', nextId: 270 }],
}, 
{
  id: 270,
  text: [
    '深吸一口氣 - 然後呼氣 - 只需感受所有的緊張感離開您的身體。',
    '現在緊緊閉上您的雙眼，然後放鬆身體，讓它感覺輕鬆舒適 - 很好，真好。'
  ],
  options: [{ text: '下一步', nextId: 271 }],
}, 
{
  id: 271,
  text: [
    '專注於您感到的平靜和放鬆 - 想想您自己在柔軟的雲朵上感受到雲朵的柔軟，沉浸在雲朵中 - 您的身體感覺如此鬆弛而沉重。',
    '感受溫暖的陽光照在您的身體上，涼爽的微風輕拂著您的臉。放鬆 - 您感覺如此安詳和安全。'
  ],
  options: [{ text: '下一步', nextId: 272 }],
}, 
{
  id: 272,
  text: [
    '現在我將從5倒數計數，當我到達1時，您將慢慢睜開眼睛，平靜與安詳的感覺將伴隨著您整個一天。',
    '吸氣5...並呼氣，平靜。',
    '吸氣4...並呼氣，平靜。',
    '吸氣3...並呼氣，平靜。',
    '吸氣2...並呼氣，平靜。',
    '吸氣1...然後呼氣...想著平靜。',
    '當您慢慢睜開眼睛時，平靜與安詳將伴隨著您。'
  ],
  options: [{ text: '下一步', nextId: 273 }],
}, 
{
  id: 273,
  text: [
    '變化：成為新父母/看護者：',
    '1. 生活中的重大變化，如生育寶寶，可能會造成壓力。',
    '2. 成為新父母/看護者涉及新的需求和損失。',
    '3. 您可能會感到害怕、悲傷、緊張和挫折 - 請記住，您並不孤單，也不是您的錯。'
  ],
  options: [{ text: '下一步', nextId: 274 }],
}, 
{
  id: 274,
  text: [
    '討論您的感受、伸出援手是很重要的。',
    '在經歷生活的損失和變化時，重要的是取代失去的目標。要設定短期目標（小步驟）和長期目標。',
    '設定持續的目標。（嬰兒期不會持續永遠）。'
  ],
  options: [{ text: '下一步', nextId: 275 }],
}, 
{
  id: 275,
  text: [
    '如果我們有時間，我希望您完成這個“我的資源”手冊。',
    '請記住，當您生完寶寶後，我會再次關心您，看看您和寶寶的情況，並提醒您我們會議中學到的內容。'
  ],
  options: [{ text: '下一步', nextId: 276 }],
}, 
{
  id: 276,
  text: [
    '1. 依賴家人和朋友：每週幾個小時的照顧可以給您帶來迫切需要的休息。公開分享感受讓家人和朋友提供支持。',
    '2. 與醫療專業人員交談：醫生會了解您可以獲得的幫助選擇，並熟悉產前產後抑鬱症。'
  ],
  options: [{ text: '下一步', nextId: 277 }],
}, 
{
  id: 277,
  text: [
    '1. 尋找支持小組：您所在社區中還有其他人在面臨相似的情況；在一群經歷相同事情的人中分享您的感受可能會有幫助。請與您的醫療提供者交談，了解如何加入小組。',
    '2. 與心理健康護理專業人員交談：心理健康護理專業人員可以為您提供一個安全的地方來表達您的感受，並在幫助您管理症狀方面提供有用的協助。',
    '3. 專注於健康：吃健康多樣的彩色食物，健康的小吃，避免酒精使用。進行運動，邀請朋友一起散步，嘗試新的活動，花時間拉伸肌肉。'
  ],
  options: [{ text: '下一步', nextId: 278 }],
}, 
{
  id: 278,
  text: [
    '這是新父母/看護者的生存工具包：',
    '1. 在身體上滋養自己（例如，足夠的休息、適當的飲食、運動）',
    '2. 進行“迷你假期”休息（休息就是休息-休息期間無需做家務！）',
    '3. 給自己一些讚揚，別忘了包括小任務',
    '4. 每天計劃至少一個涉及成人的活動（例如，打電話給朋友）',
    '5. 計劃一些有趣的事情（例如，一個電視節目、泡泡浴、看舊照片）',
    '6. 要求幫助 - 您無法做到所有事情',
    '7. 推遲生活中的重大變化'
  ],
  options: [{ text: '下一步', nextId: 279 }],
}, 
{
  id: 279,
  text: [
    '處理人際關係：',
    '1. 您有權要求幫助，也有權說不。',
    '2. 人際關係需要不斷的努力，尤其是在生活中發生變化時，如擁有新寶寶。',
    '3. 在人際關係中保持平衡 - 需求不應超過您的需求。',
    '4. 記住 - 慶祝您的努力和成就，給自己一些讚揚，即使是您作為伴侶、家庭成員、朋友或即將成為的父母/看護者所做的小事。'
  ],
  options: [{ text: '下一步', nextId: 280 }],
}, 
{
  id: 280,
  text: [
    '作為作業，繼續練習堅定行為。',
    '如果您現在開始，將來在寶寶來臨之前，保持人際關係的平衡將更容易。繼續填寫手冊，設定您的目標及其實現步驟。'
  ],
  options: [{ text: '下一步', nextId: 281 }],
}, 
{
  id: 281,
  text: [
    '並且保持您的愉快活動計劃，每週至少進行三項您喜愛的活動。與您合作是如此愉快。',
    '請記住，您具備成為一位優秀母親所需的素質。',
    '當您需要幫助時，不要猶豫尋求幫助。',
    '在您的小寶貝出生後，我將再次見到您。好好照顧自己！'
  ],
  options: [{ text: '下一步', nextId: 282 }],
}, 
{
  id: 282,
  text: [
    '至此，我們已經完成了最初的8次會議。做得很好！！！祝您未來一切順利，並在您生產後再次聯繫，幫助您應對更多產後問題。',
    '如果您想再次查看任何會議，請單擊以下會議的按鈕：-'
  ],
  options: [{ text: '1', nextId: 1 },{ text: '2', nextId: 49 },{ text: '3', nextId: 86 },{ text: '4', nextId: 115 },{ text: '5', nextId: 151 },{ text: '6', nextId: 192 },{ text: '7', nextId: 236 },{ text: '8', nextId: 262 }],
},

  
  
    ],
   'es-US': [
{
  id: 1,
  text: [
    'Hola, soy Nayomi.',
    '¡Bienvenida a la plataforma nurtur! Entendemos los desafíos únicos que conlleva ser una futura mamá y nuestra plataforma está diseñada para apoyarte en tu camino durante el embarazo al proporcionar un protocolo clínicamente probado adaptado a tus necesidades.'
  ],
  options: [
    { text: 'Siguiente', nextId: 2 }
  ]
},
{
  id: 2,
  text: 'Al completar todos los módulos, estarás equipada con valiosos conocimientos y herramientas para navegar las complejidades del período posparto con mayor facilidad. Nuestro programa se basa en un protocolo probado para prevenir la depresión posparto en hasta un 53%.',
  options: [
    { text: 'Siguiente', nextId: 3 }
  ]
},
{
  id: 3,
  text: 'Tu bienestar es nuestra prioridad, así que no dudes en contactarnos si tienes alguna pregunta o necesitas asistencia adicional en el camino. Vamos a embarcarnos en este viaje juntas y empoderarte para priorizar el autocuidado mientras nutres a tu familia que está creciendo.',
  options: [
    { text: 'Siguiente', nextId: 4 }
  ]
},
{
  id: 4,
  text: 'Déjame saber cuando estés lista para comenzar.',
  options: [{ text: 'Siguiente', nextId: 5 }]
},
{
  id: 5,
  text: [
    'Antes de comenzar la sesión de hoy, permíteme hacerte un par de preguntas para ayudarme a entender mejor cómo puedo ayudarte con cualquier problema.',
    'En las últimas 24 horas, ¿con qué frecuencia te has sentido molesta por tener poco interés o placer en hacer cosas?',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 6 }, { text: '1', nextId: 6 }, { text: '2', nextId: 6 }, { text: '3', nextId: 6 }]
},
{
  id: 6,
  text: [
    'En las últimas 24 horas, ¿con qué frecuencia te has sentido molesta por sentirte triste, deprimida o desesperanzada?',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 7 }, { text: '1', nextId: 7 }, { text: '2', nextId: 7 }, { text: '3', nextId: 7 }]
},
{
  id: 10,
  text: [
    'En las últimas 2 semanas, ¿con qué frecuencia te han molestado alguno de los siguientes problemas?',
    '¿Poco interés o placer en hacer cosas? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 11 }, { text: '1', nextId: 11 }, { text: '2', nextId: 11 }, { text: '3', nextId: 11 }]
},
{
  id: 11,
  text: [
    '¿Sentirse triste, deprimida o desesperanzada? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 12 }, { text: '1', nextId: 12 }, { text: '2', nextId: 12 }, { text: '3', nextId: 12 }]
},
{
  id: 12,
  text: [
    '¿Dificultades para conciliar o mantener el sueño, o dormir demasiado? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 13 }, { text: '1', nextId: 13 }, { text: '2', nextId: 13 }, { text: '3', nextId: 13 }]
},
{
  id: 13,
  text: [
    '¿Sentirse cansada o tener poca energía? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 14 }, { text: '1', nextId: 14 }, { text: '2', nextId: 14 }, { text: '3', nextId: 14 }]
},
{
  id: 14,
  text: [
    '¿Pérdida de apetito o comer en exceso? En una escala del 0 al 3:',
    'En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 15 }, { text: '1', nextId: 15 }, { text: '2', nextId: 15 }, { text: '3', nextId: 15 }]
},
{
  id: 15,
  text: [
    '¿Sentirse mal contigo misma o pensar que eres un fracaso o que has decepcionado a ti misma o a tu familia? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 16 }, { text: '1', nextId: 16 }, { text: '2', nextId: 16 }, { text: '3', nextId: 16 }]
},
{
  id: 16,
  text: [
    '¿Dificultades para concentrarse en cosas, como leer el periódico o ver televisión? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 17 }, { text: '1', nextId: 17 }, { text: '2', nextId: 17 }, { text: '3', nextId: 17 }]
},
{
  id: 17,
  text: [
    '¿Moverse o hablar tan lentamente que otras personas podrían haberlo notado? O al contrario, ser tan inquieta o agitada que has estado moviéndote mucho más de lo habitual? En una escala del 0 al 3:',
    '0 - En absoluto.',
    '1 - Alguna de las veces.',
    '2 - La mitad del tiempo.',
    '3 - La mayor parte del tiempo.'
  ],
  options: [{ text: '0', nextId: 19 }, { text: '1', nextId: 19 }, { text: '2', nextId: 19 }, { text: '3', nextId: 19 }]
},
{
  id: 19,
  text: [
    'La encuesta ha detectado altos niveles de estrés. Ten en cuenta que la ayuda está aquí para ti. Llama o envía un mensaje de texto a la Línea Nacional de Salud Mental Materna al 1-833-TLC-MAMA (1-833-852-6262) antes de continuar con la sesión.'
  ],
  options: [{ text: 'Siguiente', nextId: 47 }]
},
{
  id: 47,
  text: [
    '¿Has contactado el número de teléfono indicado?'
  ],
  options: [{ text: 'Sí', nextId: 7 }, { text: 'No', nextId: 19 }]
},
{
  id: 7,
  text: [
    'Gracias. Comencemos con la sesión de hoy.',
    'Hola, soy Nayomi, tu educadora prenatal virtual aquí. ¡Felicidades por tu embarazo!'
  ],
  options: [{ text: 'Sí', nextId: 8 }]
},
{
  id: 8,
  text: [
    'Estoy aquí para apoyarte a través del Programa ROSE, que significa Alcanzando Apoyo Social y Educación. Es una serie de sesiones diseñadas para enseñarte habilidades para manejar el estrés, especialmente después de que llegue tu bebé.'
  ],
  options: [{ text: 'Siguiente', nextId: 20 }]
},
{
  id: 20,
  text: [
    'Tener un bebé es un momento alegre, pero también puede traer nuevos desafíos. Queremos ayudarte a tener un buen comienzo para tu bebé y para ti misma. Puede ser útil reservar un tiempo específico cada día para completar estas sesiones.',
    'Antes de comenzar, limita cualquier distracción para mantenerte concentrada. En promedio, cada sesión tomará entre 10 y 15 minutos para que puedas planificar adecuadamente.'
  ],
  options: [{ text: 'Siguiente', nextId: 21 }]
},
{
  id: 21,
  text: [
    'Selecciona siguiente cuando estés lista para continuar.'
  ],
  options: [{ text: 'Siguiente', nextId: 22 }]
},
{
  id: 22,
  text: [
    'Las mujeres no son todas iguales. Algunas mujeres están encantadas de tener un bebé y dirán que nunca han sido más felices. Otras pueden tener sentimientos encontrados: a veces se sienten "tan felices" y en otras ocasiones "tan abrumadas". Y para algunas mujeres resulta muy estresante. Sin embargo, nadie habla de lo difícil que puede ser: los cambios que tendrás que hacer y que ser madre puede ser uno de los trabajos más difíciles de tu vida, un trabajo que viene con muy poca capacitación.'
  ],
  options: [{ text: 'Siguiente', nextId: 23 }]
},
{
  id: 23,
  text: [
    'Si lo piensas de manera realista, tienes que dejar de lado tus propias necesidades, tienes que alimentar, acunar, cambiar pañales, vestirte... sin mencionar las tareas regulares de un hogar, además de todos tus otros roles como pareja, hija, amiga, trabajadora. Todo esto mientras estás exhausta por la falta de sueño y tu cuerpo y tus hormonas se han vuelto locos. ¡Suena como una forma de tortura! De hecho, la privación del sueño es una forma de tortura bien conocida. ¿Qué le ha pasado a este maravilloso momento? Nadie habla de lo difícil que puede ser. Las nuevas madres se sienten culpables de quejarse ya que tienen la imagen perfecta de la nueva madre con su bebé sonriendo, llena de alegría y energía.'
  ],
  options: [{ text: 'Siguiente', nextId: 24 }]
},
{
  id: 24,
  text: [
    '¿Tu madre alguna vez te compartió cómo fue en los primeros meses de tenerte como bebé?'
  ],
  options: [{ text: 'Sí', nextId: 25 }, { text: 'No', nextId: 36 }]
},
{
  id: 36,
  text: [
    'Eso es normal y muchas mujeres tienden a no compartir esa fase de su vida debido a lo difícil que pudo haber sido.',
    'Es cierto que algunas mujeres no pasan por dificultades, pero la mayoría de las mujeres, si les haces muchas preguntas, te contarán sobre algunas dificultades durante los primeros tres meses. Te voy a compartir algunas realidades, no para asustarte, sino para prepararte y para asegurarme de que no te sientas culpable y no te culpes a ti misma si enfrentas dificultades una vez que tengas a tu bebé.',
    'Si estás desprevenida, puedes alarmarte y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás.'
  ],
  options: [{ text: 'Siguiente', nextId: 26 }]
},
{
  id: 37,
  text: [
    'Es genial saber que has tenido un viaje emocional relativamente estable durante tu embarazo. La experiencia de cada uno es única y es maravilloso que te hayas sentido constante durante este tiempo. Si tienes alguna pregunta o necesitas apoyo en algún momento, no dudes en contactarnos.',
    '¿Sabes cuáles son las señales de la depresión?'
  ],
  options: [{ text: 'Siguiente', nextId: 38 }]
},
{
  id: 38,
  text: [
    'Puede ser útil para todos conocer las señales, ya sea que hayan experimentado esto personalmente o no. Si alguna vez deseas saber más sobre las señales o tienes alguna preocupación sobre tu salud mental o la de otra persona, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo siempre que lo necesites. Ahora, nos adentraremos en la depresión posparto, que es una condición más seria. Las nuevas mamás deben estar al tanto de esto.'
  ],
  options: [{ text: 'Siguiente', nextId: 39 }]
},
{
  id: 39,
  text: [
    'Después del nacimiento, la depresión posparto generalmente es lenta y gradual. Por lo general, ocurre dentro de los primeros dos meses. Puedes comenzar a sentir que no puedes cuidar de tu bebé de la manera adecuada, piensas que no eres una buena madre, te sientes mal contigo misma, sientes que eres una carga para los demás. Hay una dificultad significativa en la capacidad de cuidar a tu bebé, cuidarte a ti misma y afrontar las relaciones familiares.'
  ],
  options: [{ text: 'Siguiente', nextId: 40 }]
},
{
  id: 40,
  text: [
    'Alrededor del 10-15% de las nuevas madres muestran síntomas de depresión posparto. Esto significa que una de cada siete mujeres experimentará depresión posparto. Para las mujeres que reciben asistencia pública o que enfrentan dificultades financieras, es más común: aproximadamente una de cada tres tales mujeres experimentará depresión posparto. Las mujeres que han tenido episodios anteriores de depresión son más propensas a experimentar depresión después del parto. Aquellas mujeres que han tenido depresión posparto también son más propensas a experimentarla nuevamente.'
  ],
  options: [{ text: 'Siguiente', nextId: 41 }]
},
{
  id: 41,
  text: [
    'Los síntomas pueden comenzar de manera gradual, pero a menudo pueden acumularse, dejando a una nueva madre sintiéndose abrumada, desesperanzada o frustrada. Algunos pensamientos comunes pueden ser “No puedo cuidar a mi bebé de la manera adecuada, no soy una buena madre, soy una carga para los demás.'
  ],
  options: [{ text: 'Siguiente', nextId: 42 }]
},
{
  id: 42,
  text: [
    'Mientras que el “baby blues” es temporal, la depresión posparto se caracteriza por emociones negativas persistentes y una disminución en la capacidad de funcionar. Los síntomas suelen durar al menos dos semanas.'
  ],
  options: [{ text: 'Siguiente', nextId: 43 }]
},
{
  id: 43,
  text: [
    'Algunos signos comunes incluyen sentirte abrumada, desesperanzada o culpable por cuidar a tu bebé. También podrías experimentar dificultades para dormir o comer, o una pérdida de interés en actividades que antes disfrutabas.'
  ],
  options: [{ text: 'Siguiente', nextId: 44 }]
},
{
  id: 44,
  text: [
    'Puede ser aterrador, pero el mensaje importante es que la depresión posparto es tratable. Si experimentas estos síntomas, por favor contacta a tu proveedor de atención médica de inmediato.'
  ],
  options: [{ text: 'Siguiente', nextId: 31 }]
},
{
  id: 25,
  text: [
    'Me alegra mucho saber que tu mamá compartió eso contigo.',
    'Es cierto que algunas mujeres no pasan por dificultades, pero si preguntas muchas cosas, la mayoría de las mujeres te contarán algunas dificultades durante los primeros tres meses. Voy a compartir contigo algunas realidades, no para asustarte, sino para prepararte y para asegurarte de que no sientas culpa ni te culpes a ti misma si este enfrenta dificultades una vez que tengas a tu bebé. Si estás desprevenida, podrías alarmarte, y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás.'
  ],
  options: [{ text: 'Siguiente', nextId: 26 }]
},
{
  id: 26,
  text: [
    'Hablemos sobre el rango de los "blues" posparto. Las mujeres se sienten diferentes en ese momento después de dar a luz. Para algunas es peor que para otras, y dura más. Como discutimos antes, algunas mujeres se enamoran de inmediato de su bebé y están encantadas y se mantienen encantadas, mientras que otras pueden sentirse muy abrumadas.'
  ],
  options: [{ text: 'Siguiente', nextId: 27 }]
},
{
  id: 27,
  text: [
    'La mayoría de las nuevas madres (alrededor del 30-80%) experimentan "baby blues". Los blues del bebé usualmente ocurren de dos a cinco días después del parto. Puedes experimentar períodos de llanto sin razón, cambios de humor, ser muy sensible, sentirte abrumada, irritable y simplemente agotada. Es un momento muy emocional y te sorprende. Piensas que tienes un hermoso, saludable bebé, ¿cómo puedo sentir ganas de llorar? Esta fase generalmente pasa en unos días a unas pocas semanas, a medida que tú y tu cuerpo se ajustan a tu nueva situación. Normalmente, dura alrededor de 10 días.'
  ],
  options: [{ text: 'Siguiente', nextId: 28 }]
},
{
  id: 28,
  text: [
    '¿Has experimentado algún cambio emocional durante tu embarazo hasta ahora?'
  ],
  options: [{ text: 'Sí', nextId: 29 }, { text: 'No', nextId: 37 }]
},
{
  id: 29,
  text: [
    'Es natural que te sientas así y gracias por compartir. Ahora hablemos de la Depresión Posparto. Algunas nuevas madres pasan por un momento más preocupante y las dificultades que hemos discutido son más duraderas y más intensas. La depresión posparto es cuando los síntomas persisten, casi todos los días y no hay alivio durante al menos dos semanas.',
    '¿Sabes cuáles son las señales de la depresión?'
  ],
  options: [{ text: 'Sí', nextId: 30 }, { text: 'No', nextId: 38 }]
},
{
  id: 30,
  text: [
    '¡Genial saberlo! Conocer las señales de la depresión puede ser crucial para entender tu salud mental y buscar apoyo cuando lo necesites. Si tienes alguna pregunta sobre las señales o deseas discutirlas más a fondo, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo. Es genial que seas consciente de tus emociones. Hablaremos de los mecanismos de afrontamiento para cambios emocionales en futuras sesiones.'
  ],
  options: [{ text: 'Siguiente', nextId: 31 }]
},
{
  id: 31,
  text: [
    'Antes de terminar esta sesión, ¿entiendes la diferencia entre los blues del bebé y la depresión posparto?'
  ],
  options: [{ text: 'Sí', nextId: 32 }, { text: 'No', nextId: 45 }]
},
{
  id: 32,
  text: [
    '¡Gran trabajo! Si tienes alguna pregunta sobre las diferencias entre los blues del bebé y la depresión posparto, no dudes en preguntar. Estoy aquí para ayudar a aclarar cualquier duda que puedas tener. Como recordatorio, aquí están los síntomas que debes tener en cuenta:',
    'BLUES DEL BEBÉ - Del 30-80% de las personas experimentan Blues del Bebé. Generalmente ocurre de 2 a 5 días después del parto y suele desaparecer después de unas dos semanas. Algunos síntomas son:',
    'Llanto, Cambios de humor, Agotamiento, Tensión.',
    'Ansiedad, Inquietud.'
  ],
  options: [{ text: 'Siguiente', nextId: 33 }]
},
{
  id: 33,
  text: [
    'DEPRESIÓN POSPARTO: PROBLEMAS de sueño (por ejemplo: no puedes volver a dormir después de alimentar al bebé), Problemas de alimentación - comer demasiado o muy poco, Ansiedad y preocupación, Evitar a las personas, evitar el contacto con el bebé, querer estar sola, Sin energía, Deseos de muerte, pensamientos suicidas, Dificultad para tener sentimientos positivos hacia el bebé, Dificultad para tomar decisiones, Manía - sentirse acelerada, estar excitada, irritable, hablar rápido y tener menos necesidad de dormir, Ataques de pánico, Temores por el bebé, fantasías sobre dañar o matar al bebé.'
  ],
  options: [{ text: 'Siguiente', nextId: 34 }]
},
{
  id: 34,
  text: [
    'Si alguna vez sientes que podrías lastimarte a ti misma, a tu bebé o a alguien más, por favor habla con tu proveedor de atención médica o llama al 911.'
  ],
  options: [{ text: 'Siguiente', nextId: 35 }]
},
{
  id: 35,
  text: [
    'En la próxima sesión, te ayudaremos a comprender los factores de riesgo, los síntomas y las opciones de tratamiento de la depresión posparto. Enfatizaremos la normalidad de los sentimientos, la importancia de buscar ayuda y las estrategias para afrontar y buscar apoyo durante la transición a la maternidad.'
  ],
  options: [{ text: 'Siguiente', nextId: 48 }]
}, 
// Continuing from this point would follow the same logic for translating the remaining sections. 
{id:45,text: ['No te preocupes. Revisemos esto rápidamente. La distinción entre el "baby blues" y la depresión posparto radica en su duración, gravedad e impacto en el funcionamiento diario. El "baby blues" son típicamente sentimientos de tristeza leve y cambios de humor que ocurren en las primeras semanas después del parto y que a menudo se resuelven por sí solos.',]
,options:[{ text: 'Siguiente', nextId: 46 }],},
{id:46,text: ['Por otro lado, la depresión posparto es una afección más grave y duradera caracterizada por sentimientos persistentes de tristeza, desesperanza y ansiedad que pueden interferir significativamente con la capacidad de una madre para cuidar de sí misma y de su bebé. Es esencial reconocer los signos y buscar apoyo si tú o alguien que conoces puede estar experimentando depresión posparto. Aquí hay una lista que podría ser útil.',],options:[{ text: 'Siguiente', nextId: 32 }]},

// Sesión 2

{id:48,text: ['Presiona Siguiente si deseas continuar a la sesión donde realizaremos ejercicios de relajación y transiciones de rol.',],options:[{ text: 'Siguiente', nextId: 49 },{ text: 'Repetir', nextId: 1 }],},

{
id: 49,
text: [
'¡Bienvenido a la Sesión 2! Hoy profundizaremos en el Ejercicio de Relajación y Transición de Rol.',
'De la investigación sabemos que las mujeres que han tenido episodios previos de depresión, especialmente depresión posparto, tienen más probabilidades de experimentar depresión después del parto. Además, aquellas que tienen un historial de depresión o un familiar con depresión o problemas de salud mental, y aquellas que tienen un sistema de apoyo inadecuado. Lo importante es que se puede y se debe tratar. Como recordatorio, aquí están los síntomas de la depresión posparto:',
'Alteraciones en la alimentación: comer demasiado o muy poco. Ansiedad y preocupación',
'Evitar personas, evitar el contacto con el bebé, querer estar sola',
'Sin energía',
'Pensamientos suicidas',
'Dificultad para tener sentimientos positivos hacia el bebé',
'Dificultad para tomar decisiones',
'Manía: sentirse acelerada, ser excitante e irritable, hablar rápido y tener menos necesidad de dormir',
'Atacones de pánico',
'Miedos por el bebé, fantasías sobre hacerle daño o matarlo'
],
options: [{ text: 'Siguiente', nextId: 62 }],
},
{
id: 62,
text: [
'Si tienes alguno de los síntomas que hemos discutido, por favor, habla con tu médico, proveedor de salud, o incluso con el médico de tu bebé y pide una referencia a un profesional de salud mental, terapeuta, consejero, trabajador social o psiquiatra. Aquí hay diferentes recursos y tenlo a mano: Línea de ayuda para la salud mental materna, Violencia doméstica, Servicios legales para padres, Prevención del suicidio',
],
options: [{ text: 'Siguiente', nextId: 63 }],
},
{
id: 63,
text: [
'El mensaje más importante que debes llevarte de estas sesiones es que aunque puedas tener los "baby blues", o incluso depresión tras el parto, no debes sentirte avergonzada por estos sentimientos, sentirte culpable, pensar que eres una mala madre o pensar que hay algo muy mal en ti.',
],
options: [{ text: 'Siguiente', nextId: 64 }],
},
{
id: 64,
text: [
'Necesitas entender que muchos de estos sentimientos son reacciones normales a tener un nuevo bebé. Recuerda: No estás sola, no eres culpable y puedes sentirte mejor.',
],
options: [{ text: 'Siguiente', nextId: 65 }],
},
{
id: 65,
text: [
'También está bien hablar sobre tus dificultades. No te sientas avergonzada o temerosa de discutir tus sentimientos con amigos, familiares y tu proveedor de atención médica. De hecho, te sentirás mucho mejor si hablas con alguien cercano a ti sobre tus sentimientos.',
'Tener un bebé es un evento importante que afecta el cuerpo y la mente. Todo esto ocurre en un momento en que se requiere cuidar de un ser humano necesitado e indefenso, y con poco sueño y a menudo con muy poca ayuda externa. En las próximas sesiones, hablaremos sobre formas de lidiar con el "montaña rusa" que podrías experimentar después de tener a tu bebé.',
],
options: [{ text: 'Siguiente', nextId: 66 }],
},
{
id: 66,
text: [
'No puedo suavizar el camino por ti ni quitarte algunos de los baches malos. Sin embargo, discutiremos formas para que puedas manejar la tristeza o los momentos bajos para que no caigas en una depresión.',
],
options: [{ text: 'Siguiente', nextId: 67 }],
},
{
id: 67,
text: [
'Nuestra aproximación del protocolo ROSE se basa en la idea de que estás enfrentando una gran transición. Sabemos que esto requerirá nuevas habilidades y establecerá nuevas demandas. Un tema importante cuando enfrentamos estrés es que puede ser de gran ayuda tener un buen apoyo. Un buen apoyo puede reducir la probabilidad de que una mujer desarrolle depresión posparto después del parto. Desafortunadamente, al mismo tiempo que mereces una cantidad increíble de apoyo, muchas mujeres se sorprenden al darse cuenta de que no es tan fácil encontrarlo durante este período.',
],
options: [{ text: 'Siguiente', nextId: 68 }],
},
{
id: 68,
text: [
'Las demandas de estar con el bebé pueden reducir el tiempo que pasas con otros socialmente, las demandas del bebé pueden ser muy difíciles para las relaciones, y otros pueden simplemente no darse cuenta de los tipos de apoyo o ayuda que serían agradables para ti. Así que, en Nurtur queremos hacer todo lo posible para ayudarte a sentir que estás recibiendo tanto apoyo como mereces.',
'No podemos garantizar esto, pero ciertamente podemos ayudar a pensar en tus objetivos de apoyo y enseñarte algunas estrategias para ayudar en ese área. Nos enfocaremos en diferentes formas de reducir el estrés en tu vida una vez que tu bebé esté aquí, y hablar sobre las personas que te brindan apoyo en tu vida.'
],
options: [{ text: 'Siguiente', nextId: 69 }],
},
{
id: 69,
text: [
'En una escala del 1 al 10, siendo 1 sin ansiedad y 10 ansiedad alta, ¿dónde calificarías tu nivel de ansiedad en este momento?'
],
options: [{ text: '1', nextId: 70 },{ text: '2', nextId: 70 },{ text: '3', nextId: 70 },{ text: '4', nextId: 70 },{ text: '5', nextId: 70 },{ text: '6', nextId: 70 },{ text: '7', nextId: 70 },{ text: '8', nextId: 70 },{ text: '9', nextId: 70 },{ text: '10', nextId: 70 }],
},
{
id: 70,
text: [
'Voy a proporcionarte el guion para el ejercicio. Recomiendo practicar durante 10-20 minutos diariamente. La práctica regular reforzará tus habilidades de relajación, por lo que es más probable que lo utilices cada vez que necesites controlar tu estrés.',
'Comencemos encontrando una posicón cómoda, ya sea sentada o acostada, cualquiera que te sientas mejor. Toma una respiración profunda, y al exhalar, deja ir cualquier tensión en tu cuerpo.'
],
options: [{ text: 'Siguiente', nextId: 71 }],
},
{
id: 71,
text: [
'Ahora empecemos con un ejercicio de relajación llamado Relajación Muscular Progresiva.',
'1. Encontrar maneras de relajarse y manejar el estrés puede ser muy útil al lidiar con los estreses de la vida, en general, y al lidiar con relaciones estresantes o si tienes dificultad para conciliar el sueño.',
'2. Las técnicas de relajación también han demostrado reducir el estrés y la tensión. La Relajación Muscular Progresiva está diseñada para relajar los músculos de tu cuerpo enseñándote a tensar y luego relajar varios grupos musculares.',
'3. Al crear primero tensión y luego liberar esa tensión de una vez, puedes lograr una mejor reducción de la tensión muscular. La liberación repentina de la tensión crea un tipo especial de energía que permite a los músculos relajarse más allá de lo que serían los niveles normales de reposo.',
'4. Dirige tu atención a tus pantorrillas y muslos. Tensa los músculos de tus piernas inferiores presionando tus talones contra el suelo, y luego suéltalos. Siente la calidez y la relajación expandiéndose a través de tus piernas.',
'5. Luego, tensa los músculos de tus glúteos apretándolos fuertemente, y luego suéltalos. Observa la diferencia entre la tensión y la relajación en tu cuerpo.',
'6. Ahora, enfoca tu atención en tu abdomen. Tensa tus músculos abdominales llevando tu ombligo hacia tu columna, y luego suéltalo. Permite que tu respiración se vuelva profunda y natural.',
'7. Mueve tu atención a tu pecho y espalda. Toma una respiración profunda, llenando tus pulmones de aire, y mantén por un momento. Ahora, exhala lenta y completamente, liberando cualquier tensión en tus músculos del pecho y espalda.',
'8. Cambia tu enfoque a tus hombros. Levántalos hacia tus oídos y luego déjalos caer, liberando cualquier tensión que puedas estar sosteniendo. Siente el peso de tus hombros hundiéndose en la superficie debajo de ti.',
'9. Ahora, ve a tus brazos y manos. Haz puños con tus manos, apretando fuertemente, y luego suéltalos. Siente la tensión salir de tus brazos mientras los dejas pesados y relajados.',
'10. Finalmente, enfócate en tu cara y cuello. Arruga tu cara fuertemente, frunciendo la frente y entrecerrando los ojos, y luego relájate. Deja que tu mandíbula se relaje, permitiendo que tus labios se separen ligeramente.',
'11. Tómate un momento para escanear tu cuerpo de pies a cabeza, notando cualquier área de tensión que quede.',
'12. Si notas alguna tensión, toma una respiración profunda e, al exhalar, imagina esa tensión derritiéndose, dejándote sentir completamente relajada y en calma.',
'13. Continúa respirando profundamente y lentamente por unos momentos más, disfrutando de la sensación de relajación en tu cuerpo.',
'14. Cuando estés lista, abre suavemente los ojos y regresa al momento presente, sintiéndote renovada y revitalizada.'
],
options: [{ text: 'Siguiente', nextId: 72 }],
},
{
id: 72,
text: [
'Ahora, en esa misma escala del 1 al 10, ¿dónde calificarías tu nivel de ansiedad? Muestra números del 1 al 10'
],
options: [{ text: '1', nextId: 73 },{ text: '2', nextId: 73 },{ text: '3', nextId: 73 },{ text: '4', nextId: 73 },{ text: '5', nextId: 73 },{ text: '6', nextId: 73 },{ text: '7', nextId: 73 },{ text: '8', nextId: 73 },{ text: '9', nextId: 73 },{ text: '10', nextId: 73 }],
},
{
id: 73,
text: [
'¡Maravilloso! Me alegra que haya ayudado a reducir tu nivel de estrés. Ahora, pasemos a nuestro tema principal de hoy que es Transiciones de Rol.',
'Tener un bebé cambiará tu vida. Los cambios pueden ser buenos y malos, y ambos pueden ser estresantes ya que presentan nuevas demandas y nuevos horarios.',
'Piensa en cuán estresante puede ser una boda para una novia. Los grandes cambios, particularmente los estresantes, a menudo se asocian con depresión. Uno de los períodos de alto riesgo para la depresión es cuando las mamás tienen niños muy pequeños en casa.'
],
options: [{ text: 'Siguiente', nextId: 74 }],
},
{
id: 74,
text: [
'La buena noticia es que se sabe mucho sobre cómo atravesar eficazmente grandes cambios. Hoy, vamos a guiarte a través de algunas de las herramientas importantes para pensar sobre estos tipos de cambios/transiciones importantes. Tengo información que anticipa las transiciones de rol de las que hablaremos. ¿Puedes pensar en algún otro ejemplo de cambios que has experimentado, los cambios que tuviste que hacer durante un cambio en los roles y cómo sobreviviste?'
]},

{
id: 75,
text: [
'Entiendo y reconozco lo que quieres decir. Ahora hablemos sobre los cambios que un nuevo bebé traerá a tu vida. Al aumentar nuestra comprensión, podemos esperar hacer un mejor trabajo en satisfacer nuestras necesidades durante este tiempo de agitación.'
],
options: [{ text: 'Siguiente', nextId: 76 }],
},
{
id: 76,
text: [
'Comencemos explorando algunas de las pérdidas o cosas que podrías extrañar o tener que cambiar cuando llegue el bebé. ¿Cuáles son algunas pérdidas que te vienen a la mente?'
]
},
{
id: 77,
text: [
'Entiendo y reconozco lo que quieres decir. Algunos ejemplos de pérdidas son:-',
'1. Pérdida de rutina, ya que un bebé interrumpe el horario de 24 horas',
'2. Quehaceres del hogar que no se realizan',
'3. Menos tiempo para la pareja, otros hijos, amigos, etc.',
'4. Pérdida de productividad con la colada, las compras.',
'5. Menos oportunidades de socializar, lo que lleva a la aislamiento.',
'6. Pérdida de un sentido de propósito al estar en casa durante horas.',
'7. Incluso pérdida de espacio físico, necesitando compartir una habitación con el bebé o mudarse de la situación de vivienda actual.',
'8. Aislamiento'
],
options: [{ text: 'Siguiente', nextId: 78 }],
},

{
id: 78,
text: [
'Es importante reconocer estas pérdidas. Pero también exploremos los beneficios y nuevas oportunidades que podrías ganar como nueva madre. ¿Cuáles son algunas cosas que esperas o crees que podrían ser positivas acerca de este cambio?'
]
},

{
id: 79,
text: [
'Eso es genial. Quedarse en casa con un nuevo bebé puede obligarte a:-',
'Pasar tiempo con tu bebé.',
'Observar el crecimiento de tu bebé.',
'Es una oportunidad para disfrutar de las reacciones de tu hijo hacia el mundo.',
'Reducir tu ritmo, en lugar de llevar una vida agitada.',
'Aprenderás más sobre ti misma como madre, podrás descubrir algunos talentos ocultos',
'¡Una buena excusa para no hacer quehaceres 😊!'
],
options: [{ text: 'Siguiente', nextId: 80 }],
},
{
id: 80,
text: [
'Es normal tener una variedad de sentimientos con este cambio: ira, miedo, inseguridad. ¿Cuáles son algunos de los sentimientos que surgen para ti al pensar en estas pérdidas y ganancias?'
]
},
{
id: 81,
text: [
'Son sentimientos muy comprensibles. Es importante reconocerlos y no sentirse culpable. Aún podrás disfrutar de la vida, pero es posible que debas hacerlo de una manera diferente. La regla más importante para sobrevivir a la maternidad es hacer cosas para cuidar de tus necesidades. Nutre a la madre.'
],
options: [{ text: 'Siguiente', nextId: 82 }],
},
{
id: 82,
text: [
'Ser madre es como ser una jarra de agua: sigues vertiendo, dando y dando mientras cuidas las necesidades a tu alrededor: bebé, familia, amigos, pareja.',
'Si no tomas medidas y llenas la jarra de nuevo, pronto estará vacía.',
'No hay nadie que sea una jarra sin fondo. Estar vacía te hará muy vulnerable a la depresión. No puedes ser una buena madre si no se satisfacen tus necesidades. Date permiso.'
],
options: [{ text: 'Siguiente', nextId: 83 }],
},
{
id: 83,
text: [
'Pensamientos como "estoy siendo malhumorada, débil, consentida, egoísta" seguirán saltando en tu mente, pero debes corregirte y pensar "estoy invirtiendo en mí para mi bebé/familia". Es importante agregar actividades y experiencias positivas cuando estás perdiendo otras cosas positivas que te hacían sentir bien contigo misma. Es natural que te sientas menos motivada y cansada en este momento. Pero agregar intencionadamente actividades positivas puede reducir los sentimientos negativos; puede energizarte.'
],
options: [{ text: 'Siguiente', nextId: 84 }],
},
{
id: 84,
text: [
'Hay una relación directa entre las actividades agradables y el estado de ánimo; cuantas más cosas agradables hagas, mejor te sentirás. Pero tienes que darte permiso para hacer esto. En la próxima sesión, hablaremos de algunas de las actividades positivas que podrías ver haciendo una vez que tengas al bebé. ¡Que tengas un gran día! Podemos charlar mañana.'
],
options: [{ text: 'Siguiente', nextId: 85 }],
},
{
id: 85,
text: [
'¿Te gustaría proceder a la siguiente sesión donde hablaremos sobre Actividades Placenteras y Personas Cercanas? O ¿te gustaría repetir la sesión actual?'
],
options: [{ text: 'Proceder', nextId: 86 },{ text: 'Repetir', nextId: 49 }],
},
{
id: 86,
text: [
'¡Bienvenido a la Sesión 3! Hoy estaremos hablando sobre Actividades Placenteras y Personas Cercanas.',
'¿Cuáles son algunas actividades positivas y agradables que podrías imaginar haciendo una vez que tengas al bebé? Incluso las cosas pequeñas son importantes.'
],
},
{
id: 87,
text: [
'Entiendo y reconozco lo que quieres decir. Las actividades placenteras pueden ser tan simples como leer cómics, mirar fotos antiguas, ver un programa de televisión favorito, hacer ejercicio, tomar un baño de burbujas, encender velas. La clave es regalarte algo. Te lo mereces. Sé tu mejor amiga. Este es un momento importante para cuidar de ti misma.'
],
options: [{ text: 'Siguiente',nextId:101}],
},
{
id: 101,
text: [
'El tiempo de descanso significa no hacer quehaceres. Intenta planear al menos un evento que involucre a un adulto cada día, incluso si solo es una llamada telefónica.',
'Evita emprender cambios grandes en este momento: es mejor abordar esos cuando tus hormonas estén más equilibradas.',
'Mantén tus objetivos realistas: ¡tomar una ducha es un gran logro en esas primeras semanas! Recuerda, no existe tal cosa como una super-madre.',
'Cometerás errores y eso está bien. Concéntrate en ser una madre "suficientemente buena".'
],
options: [{ text: 'Siguiente',nextId:102}],
},
{
id: 102,
text: [
'Es importante hacer cosas que te hagan sentir segura y productiva, incluso cosas pequeñas.',
'Dale crédito a lo que logres, te sorprenderás de lo que realmente haces, pero a menudo no te das crédito por ello.'
],
options: [{ text: 'Siguiente',nextId:103}],
},
{
id: 103,
text: [
'Otro aspecto clave es desarrollar apoyo. Sabemos que el fuerte apoyo de otras personas puede protegerte de la depresión.',
'Las personas que pueden confiar en otros y recibir apoyo tienen menos probabilidades de deprimirse. Tener personas que se preocupan por ti es fundamental para aliviar cualquier angustia que tengas después de dar a luz.',
'Hay evidencia de que el fuerte apoyo frente a un estrés importante puede reducir significativamente el riesgo de depresión. Si eres reacia a pedir ayuda, podrías sentirte fácilmente abrumada.'
],
options: [{ text: 'Siguiente',nextId:104}],
},
{
id: 104,
text: [
'Ahora piensa profundamente en el siguiente conjunto de preguntas y luego escribe tus pensamientos.',
'1. ¿Quiénes son algunas de las personas que puedes pedir ayuda?',
'2. ¿A quién te sientes cercana, excluyendo a tus hijos?',
'3. ¿Quién crees que seguirá estando ahí para ti dentro de cinco años?',
'4. ¿Qué tipo de ayuda crees que necesitarás?'
],
},
{
id: 105,
text: [
'A veces, llegar a alguien no funciona. Un familiar o amigo puede no estar en condiciones de brindar apoyo. No debes sentirte como una carga.',
'Cuando tienes un compañero, esposo o familia que no está ahí para ti, eso es un verdadero problema. Pero no tienes que culparte.',
'Tienes derecho a buscar ayuda y apoyo. Es importante buscar apoyo de aquellos que son receptivos y cariñosos, sean quienes sean.',
'Si no están en tu hogar o en tu grupo actual de amigos, entonces acude a grupos de apoyo, lugares de culto, profesionales, etc.'
],
options: [{ text: 'Siguiente',nextId:106}],
},
{
id: 106,
text: [
'A menos que evites completamente las relaciones, probablemente experimentarás alguna decepción.',
'Ten cuidado de no rechazar a todos, especialmente cuando te sientas estresada.',
'La mayoría de las personas tiene defectos. Al decidir a quién aceptar —con sus fallos y todo— pregúntate: "¿El bien supera a lo malo?"',
'Depende de ti decidir qué tipos de fallos deseas aceptar.'
],
options: [{ text: 'Siguiente',nextId:107}],
},
{
id: 107,
text: [
'Pensemos en “Personas Cercanas” que puedan ayudarte a identificar fuentes de apoyo. Podemos completarlo juntos. Piensa en lo siguiente y luego comparte tus pensamientos:',
'1. ¿Cuáles son las personas en cada área que ves con más frecuencia? ¿A quién puedes acudir para pedir ayuda?',
'2. Enumera 2 personas de cada categoría:-',
'-FAMILIA',
'-TRABAJO',
'-ESPIRITUAL',
'-OTRO']
},
{
id: 108,
text: [
'Ahora que se han identificado las "personas cercanas", hablemos sobre la comunicación en las relaciones: cómo te comunicas con tus seres queridos, formas de mejorar la comunicación, especialmente al pedir ayuda, y cómo lidiar con relaciones abusivas.'
],
options: [{ text: 'Siguiente', nextId: 109 }],
},
{
id: 109,
text: [
'TIPS PARA PEDIR AYUDA A OTROS',
'TIMING:',
'Elige un momento tranquilo. Introduce el hecho de que quieres hablar sobre algo. COMPORTAMIENTO NO VERBAL:',
'Usa un tono de voz apropiado, evita posturas amenazantes, mantén contacto visual con la otra persona, míralos directamente. DICE ALGO POSITIVO:',
'Coloca lo negativo entre lo positivo. Comienza con un positivo y termina con un positivo.'
],
options: [{ text: 'Siguiente', nextId: 110 }],
},
{
id: 110,
text: [
'Tu tarea después de esta sesión es hacer tu propia lista de las veinte actividades placenteras. Puedes comenzar con algunas ideas de actividades agradables:',
'Pensar en personas que me gustan',
'Ver paisajes hermosos',
'Respirar aire limpio',
'Observar a las personas',
'Tener una conversación sincera y abierta',
'Sentarme al sol',
'Usar ropa limpia',
'Hacer un proyecto a mi manera',
'Escuchar música',
'Leer cuentos, novelas, poemas o obras de teatro',
'Expresar mi amor a alguien',
'Estar con alguien a quien amo',
'Halagar o elogiar a alguien',
'Escuchar la radio',
'Ver a viejos amigos',
'Relajarme en la bañera',
'Coleccionar cosas (monedas, conchas, etc.)',
'Ir al cine a mitad de semana',
'Reír',
'Pensar en mis viajes pasados',
'Leer revistas o periódicos',
'Cuidar de mis plantas',
'Cantar por la casa',
'Practicar religión (ir a la iglesia, orar en grupo, etc.)',
'Pintar',
'Hacer bordado, trabajos manuales, etc.',
'Dormir',
'Hacer manualidades',
'Hacer un regalo para alguien',
'Coser',
'Ir a la peluquería',
'Soñar despierta',
'Ver televisión',
'Pensar en eventos agradables',
'Escribir entradas en mi diario o cartas',
'Meditar',
'Jugar a las cartas',
'Ver y/o mostrar fotos o diapositivas',
'Tejer',
'Hacer crucigramas',
'Dress up and loSiguiente nice',
'Recibir un masaje',
'Hacer un rompecabezas',
],
options: [{ text: 'Siguiente', nextId: 111 }],
},
{
id: 111,
text: [
'Espero que esta lista de actividades te ayude mientras preparas tu lista de las veinte actividades placenteras.',
'Intenta programar al menos tres actividades agradables para ti en la próxima semana.',
'Nota tu estado de ánimo en una escala del 1 al 5 antes y después de la actividad.'
],
options: [{ text: 'Siguiente', nextId: 112 }],
},
{
id: 112,
text: [
'A veces, puede que no sientas ganas de hacer nada, pero participar en una actividad placentera puede realmente energizarte y mejorar tu estado de ánimo.',
'También recuerda practicar los ejercicios de relajación diariamente y calificar tu ansiedad antes y después.'
],
options: [{ text: 'Siguiente', nextId: 113 }],
},
{
id: 113,
text: [
'En las próximas sesiones abordaremos más temas.'
],
options: [{ text: 'Siguiente', nextId: 114 }],
},
{
id: 114,
text: [
'¿Te gustaría proceder a la siguiente sesión donde profundizaremos en las Relaciones o repetir la sesión actual?'
],
options: [{ text: 'Proceder',nextId:115},{ text: 'Repetir',nextId:86}],
},
{
id: 115,
text: ['¡Bienvenido de nuevo! Hoy nos adentraremos en las Relaciones.',
'Vamos a revisar un poco tus tareas.',
'¿Pudiste influir en tu estado de ánimo con las actividades agradables? Recuerda, cuanto más disfrutes las cosas que hagas, mejor te sentirás. Las actividades placenteras realmente pueden energizarte. ¿Tuviste la oportunidad de realizar actividades específicas desde nuestra última interacción?'
],
options: [{ text: 'Sí', nextId: 129 },{ text: 'No', nextId: 129 }],
},
{
id: 129,
text: ['¿Te fue fácil seguir adelante?'
],
options: [{ text: 'Sí', nextId: 130 },{ text: 'No', nextId: 130 }],
},
{
id: 130,
text: ['Está bien si no pudiste completar la actividad de tarea. Entiendo que la vida puede ser impredecible y a veces las cosas no salen como se planean.',
'Recuerda, la terapia es un viaje y es natural encontrar desafíos en el camino. Tu honestidad sobre tu experiencia es valiosa y ayuda a guiar nuestro trabajo juntos.',
'Tómate el tiempo para enumerar tus veinte principales actividades, de modo que esto te ayude a sentirte mejor y se convierta en una práctica que te prepara bien para la llegada del bebé.'
],
options: [{ text: 'Siguiente', nextId: 131 }],
},
{
id: 131,
text: ['¿Cómo te sentiste después de completar las actividades?'
]},
{
id: 132,
text: ['Entiendo y reconozco lo que quieres decir. Ahora quiero que te comprometas con dos o tres actividades más en los próximos días:-',
'1. Es muy importante mantener estas “citas” contigo misma con la misma fidelidad que tus citas más urgentes.',
'2. Hoy nuestro enfoque principal será en las relaciones.',
'3. Las relaciones son difíciles y a menudo las personas involucradas tienen necesidades y expectativas muy diferentes.',
'4. Hay mucho trabajo detrás de ellas. Las personas traen sus propias esperanzas y sueños que no siempre coinciden con los de la otra persona.',
'5. Los cambios en las circunstancias de la vida, como tener un bebé, añaden presiones aún mayores y diferentes.'
],
options: [{ text: 'Siguiente', nextId: 133 }],
},
{
id: 133,
text: ['Hay evidencia clara de que tener un bebé ejerce una gran cantidad de estrés en las asociaciones.',
'Muchos experimentan más conflictos porque hay muchas más demandas sobre los padres para gestionar.'
],
options: [{ text: 'Siguiente', nextId: 134 }],
},
{
id: 134,
text: ['Al mismo tiempo, contar con un gran apoyo es increíblemente protector contra la tristeza y la depresión durante momentos estresantes.',
'Así que aquí está, un momento cuando el apoyo adicional sería muy valioso, y aún así es más difícil para muchas madres nuevas de encontrar.'
],
options: [{ text: 'Siguiente', nextId: 135 }],
},
{
id:135,
text:['Nuestro objetivo es ayudarte a pensar en lo que necesitas y deseas durante este tiempo de las personas en tu vida, y cómo manejar de la mejor manera pedir un poco de ayuda adicional que mereces.',
'Todo de manera realista: no podemos conseguirte una niñera, pero quizás podamos ayudarte a aprender estrategias para hablar con tu pareja sobre nuevos problemas, obtener apoyo de familiares y amigos, construir redes con otras nuevas mamás y, en general, expresar lo que necesitas de la manera más efectiva.'
],
options: [{ text: 'Siguiente', nextId: 136 }],
},
{
id: 136,
text: ['Ahora hablemos más sobre cómo gestionar tus relaciones educándote acerca de los tres tipos de disputas interpersonales o argumentos.'],
options: [{ text: 'Siguiente', nextId: 137 }],
},
{
id: 137,
text: ['Tener un bebé puede tensionar las relaciones, dificultando que obtengas el apoyo que necesitas para afrontar el estrés y los cambios tras dar a luz.',
'Es prudente comenzar a pensar ahora sobre tus relaciones: quién estará allí para ayudar y brindarte el apoyo que necesitarás cuando llegue el bebé.'
],
options: [{ text: 'Siguiente', nextId: 138 }],
},
{
id: 138,
text: ['Al evaluar las relaciones, considera qué problemas puedes tener, si deseas hacer cambios y cómo se manejan los problemas en la relación.'],
options: [{ text: 'Siguiente', nextId: 139 }],
},
{
id: 139,
text: ['Reflexiona sobre las siguientes preguntas y luego escribe tus pensamientos:-',
'Escribe el nombre de alguien importante para ti y pregúntate:',
'1. ¿Qué tan satisfactoria es la relación en general? ¿Es de apoyo?',
'2. ¿Qué cambios te gustaría ver?',
'3. ¿Siente seguridad para analizar tus preocupaciones?',
'4. ¿Crees que la otra persona quiere sentirse cercana?',
'5. ¿Quieres sentirte cercana a ellos?'
],
},
{
id: 140,
text: ['Si hay una sensación básica de confianza y compromiso, entonces puedes encontrar maneras útiles de hablar sobre los problemas.',
'¿Cómo se manejan los problemas en esta relación?',
'¿Se expresan los conflictos?'
],
options: [{ text: 'Siguiente', nextId: 141 }],
},
{
id: 141,
text: ['Una vez que evalúes la situación, debes decidir si es una relación que deseas continuar.',
'A veces los problemas no se pueden resolver y hay más negativos que positivos.',
'Si has hecho todos los esfuerzos para mejorar las cosas y no ha funcionado, puede ser hora de preguntarte si es una relación que vale la pena mantener.'
],
options: [{ text: 'Siguiente', nextId: 142 }],
},
{
id: 142,
text: ['Hay tres tipos de disputas interpersonales o argumentos:-',
'1. Compromiso - "dar y recibir" donde las personas son consciente de las diferencias y están tratando activamente de realizar cambios, incluso si no tienen éxito.',
'2. Resentimiento silencioso - la discusión se ha detenido pero el resentimiento puede seguir burbujeando bajo la superficie.',
'3. No se puede arreglar - la relación no se puede reparar y ambas partes están yendo en su propio camino emocionalmente.'
],
options: [{ text: 'Siguiente', nextId: 143 }],
},
{
id: 143,
text: ['En el primer tipo, debe haber dar y recibir, alcanzando un compromiso incluso cuando no hay un conflicto directo.',
'Puede ser necesario llegar a un compromiso cuando el comportamiento de la otra persona te está causando problemas o malestar, o cuando te das cuenta de que lo que haces les molesta.'
],
options: [{ text: 'Siguiente', nextId: 144 }],
},
{
id: 144,
text: ['Por ejemplo, una vez que tengas al bebé, tus necesidades y prioridades cambiarán, pero otros pueden no estar al tanto o pueden actuar de maneras que te resultan incómodas.',
'Quizás tu pareja espera que aún hagas todos los quehaceres del hogar o tu familia espera que los visites con tanta frecuencia y los ayudes.'
],
options: [{ text: 'Siguiente', nextId: 145 }],
},
{
id: 145,
text: ['A veces puede haber una pelea continua donde es necesario llegar a un compromiso, como si estás peleando diariamente con el padre del bebé sobre cuándo puede y cuándo no puede ver al bebé.',
'En otras relaciones, las personas tienden a no hablar sobre las inquietudes.',
'Sin hablar, el resentimiento puede burbujear bajo la superficie. El problema sigue allí impactando negativamente la relación. Llamamos a esto resentimiento silencioso.'
],
options: [{ text: 'Siguiente', nextId: 146 }],
},
{
id: 146,
text: ['¿Cuáles son algunas razones por las que podrías evitar hablar sobre problemas? Esto podría ser por razones relacionadas con la familia, vida personal, otras limitaciones o dificultades con la comunicación.'],
},
{
id: 147,
text: ['Entiendo y reconozco lo que quieres decir.',
'Son razones muy comunes. En otras relaciones, las personas intentan hablar sobre las preocupaciones, pero puede que no siempre funcione.',
'Entonces están hablando pero sin resolver.',
'Y a veces, la relación parece irreparable; no se puede solucionar y ambas partes están yendo en su propio camino emocionalmente.'
],
options: [{ text: 'Siguiente', nextId: 148 }],
},
{
id: 148,
text: ['Piensa en las diferentes disputas interpersonales que discutimos.',
'Al concluir, no olvides priorizar dos o tres actividades agradables en los próximos días.',
'En la próxima sesión discutiremos más sobre prioridades y comunicación asertiva.'
],
options: [{ text: 'Siguiente', nextId: 150 }],
},
{
id: 150,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Prioridades y Comunicación Asertiva o repetir la sesión actual?'
],
options: [{ text: 'Repetir', nextId: 115},{ text: 'Proceder', nextId: 151 }],
},
// Sesión 5
{
id: 151,
text: ['¡Bienvenido de nuevo a la Sesión 5 donde hablaremos sobre Prioridades y Comunicación Asertiva!',
'Hablemos sobre prioridades. Mantener tus prioridades, o lo que es más importante para ti, es algo que muchos encuentran difícil.',
'Primero, debes determinar qué es más o menos importante, decir que no a algunas de las demandas menos importantes, y pedir ayuda, expresar emociones y opiniones, decir firmemente que no, o pedir a alguien que actúe de manera diferente.'
],
options: [{ text: 'Siguiente', nextId: 164 }],
},
{
id: 164,
text: ['Por ejemplo, tal vez podrías cuidar al hijo pequeño de tu hermano mientras estabas embarazada, pero ahora, con tu propio bebé, no puedes hacerlo de manera regular. Necesitas decir firmemente que no.'
],
options: [{ text: 'Siguiente', nextId: 165 }],
},
{
id: 165,
text: ['O tal vez podrías hacer la colada de todos antes, pero ahora necesitas pedir ayuda en esa área.',
'También hay personas que no quieres rechazar, pero necesitas que cambien su comportamiento.'
],
options: [{ text: 'Siguiente', nextId: 166 }],
},
{
id: 166,
text: ['¿Has oído hablar del Principio del Cubo? Muchas personas no manejan las irritaciones, aguantan lo que no vale la pena cambiar.',
'Así que ponen su irritación en ese cubo. Estas pequeñas cosas se acumulan hasta que el cubo está lleno.'
],
options: [{ text: 'Siguiente', nextId: 167 }],
},
{
id: 167,
text: ['¿Qué pasa cuando se agrega la siguiente irritación? El cubo rebosa. Estallas o te enojas mucho.',
'Si abordas las pequeñas cosas y dejas que las personas sepan qué te molesta, aumentas tus posibilidades de no enojarte y de que el cubo no rebose.'
],
options: [{ text: 'Siguiente', nextId: 168 }],
},
{
id: 168,
text: ['Ahora reflexiona sobre las siguientes preguntas y escribe tus respuestas:-',
'1. ¿Qué tan fácil es para ti pedir cosas?',
'2. ¿Qué tan bien puedes decir no?',
'3. ¿Varía con diferentes personas?',
'4. ¿Qué significa para ti la asertividad?'
],
},
{
id: 169,
text: ['Entiendo. Ahora hablemos sobre la asertividad:-','1. La asertividad es la capacidad de expresar tus pensamientos y sentimientos de manera abierta y directa a los demás, sin alejarlos.',
'2. Las habilidades asertivas nos permiten establecer y hacer cumplir límites. Te sentirás más en control.'
],
options: [{ text: 'Siguiente', nextId: 170 }],
},
{
id: 170,
text: ['Tienes el derecho a decidir qué hacer en lugar de lo que alguien más espera. Cuando eres asertiva, expresas tus propias necesidades, deseos o sentimientos sin violar los derechos de los demás.',
'Así que debes reconocer que los demás también tienen derechos, al igual que tú.'
],
options: [{ text: 'Siguiente', nextId: 171 }],
},
{
id: 171,
text: ['Repasemos algunos de tus derechos en las relaciones:-',
'1. Tienes derecho a obtener apoyo, a querer o necesitar algo de alguien más.',
'2. Tienes derecho a aceptar o rechazar cualquier cosa que otros te pidan o digan.',
'3. Tienes derecho a decir NO.'
],
options: [{ text: 'Siguiente', nextId: 172 }],
},
{
id: 172,
text: ['4. Ser asertivo es una habilidad que puedes aprender. Puede ser difícil al principio. Pedir apoyo o establecer límites implica asumir riesgos.',
'5. Tomar riesgos implica autoaceptación: aceptar que tienes necesidades, que tienes derecho a esas necesidades, y que eres vulnerable ante otra persona.'
],
options: [{ text: 'Siguiente', nextId: 173 }],
},
{
id: 173,
text: ['6. Tomar riesgos implica que puedes fallar, cometer un error o que las personas pueden desaprobarte o rechazarte por tus decisiones.',
'7. Debes aceptarte sin importar el resultado. No te vuelves menos valiosa si cometes un error, fallas o te rechazan.'
],
options: [{ text: 'Siguiente', nextId: 174 }],
},
{
id: 174,
text: ['8. Tienes derecho a tomar decisiones por ti misma, sin importar lo que piensen los demás. Dejar que otras personas tomen decisiones por ti y juzguen tus elecciones te hace sentir menos valiosa.',
'9. Estás a su merced si dejas que tu autoestima sea determinada por otros. Las personas se sienten mejor consigo mismas al tomar algunos riesgos, asumir el control de sus vidas y ser asertivas.'
],
options: [{ text: 'Siguiente', nextId: 175 }],
},
{
id: 175,
text: ['Piensa cuidadosamente sobre tu relación adulta más importante:-',
'1. ¿Cuáles son las dificultades o preocupaciones?',
'2. ¿Cuáles son tus necesidades en este momento?',
'3. ¿Cuáles son tus objetivos a corto y largo plazo para la relación?',
'4. ¿Estás experimentando conflicto en algún nivel que discutimos o insatisfacción donde la otra persona no está al tanto?',
'Me gustaría que completaras esto:-',
'1. "Comunicándome con mis seres queridos" para tu relación adulta más importante.',
'2. Si después de evaluarlo sientes que se necesitan cambios y la relación se puede arreglar, hay formas efectivas de discutir esos cambios.',
'3. Ahí es donde entra la asertividad.'
],
options: [{ text: 'Siguiente', nextId: 176 }],
},
{
id: 176,
text: ['Piensa en una persona con la que te gustaría cambiar tu estilo de comunicación: ¿cómo está yendo tu estilo de comunicación con esta persona? (-10 a 0, el estilo de comunicación va extremadamente mal; 1 a 10, extremadamente bien).'
],
},
{
id: 177,
text: ['Ahora, piensa profundamente en las siguientes preguntas:-',
'1. ¿Cuáles son las dos mejores cosas sobre cómo te comunicas con esta persona?',
'2. ¿Qué tipo de cosas dice o hace esta persona que te hacen feliz?',
'3. ¿Qué hace a esta persona especial para ti?',
'4. ¿Cuáles son las dos peores cosas sobre cómo te comunicas con esta persona?',
'5. ¿Qué tipo de cosas hacen ellos que son difíciles para ti?',
'6. ¿Qué tipo de cosas olvidan hacer? ¿Sientes que obtienes suficiente tiempo/atención/cuidado de esta persona?'
],
options: [{ text: 'Siguiente', nextId: 178 }],
},
{
id: 178,
text: ['Objetivos interpersonales:-',
'1. ¿Qué tipo de cambios o apoyo te gustaría de esta persona, además de lo que hace ahora?',
'2. Está bien querer que una persona dé más o menos de algo que ya hacen.'
],
options: [{ text: 'Siguiente', nextId: 179 }],
},
{
id: 179,
text: ['También está bien pedir apoyo para que una persona deje de hacer algo que lastima los sentimientos de alguien. Si es difícil identificar qué tipo de apoyo, a veces es útil pensar en lo que una persona podría decir o hacer que sería útil.',
'Está también bien pedir apoyo para que una persona deje de hacer algo que lastima los sentimientos de alguien.',
'Si es difícil identificar tipos de apoyo, a veces es útil pensar en lo que una persona podría decir o hacer que sería útil.'
],
options: [{ text: 'Siguiente', nextId: 180 }],
},
{
id: 180,
text: ['Vamos a practicar ser más asertivos a través de un juego de roles.',
'Estas habilidades se aplican a los problemas que estamos discutiendo hoy, así como a lo que cubriremos en la próxima sesión.',
'Después de tener un bebé, es importante contar con el apoyo de otros.'
],
options: [{ text: 'Siguiente', nextId: 181 }],
},
{
id: 181,
text: ['Para obtener ese apoyo, a veces puede que tengas que pedir ayuda o hacer una solicitud asertiva.',
'Practiquemos eso ahora y obtengamos tu opinión.'
],
options: [{ text: 'Siguiente', nextId: 182 }],
},
{
id: 182,
text: ['Para poner esta práctica a prueba, usa la siguiente plantilla y completa las siguientes preguntas:-',
'1. Un cambio que pediré esta semana:-',
'2. Porque quiero que escuchen sin sentirse molestos, necesito tener cuidado con:',
'3. Fecha en la que intentaré esto:',
'4. Persona con la que intentaré esto:']
},
{
id: 183,
text: ['Vamos a trabajar en un escenario real que podrías encontrar.',
'Recuerda usar declaraciones "yo": "Yo siento", "yo pienso", "yo quiero".',
'Esto brinda un mensaje claro sin poner a la otra persona a la defensiva.'
],
options: [{ text: 'Siguiente', nextId: 184 }],
},
{
id: 184,
text: ['También hay algunas "cosas que hacer" y "no hacer" clave que debes tener en cuenta y que se describirán aquí: cosas como abordar un solo problema a la vez, criticar el comportamiento, no a la persona, y estar dispuesto a dar y recibir.'
],
options: [{ text: 'Siguiente', nextId: 185 }],
},
{
id: 185,
text: ['Aquí están algunas Reglas que Hacer:-',
'1. Reglas de Oro para ser asertivo: Tómate tu tiempo (cuando digas que no, "¿Puedo informarte mañana?")',
'2. Declara abiertamente tus propios sentimientos, o los cambios que te gustaría.',
'3. Evita cualquier ataque a la otra persona, amenazas o declaraciones insultantes sobre la otra persona.'
],
options: [{ text: 'Siguiente', nextId: 186 }],
},
{
id: 186,
text: ['4. Evita: "Nunca, deberías y siempre."',
'5. Aborda un solo problema a la vez (en contraste con el “hundimiento de la cocina”).',
'6. Critica el comportamiento, no a la persona (di lo que quieres que hagan, no lo que deseas que sean).'
],
options: [{ text: 'Siguiente', nextId: 187 }],
},
{
id: 187,
text: ['7. Pídeles lo que quieres (en contraste con decirles lo que deberían hacer). ("Gracias por ser tan comprensivos, lo aprecio mucho").',
'8. Esté dispuesto a dar para recibir. Cambia la mesa: Pregúntales, "¿qué piensas que deberíamos hacer?"'
],
options: [{ text: 'Siguiente', nextId: 188 }],
},
{
id: 188,
text: ['Aquí algunas Cosas que No Hacer:-',
'1. Juzgar o culpar',
'2. Pedir disculpas excesivamente',
'3. Degradarte',
'4. Adivinar las intenciones del otro (saber lo que piensan)',
'5. Esperar que las personas sepan lo que quieres que hagan si no se lo dices',
'6. Dar toda una lista (“hundimiento de la cocina”)',
'7. Hablar sin decir nada',
'8. Decirle a la otra persona lo que deberían hacer'
],
options: [{ text: 'Siguiente', nextId: 189 }],
},
{
id: 189,
text: ['Tu tarea será completar la evaluación de relaciones para dos relaciones importantes y practicar hacer una solicitud asertiva que ensayamos.',
'También, recuerda comprometerte a al menos tres actividades placenteras nuevamente para esta semana.'
],
options: [{ text: 'Siguiente', nextId: 190 }],
},
{
id: 190,
text: ['La próxima vez abordaremos cualquier problema que hayas tenido al pedir ayuda, discutiremos la comunicación negativa, cómo decir que no, y tocaremos planificación para tu futuro.',
'Me siento realmente orgulloso del trabajo que estás haciendo.',
'Que tengas un maravilloso día y te veré la próxima vez.'
],
options: [{ text: 'Siguiente', nextId: 191 }],
},
{
id: 191,
text: ['¿Te gustaría proceder a la siguiente sesión o repetir la sesión actual?'
],
options: [{ text: 'Proceder', nextId: 192 },{ text: 'Repetir', nextId: 163 }],
},
{
id: 192,
text: ['¡Bienvenido de nuevo a la Sesión 6 donde hablaremos sobre Relaciones!',
'Espero que hayas tenido un buen rato practicando tus habilidades asertivas. Antes de comenzar a resolver problemas, quiero tomar un momento para hablar sobre un tema muy serio: las relaciones abusivas.'
],
options: [{ text: 'Siguiente', nextId: 193 }],
},
{
id: 193,
text: ['Evaluemos cómo es una relación sana: Algunos datos sobre relaciones saludables: Tendré un compañero en mi vida que:-',
'1. Apoya mi tiempo con amigos y familia.',
'2. Me apoya para hacer las cosas que disfruto y me ayuda a crecer como persona.',
'3. Me hace sentir bien conmigo misma.',
'4. Pide y escucha mis opiniones.',
'5. Respeta mi privacidad (no necesito compartir mi contraseña de mi email, FaceboSiguiente, y teléfono).',
'6. Me respeta y confía en mí, y trata a los demás con respeto.'
],
options: [{ text: 'Siguiente', nextId: 194 }],
},
{
id: 194,
text: ['Si tu relación es poco saludable, como si tu pareja te da críticas constantes, no muestra cuidado, no respeta tus gustos y disgustos, o te amenaza, es importante pensar en tu seguridad.'
],
options: [{ text: 'Siguiente', nextId: 195 }],
},
{
id: 195,
text: ['Considera…',
'1. No puedes forzar a una persona a cambiar su comportamiento.',
'2. Concéntrate en tus necesidades, cuídate.',
'3. No te aísles. Encuentra apoyo emocional.',
'4. Piensa en romper. Mereces sentirte segura y aceptada en tu relación.'
],
options: [{ text: 'Siguiente', nextId: 196 }],
},
{
id: 196,
text: ['Si crees que tu pareja podría estar abierta a ir contigo a tu terapeuta, consejero o médico para hablar sobre el conflicto en tu relación, deberías discutir la posibilidad con tu terapeuta y cómo presentárselo a tu pareja.',
'No es un buen momento para ser asertivo o discutir problemas cuando la situación o los sentimientos se descontrolan.'
],
options: [{ text: 'Siguiente', nextId: 197 }],
},
{
id: 197,
text: ['En esos casos, podrías decir algo como "Esto no está funcionando como esperaba.',
'Hablemos en otro momento cuando las cosas estén más calmadas."',
'Durante estos tiempos, a menudo es útil tomar un “tiempo fuera”.',
'Eso es cuando ambas personas abandonan la situación y regresan para discutir el problema una vez que hayan recuperado el control sobre sus sentimientos y puedan hablar con calma.'
],
options: [{ text: 'Siguiente', nextId: 198 }],
},
{
id: 198,
text: ['Recuerda tus derechos, incluso si los demás no lo hacen. Aquí hay algunos derechos que recordar: Está bien querer o necesitar algo de otra persona.',
'Puedo insistir en mis derechos y aún ser una buena persona. A veces tengo derecho a afirmar mis necesidades, incluso si eso puede incomodar a otros.'
],
options: [{ text: 'Siguiente', nextId: 199 }],
},
{
id: 199,
text: ['Puedo querer complacer a las personas que me importan, pero no tengo tiempo para complacerlas todo el tiempo.',
'El hecho de que le diga no a alguien no me convierte en una persona egoísta.',
'Aún puedo sentirme bien conmigo misma, incluso si alguien más está molesto conmigo.'
],
options: [{ text: 'Siguiente', nextId: 200 }],
},
{
id: 200,
text: ['No puedes hacerlo todo tú misma.',
'Defiéndete.',
'Mantén el enfoque en tus necesidades.',
'¿Puedes pensar en algún ejemplo en el que sería útil tomarte un tiempo fuera?'
],
},
{
id: 201,
text: ['Entiendo.',
'Ahora, si estás lidiando con alguien que se está volviendo agresivo o violento, como empezar a lanzar cosas, maldecirte o empujarte, lo más importante es que te alejes de la situación.',
'Si te quedas, las cosas pueden empeorar y pueden volverse peligrosas.'
],
options: [{ text: 'Siguiente', nextId: 202 }],
},
{
id: 202,
text: ['Tu seguridad es más importante que resolver un problema de inmediato.',
'No merece ser tratado injustamente o ser abusado.',
'Si la situación se vuelve violenta y tu seguridad o la de tus hijos está en riesgo, es crucial que afirmes tu necesidad de seguridad y te alejes.'
],
options: [{ text: 'Siguiente', nextId: 203 }],
},
{
id: 203,
text: ['A veces es necesario llamar a la policía, tomar medidas legales como una orden de restricción, acudir a amigos o familiares, o ir a un refugio para averiguar cómo protegerte.',
'Aquí hay un número de línea de ayuda para tenerlo a mano. Línea Nacional contra la Violencia Doméstica: 1-800-799-SAFE (7233) 24 horas de líneas de ayuda por violencia doméstica que puedes llamar para hablar sobre tu situación y obtener apoyo.'
],
options: [{ text: 'Siguiente', nextId: 204 }],
},
{
id: 204,
text: ['Si estás en una relación que se vuelve violenta, es importante pensar cuidadosamente si está en un estado de "dar y recibir", "resentido en silencio", o "no se puede arreglar".',
'Pregúntate si es una relación que deseas continuar: ¿se pueden satisfacer alguna vez tus necesidades?'
],
options: [{ text: 'Siguiente', nextId: 205 }],
},
{
id: 205,
text: ['Si has identificado algún riesgo de violencia física u otro abuso, hay cosas que puedes hacer para aumentar tu seguridad.',
'Aunque no puedes controlar el comportamiento de otra persona y no siempre puedes evitar incidentes violentos, puedes controlar tu respuesta y tomar precauciones para reducir la gravedad de ciertos riesgos o la probabilidad de experimentar otros riesgos.'
],
options: [{ text: 'Siguiente', nextId: 206 }],
},
{
id: 206,
text: ['Prepararte con anticipación para la posibilidad de más violencia puede aumentar tu seguridad.',
'Las conductas de seguridad delinean cosas que ya podrías estar haciendo y cosas que podrías comenzar a hacer para protegerte.',
'Sugiero, como tarea, identificar qué conductas ya usas y cuáles deberías comenzar a usar más si estás en una situación abusiva.'
],
options: [{ text: 'Siguiente', nextId: 207 }],
},
{
id: 207,
text: ['Piensa en un lugar seguro al que ir si ocurre una discusión: evita habitaciones sin salidas (baño) o habitaciones con armas (cocina, garaje).',
'Piensa y haz una lista de personas seguras a las que contactar. Lleva contigo algo de dinero en todo momento. Ten las llaves del auto (y la bolsa) listas y siempre ponlas en un lugar donde puedas acceder rápidamente.'
],
options: [{ text: 'Siguiente', nextId: 208 }],
},
{
id: 208,
text: ['Piensa de antemano en lugares seguros a los que podrías ir (como quedarte con un amigo o familiar de confianza)',
'Habla con personas en las que confíes, como amigos, familiares, vecinos, compañeros de trabajo o tu terapeuta, consejero o médico.',
'Usa tu criterio e intuición. Si la situación es muy seria, dale a tu pareja lo que quiere para calmarlo.'
],
options: [{ text: 'Siguiente', nextId: 209 }],
},
{
id: 209,
text: ['Si tu pareja ha sido violenta en el pasado, es probable que vuelva a suceder, incluso si tu pareja prometió que no sucedería de nuevo.',
'- Recuerda que no tienes control sobre la violencia de tu pareja, pero tienes cierto control sobre cómo te preparas para ello y respondes ante eso.'
],
options: [{ text: 'Siguiente', nextId: 210 }],
},
{
id: 210,
text: ['En el peor de los casos, si tienes que irte, aquí hay una lista de cosas que debes recordar llevar:-',
'1. Identificación para ti',
'2. Actas de nacimiento de los niños',
'3. Tu acta de nacimiento',
'4. Tarjetas de Seguro Social',
'5. Registros escolares y de vacunación',
'6. Dinero',
'7. Chequera, tarjeta de cajero automático, tarjetas de crédito',
'8. Licencia de conducir y registro',
'9. Medicamentos',
'10. Gafas',
'11. Contrato de arrendamiento',
'12. Snacks no perecederos para ti y tus hijos (por ejemplo, jugo y galletas)',
'13. Cosas para el bebé (pañales, fórmula, medicamentos)'],
options: [{ text: 'Siguiente', nextId: 211 }],
},
{
id: 211,
text: ['Prepárate con anticipación por si tienes que salir apresuradamente. Plan de seguridad para romper si la situación llega a eso:-',
'1. Terminar la relación puede ser la mejor decisión para ti a largo plazo, pero el periodo de separación puede ser el más peligroso en una relación abusiva.',
'2. Pueden intentar controlarte mediante culpas, amenazas o insultos.',
'3. Puede ser muy difícil tener una ruptura pacífica o mutua con una pareja abusiva.'],
options: [{ text: 'Siguiente', nextId: 212 }],
},
{
id: 212,
text: ['Algunos consejos de seguridad:-',
'1. Planea la ruptura con ayuda de personas en las que confíes.',
'2. Habla con tu consejero o médico, o comunícale a amigos y familiares de confianza cuándo y cómo vas a romper.',
'3. No rompas en un lugar aislado. Házlo donde haya personas alrededor, especialmente personas de confianza. Si no te sientes segura, rompe por teléfono o correo electrónico.'],
options: [{ text: 'Siguiente', nextId: 213 }],
},
{
id: 213,
text: ['4. Prepara lo que le dirás a tu pareja, por qué estás terminando la relación. Dilo solo una vez.',
'5. Evita estar sola con tu expareja. Si tu expareja llega a tu casa cuando estás sola, no vayas a abrir la puerta.',
'6. Pide ayuda. Memoriza números importantes en caso de que no tengas un teléfono celular contigo.'],
options: [{ text: 'Siguiente', nextId: 214 }],
},
{
id: 214,
text: ['7. Cambia tus rutas y rutinas, y pide a personas de confianza que te acompañen.',
'8. Confía en tus sentimientos: si tienes miedo, probablemente tengas buenas razones. Si estás en peligro inminente, llama al 911.',
'9. Si crees que podrías estar en peligro, obtén una orden de restricción.'],
options: [{ text: 'Siguiente', nextId: 215 }],
},
{
id: 215,
text: ['Aquí hay algunos números de teléfono que necesitas conocer y mantener a mano:-',
'Línea Nacional de Violencia Doméstica: 1-800-799-SAFE (7233) (24 horas)',
'Departamento de Policía/Alguacil cerca de casa, escuela y trabajo:',
'Programa local de Violencia Doméstica:',
'Oficina de Órdenes de Restricción:',
'Servicio de Referencia Legal/Servicios Legales',
'Agencia:',
'Terapista/Consejero:',
'Número de trabajo:',
'Número de casa del supervisor:',
'Ministro/Sacerdote/Rabino/Líder de Fe:',
'Amigo:',
'Otro:'],
options: [{ text: 'Siguiente', nextId: 216 }],
},
{
id: 216,
text: ['Algunos Consejos de Seguridad si dejaste una relación abusiva:-',
'Cambia tu número de teléfono. Filtra las llamadas.',
'Guarda y documenta todos los contactos, mensajes, lesiones u otros incidentes que involucren al maltratador.',
'Cambia las cerraduras, si el maltratador tiene una llave. Evita quedarte sola.'],
options: [{ text: 'Siguiente', nextId: 217 }],
},
{
id: 217,
text: ['Planea cómo escapar si te enfrenta un compañero abusivo.',
'Si tienes que reunirte con tu pareja, hazlo en un lugar público.',
'Varía tu rutina.',
'Notifica a la escuela y a contactos laborales.',
'Si tienes una orden de protección, mantenla siempre contigo o cerca de ti.',
'Llama a un refugio para personas maltratadas.'],
options: [{ text: 'Siguiente', nextId: 218 }],
},
{
id: 218,
text: ['En caso de que necesites obtener una orden de restricción: ¿Qué es una Orden de Restricción Temporal? (TRO):-',
'1. Si crees que podrías estar en peligro, se recomienda una orden de restricción temporal.',
'2. NO es una garantía de seguridad, pero es una orden legal que dice que tu abusador debe mantenerse alejado de ti.',
'3. Con una orden de restricción temporal (TRO), tu abusador puede ser arrestado si intenta contactarte. No hay tarifa por una TRO, y no necesitas un abogado para obtener una TRO.'],
options: [{ text: 'Siguiente', nextId: 219 }],
},
{
id: 219,
text: ['Cómo obtener una Orden Temporal de Restricción (TRO):-',
'1. Ve a tu tribunal local de familia o distrito para llenar el papeleo.',
'2. Después de que el papeleo sea entregado a un secretario, un juez lo revisará, y el juez puede o no hacerte preguntas.',
'3. Si el juez firma la orden, es válida por hasta 21 días.',
'4. Un oficial de la corte luego entregará a tu abusador una copia de la orden de restricción.'],
options: [{ text: 'Siguiente', nextId: 230 }],
},
{
id: 230,
text: ['5. Si crees que estás en peligro durante la tarde, el fin de semana o feriados, es posible que puedas obtener una orden de restricción de emergencia contra tu abusador.',
'6. Llama a tu departamento de policía local. La policía tiene acceso las 24 horas a jueces de la Corte de Familia y del Distrito que pueden emitir una orden de protección de emergencia.'],
options: [{ text: 'Siguiente', nextId: 231 }],
},
{
id: 231,
text: ['7. Si obtienes una orden de restricción de emergencia, deberás ir a la corte el siguiente día hábil para finalizar la orden de restricción.',
'8. Si no estás segura de qué hacer, puedes llamar a una agencia de violencia doméstica o a una oficina de órdenes de restricción para obtener más información.',
'9. Algunos Números de Teléfono Útiles: Coalición Contra la Violencia Doméstica (Nacional) 1-800-494-8100 (24 horas).'],
options: [{ text: 'Siguiente', nextId: 232 }],
},
{
id: 232,
text: ['¿Tienes alguna pregunta sobre este tema o sobre algo que hemos discutido hoy? Sé que no es un tema fácil de hablar.']
},
{
id: 233,
text: ['Gracias por compartir. Entiendo y reconozco lo que quieres decir.',
'1. Recuerda, no estás sola y nunca es tu culpa si alguien abusa de ti.',
'2. Hay ayuda disponible. Para la próxima sesión, además del plan de seguridad si es necesario, me gustaría que completarás las preguntas sobre la evaluación de relaciones y cambios para dos relaciones importantes.',
'3. Además, sigue trabajando en la práctica de solicitudes asertivas.'],
options: [{ text: 'Siguiente', nextId: 234 }],
},
{
id: 234,
text: ['4. Y no olvides seguir programando actividades placenteras para ti.',
'5. Comprométete a al menos tres para la próxima semana.',
'6. Sigue con el gran trabajo y recuerda,',
'Siempre estoy aquí si necesitas apoyo adicional. ¡Disfruta tu día!'],
options: [{ text: 'Siguiente', nextId: 235 }],
},
{
id: 235,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Asertividad o repetir la sesión actual?'],
options: [{ text: 'Proceder', nextId: 236 },{ text: 'Repetir', nextId: 192 }],
},
{
id: 236,
text: ['¡Bienvenido de nuevo a la Sesión 7 donde hablaremos sobre Asertividad!',
'Profundicemos en los temas de hoy. ¿Cómo te fue cuando hiciste esas solicitudes asertivas? ¿Pudiste pedir ayuda?'],
options: [{ text: 'Sí', nextId: 237 },{ text: 'No', nextId: 237 }],
},
{
id: 237,
text: ['Siéntete libre de compartir más pensamientos sobre la pregunta anterior.']
},
{
id: 238,
text: ['- Gracias por compartir. Entiendo perfectamente lo que quieres decir.',
'- Algunas personas pueden no ser siempre receptivas, y solo porque pidas algo de manera asertiva no significa que dirán que sí.',
'- Pero tienes el derecho de preguntar, así como ellos tienen el derecho de decir que no.'],
options: [{ text: 'Siguiente', nextId: 239 }],
},
{
id: 239,
text: ['Hagamos un repaso rápido sobre la asertividad. Escribe tus pensamientos.',
'1. ¿Qué es la asertividad?',
'2. ¿Qué debes recordar al ser asertivo?']
},
{
id: 240,
text: ['Entiendo lo que quieres decir. Gracias por compartir.',
'La maternidad traerá muchas demandas y un deseo de más apoyo.',
'Al mismo tiempo, las personas a tu alrededor pueden no saber lo que más deseas.',
'Nuestro objetivo es ayudarte a pensar sobre tus necesidades interpersonales, priorizar las más importantes y practicar habilidades para ser realmente efectiva al pedir esas necesidades.'],
options: [{ text: 'Siguiente', nextId: 241 }],
},
{
id: 241,
text: ['Para entender mejor el comportamiento asertivo saludable, ayuda compararlo con la falta de asertividad y el comportamiento agresivo.',
'Si no eres asertiva, no expresas tus propios deseos, necesidades o ideas.',
'Ignoras tus propios derechos y permites que otros se aprovechen de ti.',
'¿Cuáles son algunas razones por las que las personas pueden no ser asertivas?']
},
{
id: 242,
text: ['Esas son razones comunes.',
'Cuando no eres asertiva, a menudo conduce a la ira, resentimiento, frustración, sentirte impotente, usada, atrapada o fuera de control.',
'El problema nunca se resuelve. Por otro lado, cuando las personas son agresivas, expresan sus sentimientos y necesidades a expensas de los demás.'],
options: [{ text: 'Siguiente', nextId: 243 }],
},
{
id: 243,
text: ['Violan los derechos de los demás y tratan de dominar.',
'Esto puede suceder por ira, frustración o repetidamente no ser asertiva hasta que finalmente estallas.'],
options: [{ text: 'Siguiente', nextId: 244 }],
},
{
id: 244,
text: ['Pero puede destruir relaciones y no resolver problemas a largo plazo.',
'Ser asertiva es la mejor manera de mantener tu respeto personal y intentar resolver problemas en las relaciones.',
'Pero hay obstáculos que pueden interponerse en el camino.'],
options: [{ text: 'Siguiente', nextId: 245 }],
},
{
id: 245,
text: ['La última vez tocamos algunos obstáculos para ser asertiva.',
'Exploremos eso un poco más. Algunos obstáculos comunes son: La asertividad no siempre se valora en las mujeres en diferentes culturas.',
'Miedo a la represalia y el conflicto.',
'Desconocimiento de la asertividad como habilidad.'],
options: [{ text: 'Siguiente', nextId: 246 }],
},
{
id: 246,
text: ['Miedo a ser llamada mala o egoísta.',
'Miedo a perder la relación.',
'Miedo a provocar enojo en uno mismo.',
'Creencias de que no merecemos lo que pedimos o que deberíamos ser capaces de manejarlo por nosotros mismos.',
'No estar lista para escuchar un no o enfrentarse a que una relación no se puede reparar.',
'Falta de confianza en uno misma.'],
options: [{ text: 'Siguiente', nextId: 247 }],
},
{
id: 247,
text: ['A menudo, los pensamientos preocupantes se interponen en el camino de decir que no, como "No les gustaré", "Él pensará que soy exigente", "Ella pensará que soy estúpida", o "Decir que no significa que no me importa o que no tengo consideración".',
'Es importante evaluar si estos pensamientos son realistas y contrarrestarlos con otros realistas.',
'La mejor manera de superar estos obstáculos es practicar y prepararte.',
'La información de esta clase puede ayudarte a prepararte para pedir ayuda, decir que no o comunicar tus sentimientos.'],
options: [{ text: 'Siguiente', nextId: 248 }],
},
{
id: 248,
text: ['Para obtener el apoyo que necesitamos, debemos nutrir nuestras relaciones.',
'Presta atención a ellas para mantenerlas saludables. Debe haber un equilibrio entre prioridades: cosas que son importantes para ti y demandas: cosas que otros quieren que hagas.',
'Cuando tengas un nuevo bebé, de repente habrá muchas nuevas demandas además de todas las viejas.'],
options: [{ text: 'Siguiente', nextId: 249 }],
},
{
id: 249,
text: ['Tus deseos serán constantemente relegados.',
'Por lo que será importante que tomes decisiones sobre qué es más importante para ti hacer.',
'A veces tendrás que pedir ayuda para lograr tus prioridades.',
'Y a veces tendrás que decir que no a las personas.',
'Dicho no puede ser muy difícil, al igual que pedir ayuda.'],
options: [{ text: 'Siguiente', nextId: 250 }],
},
{
id: 250,
text: ['Recuerda, tienes el derecho de decir que no.',
'No puedes ser todo para todos.',
'Como nueva mamá, habrá algunas cosas que solías hacer por otros que no podrás hacer con las responsabilidades adicionales.',
'¿Por qué es tan difícil decir que no? ¿Cuáles son algunos obstáculos? Siéntete libre de escribir tus pensamientos.']
},
{
id: 251,
text: ['Esos son obstáculos muy comunes.',
'A veces necesitas repetir no una y otra vez, especialmente si los demás no están escuchando o comienzan a discutir.',
'Llamamos a esto la técnica del disco rayado.',
'Decir que no requiere las mismas habilidades que pedir ayuda.',
'Recuerda las reglas de oro, utiliza la técnica del disco rayado y sal de la situación si alguien no acepta un no como respuesta.'],
options: [{ text: 'Siguiente', nextId: 252 }],
},
{
id: 252,
text: ['¿Puedes pensar en ejemplos de veces que te hubiera gustado decir que no, pero no lo hiciste? ¿Cómo podrías decir que no en esas situaciones en el futuro?']
},
{
id: 253,
text: ['Gracias por compartir. Entiendo por lo que estás pasando.',
'Estás siendo asertiva, pero también amable.',
'Si no estás segura respecto a una solicitud o quieres decir que no, pero no tienes valor en ese momento, siempre puedes pedir tiempo para pensar en ello.',
'Usa ese tiempo para averiguar qué quieres decir.',
'Pero es importante que regreses con la persona con tu decisión final.'],
options: [{ text: 'Siguiente', nextId: 255 }],
},
{
id: 255,
text: ['También puedes ofrecer una alternativa si deseas ayudar pero no puedes hacer lo que te están pidiendo específicamente.',
'Como "no puedo cuidar niños esta noche, pero me encantaría cuidar a los niños el próximo martes o miércoles por la noche."'],
options: [{ text: 'Siguiente', nextId: 256 }],
},
{
id: 256,
text: ['Solo ofrece esto si realmente lo sientes.',
'La asertividad es una habilidad que requiere práctica.',
'Cuanto más lo hagas, más fácil se hará, especialmente en establecer límites saludables después de que llegue el bebé.'],
options: [{ text: 'Siguiente', nextId: 257 }],
},
{
id: 257,
text: ['Resumamos lo que hemos aprendido sobre la asertividad:-',
'El bebé ocupará la mayor parte de tu tiempo con poco sobrante para relaciones.',
'Esto puede tensar tus otras relaciones si no les gusta cómo eliges gastar tu tiempo.',
'Si tenías dificultades con una pareja antes del bebé, tener un bebé no es probable que los acerque.'],
options: [{ text: 'Siguiente', nextId: 258 }],
},
{
id: 258,
text: ['Puede haber desacuerdos con padres y suegros sobre cómo cuidar al bebé.',
'Cuanto más desacuerden o te desafíen tus familiares, más enojada o llena de dudas podrías sentirte.',
'Son afortunadas las mujeres que tienen familiares solidarios.'],
options: [{ text: 'Siguiente', nextId: 259 }],
},
{
id: 259,
text: ['Los amigos y la familia pueden no ofrecer el tipo de ayuda que necesitas.',
'A veces no están disponibles porque piensan que estarán de más o temen interrumpir un tiempo muy personal.',
'Quizás no se den cuenta de que te gustaría recibir ayuda o apoyo.'],
options: [{ text: 'Siguiente', nextId: 260 }],
},
{
id: 260,
text: ['Necesitas decirles lo que necesitas. Ser madre soltera es particularmente difícil, y necesitas obtener toda la ayuda que puedas.',
'Si tienes una pareja, intenta tomarte 15 minutos para comunicarte al menos una vez a la semana sobre cómo estos enormes cambios están afectando a ambos.'],
options: [{ text: 'Siguiente', nextId: 261 }],
},
{
id: 261,
text: ['¿Te gustaría proceder a la siguiente sesión donde discutiremos más sobre Establecimiento de Objetivos o deseas repetir la sesión actual?'],
options: [{ text: 'Proceder', nextId: 262 },{ text: 'Repetir', nextId: 236 }],
},
{
id: 262,
text: ['¡Bienvenido a tu última sesión donde hablaremos sobre Establecimiento de Objetivos antes de que el bebé llegue! ¡Felicitaciones por completar las sesiones hasta ahora y nos gustaría volver a conectar después de que llegue el bebé!',
'Hablemos sobre la planificación para el futuro, que significa establecer objetivos.',
'Cuando tienes un bebé, puedes sentir que toda tu vida se ha reducido a pañales sucios, alimentación, baño, eructos y lavandería.',
'Cuando experimentamos cambios, es importante reemplazar los objetivos perdidos.'],
options: [{ text: 'Siguiente', nextId: 263 }],
},
{
id: 263,
text: ['Los seres humanos son más felices cuando tienen esperanzas, sueños y trabajan para lograrlos.',
'¿Cuáles son algunos de tus sueños u objetivos a largo plazo? Responde en la ventana de chat.',
'Recuerda, la infancia no dura para siempre. Escribe tus pensamientos.']
},
{
id: 264,
text: ['¿Qué pasos necesitas tomar para trabajar hacia tus sueños?',
'¿Cómo los lograrás y cuándo?']
},
{
id: 265,
text: ['Gracias por compartir. Reconozco y entiendo lo que quieres decir.',
'Desglosar los grandes objetivos así los hace sentir mucho más alcanzables. Aquí hay algunos consejos finales del programa ROSE.'],
options: [{ text: 'Siguiente', nextId: 266 }],
},

{
  id: 266,
  text: ['ACTIVIDADES AGRADABLES:-',
    '1. Recuerda que es importante cuidar de ti mismo y desarrollar nuevas formas de hacerlo (si es necesario).',
    '2. Incluir actividades positivas puede reducir el malestar y aumentar tu energía y perspectiva positiva.',
    '3. Recuerda tu contrato de actividades agradables.'],
  options: [{ text: 'Siguiente', nextId: 267 }],
}, 
{
  id: 267,
  text: ['RELajACION:-',
    '1. Aprender a gestionar el estrés te hará sentir mejor, aumentará tu sentido de control y te empoderará.',
    '2. Recuerda hacer tus ejercicios de relajación. Aquí está el guion para tu referencia:'],
  options: [{ text: 'Siguiente', nextId: 268 }],
}, 
{
  id: 268,
  text: ['Disminuyendo el Estrés:-',
    '1. Ejercicio de relajación como recordatorio: quiero que te pongas cómodo. Si quieres, puedes cerrar los ojos.',
    '2. Vamos a empezar con tus pies - riza tus dedos, y relájalos.',
    '3. Ahora clava tus talones en el suelo y siente la tensión en tus piernas, y relájate - bien.',
    '4. Tus piernas se sienten sueltas y pesadas. Ahora tensa tu estómago - mantén, siente la tensión y suéltala - muy bien.',
    '5. Ahora quiero que arquees tu espalda - siente la tensión en tu columna vertebral y relaja tu espalda - simplemente suéltate - deja que toda la tensión salga de tu cuerpo - bien.'],
  options: [{ text: 'Siguiente', nextId: 269 }],
}, 
{
  id: 269,
  text: ['Haz un puño con tus manos y levántalas lentamente hacia tu hombro - siente la tensión en tus brazos y suéltalos - simplemente deja que tus brazos caigan a los lados y estén sueltos y pesados, bien.',
    'Ahora levanta tus hombros hacia tus oídos - siente la tensión en tu cuello, mantén y suelta - deja que toda la tensión se vaya.',
    'Quiero que ahora tenses tu boca lo más que puedas - siente la tensión en tu mandíbula - bien y simplemente relájate.'],
  options: [{ text: 'Siguiente', nextId: 270 }],
}, 
{
  id: 270,
  text: ['Toma una respiración profunda - y exhala - simplemente siente toda la tensión salir de tu cuerpo.',
    'Ahora tensa tus ojos lo más que puedas - y suelta tu cuerpo, para que se sienta bien y relajado - bien, muy bien.'],
  options: [{ text: 'Siguiente', nextId: 271 }],
}, 
{
  id: 271,
  text: ['Concéntrate en lo tranquilo y relajado que te sientes - piensa en ti mismo en una nube suave sintiendo la suavidad de la nube y hundiéndote en la nube - tu cuerpo se siente tan suelto y pesado.',
    'Siente el cálido sol en tu cuerpo y la fresca brisa en tu cara. Simplemente relájate - te sientes tan en paz y tan seguro.'],
  options: [{ text: 'Siguiente', nextId: 272 }],
}, 
{
  id: 272,
  text: ['Ahora voy a contar hacia atrás desde 5 y cuando llegue a 1, abrirás los ojos lentamente y la sensación de calma y paz permanecerá contigo durante el resto del día.',
    'Inhala 5 . . . y exhala calma',
    'Inhala 4 . . . y exhala calma',
    'Inhala 3 . . . y exhala calma',
    'Inhala 2 . . . y exhala calma',
    'Inhala 1 . . . y fuera . . . . piensa en calma',
    'Al abrir lentamente tus ojos, la calma y la paz permanecerán contigo.'],
  options: [{ text: 'Siguiente', nextId: 273 }],
}, 
{
  id: 273,
  text: ['CAMBIOS: CONVERTIRSE EN UN NUEVO PADRE/CUIDADOR:-',
    '1. Los grandes cambios en la vida, como tener un bebé, pueden ser estresantes.',
    '2. Convertirse en un nuevo padre/cuidadores implica nuevas demandas y pérdidas.',
    '3. Puedes sentirte temeroso, triste, tenso y frustrado - recuerda que no estás solo y que no tienes la culpa.'],
  options: [{ text: 'Siguiente', nextId: 274 }],
}, 
{
  id: 274,
  text: ['Es importante hablar sobre tus sentimientos, buscar apoyo.',
    'Es importante reemplazar las metas perdidas al experimentar pérdidas y cambios en la vida. Hay metas a corto plazo (pequeños pasos) y metas a largo plazo.',
    'Establece metas continuas. (La infancia no dura para siempre).'],
  options: [{ text: 'Siguiente', nextId: 275 }],
}, 
{
  id: 275,
  text: ['Y si tenemos tiempo, me gustaría que completaras este folleto de "Mis Recursos".',
    'Recuerda, una vez que tengas tu bebé, volveré a contactarte para ver cómo estás tú y el bebé y recordarte lo que aprendiste en nuestras sesiones.'],
  options: [{ text: 'Siguiente', nextId: 276 }],
}, 
{
  id: 276,
  text: ['1. APOYATE EN FAMILIA Y AMIGOS:- Unas pocas horas de cuidado infantil a la semana pueden darte un merecido descanso. Compartir sentimientos abiertamente permite a la familia y amigos brindar apoyo.',
    '2. HABLA CON UN PROFESIONAL DE LA SALUD:- Los doctores sabrán qué opciones tienes disponibles para asistencia y estarán familiarizados con la depresión perinatal.'],
  options: [{ text: 'Siguiente', nextId: 277 }],
}, 
{
  id: 277,
  text: ['1. ENCUENTRA UN GRUPO DE APOYO:- Hay otros en tu comunidad que sufren en situaciones similares; compartir tus sentimientos en un grupo de personas que están pasando por lo mismo puede ser útil. Habla con tu proveedor de salud sobre cómo unirte a un grupo.',
    '2. HABLA CON UN PROFESIONAL DE SALUD MENTAL:- Los profesionales de salud mental pueden brindarte un espacio seguro para expresar tus sentimientos y serán útiles para ayudarte a manejar tus síntomas.',
    '3. CONCENTRATE EN EL BIENESTAR:- Come una variedad saludable de alimentos coloridos, bocadillos saludables y evita el consumo de alcohol. Haz ejercicio, invita a amigos a caminar, prueba una nueva actividad, tómate tiempo para estirar los músculos.'],
  options: [{ text: 'Siguiente', nextId: 278 }],
}, 
{
  id: 278,
  text: ['Aquí hay un Kit de Supervivencia para Nuevos Padres/Cuidadores:-',
    '1. Cuida de ti físicamente (por ej., suficiente descanso, buena alimentación, ejercicio)',
    '2. Tómate descansos “mini vacaciones” (el descanso es descanso - ¡sin tareas durante los descansos!)',
    '3. Date crédito, no olvides incluir las pequeñas tareas',
    '4. Planea al menos un evento al día que involucre a un adulto (por ej., una llamada a un amigo)',
    '5. Planea algo divertido (por ej., un programa de televisión, un baño de burbujas, ver fotos antiguas)',
    '6. Pide ayuda - no puedes hacerlo todo',
    '7. Posponer cambios importantes en tu vida'],
  options: [{ text: 'Siguiente', nextId: 279 }],
}, 
{
  id: 279,
  text: ['ENFRENTANDO RELACIONES:',
    '1. Tienes derecho a pedir ayuda y derecho a decir NO.',
    '2. Las relaciones necesitan trabajo constante, especialmente cuando hay cambios como tener un nuevo bebé en tu vida.',
    '3. Mantén el equilibrio en tus relaciones - las demandas no deben superar tus necesidades.',
    '4. RECUERDA – Celebra tus esfuerzos y éxitos, date crédito, incluso por las pequeñas cosas que haces como pareja, miembro de la familia, amigo o futuro padre/cuidor.'],
  options: [{ text: 'Siguiente', nextId: 280 }],
}, 
{
  id: 280,
  text: ['Para la tarea, sigue practicando el comportamiento asertivo.',
    'Será más fácil mantener el equilibrio en tus relaciones si comienzas ahora, antes de que llegue el bebé. Trabaja en completar el folleto con tus metas y pasos para lograrlas.'],
  options: [{ text: 'Siguiente', nextId: 281 }],
}, 
{
  id: 281,
  text: ['Y sigue con tu horario de actividades agradables, apunta a al menos 3 actividades que disfrutes cada semana. Ha sido un placer trabajar contigo.',
    'Recuerda, tienes lo que se necesita para ser una madre increíble.',
    'No dudes en pedir ayuda cuando la necesites.',
    'Nos veremos de nuevo pronto después de que llegue tu pequeño. ¡Cuídate bien!'],
  options: [{ text: 'Siguiente', nextId: 282 }],
}, 
{
  id: 282,
  text: ['Y con eso, hemos concluido las 8 sesiones iniciales. ¡Excelente trabajo!!! Te deseamos lo mejor en el futuro y nos reconectaremos después del nacimiento de tu bebé para ayudarte a navegar a través de más problemas post embarazo.',
    'Si deseas revisar alguna de las sesiones nuevamente, haz clic en los botones de las sesiones a continuación:-'],
  options: [{ text: '1', nextId: 1 },{ text: '2', nextId: 49 },{ text: '3', nextId: 86 },{ text: '4', nextId: 115 },{ text: '5', nextId: 151 },{ text: '6', nextId: 192 },{ text: '7', nextId: 236 },{ text: '8', nextId: 262 }],
}


        
    ]



    
  };
