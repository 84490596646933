import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      }
    },
  },
})

export const { addUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer;