import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

client.interceptors.request.use(async (config) => {
    try {
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
        console.error("Error setting Authorization Header", error);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default client;