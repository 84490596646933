import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { Country, State }  from 'country-state-city';

import { Input } from "../../components/ui/Input";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../../components/MainStyles";
import logo from "../../nurtur_new_logo.svg";
import styles from "./styles";
import { Tooltip } from "react-tooltip";
import { Autocomplete, TextField } from "@mui/material";
import client from "../../api";
import Urls from "../../api/urls";
import { styled } from '@mui/material/styles';

const formValidation = yup.object().shape({
  firstname: yup
    .string()
    .required('Please enter first name')
    .min(1, 'Please enter first name'),
  lastname: yup
    .string()
    .required('Please enter last name')
    .min(1, 'Please enter last name'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(8, 'Must be at least 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[^\w]/, 'Password requires a symbol'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords does not match')
    .required('Please confirm your password'),
  email: yup
    .string()
    .lowercase()
    .trim()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email address")
    .required('Please enter your email'),
});

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [countries, setCountries] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [states, setStates] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#7B7FFF',
      },
      '&:hover fieldset': {
        borderColor: '#5A5EFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3D42FF',
      },
    },
  });

  useEffect(() => {
    const countries = Country.getAllCountries();
    const allCountries = countries.map((country) => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry.value).map((state) => ({ label: state.name, value: state.isoCode }));
      setStates(states);
    }
  }, [selectedCountry])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      // Replace the URL with your FastAPI endpoint
      const response = await client.post(Urls.REGISTER, {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        country: selectedCountry.value,
        state: selectedState.value
      });
      if (response.status === 200) {
        const { email } = response.data.cosmos_user;
        
        try {
          // Send verification email
          await client.post(Urls.SEND_VERIFICATION_EMAIL, { email });
          setVerificationSent(true);
          navigate('/account-verification', { state: { email } });
        } catch (verificationError) {
          console.error('Error sending verification email:', verificationError.message);
          setErrorMessage(`Account created, but there was an issue sending the verification email. Try logging in.`);
        }
      }
    } catch (error) {
      console.log('error', error);
      setErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };


  return (
    <>
      <div style={{ position: 'relative' }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            <MainTitle>Create an account</MainTitle>

            <Formik
              initialValues={{ firstname: '', lastname: '', email: '', password: '', confirmPassword: '' }}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field id="firstname" type="text" name="firstname" placeholder="Enter First Name*" style={styles.formField} />
                  <ErrorMessage name="firstname" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="lastname" type="text" name="lastname" placeholder="Enter Last Name*" style={styles.formField} />
                  <ErrorMessage name="lastname" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="email" type="email" name="email" placeholder="Enter Email*" style={styles.formField} />
                  <ErrorMessage name="email" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="password" type="password" name="password" placeholder="Enter Password*" style={styles.formField} data-tooltip-id="password" data-tooltip-html="Password must be a combination of 8 letters, <br />numbers and special characters." />
                  <ErrorMessage name="password" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <Field id="confirmPassword" type="password" name="confirmPassword" placeholder="Confirm Password*" style={styles.formField} />
                  <ErrorMessage name="confirmPassword" component="div" style={{ color: "red", marginLeft: 10, fontSize: 'small' }} />
                  <StyledAutocomplete
                    disablePortal
                    options={countries}
                    sx={{ marginLeft: 1, width: '100%', marginTop: 2 }}
                    renderInput={(params) => <TextField {...params} label="Country" />}
                    value={selectedCountry?.label}
                    onChange={(event, newValue) => {
                      setSelectedCountry(newValue)
                    }}
                  />
                  <StyledAutocomplete
                    disablePortal
                    options={states}
                    sx={{ marginLeft: 1, width: '100%', marginTop: 3, marginBottom: 2 }}
                    renderInput={(params) => <TextField {...params} label="State" />}
                    value={selectedState?.label}
                    onChange={(event, newValue) => {
                      setSelectedState(newValue)
                    }}
                  />
                  {errorMessage && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center'}}>{errorMessage}</div>}
                  <SubmitButton theme="blue" type="submit" disabled={isSubmitting}>
                    Sign Up
                  </SubmitButton>
                  <SmallText style={{ marginTop: 5, marginBottom: 5 }}>
                    Already have an account?{" "}
                      <Link to="/" style={{ fontWeight: "bold", color: "#7B7FFF" }}>
                        Log In
                      </Link>
                  </SmallText>
                </Form>
              )}
            </Formik>

            <SmallText style={{ textDecoration: "underline" }}>
              <Link to="/terms">Terms of Use | Privacy Policy</Link>
            </SmallText>
          </LoginForm>
        </FormContainer>
        <Tooltip id="password" place="left"  data-tooltip-variant="info" />
      </div>
    </>
  );
};

export default SignUp;
