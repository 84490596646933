// Lost access to account page

import React, { useState } from "react";
import { Input } from "../components/ui/Input";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../components/MainStyles";
import logo from "../nurtur_new_logo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup
  .string()
  .lowercase()
  .trim()
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email address")
  .required('Please enter your email')
});

const Forgot = () => {
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [lastEmailSent, setLastEmailSent] = useState('');

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/send-password-reset-email', {
        email: values.email
      });
      
      if (response.status === 200) {
        setMessage('Password reset email sent. Please check your inbox.');
        setEmailSent(true);
        setLastEmailSent(values.email);
      } else {
        setMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
    setSubmitting(false);
  };

  const handleResendEmail = async () => {
    setMessage('');
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + '/send-password-reset-email', {
        email: lastEmailSent
      });
      
      if (response.status === 200) {
        setMessage('Password reset email resent. Please check your inbox.');
      } else {
        setMessage(`Error: ${response.data.message}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <FormContainer>
        <img
          src={logo}
          alt="nurtur logo"
          className="logo"
          style={{ borderRadius: "10px" }}
        />
        <LoginForm>
          <MainTitle>Forgot Password</MainTitle>

          {!emailSent ? (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <Field type="email" name="email" placeholder="Enter your email" style={{
                      minWidth: 200,
                      maxWidth: 400,
                      width: "100%",
                      height: 56,
                      borderRadius: 4,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#7B7FFF",
                      padding: 10,
                      margin: 10,
                      marginTop: "1rem",
                      backgroundColor: "#ffffff4d",
                      outlineColor: "#7B7FFF",
                    }}/>
                    <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                  </div>
                  <SubmitButton theme="blue" type="submit" disabled={isSubmitting}>
                    Reset Password
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '20px', color: '#7B7FFF' }}>
                Password reset email sent to {lastEmailSent}.
              </p>
              <p style={{ marginBottom: 5, color: '#333' }}>
                Didn't receive the email?
              </p>
              <SubmitButton theme="blue" onClick={handleResendEmail}>
                Resend Email
              </SubmitButton>
            </div>
          )}

          {message.includes('Error') && <p style={{ marginTop: 5, color: 'red' }}>{message}</p>}
          <Link to="/" style={{
            display: 'block',
            marginTop: 5,
            color: '#7B7FFF',
            textDecoration: 'none',
            textAlign: 'center'
          }}>
            Back to Login
          </Link>
          <SmallText style={{
            textDecoration: "underline",
          }}><Link to="/terms">Terms of Use | Privacy Policy</Link></SmallText>
        </LoginForm>
      </FormContainer>
    </div> 
    </>
  );
};

export default Forgot;
