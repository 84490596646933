export default {
    formField: {
        minWidth: 200,
        maxWidth: 400,
        width: "100%",
        height: 56,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#7B7FFF",
        padding: 10,
        margin: 10,
        marginTop: "1rem",
        backgroundColor: "#ffffff4d",
        outlineColor: "#7B7FFF",
    }
}