// Data for Sidebar component
// TODO: replace the placeholders with links to recent chats
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faAngleDown,
  faUser,
  faAngleUp,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

export const SidebarData = [
  {
    title: "New Chat",
    path: "/chat",
    icon: <FontAwesomeIcon icon={faPlusSquare} />,
  },
  {
    title: "Recent chats",
    path: "/chat",
    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
    iconClosed: <FontAwesomeIcon icon={faAngleDown} />,
    iconOpened: <FontAwesomeIcon icon={faAngleUp} />,

    subNav: [
      {
        title: "Service 1",
        path: "/services/services1",
        cName: "sub-nav",
      },
      {
        title: "Service 2",
        path: "/services/services2",
        cName: "sub-nav",
      },
      {
        title: "Service 3",
        path: "/services/services3",
      },
    ],
  },
  {
    title: "Account",
    path: "/account",
    icon: <FontAwesomeIcon icon={faUser} />,
  },
  {
    title: "Logout",
    path: "/",
  },
];
