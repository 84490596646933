// Code to render the site and set up the routes
	// There are three types of access: not logged in, user, and provider

import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import Login from "./pages/Login";
import VerifCode from "./pages/VerifCode.js";
import SignUp from "./pages/SignUp";
import PageNotFound from "./pages/404.js";
import Account from "./pages/user/Account.js";
import Chat from "./pages/user/Chat.js";
import Chatbot from "./pages/user/Chatbot.js";
import Forgot from "./pages/Forgot.js";
import Dashboard from "./pages/provider/Dashboard.js";
import Survey from "./pages/user/Survey.js";
import Epds from "./pages/user/Epds.js";
import Terms from "./pages/tos.js";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import GuestChatBot from "./pages/user/Guest_Session.js";
import DefaultLayout from "./pages/layouts/DefaultLayout";
import ProtectedRoutes from "./ProtectedRoutes.js";
import EmailVerification from "./pages/EmailVerification.js";
import ResetPassword from "./pages/ResetPassword.js";

// TODO: implement "logged in as user" and "logged in as provider" routes

function App() {
	return (
			<Router>
				<Routes>
					<Route exact path="/" element={<DefaultLayout />} >
						<Route index element={<Login />} />
						<Route path="/verify" element={<VerifCode />} />
						<Route path="/forgot" element={<Forgot />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route path="/signup" element={<SignUp />} />
						<Route element={<ProtectedRoutes />}>
							<Route path="/survey" element={<Survey />} />
							<Route path="/account"element={<Account />} />
							<Route path="/chat" element={<Chatbot />} />
							<Route path="/epds" element={<Epds />} />
						</Route>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="*" element={<PageNotFound />} />
						{/* <Route path="/pageNotFound" element={<PageNotFound />} /> */}
						<Route path="/GuestLogin" element={<GuestChatBot />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/privacy" element={<PrivacyPolicy />} />
						<Route path="/account-verification" element={<EmailVerification />} />
					</Route>
				</Routes>
			</Router>
	);
}

export default App;
