import React from 'react';
import { Outlet } from "react-router-dom"
import AccountMenu from "../../AccountMenu";
import Footer from '../../../components/Footer';

const DefaultLayout = () => {

    return (
        <>
            <Outlet />
            <AccountMenu />
            <Footer />
        </>
    )
}

export default DefaultLayout;