// Main styles for the site components
import styled from "styled-components";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { useField } from "formik";
import backgroundImage from "../components/loginbackground.png";

export const FormContainer = styled.div`
  align-items: center;
  &::before {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(#6464ff50, #6464ff50),
      url(${backgroundImage});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    max-width: 100%;
  }
`;

export const OthersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LoginForm = styled.div`
  min-width: 300px;
  width: 30vw;
  padding: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #ffffffb3;

  @media (max-width: 1000px) {
    margin: auto;
  }
`;

export const MainTitle = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
`;

export const SmallText = styled.h1`
  color: #707070;
  text-align: center;
`;

export const FormikInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Input {...props} label={label} value={field.value} onChange={field.onChange} htmlFor={props.id || props.name} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 10px 5px;
  padding: 10px 0;
  background-color: #aaacff;
  border: 0;
  border-radius: 36px;
  color: black;
  font-weight: bold;
`;
