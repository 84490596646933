const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h1 color="707070">Nurtur Privacy Policy</h1>
            <p><strong>Effective Date: Jun 1, 2024</strong></p>
            <p>This Privacy Policy (“Privacy Policy”) applies to all services, websites, pages, features, content, research, optional study participations, software (including mobile applications or services), collection, testing, and analysis of biological data (collectively, the “Service”) provided by nurtur (“nurtur,” “we,” or “us”). Our Privacy Policy is designed to help you (“you”, “customer”, or “user”) better understand how we collect, use, store, process, and transfer your information when using our Services.</p>

            <p>This Privacy Policy is incorporated by reference into the nurtur terms of service (“Terms” or “Terms of Service”), which can be found on our website. Please carefully review this Privacy Policy and the Terms of Service. By using our Services, you agree, accept, and consent to all of the policies and procedures described in these documents. If you do not agree with or you are not comfortable with any aspect of this Privacy Policy or the Terms of Service, you should immediately discontinue use of our Services.</p>

            <h2>Introduction</h2>
            <p>nurtur is an AI-driven, personalized information application that empowers users with data and technology based on the analysis of demographic data and history. Our Services include access to the nurtur website, including the analysis data from filling out our intake form.</p>
            <p>The data you provide us will be subjected to a process of analysis by our analytics and Artificial Intelligence driven systems to provide a personal interactive experience on our platform.</p>
            <p>nurtur takes privacy very seriously. We are committed to protecting the privacy and security of “Personal Information” which could be used to identify our patients, either alone or in combination with other information. By accessing or using the Service (as defined above), customers allow us to collect, store, and use their Personal Information which enables us to provide more accurate and personalized recommendations. nurtur recognizes and understands the importance of privacy and respects our customers’ desire to store and access Personal Information in a private and secure manner.</p>

            <p><strong>THIS PRIVACY POLICY DESCRIBES HOW INFORMATION ABOUT YOU MAY BE COLLECTED, USED, STORED, MAINTAINED, DISCLOSED, PROTECTED, AND ABLE TO ACCESS THIS INFORMATION. THIS PRIVACY POLICY FURTHER DESCRIBES OUR POLICIES ON OUR PATIENTS’ PERSONAL INFORMATION, PERSONAL DATA, PERSONALLY IDENTIFIABLE INFORMATION, SELF-REPORTED INFORMATION, PROTECTED HEALTH INFORMATION, AND SENSITIVE INFORMATION. PLEASE REVIEW IT CAREFULLY.</strong></p>

            <h2>What Information We Collect</h2>
            <p>When you create an account on or otherwise utilize our Service, nurtur collects and uses several types of information as identified below. These include information you provide directly to us, information you provide in response to our questionnaires or other forms, self-reported information, data we retain in order to improve our data analytics methods and artificial intelligence engine and our Service, information we collected through tracking technology, web analytics, and other types of information we receive about you from third party sources.</p>

            <p><strong>“Personally Identifiable Information” or “PII”</strong> is information about an individual when used alone or with other relevant data that can identify an individual (e.g., first and last name, birthdate, home address, social security number, bank account number, passport number, Health insurance ID number, etc.).</p>

            <p><strong>“Personal Data” or “Personal Information”</strong> is any information relating to a natural person, when that person can be identified, directly or indirectly, in particular by reference to an identification number (e.g. social security number) or one or more factors specific to his physical, physiological, mental, economic, cultural or social identity (e.g. last name and first name, date of birth, biometrics data, fingerprints, or DNA).</p>

            <p><strong>“Self-Reported Information”</strong> is all information you provide us about yourself, including input and answers to surveys, forms, questionnaires, email, features on our website and software applications, engaging with our customer service (“Customer Service”) or while using the Service.</p>

            <p><strong>“Protected Health Information” or “PHI”</strong> is any information about health status, provision of health care, or payment for health care that can be linked to a specific individual that also includes such information as any part of a medical record or payment history.</p>

            <p><strong>“Registration Information”</strong> is collected when you subscribe to or register for our Service. This information may include, but is not limited to Personal Information such as your name, date of birth, password, payment information (such as credit card information of which, nurtur stores only the 4 last digits and the expiration date), billing and shipping addresses, and contact information such as email address and telephone number that you provided to create your nurtur account (“nurtur Account” or “Account”) used for the Service. nurtur uses Registration Information to authenticate your access to nurtur Account, websites and mobile applications for purposes that include but not limited to the following: use the Service, enable you to purchase or access add-ons and new features related to the Service, deliver personalized reports, send Research Studies or Study Participation Consent Forms and questionnaires, marketing and communications, and certain other purposes.</p>

            <p><strong>“De-identified Information” or “De-identified Data”</strong> as used here is data after removing personal identifiers from it. Your Wearable Device Data is used with your Personal Information only to the extent necessary and for the purpose of delivering the Service to you and communicating directly with you when necessary. We use De-identified Information for research, development, debugging, analysis, and improving our artificial intelligence.</p>

            <p><strong>“Aggregate Information” or “Aggregate Data”</strong> means high-level information or data collected from a group of individual users or other data combined with similar data of the others and compiled into data collections or summaries such that when evaluated as a whole, that no specific individual may be reasonably identified. Aggregate Data is used for data analysis and research.</p>

            <h2>Use of Cookies</h2>
            <p>nurtur and its third-party service providers from whom it receives your information may use “cookies” and similar tracking technologies (such as web beacons, tags, scripts and device identifiers used for automatic collection of information), for a variety of purposes. Cookies are small data files that are stored on a user’s hard drive at the request of a website to enable the website to recognize and retain certain user information such as customer preferences and history.</p>

            <p>Cookies help us recognize when and how you use our Services, customize and improve your experience, provide security, analyze our interactions with our Services and its features, and gather demographic information about our user base.</p>

            <p>When nurtur receives reports from third parties on how certain functionalities of our website works, usage and statistical information such as the user’s browser type, operating system, device ID (only for IOS users), these third parties may collect personal information from you in connection with the services they provide and may place cookies, web beacons or other devices on your device to collect non-personal information which may be used, among other things, to deliver advertising targeted to your interests and to better understand the usage of the Service and the other services tracked by these third parties. nurtur is not responsible for, and does not control, any actions or policies of any third-party service providers.</p>

            <p>The information reports we receive from third-party service providers can be in de-identified, individual-level, or at aggregate-level, and we may also use these reports to improve our data analytics methods. If we combine cookies with, or link them to, any of the Personal Information, nurtur will treat this information as Personal Information.</p>

            <p>If you wish to block, erase, or be warned of cookies, please refer to your browser instructions or help screen to learn about these functions. However, if you reject cookies or your browser or device settings do not accept cookies, you may not be able to use certain parts of our website or sign-in to your nurtur account and may not be able to access certain Service features.</p>

            <h2>Google Analytics</h2>
            <p>Like many websites, we use Google Analytics for web behavior monitoring, a service that provides information about how many users visit our website and online resources, when they visit, and how they navigate our website. We also may use other Google Analytics tools, such as Demographics and Interest Reporting, which enable us to learn more about the characteristics and interests of the users who visit our website.</p>

            <p>To learn more about Google’s privacy practices, please go to Google Privacy Policy at <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>. You can also download the Google Analytics Opt-out Browser Add-on to prevent your data from being used by Google Analytics at <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>

            <h2>Other Types of Information</h2>
            <p>From time to time, we may collect other types of information automatically about your use of our Service through the use of log files. Such information may include your device’s Internet Protocol (IP) address, operating system, browser type, and your device ID. nurtur uses this information for purposes such as analyzing trends, administering the Service, improving customer service, diagnosing problems with our servers, monitoring the security of our systems, tracking user movement, and gathering broad demographic information for aggregate use.</p>

            <p>All other information we receive directly or indirectly through the above methods, from you, our website, social media, referral, or a third party associated with you will be used in manners consistent with this Privacy Policy.</p>

            <h2>How We Use Your Information</h2>
            <p>nurtur will use and share your information (including Personal Information) in the ways that are described below and elsewhere in the Privacy Policy.</p>

            <h3>a) Provide you with the Services</h3>
            <p>We use your information in accordance with this Privacy Policy for activities necessary for provisioning the Service that include testing and analysis of data, generating and delivering assessments and recommendations based on your data, and improving our Services. These activities may include but not limited to: i) open and maintain your nurtur Account; ii) enable purchase of our Service (e.g., process payments); iii) communicate with you (e.g., informing you of policy changes, security updates or issues, etc.); iv) implement your requests (e.g. requests to customer service); v) facilitate use of our website (including authenticating your visits, providing personalized content, and tracking your use of our Services); vi) enforce our Terms and other agreements such as monitor, detect, investigate and prevent prohibited or illegal activities, spam and other security risks, performing quality control; vii) perform research & development activities; viii) conducting data analysis to improve existing Services or develop new Services; and ix) improving our data analytics and artificial intelligence engine that help us provide more precise and accurately personalized recommendations to you.</p>

            <p>We may also use your information to fix bugs or issues, analyze use of our website, to improve or optimize the customer experience and Customer Service, or assess the efficacy of our marketing campaigns.</p>

            <p>For individuals located in Europe, we process your Personal Data in the same way in accordance with this Privacy Policy and our Terms of Service.</p>

            <h3>b) For Research</h3>
            <p>When you use our Services, nurtur may use your Personal Information, and other data at individual-level or otherwise (in de-identified, pseudonymized, or aggregate forms) for ongoing research that helps us better understand the connections between biometric monitoring and your health and wellness at individual as well as at population scale (“Research”). The information we use in Research is often summarized, aggregated, or combined across a group of subjects to minimize the chance of identification. In the event we require use of individual-level Personally Identifiable Information in Research or for other purposes, we will reach out to you for obtaining specific consent applicable to such other use.</p>

            <p>Research is an important aspect of our Service. The primary aim of nurtur is to improve our Service using the data we gather from our Research. We invite you to take part in the Research that may help improve our Services and may also help generate breakthrough discoveries. We strongly believe that the insights we gain through Research may benefit the general population as a whole, and indirectly you or your family as well sometime in the future.</p>

            <p>Unless required by law or a court order, nurtur will not release your individual-level Personal Information to any third party not identified in this Privacy Policy without first receiving your explicit consent by way of acceptance of a consent form.</p>

            <p>For individuals located in the European Union (“EU”): Our legal basis for processing your Sensitive Data for the purposes described above is based on your consent. Please read below on Data Privacy for EU Residents Under GDPR for further details.</p>

            <h3>c) Improve Service, Analysis Methods, and Artificial Intelligence Engine</h3>
            <p>We are constantly working on improving our Service and enhancing the capacity and accuracy of our data analysis methods and artificial intelligence engine we use for the purpose of delivering more accurate and personalized recommendations to you.</p>

            <p>We may use your Wearable Device Data and Self-Reported Information in de-identified, pseudonymized, anonymized, or aggregate forms (after carefully removing the identifiers that easily identify who you are), together with similar data of others, for the purpose of improving nurtur’s data analysis methods. Our artificial intelligence engine runs multiple analyses of aggregated de-identified data across our massive database of information to provide assessments of your data. It is through our artificial intelligence engine that we are able to provide better and more accurate personalized recommendations to you.</p>

            <h3>d) Provide Customer Service and Support</h3>
            <p>When you contact nurtur customer service (“Customer Service”), we may use or request additional Personal Information, as necessary to verify your identity, answer your questions, resolve disputes, and/or investigate and troubleshoot problems or complaints. In certain instances, we may require using one customer’s Personal Information to resolve another customer’s request. For example, if a customer reports behavior of another customer that violates our Terms of Service, we will separately process both customers’ Personal Information and respond separately to each customer as appropriate. We will not share your Personal Information with another customer or any third party without your specific consent.</p>

            <h2>How We Disclose Your Information</h2>
            <p>In general, nurtur will not disclose individual-level Personal Information (including Self-Reported Information) to third parties, except under the following circumstances:</p>

            <h3>a) With Express Written Permission</h3>
            <p>nurtur may disclose your Personal Information to third parties in accordance with our Terms of Service or where you have otherwise provided express written consent for sharing.</p>

            <h3>b) Facilitate Business Operations</h3>
            <p>nurtur may disclose individual-level Personal Information as stated in the Terms and this Privacy Policy to partners, vendors, or service providers (e.g., credit card processors or customer service accelerators) that process and/or store such information in order to help Nurtur provide, or improve the Service or any part of it. In these instances, the protection of your individual-level Personal Information will be subject to the service agreements or privacy policies of the specific nurtur partner or service provider. In addition, we also employ strong terms on confidentiality of information (including our customer data) shared in our agreements with the service providers.</p>

            <h3>c) As Required by Law</h3>
            <p>Under certain circumstances, Personal Information may be subject to disclosures pursuant to judicial or other government subpoenas, warrants, or orders, or in coordination with regulatory authorities. You acknowledge and agree that nurtur is free to preserve and disclose any and all Personal Information to law enforcement agencies or others if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (i) comply with legal or regulatory process (such as a judicial proceeding, court order, or government inquiry); (ii) obligations that nurtur may owe pursuant to ethical and other professional rules, laws, and regulations; (iii) enforce Terms of Service; (iv) respond to claims that any content violates the rights of third parties; or (v) protect the rights, property, or personal safety of nurtur, its employees, its customers (including you), and the public. In the event nurtur is required by law to disclose Personal Information, nurtur will notify you through the contact information provided to nurtur in advance, unless doing so would violate the law or a court order.</p>

            <h3>d) Sharing with Third Parties</h3>
            <p>We may share your individual-level Personal Information, without explicit consent, to the extent necessary, with third parties: i) in order to perform business operations that help deliver the Services to you (e.g. customer service optimizers).</p>

            <p>We may also share Personal Information in de-identified, pseudonymized, or aggregate forms (without your personal details or aggregated with the information of others so that you cannot reasonably be identified as an individual) for: i) Research; and ii) for other purposes, to the extent necessary, and as permitted by law.</p>

            <p>Unless required by law or a court order, or as specifically stated in the Terms and this Privacy Policy, nurtur will not release your individual-level or individually identifiable Personal Information to any third party without first receiving your consent. Specifically, nurtur does not share your individually identifiable sensitive Personal Information with third parties, in the following ways, without your explicit consent: i) sell, lease, or rent them; ii) release to any public databases; or iii) to insurance companies, healthcare providers, educational institutions, government agencies, or employers.</p>

            <h3>e) Business Transitions</h3>
            <p>In the event nurtur goes through a business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets, your Personal Information will likely be among the assets transferred. In such a case, Personal Information would remain subject to the terms of the pre-existing or the current Privacy Policy.</p>

            <h2>Information Security Measures</h2>
            <p>nurtur uses a number of physical, technical, and administrative measures to keep your Personal Information safe and secure. By employing these safeguards, we aim to prevent unauthorized access, minimize accidental disclosure, maintain data accuracy and integrity, and ensure appropriate use of the information in accordance with current technological and industry standards. In particular, all connections to nurtur websites, software, and mobile applications are encrypted using Secure Socket Layer (“SSL”) technology.</p>

            <p>You acknowledge and agree that protecting Personal Information is a responsibility shared between you and nurtur. In this regard, we ask all users of our Service to be responsible for keeping their login IDs, passwords, and other authentication information used to access the Service in a secure manner and maintain strict confidentiality. You should not share Account and authentication information with any third parties and should inform nurtur immediately of any prohibited use of your Account or authentication information. nurtur cannot secure and assumes no liability for Personal Information that is released by our customers to third parties, such as physicians, insurance companies, or healthcare service providers.</p>

            <p>nurtur implements several physical and technical security measures to ensure confidentiality, integrity, security, and availability of nurtur and customer data by employing industry-standard safeguards such as de-identification, pseudonymization, encryption, and data segmentation.</p>

            <p>To ensure the ongoing confidentiality, integrity, and security of your data, nurtur conducts periodic risk assessments of its electronically protected health information systems (“ePHI”) which we use to store your Personal Data. We de-identify customers’ PII from PHI and use multiple layers of industry-standard security measures applicable to encryption and access protection for Sensitive Data, based on job function and role. nurtur access controls include multi-factor authentication, single sign-on, and strict least-privileged authorization policies.</p>

            <p>nurtur keeps all customer Personal Data and information on secure cloud servers. Only a small group of qualified personnel within nurtur can access the information that can be used to identify you. These are personnel who need that information in order to provide, complete, testing, analysis, and reporting related to the Services. The Personal Information that matches the assigned codes will be kept in a secure, access controlled, and protected database at nurtur. Only a small group of essential personnel will have access to this secure and protected database.</p>

            <p>We will not include any Personal Information that would make it possible to identify you in any Research, studies or publications. All nurtur employees, consultants, and others who might have access to your Personal Information must sign confidentiality and non-disclosure agreements that mandate them to keep customer Personal Information confidential. Your Personal Information may be shared with your health care service provider only with your written permission.</p>

            <h2>Children’s Privacy</h2>
            <p>nurtur is committed to protecting the privacy of children and abiding by the provisions of the Children’s Online Privacy Protection Act (“COPPA”). The Service is not directed, designed, or intended to attract children under the age of 13.</p>

            <p>In the event nurtur is notified or becomes aware that the Service has been used by a child under the age of 13 to store information of that child without parental consent, nurtur shall be and is authorized to delete, in its entirety, with no notice to you, any of the information stored by that child or by you on that child’s behalf. nurtur also reserves the right to revoke any license to use the Service, which is being used or has been used by a child under the age of 13.</p>

            <p>Further, nurtur Services are not designed for, intended to attract, or directed toward children under the age of 18 or below the legal age of majority to form a binding contract in your country of residence, whichever is greater.</p>

            <h2>Correction of Personal Information</h2>
            <p>Your Personal Information and Registration Information, if incorrect, can be corrected, changed, or updated by using the means below, or sending a request to our Customer Service using the information stated below:</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and edit your personal information.</p>

            <p><strong>Submit by mail:</strong></p>
            <p>nurtur Customer Service (Correction of Personal Information)</p>
            <p>nurtur health Inc</p>
            <p>Derry, NH, USA</p>

            <h2>Account Closure</h2>
            <p>If you no longer wish to use the Service, or have your Personal Information processed by us in order to provide you the Service, you may close your Account and have your Account information deleted by sending our Customer Service a request using the information specified below.</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and request account closure by emailing <a href="mailto:support@nurturcare.com">support@nurturcare.com</a></p>

            <p>Once we receive your request, we will send an email to the email address linked to your Account detailing our Account Closure Policy and requesting that you confirm your closure request. Once you confirm your request to close your Account and delete your Account information, your Account will no longer be accessible. When your request is processed, it cannot be canceled, undone, withdrawn, or reversed. When closing an Account, nurtur removes or deletes Personal Information associated with that Account, subject to certain limitations stated below:</p>

            <p>To the extent necessary and permitted by law, nurtur may still retain:</p>
            <ul>
                <li>Limited Registration Information on order history (e.g., name, contact details, closure request, and transaction data) for accounting, audit, and compliance purposes;</li>
                <li>Limited Personal Information for compliance with legal retention requirements;</li>
                <li>Limited Personal Information to fulfill contractual obligations, exercise or defend legal claims;</li>
                <li>Limited Personal Information to fulfill audit and compliance processes; and</li>
                <li>Limited information in de-identified, pseudonymized, or aggregate forms used in research, data analysis and artificial intelligence.</li>
            </ul>

            <h2>Retention of Personal Information</h2>
            <p>Unless you close your Account and delete your Personal Information in the Account as described under Account Closure as specified above, nurtur will store your Personal Information as long as your Account is open.</p>

            <h2>California Residents</h2>
            <p>Pursuant to the California Consumer Privacy Act of 2018 (“CCPA”), California residents are afforded certain additional rights regarding use of your Personal Information. However, depending on your data choices, certain services may be limited or unavailable. If you are a California Resident, to learn more about your California Residents’ privacy rights under CCPA and to obtain a copy of our CCPA Notice for California Residents, please contact our Customer Service by sending a request using the information specified below.</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and request Assistance with CCPA Notice Rights.</p>

            <p><strong>Submit by mail:</strong></p>
            <p>nurtur Customer Service (CCPA Notice Rights)</p>
            <p>nurtur Health Inc</p>
            <p>NH, USA</p>
            <p>Or</p>

            <p><strong>Email customer support at <a href="mailto:support@nurturcare.com">support@nurturcare.com</a></strong></p>

            <h2>California Do-Not-Track Disclosures</h2>
            <p>nurtur does not track its customers over time and across third party websites to provide targeted advertising and therefore does not respond to Do Not Track (“DNT”) signals. Third parties that have content embedded on nurtur’s websites or mobile applications (e.g. social features) may set cookies on a user’s browser and obtain information about the web browser visiting a specific nurtur website from a certain IP address. Third parties cannot collect any other Personal Information from nurtur’s websites, software, or mobile applications unless you provide it to them directly.</p>

            <h2>Data Privacy for EU Residents Under GDPR</h2>
            <p>We describe how we process your Personal Data in this Privacy Policy. This Section outlines our policies and commitment to General Data Protection Regulation (“GDPR”) applicable ONLY to information from and of the residents of the European Union (“EU”). Except where a term is specifically defined herein, the terms used in this Section will have the meaning provided under in the GDPR. In exercising rights under GDPR, applicable only to EU residents, as a precondition for processing your requests, we require proof of your identity and EU resident status both.</p>

            <p><strong>Note: GDPR and Data Privacy policies outlined below do not extend or apply to residents of non-EU countries or residents of the United States.</strong></p>

            <h3>a) When nurtur acts as Controller</h3>
            <p>nurtur acts as a Controller when it determines the purposes and means of processing Personal Data. Where we process your Personal Data in our capacity as a Controller, this Privacy Policy will govern such processing of your Personal Data.</p>

            <h3>b) When nurtur acts as a Processor</h3>
            <p>nurtur acts as a Processor where it processes Personal Data for another Controller. Where we process your Personal Data in our capacity as a Processor, on behalf of a third-party Controller, this Privacy Policy will not govern the processing of your Personal Data. In such events, we encourage you to contact the Controller directly to learn about their privacy policies applicable to processing of your Personal Data and exercise your rights directly with the Controller, or we will forward your request directly to such Controller upon receipt of a request from you.</p>

            <h3>c) Right to Withdraw Consent</h3>
            <p>To the extent nurtur has requested and you provided your consent for processing of your Personal Data, or accepted a Consent Form, you can withdraw your consent at any time by contacting our Customer Service using the information specified below. Your withdrawal will not affect the lawfulness of our processing based on consent you granted before its withdrawal as well as use of your data in ways otherwise permitted by law.</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and request Consent Withdrawal.</p>

            <p><strong>Submit by mail:</strong></p>
            <p>nurtur Customer Service (Correction of Personal Information)</p>
            <p>nurtur Health Inc</p>
            <p>NH, USA</p>

            <h3>d) Right of Access and Rectification</h3>
            <p>nurtur allows you to access and rectify certain Registration Information, Self-Reported Information, and other information as required by applicable law. If you would like to access or rectify your Personal Data, please contact our Customer Service using the details specified below. We may not be able to fulfill part or all of your request if doing so could adversely affect the rights and freedoms of others.</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and edit or request your personal information.</p>

            <p><strong>Submit by mail:</strong></p>
            <p>nurtur Customer Service (Correction of Personal Information)</p>
            <p>nurtur Health Inc</p>
            <p>NH, USA</p>

            <h3>e) Right to Erasure (“Right to be Forgotten”)</h3>
            <p>We allow you to delete your Account or your Personal Information following a request for Account Closure at any time. You can request erasure of Personal Data that: (a) is no longer necessary in relation to the purpose for which it was collected or used; (b) was collected with your consent but later you withdrew such consent; or (c) was collected for a purpose with your consent, but there are no overriding legitimate grounds for our further processing.</p>

            <p>nurtur will take reasonable steps to fulfill your request. Our assistance with your request, however, is subject to the following limitations:</p>

            <ul>
                <li>When processing your Personal Data is necessary to comply with a legal obligation, establish, exercise, or defend legal claims;</li>
                <li>In relevant data protection laws that restrict this right for certain types of data;</li>
                <li>When there are limitations in the available technology; and</li>
                <li>When we are limited by the cost of implementing</li>
            </ul>

            <p>Subject to the above terms and conditions, nurtur will fulfill your request within thirty (30) days from receipt of a request from an EU customer, subject to verification of requester’s identity and other details such as the EU resident status, before erase of the Personal Data concerning such customer. Notwithstanding the above, nurtur shall be permitted to retain, to the extent permitted by law, any and all Personal Data that is in de-identified, anonymized, pseudonymized, and aggregated forms in accordance with the terms of this Privacy Policy.</p>

            <p>Please contact nurtur using <a href="mailto:team@nurturcare.com">team@nurturcare.com</a> email address to exercise your right to erase your Personal Data pursuant to GDPR using the subject line: GDPR: Right to Erasure</p>

            <h3>f) Right to Restrict Processing</h3>
            <p>Under GDPR, you have the right to restrict our processing of your Personal Data under the following circumstances: (a) you dispute the accuracy of Personal Data; (b) the processing is unlawful and you do not wish the erasure and request the restriction instead; (c) we no longer need the Personal Data for the purposes; and (d) when our legitimate grounds for processing override your rights.</p>

            <p>Following a request for restriction, however, we can continue to use your restricted Personal Data, when: a) we have your consent; b) to establish, exercise or defend legal claims; c) to protect the rights of another natural or legal person; or d) for reasons of important public interest.</p>

            <p>Please contact nurtur by using <a href="mailto:team@nurturcare.com">team@nurturcare.com</a> email address using the subject line: GDPR: Right to Restrict Processing to exercise your rights to restrict processing your Personal Data pursuant to GDPR.</p>

            <h3>g) Right to Data Portability</h3>
            <p>To the extent that we process your Personal Data as Controller (i) with your consent or under a contract; and (ii) through automated means, you may request your Personal Data in a structured, commonly used, machine-readable format. You may also request the transfer of your Personal Data directly to another data Controller, where it is technically feasible, unless choosing to exercise this right adversely affects the rights and freedoms of others.</p>

            <p>There may be an additional fee associated with processing your request.</p>

            <p>Please contact nurtur by using <a href="mailto:team@nurturcare.com">team@nurturcare.com</a> email address with the subject line: GDPR: Right to Data Portability to exercise your rights to portability of your Personal Data pursuant to GDPR.</p>

            <h3>h) Account Closure</h3>
            <p>To close your Account, please contact our Customer Service using the information specified below. If you closed your Account, we will take the same steps described under Account Closure above.</p>

            <p><strong>Submit online:</strong></p>
            <p>Login to your Account at <a href="https://app.nurturcare.com/">https://app.nurturcare.com/</a> and request account closure.</p>

            <h3>i) Personal Data retention</h3>
            <p>Unless you close or delete your nurtur Account, we retain and store your Personal Data as long as your Account is open, for other important purposes such as providing you with our Service, complying with legal obligations, resolving disputes, enforcing our agreements, and for other purposes specified in this Privacy Policy and permitted by applicable law.</p>

            <h3>j) Third parties with access to your Personal Data</h3>
            <p>nurtur may share your Personal Data, with third parties as necessary for them to provide their services to us and help us deliver our Services to you. Service providers are third parties (other companies or individuals) that help us provide, analyze and improve our Services. While nurtur directly conducts the majority of the data processing activities required to provide our Services to you, we may engage some third-party service providers to assist us in supporting our Services, including in the following areas and ways:</p>

            <ul>
                <li>Customer Service and related service providers: to process orders, respond to customer service requests, phone services, and for customer relationship management.</li>
                <li>Website usage analytics services: to determine who is using nurtur’s Services and how to improve those Services</li>
                <li>Payment processors: to process customer payments using billing information</li>
                <li>Software developers: to develop and test nurtur’s software and mobile applications</li>
                <li>Database service providers: to securely store Wearable Device Data and other Personal Information</li>
                <li>Business process outsourcers</li>
                <li>Other service providers that support our Services</li>
            </ul>

            <p>Like many online services, to the extent necessary and permitted by applicable law, nurtur may share de-identified, anonymous, or pseudonymized Personal Data that does not personally identify you or on aggregate basis with our business partners, service providers, and advertisers in accordance with our Terms and this Privacy Policy. This may also include non-individually identifiable data contained in databases, server log files such as your IP address and cookies, generalized traffic flow data, data used for Research, and aggregated statistical data that are not personally identifiable, including users’ demographic information.</p>

            <h3>k) How to exercise your rights on Personal Data</h3>
            <p>If you would like to exercise your rights under GDPR on use of your Personal Data as described above, please send us a request using the designated methods specified above. In your message, please indicate the right you would like to exercise, request you make, and the information that you would like to access, review, correct, or erase.</p>

            <p>We may ask you for additional information to confirm your identity and resident status and other information, for security purposes, before disclosing the Personal Data you requested.</p>

            <p>We reserve the right to charge a fee, where permitted by law (e.g., if our cost of processing your request is prohibitive, or your request is manifestly unfounded or excessive).</p>

            <p>We may not always be able to fully address your request, for example if it would impact the duty of confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way.</p>

            <p>We ask that you attempt to resolve any issues regarding your Data Protection or data requests first with us.</p>

            <p>Please contact us at <a href="mailto:team@nurturcare.com">team@nurturcare.com</a> and we will be happy to respond to your request promptly.</p>

            <p>nurtur’s “Privacy By Design” approach requires that our user data protection levels be at the highest setting by default. In the unlikely event of a breach, nurtur will notify the applicable Data Subjects and Supervisory Authorities (“SA”) in the EU according to procedures provided in GDPR Articles 33 and 34.</p>

            <h3>l) Using and sharing your Personal Data</h3>
            <p>We collect, use, and share your Personal Data where we are satisfied that we have an appropriate legal basis for doing so. This may include i) when our use of your Personal Data is necessary to perform a contract or take steps to enter into a contract with you; ii) in our legitimate interest as a commercial organization (for example in order to make improvements to our Services, perform Research for improving our Service, and to provide you with the Data you request); iii) necessary to comply with a relevant legal or regulatory obligation that we have (for e.g., where we are required to disclose Personal Data to a court); or iv) in accordance with your consent (e.g., acceptance of a Consent Form).</p>

            <p>If you would like to find out more about the legal bases on which we process your Personal Data, please contact us using the details specified above.</p>

            <h3>m) Exporting Personal Data from the EU</h3>
            <p>nurtur may transfer your Personal Data outside of the country from which it was originally provided. This transfer may be intra-group or to third parties that we work with who may be located in jurisdictions outside of the EU that have no data protection laws or have laws that are less strictive compared with those governing the EU. Whenever we transfer Personal Data outside of the EU, we take legally required steps to make sure the appropriate safeguards are in place to protect your Personal Data as further set forth below. Please contact us for more information about the safeguards we have put in place to protect your Personal Data and privacy rights in these circumstances.</p>

            <h2>Contact Us</h2>
            <p>If you have questions about this Privacy Policy, please contact us by email <a href="mailto:team@nurturcare.com">team@nurturcare.com</a> or by writing to us at:</p>

            <p>nurtur Health Inc</p>
            <p>Attn: Chief Executive Officer</p>
            <p>NH, USA</p>
        </div>
    );
};

export default PrivacyPolicy;
