import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Button, TextField } from '@mui/material';
import { SubmitButton } from '../components/MainStyles';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [resendStatus, setResendStatus] = useState('');
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const { email } = useLocation().state;

  const handleVerification = async () => {
    const code = verificationCode.join('');
    if (code.length !== 6) {
      setVerificationStatus('error');
      setResendStatus('Please enter 6-digit verification code.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-email`, {
        email,
        verification_code: code,
      });

      if (response.status === 200) {
        setVerificationStatus('success');
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/epds/create`, { email });
          } catch (epdsError) {
            setResendStatus(`Account verified, but there was an issue setting up EPDS. Please contact support. ${epdsError.message}`);
            return;
          }
    
          try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/chat/create`, { email });
          } catch (chatError) {
            setResendStatus(`Account verified, but there was an issue setting up chat. Please contact support. ${chatError.message}`);
            return;
          }
      } else {
        setVerificationStatus('error');
        setResendStatus('Verification failed. Please check your code and try again.');
      }
    } catch (error) {
      console.error('Email verification error:', error);
      setVerificationStatus('error');
      setResendStatus('An error occurred. Please try again later.');
    }
  };

  const handleCodeChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Only allow digits
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleResendVerification = async () => {
    setVerificationCode(['', '', '', '', '', '']);
    setVerificationStatus('pending');
    if (!email) {
      setResendStatus('Please enter your email address.');
      return;
    }

    try {
      setResendStatus('Sending...');
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/send-verification-email`, { email });
      if (response.status === 200) {
        setResendStatus('Verification email sent successfully. Please check your inbox.');
      } else {
        setResendStatus('Failed to send verification email. Please try again.');
      }
    } catch (error) {
      console.error('Resend verification error:', error);
      setResendStatus('An error occurred. Please try again later.');
    }
  };

  const renderContent = () => {
    switch (verificationStatus) {
      case 'pending':
        return (
          <>
            <Typography variant="h5" gutterBottom>Email Verification</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please enter the 6-digit verification code sent to your email:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, gap: 1 }}>
              {verificationCode.map((digit, index) => (
                <TextField
                  key={index}
                  inputRef={inputRefs[index]}
                  variant="outlined"
                  type="text"
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center', width: '2rem' }
                  }}
                  sx={{ width: '3rem' }}
                />
              ))}
            </Box>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleVerification}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              Submit
            </Button>
            {resendStatus && (
              <Typography variant="body2" color="textSecondary" paragraph>
                {resendStatus}
              </Typography>
            )}
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: '20px', color: '#333' }}>
                Didn't receive the verification code?
              </p>
              <Button
                variant="text"
                color="primary"
                onClick={handleResendVerification}
                sx={{ mb: 2 }}
            >
                Resend Code
            </Button>
            </div>
          </>
        );
      case 'success':
        return (
          <>
            <Typography variant="h5" gutterBottom>Email Verified Successfully!</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Your email has been verified. You can now log in to your account.
            </Typography>
            <Button component={Link} to="/" variant="contained" color="primary">
              Go to Login
            </Button>
          </>
        );
      case 'error':
        return (
          <>
            <Typography variant="h5" gutterBottom>
              Verification Failed
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We couldn't verify your email. The code may have expired or is invalid.
            </Typography>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={handleResendVerification}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              Resend Verification Code
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4} textAlign="center">
        {renderContent()}
      </Box>
    </Container>
  );
};

export default EmailVerification;
