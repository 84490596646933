import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    messages: []
  },
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addChatData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      }
    },
  },
})

export const { addChatData } = chatSlice.actions

export default chatSlice.reducer;