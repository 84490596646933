// Page where user can manage and update their account information

import React from "react";
import { Input } from "../../components/ui/Input.js";
import {
  LoginForm,
  MainTitle,
  SubmitButton,
} from "../../components/MainStyles";
import SidebarNavless from "../../components/SidebarNavless.js";
import { Formik, Form } from "formik";

// TODO: implement validation for more account update functionality here
const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

const Account = () => {
  return (
    <div style={{ background: "#ededff", position: 'relative', minHeight: '100vh' }}>
      <SidebarNavless />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5vh",
        }}
      >
        <LoginForm>
          <MainTitle>Manage Account</MainTitle>

          <Formik
        initialValues={{ email: '', password: '' }}
        validate={validate}
        onSubmit={values => {
          // TODO: implement edit functionality here
          console.log(values);
        }}>
            <Form>
              <Input id="first name" type="first name" label="First Name" name="firstname" />
              <Input id="last name" type="last name" label="Last Name" name="lastname" />
              <Input id="email" type="email" label="Email" name="email" />
              <Input id="current" type="current" label="Current Password (to change password)" name="old_password" />
              <Input id="password" type="password" label="Password" name="new_password" />
              <Input id="phone" type="phone" label="Phone Number" name="phone" />
              <Input id="age" type="age" label="Age" name="age" />
              <SubmitButton theme="blue" type="submit">Save Changes</SubmitButton>
            </Form>
          </Formik>

        </LoginForm>
      </div>
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
    </div>
    </div>
  );
};

export default Account;
