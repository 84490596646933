import React, { useState, useEffect, useRef } from 'react';


const timeLogs = {};

const getCurrentTime = () => new Date().getTime(); // Returns the current time in milliseconds

const logTime = (questionId) => {
  timeLogs[questionId] = getCurrentTime();
};

const getTimeDifference = (id1, id2) => {
  if (timeLogs[id1] && timeLogs[id2]) {
    return (timeLogs[id2] - timeLogs[id1]) / (1000 * 60 * 60); // Difference in hours
  }
  return 0;
};

const handleNextQuestion = (currentId) => {
  const timeDiff = getTimeDifference(5, 48);
  if (timeDiff <= 24) {
    // Skip questions from 49 to 61 and go to 62
    return 62;
  }
  return chatFlow.en.find((q) => q.id === currentId)?.options[0]?.nextId || null;
};

const GuestChatBot = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [language, setLanguage] = useState('en'); // Default language
  const [userResponses, setUserResponses] = useState({});
  const [userInput, setUserInput] = useState('');
  const [waitingForInput, setWaitingForInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false); // Voice disabled by default
  const [volume, setVolume] = useState(1); // Volume range: 0 to 1
  const [speed, setSpeed] = useState(1); // Speed range: 0.1 to 10
  const [textSize, setTextSize] = useState(16); // Default text size
  const [isTextInputVisible, setIsTextInputVisible] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [showVoiceDropdown, setShowVoiceDropdown] = useState(false);
  const [showSpeedDropdown, setShowSpeedDropdown] = useState(false);
  const [responses, setResponses] = useState({});
  const [isPaused, setIsPaused] = useState(false);
  const chatWindowRef = useRef(null);



  const handleAnswer = (questionId, answer) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: parseInt(answer),
    }));
    let nextId = getNextQuestionId(questionId, answer);
    setCurrentQuestionId(nextId);
  };

  const getNextQuestionId = (questionId, answer) => {
    if (questionId === 5 || questionId === 6) {
      const response5 = responses[5];
      const response6 = responses[6];
      if (response5 !== undefined && response6 !== undefined) {
        const cumulativeValue = response5 + response6;
        return cumulativeValue < 2 ? 7 : 10;
      }
    }

    // Handle questions 10 through 18 redirection
    if (questionId >= 10 && questionId <= 18) {
      const allAnswered = Object.keys(responses).length >= 9; // IDs 10 through 18
      if (allAnswered) {
        const values = Object.values(responses).filter((value) => !isNaN(value));
        const cumulativeValue = values.reduce((sum, value) => sum + value, 0);
        return cumulativeValue < 2 ? 7 : 19;
      }
    }

    // Default nextId if no special condition applies
    return (
      chatFlow.en.find((q) => q.id === questionId)?.options.find((option) => option.text === answer)?.nextId || questionId
    );
  };

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      let filteredVoices = availableVoices;

      if (language === 'en') {
        filteredVoices = availableVoices.filter((voice) => voice.lang === 'en-US' || voice.lang === 'en-GB');
      } else if (language === 'zh-TW') {
        filteredVoices = availableVoices.filter((voice) => voice.lang === 'zh-TW');
      } else if (language === 'es') {
filteredVoices = availableVoices.filter((voice) => voice.lang.startsWith('es') || (voice.name.includes('Paulina') || voice.name.includes('female')));
      }

      setVoices(filteredVoices);
      if (!selectedVoice || !filteredVoices.includes(selectedVoice)) {
        setSelectedVoice(filteredVoices[0]);
      }
    };

    loadVoices();
    speechSynthesis.onvoiceschanged = loadVoices;
  }, [language, selectedVoice]);

  useEffect(() => {
    if (chatHistory.length > 0 && isVoiceEnabled) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      readOutLoud(lastMessage.text);
    }
  }, [chatHistory, isVoiceEnabled]);

  useEffect(() => {
    if (selectedVoice) {
      readOutLoud(chatHistory.map((entry) => entry.text).flat());
    }
  }, [selectedVoice, volume, speed, language]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleUserResponse = (questionId, response) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: response,
    }));
  };

  const readOutLoud = (texts) => {
    if ('speechSynthesis' in window && selectedVoice) {
      const textArray = Array.isArray(texts) ? texts : [texts];
      const fullText = textArray.join(' ');
      const utterance = new SpeechSynthesisUtterance(fullText);
      utterance.voice = selectedVoice;
      utterance.rate = speed; // Use the speed setting
      utterance.pitch = 1.1; // You can also add a state for pitch if needed
      utterance.volume = volume; // Use the volume setting

      if (isPaused) {
        window.speechSynthesis.cancel();
      } else {
        window.speechSynthesis.speak(utterance);
      }
    }
  };

const handleOptionClick = (nextId, optionText) => {
    setCurrentQuestionId(nextId);

    handleUserResponse(currentQuestionId, parseInt(optionText, 10));

    // Determine the next step based on the values
    const value5 = userResponses[5] || 0;
    const value6 = userResponses[6] || 0;
    const cumulativeValue = value5 + (parseInt(optionText, 10) || 0);

    if (currentQuestionId === 5) {
      // Move to question 6 after question 5
      setChatHistory([
        ...chatHistory,
        {
          text: chatFlow[language].find((step) => step.id === 6).text,
          options: chatFlow[language].find((step) => step.id === 6).options,
        },
      ]);
      setCurrentQuestionId(6);
      setWaitingForInput(true);
    } else if (currentQuestionId === 6) {
      // Determine next step based on cumulative value
      const nextStep = cumulativeValue < 2 ? 7 : 10;
      setChatHistory([
        ...chatHistory,
        {
          text: chatFlow[language].find((step) => step.id === nextStep).text,
          options: chatFlow[language].find((step) => step.id === nextStep).options,
        },
      ]);
      setCurrentQuestionId(nextStep);
      setWaitingForInput(nextStep === 6);
      setSelectedOption(optionText);
    } else {
      // For other steps, proceed with the default behavior
      const nextStep = chatFlow[language].find((step) => step.id === nextId);
      setChatHistory([
        ...chatHistory,
        { text: nextStep.text, options: nextStep.options },
      ]);
      setCurrentQuestionId(nextId);
      setWaitingForInput(nextId === 6);
      setSelectedOption(optionText);

      // Show input text window for specific IDs
      if ([74, 76, 78, 80].includes(nextId)) {
        setIsTextInputVisible(true);
        setWaitingForInput(true);
      } else {
        setIsTextInputVisible(false);
        setWaitingForInput(false);
      }
    }
  };

  const startChat = () => {
    const firstStep = chatFlow[language].find((step) => step.id === 1);
    setCurrentQuestionId(1);
    setChatHistory([{ text: firstStep.text, options: firstStep.options }]);
  };

  const handleUserInput = () => {
    if (userInput.trim()) {
      handleUserResponse(currentQuestionId, userInput);
      setChatHistory([
        ...chatHistory,
        { text: userInput, options: [] },
      ]);
      setUserInput('');
      setWaitingForInput(false);
      setIsTextInputVisible(false);
      const nextId = currentQuestionId + 1;
      setCurrentQuestionId(nextId); // Proceed to next question
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const currentQuestion = chatFlow[language].find((q) => q.id === currentQuestionId);

  const renderChatHistory = () => {
    return chatHistory.map((entry, index) => (
      <div key={index} style={{ marginBottom: '20px', display: 'flex', position: 'relative' }}>
        <img
          src="https://drive.google.com/thumbnail?id=1i-IeMQwo6SuFbpXJMm36kRYYPlpXiPgZ&sz=w1000" // Replace with the path to your profile image
          alt="Profile"
          style={{
            width: '42px',
            height: '42px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
        <div
          style={{
            background: '#ffffff',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '10px',
            fontFamily: 'Helvetica Neue, Arial, sans-serif',
            flex: 1,
            fontSize: `${textSize}px`,
          }}
        >
          {(Array.isArray(entry.text) ? entry.text : [entry.text]).map((line, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
          ))}
          {isVoiceEnabled && (
            <img
              src="https://drive.google.com/thumbnail?id=1LvMyD0PAujX1pQwdLMHuRYzzuqfQNMO6&sz=w100" // Replace with the path to your replay icon
              alt="Replay"
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                cursor: 'pointer',
              }}
              onClick={() => readOutLoud(entry.text)}
            />
          )}
        </div>
      </div>
    ));
  };

  return (
    <div
      style={{
        maxWidth: '600px', // Limit maximum width for larger screens
        width: '90%', // Take 90% of the viewport width for smaller screens
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        height: '100vh', // Full height for better mobile experience
        overflow: 'hidden', // Prevent overflow
      }}
    >
      <img
        src="https://i0.wp.com/nurturcare.com/wp-content/uploads/2024/08/Purple_Horiz.png?resize=300%2C109&ssl=1" // Replace with the path to your header image
        alt="Header"
        style={{ width: '100%', maxWidth: '150px', marginBottom: '10px' }}
      />

      {/* Circular Buttons with Icons */}
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', position: 'relative' }}>
        <button
          onClick={() => setIsVoiceEnabled((prev) => !prev)}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: isVoiceEnabled ? '#ffcf4f' : '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1RGuggUvWKHIt17pkhGKhUKq4svDn4h74&sz=w1000"} alt="Enable Voice" style={{ width: '42px', height: '42px' }} />
        </button>
        <button
          onClick={() => setShowLanguageDropdown((prev) => !prev)}
          style={{
            width: '42px',
            height: '42px',
            borderRadius: '50%',
            background: showLanguageDropdown ? '#ffffff' : '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1bZ5pXYyWu2DnYpkQYbo6Uq8unzB3kFJY&sz=w1000"} alt="Select Language" style={{ width: '42px', height: '42px' }} />
        </button>

        {showLanguageDropdown && (
          <div style={{ position: 'absolute', top: '60px', left: '60px', background: '#FFCF4F', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
            <select
              onChange={(e) => {
                setLanguage(e.target.value);
                setShowLanguageDropdown(false);
              }}
              value={language}
              style={{ padding: '10px', width: '100%' }}
            >
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="zh-TW">繁體中文</option>
            </select>
          </div>
        )}
        {isVoiceEnabled && (
          <>
            <button
              onClick={() => setShowVoiceDropdown((prev) => !prev)}
              style={{
                width: '42px',
                height: '42px',
                borderRadius: '50%',
                background: showVoiceDropdown ? '#ffffff' : '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1AEKIKhs6RffndnEqh7sUKP5nDutOZ9hs&sz=w1000"} alt="Select Voice" style={{ width: '42px', height: '42px' }} />
            </button>

            {showVoiceDropdown && (
              <div style={{ position: 'absolute', top: '60px', left: '120px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                <select
                  onChange={(e) => {
                    setSelectedVoice(voices.find((voice) => voice.name === e.target.value));
                    setShowVoiceDropdown(false);
                  }}
                  value={selectedVoice ? selectedVoice.name : ''}
                  style={{ padding: '10px', width: '100%' }}
                >
                  {voices.map((voice) => (
                    <option key={voice.name} value={voice.name}>
                      {voice.name} ({voice.lang})
                    </option>
                  ))}
                </select>
              </div>
            )}
            <button
              onClick={() => setShowSpeedDropdown((prev) => !prev)}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: showSpeedDropdown ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1zs6tHOt-qfOCvoYBJhbiDi5z2cLy5dsT&sz=w1000"} alt="Adjust Speed" style={{ width: '42px', height: '42px' }} />
            </button>

            {showSpeedDropdown && (
              <div style={{ position: 'absolute', top: '60px', left: '180px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                <select
                  onChange={(e) => {
                    setSpeed(parseFloat(e.target.value));
                    setShowSpeedDropdown(false);
                  }}
                  value={speed}
                  style={{ padding: '10px', width: '100%' }}
                >
                  <option value="5">5x</option>          
                  <option value="4">4x</option>
                  <option value="3">3x</option>
                  <option value="2">2x</option>
                  <option value="0.5">0.5x</option>          
                  <option value="0.7">0.7x</option>
                  <option value="0.8">0.8x</option>
                  <option value="0.9">0.9x</option>
                  <option value="1">1x</option>
                </select>
              </div>
            )}
          </>
        )}
        <button
          onClick={() => setIsPaused((prev) => !prev)}
          style={{
            width: '42px',
            height: '42px',
            borderRadius: '50%',
            background: isPaused ? '#ffffff' : '#ffcf4f',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1OMK2EFI_9qgQqG1ffdowYYRBYwJDErc6&sz=w1000"} alt="Pause/Play Voice" style={{ width: '42px', height: '42px' }} />
        </button>

        <button
          onClick={() => setTextSize((prev) => prev + 1)}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=14pZEsXniPPn1rgajSdY9GT5Cx_IonPYd&sz=w1000"} alt="Increase Text Size" style={{ width: '42px', height: '42px' }} />
        </button>

        <button
          onClick={() => setTextSize((prev) => prev - 1)}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=15NdJsuPm_aFSSAp-EEtu5c7-Af-w9Yji&sz=w1000"} alt="Decrease Text Size" style={{ width: '42px', height: '42px' }} />
        </button>

      </div>

      <div
        ref={chatWindowRef}
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          border: '1px solid #ccc',
          borderRadius: '8px',
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: '#D0D5FF',
        }}
      >
        <div style={{ flex: 1, padding: '10px', backgroundColor: '#D0D5FF', borderRadius: '8px' }}>
          {renderChatHistory()}
        </div>
      </div>

      {currentQuestion ? (
        <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {currentQuestion.options.map((option, idx) => (
            <button
              key={idx}
              onClick={() => handleOptionClick(option.nextId, option.text)}
              style={{
                borderRadius: '8px',
                border: '1px solid #ddd',
                padding: '10px',
                background: '#7B7FFF',
                color: '#fff',
                cursor: 'pointer',
                fontFamily: 'Helvetica Neue, Arial, sans-serif',
                flex: '1 1 auto',
              }}
            >
              {option.text}
            </button>
          ))}
        </div>
      ) : (
        <button
          onClick={startChat}
          style={{
            marginTop: '10px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            padding: '10px',
            background: '#7B7FFF',
            color: '#fff',
            cursor: 'pointer',
            fontFamily: 'Helvetica Neue, Arial, sans-serif',
          }}
        >
          Start Chat
        </button>
      )}

      {isTextInputVisible && (
        <div style={{ marginTop: '20px', width: '100%' }}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '100%',
              fontSize: '16px',
            }}
          />
          <button
            onClick={handleUserInput}
            style={{
              marginTop: '10px',
              padding: '10px',
              borderRadius: '4px',
              background: '#7B7FFF',
              color: '#fff',
              cursor: 'pointer',
              width: '100%',
              fontFamily: 'Helvetica Neue, Arial, sans-serif',
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default GuestChatBot;

// Define the chatbot flow with multiple languages
const chatFlow = {
    en: [
      {
        id: 1,
        text: [
          'Hello, I’m Nayomi.',
          'Welcome to the nurtur platform! We understand the unique challenges that come with being a mom-to-be and our platform is designed to support you through your pregnancy journey by providing a clinically proven protocol tailored to your needs.',
        ],
        options: [
          { text: 'Next', nextId: 2 }
        ],
      },
      {
        id: 2,
        text: 'By completing all the modules, you’ll be equipped with valuable insights and tools to navigate the complexities of the postpartum period with greater ease. Our program is based on a protocol proven to prevent postpartum depression by up to 53%.',
        options: [
          { text: 'Next', nextId: 3 }
        ],
      },
      {
        id: 3,
        text: 'Your well-being is our priority, so please don’t hesitate to reach out if you have any questions or need further assistance along the way. Let’s embark on this journey together and empower you to prioritize self-care while nurturing your growing family.',
        options: [
          { text: 'Next', nextId: 4 }
        ],
      },
      {
        id: 4,
        text: 'Let me know when you’re ready to get started.',
        options: [{ text: 'Next', nextId: 5 }],
      },
      {
        id: 5,
        text: [
          'Before we start today’s session, please let me ask a couple of questions to help me better understand how to better help you with any issues.',
          'Over the last 24 hours, how often have you been bothered by having little interest or pleasure in doing things?',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 6 },{ text: '1', nextId: 6 },{ text: '2', nextId: 6 },{ text: '3', nextId: 6 }],
      },
      {
        id: 6,
        text: [
          'Over the last 24 hours, how often have you been bothered by feeling down, depressed, or hopeless?',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 7 },{ text: '1', nextId: 7 },{ text: '2', nextId: 7 },{ text: '3', nextId: 7 }],
      },
      {
      id: 10,
        text: [
          'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
          'Little interest or pleasure in doing things? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 11 },{ text: '1', nextId: 11 },{ text: '2', nextId: 11 },{ text: '3', nextId: 11 }],
      },
      {
      id: 11,
        text: [
          'Feeling down, depressed, or hopeless? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 12 },{ text: '1', nextId: 12 },{ text: '2', nextId: 12 },{ text: '3', nextId: 12 }],
      },                        
      {
      id: 12,
        text: [
          'Trouble falling or staying asleep, or sleeping too much? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 13 },{ text: '1', nextId: 13 },{ text: '2', nextId: 13 },{ text: '3', nextId: 13 }],
      },
      {
      id: 13,
        text: [
          'Feeling tired or having little energy? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 14 },{ text: '1', nextId: 14 },{ text: '2', nextId: 14 },{ text: '3', nextId: 14 }],
      },
      {
      id: 14,
        text: [
          'Poor appetite or overeating? On a scale of 0 to 3:',
          'On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 15 },{ text: '1', nextId: 15 },{ text: '2', nextId: 15 },{ text: '3', nextId: 15 }],
      },
      {
      id: 15,
        text: [
          'Feeling bad about yourself or that you are a failure or have let yourself or your family down? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 16 },{ text: '1', nextId: 16 },{ text: '2', nextId: 16 },{ text: '3', nextId: 16 }],
      },  
      {
      id: 16,
        text: [
          'Trouble concentrating on things, such as reading the newspaper or watching television? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 17 },{ text: '1', nextId: 17 },{ text: '2', nextId: 17 },{ text: '3', nextId: 17 }],
      },
      {
      id: 17,
        text: [
          'Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 18 },{ text: '1', nextId: 18 },{ text: '2', nextId: 18 },{ text: '3', nextId: 18 }],
      },
      {
      id: 18,
        text: [
          'Thoughts that you would be better off dead, or of hurting yourself? On a scale of 0 to 3:',
          '0 - Not at all.',
          '1 - Some of the time.',
          '2 - Half of the time.',
          '3 - Most of the time.'
        ],
        options: [{ text: '0', nextId: 19 },{ text: '1', nextId: 19 },{ text: '2', nextId: 19 },{ text: '3', nextId: 19 }],
      },
      {
      id: 19,
        text: [
          'The survey has detected high levels of stress. Please know that help is here for you. Call or text the National Maternal Mental Health Hotline at 1-833-TLC-MAMA (1-833-852-6262) before continuing with the session.'
        ],
        options: [{ text: 'OK', nextId: 47 }],
      },
        {
      id: 47,
        text: [
          'Have you contacted the listed phone number?'
        ],
        options: [{ text: 'Yes', nextId: 7 },{ text: 'No', nextId: 19 }],
      },
              {
        id: 7,
        text: [
          'Thank you. Let’s get started with today’s session.',
          'Hello, I’m Nayomi, your virtual prenatal educator here. Congratulations on your pregnancy!',
        ],
        options: [{ text: 'Yes', nextId: 8 }],
      },
                  {
      id: 8,
        text: [
          'I’m here to support you through the ROSE Program, which stands for Reaching Out to Social Support and Education. It’s a series of sessions designed to teach you skills to manage stress, especially after your baby arrives.',
        ],
        options: [{ text: 'OK', nextId: 20 }],
      },
  {
    id:20,text: ['Having a baby is a joyful time, but it can also bring new challenges. We want to help you get a good start for your baby and yourself. It may be helpful to set aside a specific time every day to complete these sessions.',
  'Before we begin, please limit any distractions to stay focused. On average, each session will take between 10 to 15 minutes so you can plan accordingly.',],
  options:[{text: 'OK', nextId: 21 }],
},    
  {
    id:21,text:[ 'Select next when you’re ready to continue.',],options:[{text: 'NEXT', nextId: 22 }],
},
  
  {id:22,text: ['Women are not all the same. Some women are overjoyed at having a baby and will say they have never been happier. Others may have mixed feelings- at times they feel “so happy’” and at other times “so overwhelmed.” And for some women it is very stressful. However, no-one talks about how hard it can be - the changes you will need to make and that becoming a parent can be one of the hardest jobs in your life - a job that comes with very little training.',],
  options:[{text: 'Next', nextId: 23}],},
  
  {id:23,text: ['If you think about it realistically - you have to put your own needs aside, you have to feed, rock, change diapers, clothes, not to mention the regular chores of a home - plus all your other roles as a partner, daughter, friend, worker. All this while you are exhausted from sleep deprivation and your body, and your hormones have gone haywire. Sounds like a form of torture - actually sleep deprivation is a well-known form of torture.What has happened to this wonderful time! No one talks about how difficult it can be. New mothers feel guilty to complain as they have the picture-perfect image of the new mother with her baby smiling filled with joy and energy.',],
  options:[{text: 'OK', nextId: 24}],},
  
  {id:24,text:['Did your mother ever share with you what it was like the first couple of months of having you as a baby?',],
  options:[{ text: 'Yes', nextId: 25 },{ text: 'No', nextId: 36 }],},
  
  {id:36,text:['That is normal and many women tend to not share that phase of their life based on how difficult it might has been.',
  'It is true that some women do not have a hard time, but most women if you ask lots of questions, will tell you of some difficulties during the first three months. I am going to share with you some realities - not to scare you, but to prepare you, and to make sure that you do not feel guilty, and blame yourself should you struggle once you have your baby.',
  'If you are unprepared, you may become alarmed, and this could increase your negative feelings. The more you know the better off you will be.'],options:[{ text: 'OK', nextId: 26 }],},
  
  {id:37,text:['It’s great to hear that you’ve had a relatively stable emotional journey during your pregnancy. Everyone’s experience is unique, and it’s wonderful that you’ve been feeling steady throughout this time. If you have any questions or need support at any point, feel free to reach out.',
  'Do you know the signs of depression?',],
  options:[{ text: 'Next', nextId: 38 }],},
  {id:38,text: ['It can be helpful for everyone to know the signs, whether they’ve experienced it personally or not. If you ever want to learn more about the signs or have any concerns about your mental health or that of someone else, feel free to ask. I’m here to provide information and support whenever you need it. Now, we’ll delve into postpartum depression, which is a more serious condition. New moms must be aware of this.',],options:[{ text: 'Next', nextId: 39 }],},
  
  {id:39,text: ['After the birth, postpartum depression is usually slow and gradual. It usually happens within the first two months, and you may start to feel you cannot take care of your baby in the right way, you think you are not a good mother, you feel badly about yourself, you feel a burden to others. There is significant difficulty in the ability to care for your baby, care for yourself, and cope with family relationships.',],options:[{ text: 'Next', nextId: 40 }],},
  {id:40,text:['About 10-15% of new mothers show symptoms of postpartum depression. This means one in seven women will experience postpartum depression. For women who are on public assistance or struggle financially it is more common: about one in three such women will experience postpartum depression. Women who have had previous episodes of depression are more likely to experience depression after childbirth. Those women who have had postpartum depression are also more likely to experience it again.',]
  ,options:[{ text: 'Next', nextId: 41 }],},
  {id:41,text:['The symptoms can begin gradually, but they can often build leaving a new mom feeling overwhelmed, hopeless, or frustrated. Some common thoughts may be “I cannot take care of my baby in the right way, I am not a good mother, I am a burden to others.',]
  ,options:[{ text: 'Next', nextId: 42 }],},
  {id:42,text:['While baby blues are temporary, postpartum depression is characterized by persistent negative emotions and a diminished ability to function. Symptoms typically last for at least two weeks.',],options:[{ text: 'Next', nextId: 43 }],},
  {id:43,text: ['Some common signs include feeling overwhelmed, hopeless, or guilty about caring for your baby. You might also experience difficulty sleeping or eating, or a loss of interest in activities you once enjoyed.',]
  ,options:[{ text: 'Next', nextId: 44 }],},
  {id:44,text:['It can be scary, but the important message is that postpartum depression is treatable. If you experience these symptoms, please reach out to your healthcare provider immediately.',]
  ,options:[{ text: 'Next', nextId: 31 }],},
  {id:25,text: ['I’m so glad to hear that your mom shared that with you.',
  'It is true that some women do not have a hard time, but most women if you ask lots of questions, will tell you of some difficulties during the first three months. I am going to share with you some realities - not to scare you, but to prepare you, and to make sure that you do not feel guilty, and blame yourself should you struggle once you have your baby. If you are unprepared, you may become alarmed, and this could increase your negative feelings. The more you know the better off you will be.',],options:[{ text: 'OK', nextId: 26 }],},
  {id:26,text: ['Let’s discuss the range of postpartum “blues”. Women do feel differently in that time after the birth of their baby. For some it is worse than for others, and lasts longer. As we discussed earlier, some women fall in love immediately with their baby are overjoyed and remain overjoyed, while others may feel very overwhelmed.',],options:[{ text: 'Next', nextId: 27 }],},
  {id:27,text: ['Most new mothers (about 30-80%) have the “baby blues.” Baby blues is usually two to five days after childbirth. You may experience periods of weeping for no reason, mood swings, be very sensitive, feel overwhelmed, irritable, and just plain exhausted. It is a very emotional time and you are caught off guard. You think I have a beautiful, healthy baby how can I feel like crying? This phase usually passes in a few days to a few weeks, as you and your body adjusts to your new situation. Usually, it lasts about 10 days.',],options:[{ text: 'Next', nextId: 28 }],},
  {id:28,text: ['Have you experienced any emotional changes during your pregnancy so far?',],options:[{ text: 'Yes', nextId: 29 },{ text: 'No', nextId: 37 }],},
  {id:29,text: ['Its natural you are feeling this and thanks for sharing. Now let’s talk about Postpartum Depression. Some new mothers have a more upsetting time and the difficulties we have discussed are more long-lasting and more intense. Postpartum depression is when the symptoms persist, nearly every day and there is no relief for at least two weeks.',
  'Do you know the signs of depression?',],options:[{ text: 'Yes', nextId: 30 },{ text: 'No', nextId: 38 }],},
  {id:30,text: ['Great to hear! Knowing the signs of depression can be crucial for understanding your mental health and seeking support when needed. If you have any questions about the signs or want to discuss them further, feel free to ask. I’m here to provide information and support. It’s great you’re aware of your emotions. We’ll discuss coping mechanisms for emotional changes in future sessions.',],options:[{ text: 'Next', nextId: 31 }],},
  {id:31,text: ['Before we wrap up this session, do you understand the difference between baby blues and postpartum depression?',],options:[{ text: 'Yes', nextId: 32 },{ text: 'No', nextId: 45 }],},
  {id:32,text: ['Great job! If you have any questions about the differences between baby blues and postpartum depression, feel free to ask. I’m here to help clarify any concerns you might have. As a reminder here are the symptoms you should look out for:',
  'BABY BLUES - 30-80% of people experience Baby Blues It usually occurs 2-5 days after delivery and usually goes away after about two weeks. Some symptoms are',
  'CryingMood swings',
  'Exhaustion',
  'Tension',
  'Anxiety',
  'Restlessness',],options:[{ text: 'Next', nextId: 33 }],},
  
  {id:33,text:['POSTPARTUM DEPRESSION SESSION Sleep problems (example: you cannot return to sleep after feeding the baby)',
  
  'Eating problems-eating too much or too little',
  'Anxiety and worryAvoiding people, avoiding contact with the baby, wanting to be on your own',
  'No energy',
  'Death wish, suicidal thoughts',
  'Difficulty having positive feelings towards the baby',
  'Difficulty making decisions',
  'Mania-feeling speedy, being excitable and irritable, talking fast, and having less need for sleep',
  'Panic attacks',
  'Fears for the baby, fantasies about harming or killing the baby',],options:[{ text: 'Next', nextId: 34 }],},
  {id:34,text: ['If you ever feel that you might hurt yourself, your baby or anyone else, please talk to your healthcare provider or call 911.',],options:[{ text: 'Next', nextId: 35 }],},
  {id:35,text: ['In the next session, we will help you understand postpartum depression risk factors, symptoms, and treatment options.',
  'Emphasizing normalcy of feelings, importance of seeking help, and strategies for coping and seeking support during the transition to motherhood.',],options:[{ text: 'Next', nextId: 48 }],},
  
  {id:45,text: ['No worries. Let’s quickly review it. The distinction between baby blues and postpartum depression lies in their duration, severity, and impact on daily functioning. Baby blues are typically mild, transient feelings of sadness and mood swings that occur within the first few weeks after childbirth and often resolve on their own.',]
  ,options:[{ text: 'OK', nextId: 46 }],},
  {id:46,text: ['On the other hand, postpartum depression is a more serious and longer-lasting condition characterized by persistent feelings of sadness, hopelessness, and anxiety that can significantly interfere with a mother’s ability to care for herself and her baby. It’s essential to recognize the signs and seek support if you or someone you know may be experiencing postpartum depression. Here is a list that could be helpful.',],options:[{ text: 'OK', nextId: 32 }]},
  
  
      ],
    es: [
            {
               "id":1,
               "text":[
                  "Hola, soy Nayomi.",
                  "¡Bienvenida a la plataforma nurtur! Entendemos los desafíos únicos que vienen con ser una futura mamá y nuestra plataforma está diseñada para apoyarte durante tu viaje de embarazo proporcionando un protocolo clínicamente probado adaptado a tus necesidades."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":2
                  }
               ]
            },
            {
               "id":2,
               "text":"Al completar todos los módulos, estarás equipada con valiosos conocimientos y herramientas para navegar las complejidades del período postparto con mayor facilidad. Nuestro programa se basa en un protocolo probado para prevenir la depresión postparto hasta en un 53%.",
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":3
                  }
               ]
            },
            {
               "id":3,
               "text":"Tu bienestar es nuestra prioridad, así que no dudes en comunicarte si tienes alguna pregunta o necesitas más ayuda en el camino. Emprendamos juntos este viaje y empoderémonos para priorizar el autocuidado mientras cuidas de tu creciente familia.",
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":4
                  }
               ]
            },
            {
               "id":4,
               "text":"Déjame saber cuando estés lista para empezar.",
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":5
                  }
               ]
            },
            {
               "id":5,
               "text":[
                  "Antes de empezar la sesión de hoy, permíteme hacerte un par de preguntas para entender mejor cómo ayudarte con cualquier problema.",
                  "En las últimas 24 horas, ¿con qué frecuencia te has sentido molesta por tener poco interés o placer en hacer cosas?",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":6
                  },
                  {
                     "text":"1",
                     "nextId":6
                  },
                  {
                     "text":"2",
                     "nextId":6
                  },
                  {
                     "text":"3",
                     "nextId":6
                  }
               ]
            },
            {
               "id":6,
               "text":[
                  "En las últimas 24 horas, ¿con qué frecuencia te has sentido deprimida, triste o sin esperanzas?",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":7
                  },
                  {
                     "text":"1",
                     "nextId":7
                  },
                  {
                     "text":"2",
                     "nextId":7
                  },
                  {
                     "text":"3",
                     "nextId":7
                  }
               ]
            },
            {
               "id":10,
               "text":[
                  "En las últimas 2 semanas, ¿con qué frecuencia te han molestado los siguientes problemas?",
                  "Poco interés o placer en hacer cosas? En una escala del 0 al 3:",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":11
                  },
                  {
                     "text":"1",
                     "nextId":11
                  },
                  {
                     "text":"2",
                     "nextId":11
                  },
                  {
                     "text":"3",
                     "nextId":11
                  }
               ]
            },
            {
               "id":11,
               "text":[
                  "Sentirse deprimida, triste o sin esperanzas? En una escala del 0 al 3:",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":12
                  },
                  {
                     "text":"1",
                     "nextId":12
                  },
                  {
                     "text":"2",
                     "nextId":12
                  },
                  {
                     "text":"3",
                     "nextId":12
                  }
               ]
            },
            {
               "id":12,
               "text":[
                  "Problemas para dormir o dormir demasiado? En una escala del 0 al 3:",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":13
                  },
                  {
                     "text":"1",
                     "nextId":13
                  },
                  {
                     "text":"2",
                     "nextId":13
                  },
                  {
                     "text":"3",
                     "nextId":13
                  }
               ]
            },
            {
               "id":13,
               "text":[
                  "Sentirse cansada o tener poca energía? En una escala del 0 al 3:",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":14
                  },
                  {
                     "text":"1",
                     "nextId":14
                  },
                  {
                     "text":"2",
                     "nextId":14
                  },
                  {
                     "text":"3",
                     "nextId":14
                  }
               ]
            },
            {
               "id":14,
               "text":[
                  "Poco apetito o comer en exceso? En una escala del 0 al 3:",
                  "En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":15
                  },
                  {
                     "text":"1",
                     "nextId":15
                  },
                  {
                     "text":"2",
                     "nextId":15
                  },
                  {
                     "text":"3",
                     "nextId":15
                  }
               ]
            },
            {
               "id":15,
               "text":[
                  "Sentirse mal contigo misma o que has fallado a ti misma o a tu familia? En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":16
                  },
                  {
                     "text":"1",
                     "nextId":16
                  },
                  {
                     "text":"2",
                     "nextId":16
                  },
                  {
                     "text":"3",
                     "nextId":16
                  }
               ]
            },
            {
               "id":16,
               "text":[
                  "Problemas para concentrarte en cosas como leer el periódico o ver televisión? En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":17
                  },
                  {
                     "text":"1",
                     "nextId":17
                  },
                  {
                     "text":"2",
                     "nextId":17
                  },
                  {
                     "text":"3",
                     "nextId":17
                  }
               ]
            },
            {
               "id":17,
               "text":[
                  "Moverse o hablar tan lentamente que otras personas podrían haberlo notado. O lo contrario, estar tan inquieta o inquieta que has estado moviéndote mucho más de lo usual? En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":18
                  },
                  {
                     "text":"1",
                     "nextId":18
                  },
                  {
                     "text":"2",
                     "nextId":18
                  },
                  {
                     "text":"3",
                     "nextId":18
                  }
               ]
            },
            {
               "id":18,
               "text":[
                  "Pensamientos de que estarías mejor muerta o de lastimarte a ti misma? En una escala del 0 al 3:",
                  "0 - Para nada.",
                  "1 - Parte del tiempo.",
                  "2 - La mitad del tiempo.",
                  "3 - La mayor parte del tiempo."
               ],
               "options":[
                  {
                     "text":"0",
                     "nextId":19
                  },
                  {
                     "text":"1",
                     "nextId":19
                  },
                  {
                     "text":"2",
                     "nextId":19
                  },
                  {
                     "text":"3",
                     "nextId":19
                  }
               ]
            },
            {
               "id":19,
               "text":[
                  "La encuesta ha detectado altos niveles de estrés. Por favor, sepa que hay ayuda disponible para ti. Llama o envía un mensaje de texto a la Línea Nacional de Salud Mental Materna al 1-833-TLC-MAMA (1-833-852-6262) antes de continuar con la sesión."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":47
                  }
               ]
            },
            {
               "id":47,
               "text":[
                  "¿Has contactado el número de teléfono listado?"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":7
                  },
                  {
                     "text":"No",
                     "nextId":19
                  }
               ]
            },
            {
               "id":7,
               "text":[
                  "Gracias. Empecemos con la sesión de hoy.",
                  "Hola, soy Nayomi, tu educadora prenatal virtual aquí. ¡Felicidades por tu embarazo!"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":8
                  }
               ]
            },
            {
               "id":8,
               "text":[
                  "Estoy aquí para apoyarte a través del Programa ROSE, que significa Reaching Out to Social Support and Education. Es una serie de sesiones diseñadas para enseñarte habilidades para manejar el estrés, especialmente después de que llegue tu bebé."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":20
                  }
               ]
            },
            {
               "id":20,
               "text":[
                  "Tener un bebé es un momento de alegría, pero también puede traer nuevos desafíos. Queremos ayudarte a empezar bien para tu bebé y para ti. Puede ser útil reservar un tiempo específico cada día para completar estas sesiones.",
                  "Antes de comenzar, limita cualquier distracción para mantenerte concentrada. En promedio, cada sesión tomará entre 10 a 15 minutos, así que puedes planificar en consecuencia."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":21
                  }
               ]
            },
            {
               "id":21,
               "text":[
                  "Selecciona siguiente cuando estés lista para continuar."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":22
                  }
               ]
            },
            {
               "id":22,
               "text":[
                  "Las mujeres no son todas iguales. Algunas mujeres están muy felices de tener un bebé y dirán que nunca han sido más felices. Otras pueden tener sentimientos encontrados - a veces se sienten “muy felices” y otras veces “muy abrumadas”. Y para algunas mujeres es muy estresante. Sin embargo, nadie habla de lo difícil que puede ser - los cambios que tendrás que hacer y que convertirse en madre puede ser uno de los trabajos más difíciles de tu vida - un trabajo que viene con muy poca capacitación."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":23
                  }
               ]
            },
            {
               "id":23,
               "text":[
                  "Si lo piensas de manera realista, tienes que dejar de lado tus propias necesidades, tienes que alimentar, acunar, cambiar pañales, ropa, sin mencionar las tareas domésticas regulares - además de todos tus otros roles como pareja, hija, amiga, trabajadora. Todo esto mientras estás agotada por la falta de sueño y tu cuerpo, y tus hormonas se han vuelto locas. Suena como una forma de tortura, de hecho, la privación del sueño es una forma de tortura bien conocida. ¿Qué ha pasado con este tiempo maravilloso? Nadie habla de lo difícil que puede ser. Las nuevas madres se sienten culpables de quejarse porque tienen la imagen perfecta de la nueva madre con su bebé sonriendo llena de alegría y energía."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":24
                  }
               ]
            },
            {
               "id":24,
               "text":[
                  "¿Tu madre alguna vez te contó cómo fueron los primeros meses de tenerte como bebé?"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":25
                  },
                  {
                     "text":"No",
                     "nextId":36
                  }
               ]
            },
            {
               "id":36,
               "text":[
                  "Eso es normal y muchas mujeres tienden a no compartir esa fase de su vida en función de lo difícil que pudo haber sido.",
                  "Es cierto que algunas mujeres no tienen un tiempo difícil, pero la mayoría de las mujeres si les haces muchas preguntas, te contarán algunas dificultades durante los primeros tres meses. Voy a compartir contigo algunas realidades - no para asustarte, sino para prepararte, y para asegurarte de que no te sientas culpable y te culpes a ti misma si luchas una vez que tengas a tu bebé.",
                  "Si no estás preparada, puedes alarmarte y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":26
                  }
               ]
            },
            {
               "id":37,
               "text":[
                  "Es genial escuchar que has tenido un viaje emocional relativamente estable durante tu embarazo. La experiencia de cada persona es única, y es maravilloso que te hayas sentido estable durante este tiempo. Si tienes alguna pregunta o necesitas apoyo en cualquier momento, no dudes en comunicarte.",
                  "¿Conoces los signos de la depresión?"
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":38
                  }
               ]
            },
            {
               "id":38,
               "text":[
                  "Puede ser útil para todos conocer los signos, ya sea que lo hayan experimentado personalmente o no. Si alguna vez quieres aprender más sobre los signos o tienes alguna preocupación sobre tu salud mental o la de otra persona, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo cuando lo necesites. Ahora, profundizaremos en la depresión postparto, que es una condición más grave. Es importante que las nuevas mamás estén conscientes de esto."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":39
                  }
               ]
            },
            {
               "id":39,
               "text":[
                  "Después del nacimiento, la depresión postparto suele ser lenta y gradual. Generalmente ocurre dentro de los primeros dos meses, y puedes comenzar a sentir que no puedes cuidar a tu bebé de la manera correcta, piensas que no eres una buena madre, te sientes mal contigo misma, te sientes una carga para los demás. Hay una dificultad significativa en la capacidad de cuidar a tu bebé, cuidarte a ti misma y lidiar con las relaciones familiares."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":40
                  }
               ]
            },
            {
               "id":40,
               "text":[
                  "Aproximadamente el 10-15% de las nuevas madres muestran síntomas de depresión postparto. Esto significa que una de cada siete mujeres experimentará depresión postparto. Para las mujeres que reciben asistencia pública o tienen dificultades financieras, es más común: aproximadamente una de cada tres de estas mujeres experimentará depresión postparto. Las mujeres que han tenido episodios previos de depresión tienen más probabilidades de experimentar depresión después del parto. Aquellas mujeres que han tenido depresión postparto también tienen más probabilidades de experimentarla nuevamente."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":41
                  }
               ]
            },
            {
               "id":41,
               "text":[
                  "Los síntomas pueden comenzar gradualmente, pero a menudo pueden aumentar dejando a una nueva madre sintiéndose abrumada, sin esperanza o frustrada. Algunos pensamientos comunes pueden ser “No puedo cuidar a mi bebé de la manera correcta, no soy una buena madre, soy una carga para los demás."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":42
                  }
               ]
            },
            {
               "id":42,
               "text":[
                  "Mientras que los baby blues son temporales, la depresión postparto se caracteriza por emociones negativas persistentes y una capacidad disminuida para funcionar. Los síntomas generalmente duran al menos dos semanas."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":43
                  }
               ]
            },
            {
               "id":43,
               "text":[
                  "Algunos signos comunes incluyen sentirse abrumada, sin esperanza o culpable por cuidar a tu bebé. También podrías experimentar dificultades para dormir o comer, o una pérdida de interés en actividades que antes disfrutabas."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":44
                  }
               ]
            },
            {
               "id":44,
               "text":[
                  "Puede dar miedo, pero el mensaje importante es que la depresión postparto es tratable. Si experimentas estos síntomas, por favor comunícate con tu proveedor de atención médica de inmediato."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":31
                  }
               ]
            },
            {
               "id":25,
               "text":[
                  "Me alegra escuchar que tu madre compartió eso contigo.",
                  "Es cierto que algunas mujeres no tienen un tiempo difícil, pero la mayoría de las mujeres si les haces muchas preguntas, te contarán algunas dificultades durante los primeros tres meses. Voy a compartir contigo algunas realidades - no para asustarte, sino para prepararte, y para asegurarte de que no te sientas culpable y te culpes a ti misma si luchas una vez que tengas a tu bebé. Si no estás preparada, puedes alarmarte y esto podría aumentar tus sentimientos negativos. Cuanto más sepas, mejor estarás."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":26
                  }
               ]
            },
            {
               "id":26,
               "text":[
                  "Hablemos del rango de “baby blues”. Las mujeres se sienten diferentes en ese tiempo después del nacimiento de su bebé. Para algunas es peor que para otras y dura más. Como discutimos antes, algunas mujeres se enamoran inmediatamente de su bebé y están muy felices, mientras que otras pueden sentirse muy abrumadas."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":27
                  }
               ]
            },
            {
               "id":27,
               "text":[
                  "La mayoría de las nuevas madres (aproximadamente el 30-80%) tienen los “baby blues”. Los baby blues suelen aparecer entre dos a cinco días después del parto. Puedes experimentar períodos de llanto sin razón, cambios de humor, ser muy sensible, sentirte abrumada, irritable y simplemente agotada. Es un momento muy emocional y te toma por sorpresa. Piensas que tienes un bebé hermoso y saludable, ¿cómo puedo sentir ganas de llorar? Esta fase suele pasar en unos días a unas semanas, a medida que tú y tu cuerpo se ajustan a tu nueva situación. Generalmente, dura alrededor de 10 días."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":28
                  }
               ]
            },
            {
               "id":28,
               "text":[
                  "¿Has experimentado algún cambio emocional durante tu embarazo hasta ahora?"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":29
                  },
                  {
                     "text":"No",
                     "nextId":37
                  }
               ]
            },
            {
               "id":29,
               "text":[
                  "Es natural que te sientas así y gracias por compartir. Ahora hablemos de la Depresión Postparto. Algunas nuevas madres tienen un tiempo más perturbador y las dificultades que hemos discutido son más duraderas y más intensas. La depresión postparto es cuando los síntomas persisten, casi todos los días y no hay alivio durante al menos dos semanas.",
                  "¿Conoces los signos de la depresión?"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":30
                  },
                  {
                     "text":"No",
                     "nextId":38
                  }
               ]
            },
            {
               "id":30,
               "text":[
                  "¡Me alegra escucharlo! Conocer los signos de la depresión puede ser crucial para entender tu salud mental y buscar apoyo cuando sea necesario. Si tienes alguna pregunta sobre los signos o quieres discutirlos más a fondo, no dudes en preguntar. Estoy aquí para proporcionar información y apoyo. Es genial que estés consciente de tus emociones. Discutiremos mecanismos de afrontamiento para los cambios emocionales en futuras sesiones."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":31
                  }
               ]
            },
            {
               "id":31,
               "text":[
                  "Antes de terminar esta sesión, ¿entiendes la diferencia entre los baby blues y la depresión postparto?"
               ],
               "options":[
                  {
                     "text":"Sí",
                     "nextId":32
                  },
                  {
                     "text":"No",
                     "nextId":45
                  }
               ]
            },
            {
               "id":32,
               "text":[
                  "¡Buen trabajo! Si tienes alguna pregunta sobre las diferencias entre los baby blues y la depresión postparto, no dudes en preguntar. Estoy aquí para ayudar a aclarar cualquier preocupación que puedas tener. Como recordatorio, aquí están los síntomas que debes vigilar:",
                  "BABY BLUES - El 30-80% de las personas experimentan Baby Blues. Generalmente ocurre de 2 a 5 días después del parto y generalmente desaparece después de unas dos semanas. Algunos síntomas son:",
                  "Llanto, Cambios de humor",
                  "Agotamiento",
                  "Tensión",
                  "Ansiedad",
                  "Inquietud"
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":33
                  }
               ]
            },
            {
               "id":33,
               "text":[
                  "DEPRESIÓN POSTPARTO - Problemas para dormir (ejemplo: no puedes volver a dormir después de alimentar al bebé)",
                  "Problemas para comer - comer demasiado o muy poco",
                  "Ansiedad y preocupación, Evitar a las personas, evitar el contacto con el bebé, querer estar sola",
                  "Sin energía",
                  "Deseo de muerte, pensamientos suicidas",
                  "Dificultad para tener sentimientos positivos hacia el bebé",
                  "Dificultad para tomar decisiones",
                  "Manía - sentirse acelerada, estar excitable e irritable, hablar rápido y necesitar menos sueño",
                  "Ataques de pánico",
                  "Miedos por el bebé, fantasías de hacerle daño o matar al bebé"
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":34
                  }
               ]
            },
            {
               "id":34,
               "text":[
                  "Si alguna vez sientes que podrías lastimarte a ti misma, a tu bebé o a alguien más, por favor habla con tu proveedor de atención médica o llama al 911."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":35
                  }
               ]
            },
            {
               "id":35,
               "text":[
                  "En la próxima sesión, te ayudaremos a entender los factores de riesgo, síntomas y opciones de tratamiento para la depresión postparto.",
                  "Enfatizando la normalidad de los sentimientos, la importancia de buscar ayuda y estrategias para afrontarlos y buscar apoyo durante la transición a la maternidad."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":48
                  }
               ]
            },
            {
               "id":45,
               "text":[
                  "No te preocupes. Vamos a revisarlo rápidamente. La distinción entre baby blues y la depresión postparto radica en su duración, severidad e impacto en el funcionamiento diario. Los baby blues son sentimientos leves y transitorios de tristeza y cambios de humor que ocurren dentro de las primeras semanas después del parto y a menudo se resuelven por sí solos."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":46
                  }
               ]
            },
            {
               "id":46,
               "text":[
                  "Por otro lado, la depresión postparto es una condición más grave y duradera caracterizada por sentimientos persistentes de tristeza, desesperanza y ansiedad que pueden interferir significativamente en la capacidad de una madre para cuidarse a sí misma y a su bebé. Es esencial reconocer los signos y buscar apoyo si tú o alguien que conoces puede estar experimentando depresión postparto. Aquí hay una lista que podría ser útil."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":32
                  }
               ]
            },
            {
               "id":48,
               "text":[
                  "Presiona OK si deseas proceder a la sesión donde repasaremos ejercicios de relajación y transiciones de roles."
               ],
               "options":[
                  {
                     "text":"Siguiente",
                     "nextId":49
                  },
                  {
                     "text":"Repetir Sesión 1",
                     "nextId":1
                  }
               ]
            },
      
    ],
    'zh-TW': [
  {
    id: 1,
    text: [
      '你好，我是 Nayomi。',
      '歡迎來到 nurtur 平台！我們了解即將成為媽媽的獨特挑戰，我們的平臺旨在通過提供經臨床證實的專屬方案來支持你的孕期旅程，以滿足你的需求。',
    ],
    options: [
      { text: '下一步', nextId: 2 }
    ],
  },
  {
    id: 2,
    text: '完成所有模組後，你將獲得有價值的見解和工具，以更輕鬆地應對產後的複雜問題。我們的計劃基於一個經證實能降低產後憂鬱症風險高達 53% 的方案。',
    options: [
      { text: '下一步', nextId: 3 }
    ],
  },
  {
    id: 3,
    text: '你的福祉是我們的首要任務，所以如果你有任何問題或需要進一步的協助，請隨時聯繫我們。讓我們一起踏上這段旅程，幫助你優先考慮自我照顧的同時，養育你不斷成長的家庭。',
    options: [
      { text: '下一步', nextId: 4 }
    ],
  },
  {
    id: 4,
    text: '當你準備好開始時，請告訴我。',
    options: [{ text: '下一步', nextId: 5 }],
  },
  {
    id: 5,
    text: [
      '在我們開始今天的會話之前，請允許我問幾個問題，以幫助我更好地了解如何更有效地協助你。',
      '在過去的 24 小時內，你有多少次因為對做事情缺乏興趣或快樂而感到困擾？',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 6 }, { text: '1', nextId: 6 }, { text: '2', nextId: 6 }, { text: '3', nextId: 6 }],
  },
  {
    id: 6,
    text: [
      '在過去的 24 小時內，你有多少次因為感到沮喪、憂鬱或絕望而感到困擾？',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 7 }, { text: '1', nextId: 7 }, { text: '2', nextId: 7 }, { text: '3', nextId: 7 }],
  },
  {
    id: 10,
    text: [
      '在過去的 2 週內，你有多少次因為以下問題而感到困擾？',
      '對做事情缺乏興趣或快樂？在 0 到 3 的刻度上：',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 11 }, { text: '1', nextId: 11 }, { text: '2', nextId: 11 }, { text: '3', nextId: 11 }],
  },
  {
    id: 11,
    text: [
      '感到沮喪、憂鬱或絕望？在 0 到 3 的刻度上：',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 12 }, { text: '1', nextId: 12 }, { text: '2', nextId: 12 }, { text: '3', nextId: 12 }],
  },
  {
    id: 12,
    text: [
      '入睡或保持睡眠困難，或過度睡眠？在 0 到 3 的刻度上：',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 13 }, { text: '1', nextId: 13 }, { text: '2', nextId: 13 }, { text: '3', nextId: 13 }],
  },
  {
    id: 13,
    text: [
      '感到疲倦或精力不足？在 0 到 3 的刻度上：',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 14 }, { text: '1', nextId: 14 }, { text: '2', nextId: 14 }, { text: '3', nextId: 14 }],
  },
  {
    id: 14,
    text: [
      '食慾不振或暴飲暴食？在 0 到 3 的刻度上：',
      '在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 15 }, { text: '1', nextId: 15 }, { text: '2', nextId: 15 }, { text: '3', nextId: 15 }],
  },
  {
    id: 15,
    text: [
      '對自己感到難過，或覺得自己是個失敗者，或讓自己或家人失望？在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 16 }, { text: '1', nextId: 16 }, { text: '2', nextId: 16 }, { text: '3', nextId: 16 }],
  },
  {
    id: 16,
    text: [
      '專注於事情困難，例如閱讀報紙或看電視？在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 17 }, { text: '1', nextId: 17 }, { text: '2', nextId: 17 }, { text: '3', nextId: 17 }],
  },
  {
    id: 17,
    text: [
      '行動或說話緩慢到讓其他人注意到。或者，表現得過於煩躁或不安，比平時活動更多？在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 18 }, { text: '1', nextId: 18 }, { text: '2', nextId: 18 }, { text: '3', nextId: 18 }],
  },
  {
    id: 18,
    text: [
      '是否有想過自己死去會更好，或是傷害自己？在 0 到 3 的刻度上：',
      '0 - 完全沒有。',
      '1 - 有時。',
      '2 - 一半時間。',
      '3 - 大部分時間。'
    ],
    options: [{ text: '0', nextId: 19 }, { text: '1', nextId: 19 }, { text: '2', nextId: 19 }, { text: '3', nextId: 19 }],
  },
  {
    id: 19,
    text: [
      '調查顯示你有高水平的壓力。請知道我們在這裡提供幫助。在繼續進行會話之前，請撥打或發送簡訊至國家母嬰心理健康熱線 1-833-TLC-MAMA (1-833-852-6262)。'
    ],
    options: [{ text: '確定', nextId: 47 }],
  },
  {
    id: 47,
    text: [
      '你是否已經聯絡了列出的電話號碼？'
    ],
    options: [{ text: '是', nextId: 7 }, { text: '否', nextId: 19 }],
  },
  {
    id: 7,
    text: [
      '謝謝你。我們來開始今天的會話吧。',
      '你好，我是 Nayomi，你的虛擬產前教育者。恭喜你懷孕了！',
    ],
    options: [{ text: '是', nextId: 8 }],
  },
  {
    id: 8,
    text: [
      '我在這裡支持你參加 ROSE 計劃，即「Reach Out to Social Support and Education」的縮寫。這是一系列旨在教你管理壓力技巧的課程，尤其是在你的寶寶出生後。',
    ],
    options: [{ text: '確定', nextId: 20 }],
  },
  {
    id: 20,
    text: [
      '擁有寶寶是快樂的時刻，但也可能帶來新的挑戰。我們希望幫助你和你的寶寶有一個良好的開始。每天預留一段時間來完成這些課程可能會很有幫助。',
      '在我們開始之前，請盡量避免任何干擾以保持專注。每次課程大約需要 10 到 15 分鐘，所以你可以根據這個時間來安排。',
    ],
    options: [{ text: '確定', nextId: 21 }],
  },
  {
    id: 21,
    text: [
      '當你準備好繼續時，請選擇「下一步」。',
    ],
    options: [{ text: '下一步', nextId: 22 }],
  },
  {
    id: 22,
    text: [
      '每位女性的經歷都不同。有些女性對擁有寶寶感到非常高興，會說她們從未如此幸福。另一些則可能有複雜的感受—有時感覺「非常快樂」，有時卻「感到壓倒性」。對於某些女性來說，這是一種非常有壓力的經歷。然而，沒有人談論這段時間的困難——你需要做出的改變以及成為父母可能是你人生中最艱難的工作之一——這是一份幾乎沒有任何訓練的工作。',
    ],
    options: [{ text: '下一步', nextId: 23 }],
  },
  {
    id: 23,
    text: [
      '如果你從現實角度來看，你必須把自己的需求放在一邊，你需要餵養、搖晃、換尿布、換衣服，更不用說家裡的日常工作——還有你作為伴侶、女兒、朋友和工作的其他角色。這一切都在你因為缺乏睡眠而感到疲憊不堪，你的身體和荷爾蒙也失去了平衡。聽起來像是一種折磨——其實，缺乏睡眠是眾所周知的一種折磨。這段美好的時光怎麼了！沒有人談論這段經歷的困難。新媽媽感到抱歉抱怨，因為她們有著完美的新媽媽形象，和寶寶一起微笑，充滿喜悅和精力。',
    ],
    options: [{ text: '確定', nextId: 24 }],
  },
  {
    id: 24,
    text: [
      '你的媽媽曾經告訴過你她剛有你的那幾個月的情況嗎？',
    ],
    options: [{ text: '是', nextId: 25 }, { text: '否', nextId: 36 }],
  },
  {
    id: 36,
    text: [
      '這是正常的，許多女性因為這段時期可能非常困難而選擇不分享。',
      '確實有些女性不會遇到困難，但如果你問很多問題，大多數女性會告訴你她們在前三個月中的一些困難。我將與你分享一些現實——不是為了嚇唬你，而是為了讓你做好準備，並確保你在寶寶出生後如果遇到困難，不會感到內疚和自責。',
      '如果你沒有準備好，可能會感到驚慌，這會增加你的負面情緒。了解得越多，你會越好。',
    ],
    options: [{ text: '確定', nextId: 26 }],
  },
  {
    id: 37,
    text: [
      '聽到你在懷孕期間情緒相對穩定的消息真是太好了。每個人的經歷都是獨特的，很高興你在這段時間感到穩定。如果你有任何問題或需要支持，隨時可以聯繫我們。',
      '你知道抑鬱症的症狀嗎？',
    ],
    options: [{ text: '下一步', nextId: 38 }],
  },
  {
    id: 38,
    text: [
      '了解症狀對每個人來說都很有幫助，無論他們是否親身經歷過。如果你想了解更多症狀或對自己的心理健康或他人的心理健康有任何擔憂，隨時可以詢問。我在這裡隨時提供資訊和支持。現在，我們將深入探討產後抑鬱症，這是一種更嚴重的情況。新媽媽必須意識到這一點。',
    ],
    options: [{ text: '下一步', nextId: 39 }],
  },
  {
    id: 39,
    text: [
      '在分娩後，產後抑鬱症通常是緩慢而漸進的。它通常發生在頭兩個月內，你可能會開始感覺無法以正確的方式照顧你的寶寶，覺得自己不是一個好媽媽，對自己感到不好，覺得自己是別人的負擔。在照顧寶寶、照顧自己和應對家庭關係方面存在顯著困難。',
    ],
    options: [{ text: '下一步', nextId: 40 }],
  },
  {
    id: 40,
    text: [
      '大約 10-15% 的新媽媽會出現產後抑鬱症的症狀。這意味著每七位女性中就有一位會經歷產後抑鬱症。對於接受公共援助或經濟困難的女性來說，這種情況更為普遍：約三分之一的此類女性會經歷產後抑鬱症。曾經有抑鬱症發作的女性在分娩後更容易經歷抑鬱症。曾經有產後抑鬱症的女性也更可能再次經歷。',
    ],
    options: [{ text: '下一步', nextId: 41 }],
  },
  {
    id: 41,
    text: [
      '症狀可能會逐漸開始，但往往會累積，讓新媽媽感到不堪重負、絕望或挫敗。一些常見的想法可能是「我無法以正確的方式照顧寶寶，我不是一個好媽媽，我是別人的負擔。」',
    ],
    options: [{ text: '下一步', nextId: 42 }],
  },
  {
    id: 42,
    text: [
      '雖然嬰兒藍調是暫時的，但產後抑鬱症的特徵是持續的負面情緒和減少的功能能力。症狀通常持續至少兩週。',
    ],
    options: [{ text: '下一步', nextId: 43 }],
  },
  {
    id: 43,
    text: [
      '一些常見的徵兆包括感到不堪重負、絕望或對照顧寶寶感到內疚。你也可能會經歷睡眠或飲食困難，或對曾經喜愛的活動失去興趣。',
    ],
    options: [{ text: '下一步', nextId: 44 }],
  },
  {
    id: 44,
    text: [
      '這可能很可怕，但重要的信息是產後抑鬱症是可以治療的。如果你經歷這些症狀，請立即聯繫你的醫療提供者。',
    ],
    options: [{ text: '下一步', nextId: 31 }],
  },
  {
    id: 25,
    text: [
      '我很高興聽到你的媽媽跟你分享了這些。',
      '確實有些女性不會遇到困難，但如果你問很多問題，大多數女性會告訴你她們在前三個月中的一些困難。我將與你分享一些現實——不是為了嚇唬你，而是為了讓你做好準備，並確保你在寶寶出生後如果遇到困難，不會感到內疚和自責。如果你沒有準備好，可能會感到驚慌，這會增加你的負面情緒。了解得越多，你會越好。',
    ],
    options: [{ text: '確定', nextId: 26 }],
  },
  {
    id: 26,
    text: [
      '讓我們來討論產後“藍調”的範圍。女性在寶寶出生後的這段時間感覺會有所不同。對某些人來說，比其他人更糟，持續的時間也更長。正如我們之前所討論的，有些女性會立刻愛上她們的寶寶，感到欣喜若狂並持續快樂，而另一些女性可能會感到非常壓倒。',
    ],
    options: [{ text: '下一步', nextId: 27 }],
  },
  {
    id: 27,
    text: [
      '大多數新媽媽（約 30-80%）會出現“嬰兒藍調”。嬰兒藍調通常發生在分娩後的兩到五天內。你可能會經歷無緣無故的哭泣、情緒波動、過度敏感、感到壓倒、易怒和疲憊不堪。這是一個非常情緒化的時期，你會感到措手不及。你會想，我有一個美麗、健康的寶寶，我怎麼會感覺想哭？這一階段通常會在幾天到幾週內過去，因為你和你的身體在適應新情況。通常，它會持續約 10 天。',
    ],
    options: [{ text: '下一步', nextId: 28 }],
  },
  {
    id: 28,
    text: [
      '你在懷孕期間是否經歷過情緒變化？',
    ],
    options: [{ text: '是', nextId: 29 }, { text: '否', nextId: 37 }],
  },
  {
    id: 29,
    text: [
      '你感受到這些情緒變化是很自然的，謝謝你分享。現在讓我們來談談產後抑鬱症。一些新媽媽經歷了更令人沮喪的時期，這些困難比我們討論的更持久和更強烈。產後抑鬱症是指症狀持續出現，幾乎每天都有，並且至少有兩週沒有緩解。',
      '你知道抑鬱症的徵兆嗎？',
    ],
    options: [{ text: '是', nextId: 30 }, { text: '否', nextId: 38 }],
  },
  {id:30,text: ['太好了！了解抑鬱的徵兆對於了解你的心理健康和在需要時尋求支持非常重要。如果你對這些徵兆有任何疑問或想進一步討論，隨時詢問。我在這裡提供信息和支持。很高興你對自己的情緒有認識。我們會在未來的課程中討論應對情緒變化的機制。'],options:[{ text: '下一步', nextId: 31 }],},
  
  {id:31,text: ['在我們結束這一節之前，你是否了解「產後憂鬱症」和「產後藍調」的區別？'],options:[{ text: '是', nextId: 32 },{ text: '否', nextId: 45 }],},
  
  {id:32,text: ['做得好！如果你對「產後憂鬱症」和「產後藍調」之間的區別有任何疑問，隨時詢問。我在這裡幫助澄清任何你可能有的疑慮。作為提醒，以下是你應該留意的徵兆：',
  '產後藍調 - 30-80% 的人會經歷產後藍調。它通常在分娩後的 2-5 天出現，通常在大約兩週後消失。部分症狀包括：',
  '哭泣',
  '情緒波動',
  '疲憊',
  '緊張',
  '焦慮',
  '不安'],options:[{ text: '下一步', nextId: 33 }],},
  
  {id:33,text: ['產後憂鬱症徵兆：睡眠問題（例如：餵完奶後無法重新入睡）',
  '飲食問題 - 吃得太多或太少',
  '焦慮和擔憂',
  '避免與人接觸，避免接觸寶寶，想要獨處',
  '沒有精力',
  '死亡念頭，自殺想法',
  '對寶寶的積極情感困難',
  '做決定困難',
  '躁狂 - 感覺迅速，容易激動和易怒，說話快，睡眠需求減少',
  '驚恐發作',
  '對寶寶的擔憂，幻想傷害或殺死寶寶'],options:[{ text: '下一步', nextId: 34 }],},
  
  {id:34,text: ['如果你感覺自己可能會傷害自己、寶寶或其他人，請立即聯繫你的醫療提供者或撥打 911。'],options:[{ text: '下一步', nextId: 35 }],},
  
  {id:35,text: ['在下一節課中，我們將幫助你了解產後憂鬱症的風險因素、症狀和治療選擇。',
  '強調情感的正常性，尋求幫助的重要性，以及在過渡到母職階段時的應對策略和尋求支持的方式。'],options:[{ text: '下一步', nextId: 48 }],},
  
  {id:45,text: ['不用擔心。我們來快速回顧一下。「產後藍調」和「產後憂鬱症」的區別在於它們的持續時間、嚴重程度和對日常功能的影響。「產後藍調」通常是輕微的、短暫的悲傷和情緒波動，發生在分娩後的頭幾週內，通常會自行消失。'],options:[{ text: '確定', nextId: 46 }],},
  
  {id:46,text: ['另一方面，「產後憂鬱症」是一種更為嚴重且持續時間更長的狀況，特徵是持續的悲傷、絕望和焦慮感，這些會顯著干擾母親照顧自己和寶寶的能力。識別徵兆並尋求支持非常重要，如果你或你認識的人可能經歷產後憂鬱症，請尋求幫助。以下是可能有用的列表。'],options:[{ text: '確定', nextId: 32 }]},
  
    
  
  
    ]
  };
