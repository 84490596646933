export const SURVEY_QUESTIONS = [
    {
      key: "q1",
      text: 'In the past 7 days I have been able to laugh and see the funny side of things',
      answers: ['As much as I always could', 'Not quite so much now', 'Definitely not so much now', 'Not at all'],
    },
    {
      key: "q2",
      text: 'In the past 7 days I have looked forward with enjoyment to things',
      answers: ['As much as I ever did', 'Rather less than I used to', 'Definitely less than I used to', 'Hardly at all'],
    },
    {
        key: "q3",
      text: 'In the past 7 days I have blamed myself unnecessarily when things went wrong',
      answers: ['No, never', 'Not very often', 'Yes, some of the time', 'Yes, most of the time'],
    },
    {
        key: "q4",
      text: 'In the past 7 days I have been anxious or worried for no good reason',
      answers: ['No, not at all', 'Hardly ever', 'Yes, sometimes', 'Yes, very often'],
    },
    {
        key: "q5",
      text: 'In the past 7 days I have felt scared or panicky for no very good reason',
      answers: ['No, not at all', 'No, not much', 'Yes, sometimes', 'Yes, quite a lot'],
    },
    {
        key: "q6",
      text: 'In the past 7 days things have been getting on top of me',
      answers: ['No, I have been coping as well as ever', 'No, most of the time I have coped quite well', 'Yes, sometimes I have not been coping as well as usual', 'Yes, most of the time I have not been able to cope at all'],
    },
    {
        key: "q7",
      text: 'In the past 7 days I have been so unhappy that I have had difficulty sleeping',
      answers: ['No, not at all', 'Not very often', 'Yes, sometimes', 'Yes, most of the time'],
    },
    {
        key: "q8",
      text: 'In the past 7 days I have felt sad or miserable',
      answers: ['No, not at all', 'Not very often', 'Yes, quite often', 'Yes, most of the time'],
    },
    {
        key: "q9",
      text: 'In the past 7 days I have been so unhappy that I have been crying',
      answers: ['No, never', 'Only occasionally', 'Yes, quite often', 'Yes, most of the time'],
    },
    {
        key: "q10",
      text: 'In the past 7 days the thought of harming myself has occurred to me',
      answers: ['Never', 'Hardly ever', 'Sometimes', 'Yes, quite often'],
    },
  ];

export const MEDICAL_INSURANCE_LIST = [
    "Blue Cross Blue Shield of Massachusetts",
    "Harvard Pilgrim Health Care",
    "Tufts Health Plan",
    "Fallon Health",
    "Health New England",
    "AllWays Health Partners (formerly Neighborhood Health Plan)",
    "BMC HealthNet Plan",
    "Celticare Health Plan",
    "Aetna",
    "UnitedHealthcare",
    "MassHealth",
    "WellSense Health Plan (formerly Boston Medical Center HealthNet Plan)",
    "UnitedHealth Group",
    "Anthem/Elevance Health",
    "Aetna (part of CVS Health)",
    "Cigna",
    "Humana",
    "Kaiser Permanente",
    "Blue Cross Blue Shield Association companies",
    "Centene Corporation",
    "Molina Healthcare",
    "Oscar Health",
    "Bright Health",
    "Ambetter",
    "CareSource",
    "Harvard Pilgrim Health Care",
    "Health Net",
    "HealthPartners",
    "Highmark",
    "Independence Blue Cross",
    "Premera Blue Cross",
    "WellCare",
    "Medicare",
    "Medicare Advantage",
    "Medicaid",
    "Anthem (CA, CO, CT, GA, IN, KY, ME, MO, NE, NH, NY, OH, VA, WI)",
    "Care First Blue Cross Blue Shield MD, DC and Delaware",
    "BC ID",
    "Health Care Services Corp: Blue Cross Blue Shield IL, MT, NM, OK, TX",
    "Beacon",
    "Blue Cross Blue Shield of Florida",
    "Blue Cross Blue Shield of Massachusetts",
    "Blue Cross Blue Shield of Michigan",
    "Blue Cross Blue Shield of Minnesota",
    "Blue Cross Blue Shield of North Carolina",
    "Blue Cross Blue Shield of North Dakota",
    "Blue Cross Blue Shield of New Jersey",
    "Blue Cross Blue Shield of South Carolina",
    "Emblem GHI PPO",
    "Fidelis (Commercial and public sector)",
    "HealthPartners (MN)",
    "Humana",
    "Magellan",
    "Moda Health",
    "PreferredOne",
    "Qualcare",
    "Regence Blue Shield of ID, OR, UT, WA",
    "Tricare",
    "UCare",
    "UnitedHealthCare/Optum",
    "WellCare Health Plans, Inc",
]