// Table for displaying the provider dashboard
import React, { useState } from 'react';

const Table = ({ data }) => {
  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState(null);

  const columns = [
    { Header: 'First Name', accessor: 'firstName'},
    { Header: 'Last Name', accessor: 'lastName'},
    { Header: 'Email', accessor: 'email'},
    { Header: 'Birthdate (MM/DD/YYY)', accessor: 'bday'},
    { Header: 'Height (ft./in.)', accessor: 'height'},
    { Header: 'Weight (lbs.)', accessor: 'weight'},
    { Header: 'Patient\'s Phone #', accessor: 'patientPhone'},
    { Header: 'OBGYN or PCP', accessor: 'obgyn'},
    { Header: 'Doctor\'s Phone #', accessor: 'doctorPhone'},
    { Header: 'EPDS Score', accessor: 'epds'},
    { Header: 'Due Date', accessor: 'dueDate'},
  ]

  // Sorting function
  const sortData = (key) => {
    let sortedDataCopy = [...sortedData];
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      sortedDataCopy.reverse();
      setSortConfig({ key, direction: 'descending' });
    } else {
      sortedDataCopy.sort((a, b) => {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      });
      setSortConfig({ key, direction: 'ascending' });
    }
    setSortedData(sortedDataCopy);
  };

  return (
    <table>
      <thead>
        {columns.map(item => (
          <th onClick={() => sortData(item.accessor)}><div style={{width: 150, height: 100, justify_content: "center"}}>{item.Header}</div></th>
          ))}
      </thead>
      <tbody>
        {sortedData.map((item, index) => (
          <tr key={index} style={{justify_content: "center"}}>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.email}</td>
            <td>{item.bday}</td>
            <td>{item.height}</td>
            <td>{item.weight}</td>
            <td>{item.patientPhone}</td>
            <td>{item.obgyn}</td>
            <td>{item.doctorPhone}</td>
            <td>{item.epds}</td>
            <td>{item.dueDate}</td>
            {/* Render other columns as needed */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;