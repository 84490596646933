export default class Urls {
    static LOGIN = '/login';
    static SEND_VERIFICATION_EMAIL = '/send-verification-email';
    static LOGOUT = '/logout';
    static REGISTER = '/register';
    static CURRENT_USER = '/users/me';
    static CLINIC = '/clinic';
    static EPDS = '/epds';
    static CREATE_EPDS = '/epds/create';
    static UPDATE_EPDS = '/epds/update';
    static GET_CHAT = '/chat';
    static CREATE_CHAT = '/chat/create';
    static UPDATE_CHAT = '/chat/update';
    static SEND_SMS = '/send-sms';
    static SEND = '/send';
}