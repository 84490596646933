// Page where first-time users get directed to register and complete EPDS survey

import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import {Input} from '../../components/ui/Input';
import {
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../../components/MainStyles";
import { Formik, Form } from "formik";
import { useNavigate } from 'react-router-dom';

// Define EPDS survey questions and answer options
const questions = [
  {
    text: 'In the past 7 days I have been able to laugh and see the funny side of things',
    answers: ['As much as I always could', 'Not quite so much now', 'Definitely not so much now', 'Not at all'],
  },
  {
    text: 'In the past 7 days I have looked forward with enjoyment to things',
    answers: ['As much as I ever did', 'Rather less than I used to', 'Definitely less than I used to', 'Hardly at all'],
  },
  {
    text: 'In the past 7 days I have blamed myself unnecessarily when things went wrong',
    answers: ['No, never', 'Not very often', 'Yes, some of the time', 'Yes, most of the time'],
  },
  {
    text: 'In the past 7 days I have been anxious or worried for no good reason',
    answers: ['No, not at all', 'Hardly ever', 'Yes, sometimes', 'Yes, very often'],
  },
  {
    text: 'In the past 7 days I have felt scared or panicky for no very good reason',
    answers: ['No, not at all', 'No, not much', 'Yes, sometimes', 'Yes, quite a lot'],
  },
  {
    text: 'In the past 7 days things have been getting on top of me',
    answers: ['No, I have been coping as well as ever', 'No, most of the time I have coped quite well', 'Yes, sometimes I have not been coping as well as usual', 'Yes, most of the time I have not been able to cope at all'],
  },
  {
    text: 'In the past 7 days I have been so unhappy that I have had difficulty sleeping',
    answers: ['No, not at all', 'Not very often', 'Yes, sometimes', 'Yes, most of the time'],
  },
  {
    text: 'In the past 7 days I have felt sad or miserable',
    answers: ['No, not at all', 'Not very often', 'Yes, quite often', 'Yes, most of the time'],
  },
  {
    text: 'In the past 7 days I have been so unhappy that I have been crying',
    answers: ['No, never', 'Only occasionally', 'Yes, quite often', 'Yes, most of the time'],
  },
  {
    text: 'In the past 7 days the thought of harming myself has occurred to me',
    answers: ['Never', 'Hardly ever', 'Sometimes', 'Yes, quite often'],
  },
];

const RadioButton = styled.label`
  display: inline-block;
  background-color: #ededff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 10px;
  width: 95%;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 4px;
  &:hover {
    background-color: #aaacff;
  }
  input {
    display: none;
  }
`;

// TODO: Add validation for onboarding information
const validate = values => {
  const errors = {};
  return errors;
};

const Epds = () => {
  const navigate = useNavigate();
  const [currentInfo, setCurrentInfo] = useState(0);
  const location = useLocation();
  const email  = location.state || {};

  console.log(email)


  // // TODO: update with healthcare plan and phone number fields
  // const [infoList, setInfoList] = useState([
  //   { question: 'First Name', answer: '' },
  //   { question: 'Last Name', answer: '' },
  //   { question: 'State', answer: '' },
  //   { question: 'Doctor\'s Name', answer: '' },
  // ]);
  

  // Onboarding info to collect - these fields aren't currently used
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [state, setState] = useState('');
  // const [doctorName, setDoctorName] = useState('');
  // useEffect(() => {
  //   // setFirstName(infoList[0].answer);
  // }, [infoList]);
  
  const [EPDS_score, setEPDS_score] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [infoComplete, setInfoComplete] = useState(false);

  const handleScoreChange = (event) => {
    setEPDS_score(prevScore => prevScore + parseInt(event.target.value));
    setCurrentQuestion(prevQuestion => prevQuestion + 1);
  };

  return (
    <div style={{ background: "#ffffff", minHeight: "100vh", position: 'relative' }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5vh",
        }}>
        {/* Deending on the status of infoComplete the user is presented either with the 
        registration questionnaire (if False) or the EPDS survey (if True).
        This ensures the sequential presentations of these two surveys. */}
        { (
          // EPDS survey
        <LoginForm>
          <MainTitle>Onboarding Survey</MainTitle>
          {currentQuestion < questions.length ? (
            questions[currentQuestion] && (
              <div key={currentQuestion}>
                <SmallText>{questions[currentQuestion].text}</SmallText>
                {questions[currentQuestion].answers.map((answer, i) => (
                  <RadioButton key={i}>
                    <input type="radio" id={`option${i}`} name={`question${currentQuestion}`} value={i} onChange={handleScoreChange}/>
                    {answer}
                  </RadioButton>
                ))}
              </div>
            )
          ) : (
            
            // Logic to determine if the user should be directed to the chat page or to seek professional help
            <div>
              <SmallText>Thank you for completing the survey!</SmallText>
              {EPDS_score < 13 ? (<SubmitButton theme="blue"><Link to="/chat">Continue to Chat</Link></SubmitButton>
)
              :
              <SmallText>
                Based on your score, we recommend that you seek professional help. 
                Please contact your healthcare provider for further assistance. 
                Or access the following help lines! <span style={{ fontWeight: 'bold' }}>Postpartum Support International HelpLine: 1-800-944-4773</span> (available for calls and texts).
                Support for new and expecting parents struggling with perinatal mental health concerns. 
                <span style={{ fontWeight: 'bold' }}>National Maternal Mental Health Hotline: 1-833-TLC-MAMA (1-833-852-6262).</span> 24/7 emotional support, resources, and counseling for pregnant and postpartum individuals.
              </SmallText>                      
                }
            </div>
          )}</LoginForm>) }
      </div>
    </div>
  );
};

export default Epds;
