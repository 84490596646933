// Styles for the Provider Dashboard
import styled from "styled-components";

export const OthersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
  padding-bottom: 30em;
  padding-top: 10em;
  padding-left: 0.5em;
  background:linear-gradient(#aaacff, #fde992);
  max-width: 100%;
  overflow-x: auto;
`;

export const TableStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.3em;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 1.2rem;
  max-width: 100%;
  overflow-x: auto;
  text-align: left;
  border-collapse: collapse;
  width: 100%;
`;

export const Header = styled.h1`
  text-color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
`;

export const TableCell = styled.td`
  padding: 1.3em;
  border: 1px solid #ddd; /* Add this line if you want borders */
`;

export const TableHeader = styled.th`
  padding: 1.3em;
  border: 1px solid #ddd; /* Add this line if you want borders */
`;