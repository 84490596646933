// Desc: Enter verification code for users with accounts to verify their login attempt

import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { Input } from "../components/ui/Input";
import {
  FormContainer,
  LoginForm,
  MainTitle,
  SubmitButton,
  SmallText,
} from "../components/MainStyles";
import logo from "../nurtur_new_logo.svg";

// TODO: implement numerical constraint
const validate = values => {
  const errors = {};

  if (!values.code) {
    errors.code = 'Required';
  } else if (values.code.length < 6) {
    errors.code = 'Code must be 6 digits';
  }

  return errors;
};

const VerifCode = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ position: 'relative' }}>
        <FormContainer>
          <img
            src={logo}
            alt="nurtur logo"
            className="logo"
            style={{ borderRadius: "10px" }}
          />
          <LoginForm>
            <MainTitle>Enter Code</MainTitle>
            <SmallText>
              <Link to="/verify" style={{ fontWeight: "bold" }}>
                Get new code
              </Link>
            </SmallText>

            <Formik
            initialValues={{ code: '' }}
            validate={validate}
            onSubmit={values => {
              // TODO: implement login functionality here
              console.log(values);
              navigate('/survey');
            }}>
              <Form>
            <Input id="Code" type="code" label="Code" name="code" />
            <SubmitButton theme="blue" type="submit">Verify</SubmitButton>
            </Form>
          </Formik>
            <SmallText style={{
                  textDecoration: "underline",
                }}>
                    Can't receive code?</SmallText>
          </LoginForm>
        </FormContainer>
      </div>
    </>
  );
};

export default VerifCode;